import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";

import PlaybookSelectModalComponent
  from "@/platform-context/control-icm-management/components/CaseTypes/Details/components/TeamsAndRoles/components/modals/PlaybookSelectModal/PlaybookSelectModal.component";
import { ItemProps } from "@/platform-context/control-icm-management/types/CaseTypes/caseTypeTeamsAndRoles";

type Props = {
  cancelButtonHandler: () => void
  onSubmit: (data: FieldValues) => void
  playbookSelectOptions: ItemProps[]
}

const PlaybookSelectModal: FC<Props>= ({
  cancelButtonHandler,
  onSubmit,
  playbookSelectOptions
}) => {

  const formControls = useForm({ mode: 'onChange' });

  const { watch, setValue,  reset } = formControls;

  const [activePlaybookItem, setActivePlaybookItem] = useState<string>();

  const handleSetActivePlaybookItem = useCallback((val: string) => {
    setActivePlaybookItem(val);
    reset();
  },[reset]);

  const tasks = useMemo(() => {
    return playbookSelectOptions?.find((option) => option.value === activePlaybookItem)?.tasks || [];
  },[activePlaybookItem, playbookSelectOptions]);

  const watchSelectAll = watch('selectAll');

  useEffect(() => {
    if(watchSelectAll){
      tasks.forEach((task) => setValue(`${activePlaybookItem}.${task._id}`, true));
    } else {
      tasks.forEach((task) => setValue(`${activePlaybookItem}.${task._id}`, false));
    }
  }, [activePlaybookItem, setValue, tasks, watchSelectAll]);


  useEffect(() => {
    setValue('playbookId', activePlaybookItem);
  }, [activePlaybookItem, formControls, setValue]);

  return (
    <PlaybookSelectModalComponent
      formControls={ formControls }
      cancelButtonHandler={ cancelButtonHandler }
      onSubmit={ onSubmit }
      selectOptions={ playbookSelectOptions }
      activePlaybookItem={ activePlaybookItem }
      handleSetActivePlaybookItem={ handleSetActivePlaybookItem }
      tasks={ tasks }
    />
  );
};

export default PlaybookSelectModal;