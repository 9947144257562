
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  ToggleableContextLayers
} from "@/common/components/ToggleableLayer/ToggleableLayer.config";
import usePermission from "@/common/hooks/usePermission";
import { CorePolicies } from "@/core/config/CorePolicies.config";
import { Dispatch, RootState } from "@/core/store";

import ContextualLayers from "./ContextualLayers.component";

const ContextualLayersContainer = () => {
  const isTimeTravelActive = useSelector((state: RootState) => state.rankingSettings.isTimeTravelActive);
  const contextualLayersOptions = useSelector((state: RootState) => state.dataOptions.contextLayerOptions);
  const enabledRiskConnectorServices = useSelector((state: RootState) =>
    state.commonData?.enabledRiskConnectorServices);
  const hasRisklineCountryRiskSubscription = 'riskline-country-risk-level-connector-service' in enabledRiskConnectorServices;

  const {
    dataOptions: { ENABLE_CONTEXTUAL_LAYER_TYPE, DISABLE_CONTEXTUAL_LAYER_TYPE }
  } = useDispatch<Dispatch>();

  const isRiskConnectorsPermissionAvailable = usePermission(
    CorePolicies.RISK_CONNECTOR_POLICY
  );
  const isContextualLayersSubscribed = useSelector((state: RootState) => state.commonData.tenantSubscribedProducts)
    ?.findIndex((product) => product.name === "Contextual Map Layers") !== -1;

  // Handlers for switches
  const handleSwitchToggle = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const layerType = event.target.value as ToggleableContextLayers;
      const { checked } = event.target;

      if (checked) {
        ENABLE_CONTEXTUAL_LAYER_TYPE([layerType]);
      } else {
        DISABLE_CONTEXTUAL_LAYER_TYPE([layerType]);
      }
    },
    [DISABLE_CONTEXTUAL_LAYER_TYPE, ENABLE_CONTEXTUAL_LAYER_TYPE]
  );

  const isLayerVisible = useCallback((layerType: ToggleableContextLayers) => {
    return contextualLayersOptions[layerType];
  }, [contextualLayersOptions]);

  useEffect(() => {
    if (isTimeTravelActive) {
      DISABLE_CONTEXTUAL_LAYER_TYPE([...Object.values(ToggleableContextLayers)]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTimeTravelActive]);

  return (
    <ContextualLayers
      isLayerVisible={ isLayerVisible }
      onChangeContextualLayerSwitch={ handleSwitchToggle }
      isRiskConnectorsPermissionAvailable={ isRiskConnectorsPermissionAvailable }
      isContextualLayersSubscribed={ isContextualLayersSubscribed }
      isTimeTravelActive={ isTimeTravelActive }
      hasRisklineCountryRiskSubscription={ hasRisklineCountryRiskSubscription }
    />
  );
};

export default ContextualLayersContainer;
