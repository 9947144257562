import { callApi, TokenType } from "@/common/util/api";
import browserDatabase from "@/common/util/browser-database";
import { CONTEXT_KEY, ContextTypes } from "@/core/store/shared-data/sharedData.types";
import { SortData } from "@/tenant-context/control-groups/types/group";
import { PaginatedResult } from "@/tenant-context/control-profile/types/profile";

import {
  CaseTypeForm,
  CaseTypeItem,
  CaseTypesList, 
  ICloneCaseType } from "../../types/CaseTypes/caseTypeListOverview";

export const caseTypesListOverviewApi = {
  tokenType: browserDatabase.getItem(CONTEXT_KEY) as ContextTypes === "platform" ? "access": "tenant",
  getCaseTypesListData: function(
    page = 0,
    size = 10,
    sort?: SortData,
    query?: string,
    additionalParamsQuery?: string
  ) {
    return callApi<PaginatedResult<CaseTypesList>>(
      `managed/icm-service/v1/case-types?${sort?.colId ? 'orderBy=' + sort.colId + ':' + sort.sort + '&' : ''}size=${size}&page=${page}${query ? '&search=(' + query + ')&' : ''}${additionalParamsQuery ? `&${additionalParamsQuery}` : ''}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=ListCaseType'
        },
        method: 'get',
        tokenType: this.tokenType as TokenType,
        withTenantId: this.tokenType === "tenant"
      }
    );
  },
  saveCaseType: function(params: CaseTypeForm) {
    return callApi(
      `/managed/icm-service/v1/case-types`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=CreateCaseType'
        },
        method: 'post',
        body: JSON.stringify(params),
        tokenType: this.tokenType as TokenType,
        withTenantId: this.tokenType === "tenant"
      }
    );
  },
  updateCaseType: function(params: CaseTypeForm) {
    return callApi(
      `/managed/icm-service/v1/case-types/${params._id}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=UpdateCaseType'
        },
        method: 'post',
        body: JSON.stringify(params),
        tokenType: this.tokenType as TokenType,
        withTenantId: this.tokenType === "tenant"
      }
    );
  },
  deleteCaseType: function(caseTypeId: string){
    return callApi(
      `managed/icm-service/v1/case-types/${caseTypeId}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model'
        },
        method: 'delete',
        tokenType: this.tokenType as TokenType,
        withTenantId: this.tokenType === "tenant"
      }
    );
  },
  getCaseTypeById: function(id: string) {
    return callApi<CaseTypeItem>(
      `managed/icm-service/v1/case-types/${id}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=GetCaseType'
        },
        method: 'get',
        tokenType: this.tokenType as TokenType,
        withTenantId: this.tokenType === "tenant"
      }
    );
  },
  cloneCaseType: function(params: ICloneCaseType) {
    return callApi(
      `managed/icm-service/v1/case-types`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=CloneCaseType'
        },
        method: 'post',
        body: JSON.stringify(params),
        tokenType: this.tokenType as TokenType,
        withTenantId: this.tokenType === "tenant"
      }
    );
  }
};