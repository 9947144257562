/* eslint-disable react/jsx-no-bind,no-magic-numbers */
import { ActionIcon, Button, Modal, Radio, Tabs, Tooltip } from "@mantine/core";
import { FC } from "react";

import { ReactComponent as GearIcon } from "@/common/icons/gear.svg";
import { ReactComponent as PlusIcon } from "@/common/icons/plus.svg";
import { useCreateCommsPageStyles } from "@/tenant-context/control-mass-comms/components/CreateCommsPage/CreateCommsPage.styles";
import GenericResponseItemComponent
  from "@/tenant-context/control-mass-comms/components/CreateCommsPage/Response-Methods/GenericResponseItem.component";
import {
  useResponseMethodsStyles
} from "@/tenant-context/control-mass-comms/components/CreateCommsPage/Response-Methods/ResponseMethods.styles";
import { ReactComponent as WorkAppIcon } from "@/tenant-context/control-mass-comms/icons/app-gray.svg";
import { ReactComponent as EmailIcon } from "@/tenant-context/control-mass-comms/icons/email-gray.svg";
import { ReactComponent as PhoneIcon } from "@/tenant-context/control-mass-comms/icons/phone-gray.svg";
import { ReactComponent as MessageSmsIcon } from "@/tenant-context/control-mass-comms/icons/sms-gray.svg";
import {
  DeliveryChannel, MassCommsAppCommunicationsResponseItem,
  MassCommsEmailResponseItem, MassCommsPhoneResponseItem,
  ResponseMethod
} from "@/tenant-context/control-mass-comms/types/MassComms.types";

type Props = {
  selectedResponseMethod: ResponseMethod;
  activeResponseMethodSettings: ResponseMethod | undefined;
  deliveryChannels: DeliveryChannel;
  appCommunicationsResponseItems: Array<MassCommsAppCommunicationsResponseItem>;
  emailResponseItems: Array<MassCommsEmailResponseItem>;
  smsResponseItems: Array<MassCommsPhoneResponseItem>;
  voiceResponseItems: Array<MassCommsPhoneResponseItem>;
  pollResponses: Map<number, string>;
  isResponseItemsValid: boolean;
  onResponseMethodChanged: (responseMethod: ResponseMethod) => void;
  onResponseMethodSettingsPageRequested: (responseMethod: ResponseMethod) => void;
  onResponseMethodSettingsModalClosed: () => void;
  onAddNewResponseMethodRequested: (deliveryChannel: string) => void;
  onRemoveResponseMethodRequested: (
    deliveryChannel: string, item: MassCommsPhoneResponseItem | MassCommsEmailResponseItem
  ) => void;
  onResponseItemChanged: (
    deliveryChannel: string,
    responseItem: MassCommsEmailResponseItem & MassCommsPhoneResponseItem
  ) => void;
  onSaveSettingsRequested: () => void;
  onPollResponseChanged: (pollResponse: string, index: number) => void;
  activeTab?: string;
  onTabChange: (tab: string) => void;
}

const ResponseMethodsComponent: FC<Props> = ({
  selectedResponseMethod,
  activeResponseMethodSettings,
  deliveryChannels,
  appCommunicationsResponseItems,
  emailResponseItems,
  smsResponseItems,
  voiceResponseItems,
  pollResponses,
  isResponseItemsValid,
  onResponseMethodChanged,
  onResponseMethodSettingsPageRequested,
  onResponseMethodSettingsModalClosed,
  onAddNewResponseMethodRequested,
  onRemoveResponseMethodRequested,
  onResponseItemChanged,
  onSaveSettingsRequested,
  onPollResponseChanged,
  activeTab,
  onTabChange
}) => {

  const { classes: parentPageClasses, cx } = useCreateCommsPageStyles({ isCommsSequenceNoAvailable: true });
  const { classes } = useResponseMethodsStyles();

  const isDeliveryChannelSelected = deliveryChannels.app ||
    deliveryChannels.email || deliveryChannels.sms || deliveryChannels.voice;

  return (
    <div>
      <div className={ cx(parentPageClasses.sectionHeader, parentPageClasses.extendedMargin) }>Response Methods</div>

      <Tooltip disabled={ isDeliveryChannelSelected } label={ 'Please select delivery channels first' }>
        <Radio.Group
          value={ selectedResponseMethod }
          defaultValue={ selectedResponseMethod }
          orientation="vertical"
          className={ classes.radioGroup }
          onChange={ onResponseMethodChanged }
        >
          <div className={ classes.responseMethodItem }>
            <Radio value="NO_RESPONSE" label="No Response Required" disabled={ !isDeliveryChannelSelected } />
          </div>

          <div className={ classes.responseMethodItem }>
            <Radio value="SAFETY_POLL" label="Safety Poll" disabled={ !isDeliveryChannelSelected }/>
            { selectedResponseMethod === 'SAFETY_POLL' && (<>
              <hr/>
              <ActionIcon disabled={ !isDeliveryChannelSelected }
                onClick={ () => onResponseMethodSettingsPageRequested('SAFETY_POLL') }>
                <GearIcon />
              </ActionIcon>
            </>) }
          </div>

          <div className={ classes.responseMethodItem }>
            <Radio value="CUSTOM_POLL" label="Custom Poll" disabled={ !isDeliveryChannelSelected }/>
            { selectedResponseMethod === 'CUSTOM_POLL' && (<>
              <hr />
              <ActionIcon disabled={ !isDeliveryChannelSelected }
                onClick={ () => onResponseMethodSettingsPageRequested('CUSTOM_POLL') }>
                <GearIcon />
              </ActionIcon>
            </>) }
          </div>

          <div className={ classes.responseMethodItem }>
            <Radio value="ACKNOWLEDGE" label="Acknowledge" disabled={ !isDeliveryChannelSelected }/>
            { selectedResponseMethod === 'ACKNOWLEDGE' && (<>
              <hr />
              <ActionIcon disabled={ !isDeliveryChannelSelected }
                onClick={ () => onResponseMethodSettingsPageRequested('ACKNOWLEDGE') }>
                <GearIcon />
              </ActionIcon>
            </>) }
          </div>
        </Radio.Group>
      </Tooltip>

      <Modal
        opened={ !!activeResponseMethodSettings }
        onClose={ onResponseMethodSettingsModalClosed }
        withCloseButton={ true }
        title={ 'Poll Configuration' }
        size={ '80% !important' }
        centered>
        <div className={ classes.responseMethodModalSubtitle }>
          Ensure to instruct your recipients to respond to your communication as you have configured below.
        </div>

        <Tabs
          defaultValue={ deliveryChannels.app ? 'app' : deliveryChannels.email ? 'email' : deliveryChannels.sms ? 'sms' : 'voice' }
          onTabChange={ onTabChange }
        >
          <div>
            <Tabs.List>
              { deliveryChannels.app ? (<Tabs.Tab value="app" icon={ <WorkAppIcon /> }>Restrata App</Tabs.Tab>) : null }
              { deliveryChannels.email ? (<Tabs.Tab value="email" icon={ <EmailIcon /> }>Email</Tabs.Tab>) : null }
              { deliveryChannels.sms ? (<Tabs.Tab value="sms" icon={ <MessageSmsIcon /> }>SMS</Tabs.Tab>) : null }
              { deliveryChannels.voice ? (<Tabs.Tab value="voice" icon={ <PhoneIcon /> }>Call</Tabs.Tab>) : null }
            </Tabs.List>
          </div>

          { deliveryChannels.app ? <Tabs.Panel value="app">
            { appCommunicationsResponseItems.map((responseItem, index) => (
              <GenericResponseItemComponent
                index={ index }
                key={ index }
                deliveryChannel={ 'app' }
                responseMethod={ activeResponseMethodSettings }
                responseItem={ responseItem }
                pollResponse={ pollResponses.get(index)|| '' }
                isWithRemoveButton={ activeResponseMethodSettings === 'CUSTOM_POLL' }
                onRemoveResponseItemRequested={ () => onRemoveResponseMethodRequested('app', responseItem) }
                onResponseItemChanged={ onResponseItemChanged }
                onPollResponseChanged={ onPollResponseChanged }
                activeTab={ activeTab }
              />
            )) }

            { activeResponseMethodSettings === 'CUSTOM_POLL' && (
              <button
                className={ classes.addNewResponseItem }
                onClick={ () => onAddNewResponseMethodRequested('app') }
                disabled={ appCommunicationsResponseItems.length >= 5 }
              >
                <ActionIcon
                  variant='filled'
                  color='royalBlue'
                  disabled={ appCommunicationsResponseItems.length >= 5 }
                >
                  <PlusIcon />
                </ActionIcon>
                Add Poll Response
              </button>
            ) }

          </Tabs.Panel> : null }

          { deliveryChannels.email ? <Tabs.Panel value="email">
            { emailResponseItems.map((responseItem, index) => (
              <GenericResponseItemComponent
                index={ index }
                key={ index }
                deliveryChannel={ 'email' }
                responseMethod={ activeResponseMethodSettings }
                responseItem={ responseItem }
                pollResponse={ pollResponses.get(index)|| '' }
                isWithRemoveButton={ activeResponseMethodSettings === 'CUSTOM_POLL' }
                onRemoveResponseItemRequested={ () => onRemoveResponseMethodRequested('email', responseItem) }
                onResponseItemChanged={ onResponseItemChanged }
                onPollResponseChanged={ onPollResponseChanged }
                activeTab={ activeTab }
              />
            )) }

            { activeResponseMethodSettings === 'CUSTOM_POLL' && (
              <button
                className={ classes.addNewResponseItem }
                onClick={ () => onAddNewResponseMethodRequested('email') }
                disabled={ emailResponseItems.length >= 5 }
              >
                <ActionIcon
                  variant='filled'
                  color='royalBlue'
                  disabled={ emailResponseItems.length >= 5 }
                >
                  <PlusIcon />
                </ActionIcon>
                Add Poll Response
              </button>
            ) }

          </Tabs.Panel> : null }

          { deliveryChannels.sms ? <Tabs.Panel value="sms">
            { smsResponseItems.map((responseItem, index) => (
              <GenericResponseItemComponent
                index={ index }
                key={ index }
                deliveryChannel={ 'sms' }
                responseMethod={ activeResponseMethodSettings }
                responseItem={ responseItem }
                pollResponse={ pollResponses.get(index)|| '' }
                isWithRemoveButton={ activeResponseMethodSettings === 'CUSTOM_POLL' }
                onRemoveResponseItemRequested={ () => onRemoveResponseMethodRequested('sms', responseItem) }
                onResponseItemChanged={ onResponseItemChanged }
                onPollResponseChanged={ onPollResponseChanged }
                activeTab={ activeTab }
              />
            )) }

            { activeResponseMethodSettings === 'CUSTOM_POLL' && (
              <button
                className={ classes.addNewResponseItem }
                onClick={ () => onAddNewResponseMethodRequested('sms') }
                disabled={ smsResponseItems.length >= 5 }
              >
                <ActionIcon
                  variant='filled'
                  color='royalBlue'
                  disabled={ smsResponseItems.length >= 5 }
                >
                  <PlusIcon />
                </ActionIcon>
                Add Poll Response
              </button>
            ) }
          </Tabs.Panel> : null }

          { deliveryChannels.voice ? <Tabs.Panel value="voice">
            { voiceResponseItems.map((responseItem, index) => (
              <GenericResponseItemComponent
                index={ index }
                key={ index }
                deliveryChannel={ 'voice' }
                responseMethod={ activeResponseMethodSettings }
                responseItem={ responseItem }
                pollResponse={ pollResponses.get(index)|| '' }
                isWithRemoveButton={ activeResponseMethodSettings === 'CUSTOM_POLL' }
                onRemoveResponseItemRequested={ () => onRemoveResponseMethodRequested('voice', responseItem) }
                onResponseItemChanged={ onResponseItemChanged }
                onPollResponseChanged={ onPollResponseChanged }
                activeTab={ activeTab }
              />
            )) }

            { activeResponseMethodSettings === 'CUSTOM_POLL' && (
              <button
                className={ classes.addNewResponseItem }
                onClick={ () => onAddNewResponseMethodRequested('voice') }
                disabled={ voiceResponseItems.length >= 5 }
              >
                <ActionIcon
                  variant='filled'
                  color='royalBlue'
                  disabled={ voiceResponseItems.length >= 5 }
                >
                  <PlusIcon />
                </ActionIcon>
                Add Poll Response
              </button>
            ) }
          </Tabs.Panel> : null }
        </Tabs>

        <div className={ classes.modalButtons }>
          <Button size='md' onClick={ onResponseMethodSettingsModalClosed } variant={ 'outline' }>Cancel</Button>
          <Button size='md' onClick={ onSaveSettingsRequested } disabled={ !isResponseItemsValid }>Save Settings</Button>
        </div>
      </Modal>
    </div>
  );
};

export default ResponseMethodsComponent;
