import { Box } from "@mantine/core";
import { useId } from "@mantine/hooks";
import { FC, ReactNode, useEffect, useState } from "react";

import { ToggleableContextLayers } from "@/common/components/ToggleableLayer/ToggleableLayer.config";
import { useWeatherLegendStyles } from "@/tenant-context/visualisation-weather/components/WeatherLegendCopier.styles";
import {
  checkIfInteractiveLegend,
  copyAlertsLegend
} from "@/tenant-context/visualisation-weather/config/weather-legend.config";
import {
  ReactComponent as WeatherAlertsSvg
} from '@/tenant-context/visualisation-weather/images/weather-alerts-legend-all.svg';

type Props = {
  contextualLayerType: ToggleableContextLayers;
}

const fallbackComponents: Partial<Record<ToggleableContextLayers, ReactNode>> = {
  [ToggleableContextLayers.Alerts]: <WeatherAlertsSvg style={ { transform: `translateX(-2px)` } }/>
};

/**
 * This component copies the legend's DOM elements from the global legend on the map as img.
 *
 * The legend controller(provided by third-party) tracks the weather layers and area on the big map and draw the legend for them
 *
 * If you can, please consider the static approach for legend inside drawer
 * (look for static component in this folder)
 */
export const WeatherLegendCopier: FC<Props> = ({ contextualLayerType }) => {
  const id = useId();
  const containerId = `${id}-weather-legend`;
  const [isCopied, setIsCopied] = useState<boolean | null>(null);

  const { classes } = useWeatherLegendStyles();

  // Please add a separate side effect for each layer type
  useEffect(() => {
    if (!checkIfInteractiveLegend(contextualLayerType)) {
      return;
    }
    // Alerts
    const copy = () => copyAlertsLegend(containerId, containerId + '-img');
    setIsCopied(copy());
    const interval = setInterval(copy, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [containerId, contextualLayerType]);

  const fallback = fallbackComponents[contextualLayerType];

  if (!checkIfInteractiveLegend(contextualLayerType)) {
    return null;
  }

  return (
    <Box
      id={ containerId }
      data-for-layer-type={ contextualLayerType }
      className={ classes.root }
    >
      { isCopied === false && fallback }
    </Box>
  );
};
