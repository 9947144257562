import { AgGridReactProps } from "ag-grid-react";

import BorderedDataGridComponent from "./BorderedDataGrid.component";

interface BorderedDataGridProps<_T, _P = Record<string, string | number | boolean>> extends AgGridReactProps {
  headerContent?: React.ReactNode | (() => React.ReactNode),
}

const BorderedDataGrid = <T, P = Record<string, string | number | boolean>>({
  ...props
}: BorderedDataGridProps<T, P>) => {

  return (
    <BorderedDataGridComponent 
      // eslint-disable-next-line react/jsx-props-no-spreading
      { ...props }
    />
  );
};

export default BorderedDataGrid;