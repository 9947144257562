import { ReferenceData } from "@/common/types/reference-data";
import { callApi, TokenType } from "@/common/util/api";
import { SortData } from "@/tenant-context/control-groups/types/group";
import { PaginatedResult } from "@/tenant-context/control-profile/types/profile-list";

import { ICMEvent, ICMEventCreate } from "../types/icmEvents";

export const getICMEventsData = (
  page = 0,
  size = 10,
  sort?: SortData,
  query?: string
) => callApi<PaginatedResult<ICMEvent>>(
  `managed/icm-service/v1/icm-events?size=${size}&page=${page}${query ? '&search=(' + query + ')&' : ''}${sort?.colId ? '&orderBy=' + sort.colId + ':' + sort.sort : ''}`,
  {
    headers: {
      'Content-Type': 'application/json;domain-model=ListIcmEvent'
    },
    method: 'get',
    tokenType: TokenType.ACCESS,
    withTenantId: false
  }
);

export const exportICMEvents = (
  page = 0,
  size = 10
) => callApi(
  `/managed/icm-service/v1/icm-events?size=${size}&page=${page}`,
  {
    headers: {
      'Content-Type': 'application/json;domain-model=DownloadICMEvents'
    },
    method: 'get',
    tokenType: TokenType.ACCESS,
    withTenantId: false

  }
);

export const uploadEventsFile = (formData: FormData) => callApi<
  { status?: string }
>(
  `/managed/icm-service/v1/icm-events/files`,
  {
    method: 'post',
    body: formData,
    headers: {
      'x-domain-model': 'UploadICMEvent'
    },
    withContentType: false,
    tokenType: TokenType.ACCESS,
    withTenantId: false

  }
);

export const createICMEvent = (event: ICMEventCreate) => callApi<ICMEvent>(
  `/managed/icm-service/v1/icm-events`,
  {
    method: 'post',
    body: JSON.stringify(event),
    headers: {
      'Content-Type': 'application/json;domain-model=CreateICMEvent'
    },
    tokenType: TokenType.ACCESS,
    withTenantId: false

  }
);

export const updateICMEvent = (event: ICMEventCreate, eventId: string) => callApi<ICMEvent>(
  `/managed/icm-service/v1/icm-events/${eventId}`,
  {
    method: 'post',
    body: JSON.stringify(event),
    headers: {
      'Content-Type': 'application/json;domain-model=UpdateICMEvent'
    },
    tokenType: TokenType.ACCESS,
    withTenantId: false

  }
);

export const deleteICMEvent = (eventId: string) => callApi<void>(
  `/managed/icm-service/v1/icm-events/${eventId}`,
  {
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;domain-model=DeleteICMEvent'
    },
    tokenType: TokenType.ACCESS,
    withTenantId: false

  }
);

export const getEventUseCaseReference = () => callApi<PaginatedResult<ReferenceData>>(
  `/public/platform-service/v1/reference-data?search=(type='Use-Case')&size=1000`,
  {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    withTenantId: false,
    withAuthorization: false
  }
);

export const getEventTypeReference = () => callApi<PaginatedResult<ReferenceData>>(
  `/public/platform-service/v1/reference-data?search=(type='Event-Type')&size=1000`,
  {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    withTenantId: false,
    withAuthorization: false
  }
);


