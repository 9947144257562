import { Box, SegmentedControl, Text } from "@mantine/core";
import { FC } from "react";

import { ReactComponent as MiniClock } from '@/tenant-context/control-action-response-center/icons/mini-clock.svg';

import { useArcHeaderStyles, useArcSegmentedControlStyles } from "../../../ArcHeader/ArcHeader.style";


type Props = {
    peopleCount : number,
    assetCount : number,
    fromDate: string,
    toDate: string,
    isPeopleImpactedTab: boolean,
    onSubImpactTabChange: () => void
}

const SubImpactTabComponent: FC<Props> = ({ 
  peopleCount, 
  assetCount, 
  fromDate, 
  toDate, 
  isPeopleImpactedTab, 
  onSubImpactTabChange
}) => {
  const { classes } = useArcHeaderStyles();
  const { classes: segmentedControlClasses } = useArcSegmentedControlStyles();

  return (
    <Box className={ classes.segmentedTabContainer }>
      <SegmentedControl
        classNames={ segmentedControlClasses }
        radius={ 8 }
        value={ isPeopleImpactedTab ? "peopleCount" : "assetCount" }
        onChange={ onSubImpactTabChange }
        data={ [
          { label: `People Impacted (${peopleCount ?? 0})`, value: "peopleCount" },
          { label: `Assets Impacted (${assetCount ?? 0})`, value: "assetCount" }
        ] }
      />
      <Box className={ classes.periodContainer }>
        <MiniClock />
        <Text>{ `Showing results between:` }</Text>
        <Text opacity={ '65%' }>{ ` ${fromDate} - ${toDate}` }</Text>
      </Box> 
    </Box>
  );
};

export default SubImpactTabComponent;
