import { useMemo } from "react";
import { useSelector } from "react-redux";

import { RootState } from "@/core/store";
import { RiskProviders } from "@/tenant-context/common/types/risk";
import { RiskAlertFeatureCollection } from "@/tenant-context/visualisation-risk-alerts/types/risk-alerts";

export const useTOCCountersCalc = (riskAlertsGeoData: RiskAlertFeatureCollection) => {
  const isOnlyImpactedAlerts = useSelector(
    (state: RootState) => state.riskAlerts.showOnlyImpactedRiskEvents
  );
  const disabledRiskFilterTypes = useSelector(
    (state: RootState) => state.riskEventFilterDataOptions.disabledRiskFilterTypes
  );

  const {
    isGlobalCategoryToggleOnFactal
  } = useSelector((state: RootState) => state.riskEventFilterDataOptions);

  const riskLevelFilters = useSelector(
    (state: RootState) => state.riskEventFilterDataOptions.riskLevelFilters
  );
  const {
    risklineLevelsFilter,
    dataminrLevelsFilter,
    factalLevelsFilter
  } = useMemo(() => ({
    risklineLevelsFilter: new Set(riskLevelFilters.riskline.map(String)),
    dataminrLevelsFilter: new Set(riskLevelFilters.dataminr.map(String)),
    factalLevelsFilter: new Set(riskLevelFilters.factal.map(String))
  }), [riskLevelFilters.dataminr, riskLevelFilters.riskline, riskLevelFilters.factal]);
  const riskLevelBSOCFilters = useSelector((state: RootState) => state.riskEventFilterDataOptions.riskLevelBSOCFilters);

  const groupsByProvider = useMemo(() => {
    const groups = new Map<string, typeof riskAlertsGeoData.features>();
    riskAlertsGeoData.features?.forEach((feature) => {
      if (isOnlyImpactedAlerts && !feature.properties?.isImpacted) {
        return;
      }
      const source = feature.properties?.source;
      if (source) {
        const group = groups.get(source);
        if (group) {
          group.push(feature);
        } else {
          groups.set(source, [feature]);
        }
      }
    });

    return groups;
  }, [isOnlyImpactedAlerts, riskAlertsGeoData]);

  const totalCounters = useMemo(() => {
    const counters = new Map<string, number>();
    groupsByProvider.forEach((f, provider) => {
      if (provider === RiskProviders.factal) {
        const uniqueIds = new Set(f.map(feature => feature.properties?.json?.alert?.id));
        counters.set(provider, uniqueIds.size);
      } else {
        counters.set(provider, f.length);
      }
    });

    return counters;
  }, [groupsByProvider]);

  const filterCounters = useMemo(() => {
    const counters = new Map<string, number>();
    const disabledRiskFilters = new Set(disabledRiskFilterTypes);
    const riskLevelBSOCFiltersSet = new Set<string>();
    if(riskLevelBSOCFilters.critical) {
      riskLevelBSOCFiltersSet.add('true'); 
    }
    if(riskLevelBSOCFilters.nonCritical) {
      riskLevelBSOCFiltersSet.add('false');
    }

    groupsByProvider.forEach((features, provider) => {
      const filtered = features
        .filter((f) => !disabledRiskFilters.has(f.properties.json.alert.category.id))
        .filter((f) => {
          if (provider === RiskProviders.riskline) {
            return risklineLevelsFilter.has(f.properties.json.alert.risk_level.id);
          } else if (provider === RiskProviders.dataminr) {
            return dataminrLevelsFilter.has(f.properties.json.alert.risk_level.id);
          } else if (provider === RiskProviders.bsoc) {
            return riskLevelBSOCFiltersSet.has(
              String(f.properties.json.externalData?.critical_incident)
            );
          } else if (provider === RiskProviders.factal) {
            if (!isGlobalCategoryToggleOnFactal) {
              return false;
            }

            return factalLevelsFilter.has(f.properties.json.alert.risk_level.id);
          }
          
          return true;
        });

      if (provider === RiskProviders.factal) {
        const uniqueIds = new Set(filtered.map(feature => feature.properties?.json?.alert?.id));
        counters.set(provider, uniqueIds.size);
      } else {
        counters.set(provider, filtered.length);
      }
    });

    return counters;
  }, [
    disabledRiskFilterTypes,
    riskLevelBSOCFilters.critical,
    riskLevelBSOCFilters.nonCritical,
    groupsByProvider,
    risklineLevelsFilter,
    dataminrLevelsFilter,
    isGlobalCategoryToggleOnFactal,
    factalLevelsFilter
  ]);

  return useMemo(() => {
    const countersMap = new Map<string, { total: number, filtered: number }>();
    totalCounters.forEach((value, key) => {
      countersMap.set(key, {
        total: value,
        filtered: filterCounters.get(key) ?? 0
      });
    });

    return countersMap;
  }, [filterCounters, totalCounters]);
};
