import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useMap } from "react-map-gl";
import { useDispatch, useSelector } from "react-redux";

import { ToggleableContextLayers } from "@/common/components/ToggleableLayer/ToggleableLayer.config";
import useLayerListener from "@/common/hooks/useLayerListener";
import usePermission from "@/common/hooks/usePermission";
import { CorePolicies } from "@/core/config/CorePolicies.config";
import { RootState } from "@/core/store";
import useMapPopup from "@/tenant-context/common/hooks/useMapPopup";
import { getClickedOnFeatures } from "@/tenant-context/common/util/map-click";
import CountryRiskLevelLayerComponent
  from "@/tenant-context/visualize-country-risk/layers/CountryRiskLevelLayer/CountryRiskLevelLayer.component";
import {
  CountryRiskLevel
} from "@/tenant-context/visualize-country-risk/types/country-risk.types";

export const DEFAULT_COUNTRY_RISK_FILL_OPACITY = 0.75;

const CountryRiskLevelLayer: FC = () => {

  const [popupContent, setPopupContent] = useState<CountryRiskLevel>();

  const {
    isPopupShown,
    setIsPopupShown,
    popupCoordinates,
    setPopupCoordinates
  } = useMapPopup();
  const countryRiskLevels = useSelector((state: RootState) => state.countryRiskLevels.countryRiskLevels);

  const layerOpacity = useSelector((state: RootState) => state.dataOptions.layerOpacity);
  const opacityValue = layerOpacity[ToggleableContextLayers.CountryRiskLevel] ?? DEFAULT_COUNTRY_RISK_FILL_OPACITY;

  const { current: map } = useMap();
  const isRiskConnectorsPermissionAvailable = usePermission(CorePolicies.RISK_CONNECTOR_POLICY);

  const {
    countryRiskLevels: {
      fetchCountryRiskLevels
    }
  } = useDispatch();

  useEffect(() => {
    if (!isRiskConnectorsPermissionAvailable) {
      return;
    }

    fetchCountryRiskLevels();
  }, [fetchCountryRiskLevels, isRiskConnectorsPermissionAvailable]);

  const riskLevelWiseCountryList = useMemo(() => {
    const riskLevels = {
      "1": [],
      "2": [],
      "3": [],
      "4": [],
      "5": []
    } as { [key: string]: string[] };

    countryRiskLevels.forEach((countryRiskLevel) => {
      switch (countryRiskLevel.risk_level.id) {
      case "1":
        riskLevels["1"].push(countryRiskLevel.countryISO);
        break;
      case "2":
        riskLevels["2"].push(countryRiskLevel.countryISO);
        break;
      case "3":
        riskLevels["3"].push(countryRiskLevel.countryISO);
        break;
      case "4":
        riskLevels["4"].push(countryRiskLevel.countryISO);
        break;
      case "5":
        riskLevels["5"].push(countryRiskLevel.countryISO);
        break;
      }
    });

    return riskLevels;
  }, [countryRiskLevels]);

  useLayerListener(
    "click",
    [
      "countryRiskLevelLayer_low",
      "countryRiskLevelLayer_moderate",
      "countryRiskLevelLayer_medium",
      "countryRiskLevelLayer_high",
      "countryRiskLevelLayer_extreme"
    ],
    useCallback(
      (evt) => {
        const clickedCoords = evt.lngLat;
        const { layerFeatures } = getClickedOnFeatures(evt, [
          "countryRiskLevelLayer_low",
          "countryRiskLevelLayer_moderate",
          "countryRiskLevelLayer_medium",
          "countryRiskLevelLayer_high",
          "countryRiskLevelLayer_extreme"
        ]);

        if (!layerFeatures.length) {
          return;
        }

        const feature = layerFeatures[0];

        const countryISO = feature.properties?.iso_3166_1;
        const countryRiskLevel = countryRiskLevels
          .find((countryRisk) => countryRisk.countryISO === countryISO);

        if (!countryRiskLevel) {
          return;
        }

        setPopupContent(countryRiskLevel);

        map?.setCenter(clickedCoords);

        setIsPopupShown(false);

        setPopupCoordinates({
          latitude: clickedCoords.lat,
          longitude: clickedCoords.lng
        });

        setIsPopupShown(true);
      },
      [countryRiskLevels, map, setIsPopupShown, setPopupCoordinates]
    )
  );

  const closePopup = useCallback(() => setIsPopupShown(false), [setIsPopupShown]);

  return (
    <CountryRiskLevelLayerComponent
      riskLevelWiseCountries={ riskLevelWiseCountryList }
      isPopupShown={ isPopupShown }
      popupCoordinates={ popupCoordinates }
      popupData={ popupContent }
      onClickClosePopup={ closePopup }
      opacityValue={ opacityValue }
    />
  );
};

export default CountryRiskLevelLayer;
