import { Slider, Stack, Text } from "@mantine/core";
import { FC, useCallback } from "react";

import { ReactComponent as SliderHandle } from "@/common/icons/slider-handle.svg";

type Props = {
  onChange: (value: number) => void;
  // 0..1
  initialValue?: number;
}

const LayerOpacityControlComponent: FC<Props> = ({ onChange, initialValue = 1 }) => {
  const initialValuePercent = initialValue * 100;

  const handleValueChange = useCallback(
    (value: number) => onChange(+(value / 100).toFixed(2)),
    [onChange]
  );
  
  return (
    <Stack spacing={ 48 }>
      <Text size={ 16 } fw={ 700 }>
        Layer Opacity
      </Text>
      <Slider
        min={ 0 }
        max={ 100 }
        mx={ 16 }
        mb={ 20 }
        labelAlwaysOn
        thumbChildren={ <SliderHandle /> }
        /* eslint-disable-next-line react/jsx-no-bind */
        label={ (value) => `${value}%` }
        marks={ [{ value: 0, label: "0%" }, { value: 100, label: "100%" }] }
        defaultValue={ initialValuePercent }
        onChange={ handleValueChange }
      />
    </Stack>
  );
};

export default LayerOpacityControlComponent;