import { openModal } from '@mantine/modals';
import bbox from '@turf/bbox';
import { Feature, FeatureCollection } from 'geojson';
import { EventData, MapLayerEventType } from 'mapbox-gl';
import { ChangeEvent, FC, useCallback, useEffect, useRef, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useMap } from 'react-map-gl';
import { useDispatch, useSelector } from 'react-redux';

import useDrawer from '@/common/hooks/useDrawer';
import { showNotification } from "@/common/util/notification";
import { Dispatch, RootState, store } from '@/core/store';
import CreateGeofenceDrawerComponent
  from '@/tenant-context/control-draw/components/CreateGeofenceDrawer/CreateGeofenceDrawer.component';
import DeleteGeofenceWarningModal from '@/tenant-context/control-draw/components/DeleteGeofenceWarningModal';
import {
  DrawingTypes,
  Geofence,
  GeofencePermissionType, GeofenceStatusTypes,
  GeofenceType,
  GeoPolygon,
  ViewportBbox
} from '@/tenant-context/control-draw/types/draw';
import { getUpdatedGeofencePolygons } from '@/tenant-context/control-draw/util/get-updated-geofence-polygons';

const drawerId = 'create-geofence';
const drawerPosition = 'left';

const CreateGeofenceDrawerContainer: FC = () => {
  const {
    geofenceType,
    geofencePermissionType,
    currentGeoJson,
    editableGeofence
  } = useSelector((state: RootState) => state.geofence);

  const {
    geofence: {
      SET_CURRENT_GEO_FENCES,
      SET_GEOFENCE_TYPE,
      SET_GEOFENCE_PERMISSION_TYPE,
      SET_CURRENT_GEO_JSON,
      SET_EDITABLE_GEOFENCE,
      loadGeofenceList,
      createGeofence,
      updateGeofence,
      updateEditableGeofenceStatus
    }
  } = useDispatch<Dispatch>();

  const [ intermediateArchiveState, setIntermediateArchiveState ] = useState<string | undefined>();
  const drawControl = useRef<MapboxDraw>();
  const selectedDrawing = useRef<Feature>();
  const { close } = useDrawer(drawerId, drawerPosition);

  const { GeofenceMap: geofenceMap } = useMap();

  useEffect(() => {
    // invoke close if we go away from geofence manage(it is unmounting, so we clear its drawer state)
    return () => {
      close();
    };
  }, [close]);

  const handleDrawingToolSelect = useCallback((type: DrawingTypes) => {
    ({
      [DrawingTypes.POLYGON]: () => drawControl.current?.changeMode('draw_polygon'),
      [DrawingTypes.CIRCLE]: () => drawControl.current?.changeMode('draw_point'),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [DrawingTypes.DRAG_CIRCLE]: () => drawControl.current?.changeMode('drag_circle'),
      [DrawingTypes.SQUARE]: () => drawControl.current?.changeMode('draw_point'),
      [DrawingTypes.LINE]: () => drawControl.current?.changeMode('draw_line_string'),
      [DrawingTypes.DELETE]: () => {
        if (selectedDrawing.current?.id) {
          drawControl.current?.delete(selectedDrawing.current.id as string);
        } else {
          drawControl.current?.deleteAll();
        }
        SET_CURRENT_GEO_FENCES([]);
        SET_CURRENT_GEO_JSON(undefined);
      },
      [DrawingTypes.NONE]: () => null
    })[type]();
  }, [SET_CURRENT_GEO_FENCES, SET_CURRENT_GEO_JSON]);

  const onGeoFenceUpdated = useCallback((evt: MapLayerEventType & EventData) => {
    const actionType = evt.type === 'draw.create' ? 'CREATE' : 'UPDATE';
    const polygonFeature = evt.features[0];

    const boundingBox: ViewportBbox = polygonFeature.geometry.type === 'LineString' ? polygonFeature.geometry.coordinates : polygonFeature.geometry.coordinates[0];


    const isPolygonOrLine = polygonFeature.geometry.type === 'Polygon' || polygonFeature.geometry.type === 'LineString';

    if (isPolygonOrLine) {
      const { geofence: { currentGeoFences } } = store.getState();

      const cloneGeoJson = Object.assign({}, polygonFeature);
      // eslint-disable-next-line fp/no-delete
      delete cloneGeoJson.id;
      SET_CURRENT_GEO_JSON(cloneGeoJson);


      if (actionType === 'CREATE') {
        currentGeoFences.forEach((geoPolygon) => {
          if (geoPolygon?.id) {
            drawControl.current?.delete(geoPolygon.id as string);
          }
        });

        const updatedGeoFences = getUpdatedGeofencePolygons(
          actionType,
          [],
          polygonFeature,
          boundingBox
        );

        SET_CURRENT_GEO_FENCES(updatedGeoFences);

      } else {
        const updatedGeoFences = getUpdatedGeofencePolygons(
          actionType,
          currentGeoFences,
          polygonFeature,
          boundingBox
        );

        SET_CURRENT_GEO_FENCES(updatedGeoFences);
      }
    }
  }, [SET_CURRENT_GEO_FENCES, SET_CURRENT_GEO_JSON]);

  const onGeoFenceDeleted = useCallback((evt: MapLayerEventType & EventData) => {
    const isPolygon = evt.features[0].geometry.type === 'Polygon'; // Circle also is a polygon
    const polygonId = evt.features[0].id;

    if (!isPolygon) {
      return;
    }

    const { geofence: { currentGeoFences } } = store.getState();

    // Removing the deleted polygon from the list
    const polygons = currentGeoFences
      .filter((geoFence) => geoFence.id !== polygonId);

    SET_CURRENT_GEO_FENCES(polygons);
  }, [SET_CURRENT_GEO_FENCES]);

  const onDrawingControlCreated = useCallback((control: MapboxDraw) => {
    drawControl.current = control;
  }, [drawControl]);

  const onDrawingSelected = useCallback((evt: MapLayerEventType & EventData) => {
    const selectedFeature = evt.features[0];
    if (!selectedFeature) {
      selectedDrawing.current = undefined;
    }
    selectedDrawing.current = selectedFeature;
  },[]);

  const  formControls = useForm();
  const  { setValue } = formControls;

  const onDrawerClose = useCallback(() => {
    SET_CURRENT_GEO_FENCES([]);
    SET_GEOFENCE_TYPE(GeofenceType.GENERAL);
    SET_GEOFENCE_PERMISSION_TYPE(undefined);
    SET_CURRENT_GEO_JSON(undefined);
    SET_EDITABLE_GEOFENCE(undefined);
    formControls.reset();
    close();
  }, [
    SET_CURRENT_GEO_FENCES,
    SET_GEOFENCE_TYPE,
    SET_GEOFENCE_PERMISSION_TYPE,
    SET_CURRENT_GEO_JSON,
    SET_EDITABLE_GEOFENCE,
    formControls,
    close
  ]);

  const onGeofenceTypeChanged = useCallback((value: GeofenceType) => {
    SET_GEOFENCE_TYPE(value);
  }, [SET_GEOFENCE_TYPE]);

  const onGeofencePermissionTypeChanged = useCallback((value: GeofencePermissionType) => {
    SET_GEOFENCE_PERMISSION_TYPE(value);
  }, [SET_GEOFENCE_PERMISSION_TYPE]);

  const archiveGeoFence = useCallback((archive: boolean) => {
    if (editableGeofence && editableGeofence.tid) {
      const status = archive ? GeofenceStatusTypes.DISABLED : GeofenceStatusTypes.ACTIVE;
      const updatedGeofence = updateEditableGeofenceStatus({ tid: editableGeofence.tid, status });

      updatedGeofence.then(() => {
        setValue('status', status);
        showNotification({
          title: 'Successful',
          message: `Geofence ${ archive ? 'archived' : 'un-archived' } successfully!`,
          color: 'success'
        });
      }).then(() => {
        loadGeofenceList({
          page: 0,
          size: 10,
          sort: { colId: '', sort: '' }
        });
      }).catch((err) => {
        showNotification({
          title: 'Error',
          message: err.message,
          color: 'error'
        });
      });
    }
  }, [ editableGeofence, loadGeofenceList, setValue, updateEditableGeofenceStatus ]);

  const onSubmit = useCallback(
    (data: FieldValues) => {

      const featureCollectionGeoJson = currentGeoJson?.type === 'FeatureCollection' ? currentGeoJson : {
        type: 'FeatureCollection',
        features: [
          currentGeoJson
        ]
      };

      if (geofenceType === GeofenceType.PROHIBITIVE) {
        SET_GEOFENCE_PERMISSION_TYPE(GeofencePermissionType.SHARED);
      }

      if (geofencePermissionType === undefined) {
        SET_GEOFENCE_PERMISSION_TYPE(GeofencePermissionType.PRIVATE);
      }

      if (editableGeofence) {
        const isGeoJsonChanged = JSON.stringify(featureCollectionGeoJson) !== editableGeofence.geoJson;


        const updatedGeofence: Geofence = {
          ...data as Geofence,
          id: editableGeofence.id,
          tid: editableGeofence.tid,
          enablePrivate: geofencePermissionType === GeofencePermissionType.PRIVATE,
          geoJson: isGeoJsonChanged ? JSON.stringify(featureCollectionGeoJson) : undefined,
          type: geofenceType
        };


        const updatedGeofencePromise = updateGeofence(updatedGeofence);

        updatedGeofencePromise.then((geoFenceResponse) => {
          showNotification({
            title: 'Successful',
            message: 'Geofence updated successfully!',
            color: 'success'
          });

          if (geoFenceResponse && geoFenceResponse?.status?.toUpperCase() !== intermediateArchiveState?.toUpperCase()) {
            archiveGeoFence(intermediateArchiveState?.toUpperCase() === GeofenceStatusTypes.DISABLED);
          }

          onDrawerClose();
        }).catch((err) => {
          showNotification({
            title: 'Error',
            message: err.message,
            color: 'error'
          });
        });

        return;
      }

      if (currentGeoJson) {
        const newGeofence: Geofence = {
          ...data as Geofence,
          enablePrivate: geofencePermissionType === GeofencePermissionType.PRIVATE,
          geoJson: JSON.stringify(featureCollectionGeoJson),
          type: geofenceType
        };

        const createdGeofence = createGeofence(newGeofence);

        createdGeofence.then(() => {
          showNotification({
            title: 'Successful',
            message: 'Geofence created successfully!',
            color: 'success'
          });
          onDrawerClose();
        }).catch((err) => {
          showNotification({
            title: 'Error',
            message: err.message,
            color: 'error'
          });
        });

      } else {
        showNotification({
          title: 'Error',
          message: 'No geofences were found in the map!',
          color: 'error'
        });
      }
    },
    [
      editableGeofence,
      onDrawerClose,
      createGeofence,
      currentGeoJson,
      geofencePermissionType,
      geofenceType,
      updateGeofence,
      SET_GEOFENCE_PERMISSION_TYPE,
      archiveGeoFence,
      intermediateArchiveState
    ]
  );

  const onDelete = useCallback(() => {
    openModal({
      title: 'Delete Geofence',
      size: 648,
      children: (
        <DeleteGeofenceWarningModal closeDrawer={ onDrawerClose } geofenceId={ editableGeofence?.tid }/>
      )
    });
  }, [onDrawerClose, editableGeofence?.tid]);

  const onArchive = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    if (checked) {
      setIntermediateArchiveState(GeofenceStatusTypes.DISABLED);
    } else {
      setIntermediateArchiveState(GeofenceStatusTypes.ACTIVE);
    }
  }, []);

  useEffect(() => {
    if (editableGeofence && drawControl.current && geofenceMap) {
      if (editableGeofence) {
        setValue('name', editableGeofence.name);
        setValue('description', editableGeofence.description);
        setValue('type', editableGeofence.type);
        setValue('enablePrivate', editableGeofence.enablePrivate);
        setValue('pushNotificationText', editableGeofence.pushNotificationText);
        setValue('enableLocationTracking', editableGeofence.enableLocationTracking);

        SET_GEOFENCE_TYPE(
          editableGeofence.type === 'Prohibitive Geofence' ? GeofenceType.PROHIBITIVE : GeofenceType.GENERAL
        );
        SET_GEOFENCE_PERMISSION_TYPE(
          editableGeofence.enablePrivate ? GeofencePermissionType.PRIVATE : GeofencePermissionType.SHARED
        );
        setIntermediateArchiveState(intermediateArchiveState === undefined
          ? editableGeofence.status : intermediateArchiveState);
      }

      if (editableGeofence.geoJson) {
        try {
          const geoJson = JSON.parse(editableGeofence.geoJson);
          drawControl.current?.set(geoJson);

          const updatedGeoJson : FeatureCollection = drawControl.current?.getAll();

          const geoPolygons: GeoPolygon[] = getUpdatedGeofencePolygons(
            'CREATE',
            [],
            updatedGeoJson.features[0] as Feature,
            geoJson.features[0].geometry.type === 'LineString' ? geoJson.features[0].geometry.coordinates : geoJson.features[0].geometry.coordinates[0]
          );

          SET_CURRENT_GEO_FENCES(geoPolygons);
          SET_CURRENT_GEO_JSON(geoJson);

          const bbox_ = bbox(geoJson.features[0].geometry);
          geofenceMap?.fitBounds(bbox_ as [number, number, number, number], { padding: 100 });
        } catch (e) {
          showNotification({
            title: 'Error',
            message: 'Something went wrong while parsing GeoJSON!',
            color: 'error'
          });
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    editableGeofence,
    SET_CURRENT_GEO_FENCES,
    SET_CURRENT_GEO_JSON,
    geofenceMap,
    setValue,
    SET_GEOFENCE_TYPE,
    SET_GEOFENCE_PERMISSION_TYPE
  ]);

  return (
    <CreateGeofenceDrawerComponent
      drawerId={ drawerId }
      drawerPosition={ drawerPosition }
      formControls={ formControls }
      geofenceType={ geofenceType }
      geofencePermissionType={ geofencePermissionType }
      isHiddenGeofenceArchiveOption={ !editableGeofence }
      isHiddenGeofencePermission={ geofenceType !== GeofenceType.GENERAL }
      isHiddenProhibitiveGeofenceConfig={ geofenceType !== GeofenceType.PROHIBITIVE }
      editableGeofence={ editableGeofence }
      intermediateArchiveState={ intermediateArchiveState }
      onDrawingUpdate={ onGeoFenceUpdated }
      onDrawingDelete={ onGeoFenceDeleted }
      onDrawingSelected={ onDrawingSelected }
      onDrawControlCreated={ onDrawingControlCreated }
      handleDrawingToolSelect={ handleDrawingToolSelect }
      onDrawerClose={ onDrawerClose }
      onGeofenceTypeChanged={ onGeofenceTypeChanged }
      onGeofencePermissionTypeChanged={ onGeofencePermissionTypeChanged }
      onSubmit={ onSubmit }
      onDelete={ onDelete }
      onArchive={ onArchive }
    />
  );
};

export default CreateGeofenceDrawerContainer;
