import { Select, TextInput } from "@mantine/core";
import { FC } from "react";

import { ReactComponent as SearchIcon } from "@/common/icons/search-light.svg";
import { useImpactTabStyles } from "@/tenant-context/control-action-response-center/components/ImpactTab/ImpactTab.styles";
import { ArcImpactStatus } from "@/tenant-context/control-action-response-center/types/ARC.types";

type Props = {
  selectedImpactStatus: ArcImpactStatus | undefined;
  onSearchStringChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onStatusFilterChange: (status: ArcImpactStatus | 'ALL') => void;
  onPersonTypeFilterChange: (personType: string) => void;
  onImpLocationFilterChange: (locationType: string) => void;
  onLatLocationFilterChange: (locationType: string) => void;
  onAlertFilterChange: (isAlertSent: string) => void;
  onRiskFilterChange: (response: string) => void;
}

const ImpactTabGridFiltersComponent: FC<Props> = ({
  selectedImpactStatus,
  onSearchStringChange,
  onStatusFilterChange,
  onPersonTypeFilterChange,
  onImpLocationFilterChange,
  onLatLocationFilterChange,
  onRiskFilterChange,
  onAlertFilterChange
}) => {

  const { classes } = useImpactTabStyles();

  return (
    <div className={ classes.filterWrapper }>
      <TextInput
        className={ classes.impactSearch }
        placeholder="Search for person..."
        onChange={ onSearchStringChange }
        classNames={ { input: classes.impactSelectItems } }
        icon={ <SearchIcon /> }
      />

      <Select
        placeholder="Risk Tier"
        style={ { width: '140px', minWidth: '140px', flex: 1 } }
        onChange={ onRiskFilterChange }
        data={ [
          { value: 'ALL', label: 'All' },
          { value: 'LOW', label: 'Low' },
          { value: 'HIGH', label: 'High' },
          { value: 'FUTURE', label: 'Future' }
        ] }
        classNames={ { item: classes.impactSelectItems, input: classes.impactSelectItems } }
      />

      <Select
        placeholder="Person Type"
        style={ { width: '160px', minWidth: '160px', flex: 1 } }
        data={ [
          { value: 'ALL', label: 'All' },
          { value: 'EMPLOYEE', label: 'Employee' },
          { value: 'CONTRACTOR', label: 'Contractors' },
          { value: 'SUB-CONTRACTOR', label: 'Sub-Contractors' },
          { value: 'VISITOR', label: 'Visitors' }
        ] }
        onChange={ onPersonTypeFilterChange }
        classNames={ { item: classes.impactSelectItems, input: classes.impactSelectItems } }
      />

      <Select
        placeholder="Impact Location"
        style={ { width: '185px', minWidth: '185px', flex: 1 } }
        data={ [
          { value: 'ALL', label: 'All' },
          { value: 'Home Address', label: 'Home Address' },
          { value: 'Work Location', label: 'Work Location' },
          { value: 'Requested Flight', label: 'Requested Flight' },
          { value: 'Requested Taxi', label: 'Requested Taxi' },
          { value: 'Requested Hotel', label: 'Requested Hotel' },
          { value: 'Booked Flight', label: 'Booked Flight' },
          { value: 'Booked Train', label: 'Booked Train' },
          { value: 'Booked Hotel', label: 'Booked Hotel' },
          { value: 'Booked Taxi', label: 'Booked Taxi' },
          { value: 'Booked Car Rental', label: 'Booked Car Rental' },
          { value: 'Planned Visit', label: 'Planned Visit' },
          { value: 'Planned Journey', label: 'Planned Journey' },
          { value: 'Managed Visit', label: 'Managed Visit' },
          { value: 'Managed Journey', label: 'Managed Journey' },
          { value: 'Access Control', label: 'Access Control' },
          { value: 'Muster Control', label: 'Muster Control' },
          { value: 'Check In (Restrata APP)', label: 'Check In (Restrata APP)' },
          { value: 'GPS Location (Restrata APP)', label: 'GPS Location (Restrata APP)' },
          { value: 'Other', label: 'Other' }
        ] }
        onChange={ onImpLocationFilterChange }
        classNames={ { item: classes.impactSelectItems, input: classes.impactSelectItems } }
      />

      <Select
        placeholder="Latest Location"
        style={ { width: '180px', minWidth: '180px', flex: 1 } }
        data={ [
          { value: 'ALL', label: 'All' },
          { value: 'true', label: 'Inside Zone' },
          { value: 'false', label: 'Outside Zone' }
        ] }
        onChange={ onLatLocationFilterChange }
        classNames={ { item: classes.impactSelectItems, input: classes.impactSelectItems } }
      />

      <Select
        placeholder="Alert Sent"
        style={ { width: '145px', minWidth: '145px', flex: 1 } }
        data={ [
          { value: 'ALL', label: 'All' },
          { value: 'true', label: 'Yes' },
          { value: 'false', label: 'NO' }
        ] }
        onChange={ onAlertFilterChange }
        classNames={ { item: classes.impactSelectItems, input: classes.impactSelectItems } }
      />

      <Select
        placeholder="Response Status"
        value={ selectedImpactStatus }
        style={ { width: '190px', minWidth: '190px', flex: 1 } }
        onChange={ onStatusFilterChange }
        classNames={ { item: classes.impactSelectItems, input: classes.impactSelectItems } }
        data={ [
          { value: 'ALL', label: 'All' },
          { value: 'NOT_SAFE', label: 'Not Safe' },
          { value: 'NO_RESPONSE', label: 'No Response' },
          { value: 'SAFE', label: 'Safe' },
          { value: 'OTHER', label: 'Other' }
        ] }
      />
    </div>
  );
};

export default ImpactTabGridFiltersComponent;
