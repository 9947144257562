/* eslint-disable @typescript-eslint/no-explicit-any */
import { WeatherLayers, WeatherPopupData, XWeatherAlertEventData } from "@/tenant-context/visualisation-weather";


export const mapWeatherRawFeatureToPopupData = (
  data: Record<WeatherLayers, { value: any }>
): Omit<WeatherPopupData, 'longitude' | 'latitude'> | null => {
  const popupData: Omit<WeatherPopupData, 'longitude' | 'latitude'> = {};

  if (!data) {
    return null;
  }

  if (data.alerts && data.alerts.value.length > 0) {
    popupData.alerts = [
      ...new Map<string, XWeatherAlertEventData>(
        data.alerts.value
          .map((alert: XWeatherAlertEventData) => [alert['ADVISORY'], alert])
      ).values()
    ];
  }

  // TODO - Uncomment this block after design allows multiple alerts
  // if (data.radar && data.radar.value > 0) {
  //   popupData.radar = data.radar.value;
  // }

  if (Object.keys(popupData).length === 0) {
    return null;
  }

  return popupData;
};
