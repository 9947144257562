import { Tabs, TabsValue } from "@mantine/core";
import { createContext, FC, useCallback, useEffect, useState } from "react";

import useContextValue from "@/common/hooks/useContextValue";
import useUnauthorizedModal from "@/common/hooks/useUnauthorizedModal";
import { logger } from "@/common/util/ConsoleLogger";
import { CaseListFilters } from "@/tenant-context/control-icm-management/types/caseList";

import { useCaseManagementStyles } from "../CaseManagement.styles";

export enum CaseListTabs {
    Open = 'OPEN',
    Closed = 'CLOSED',
    Archived = 'ARCHIVED',
}

export type TabProps = {
    value: string;
    label: string;
    isAuthorized?: boolean;
}

const caseTabs: TabProps[] = [
  {
    value: CaseListTabs.Open,
    label: 'Open',
    isAuthorized: true
  },
  {
    value: CaseListTabs.Closed,
    label: 'Closed',
    isAuthorized: true
  },
  {
    value: CaseListTabs.Archived,
    label: 'Archived',
    isAuthorized: true
  }
];

export type CaseListContextType = {
    activeTab?: CaseListTabs
    renderTab: (tab: TabProps, key: number) => JSX.Element,
    interceptTabChange: (event: TabsValue) => void,
    caseTabs: TabProps[]
    handleDownloadReport: (id: string) => void
    filters: CaseListFilters,
    setFilters: React.Dispatch<React.SetStateAction<CaseListFilters>>
}

export const CaseListContext = createContext<CaseListContextType>({} as CaseListContextType);

export const CaseListContextProvider: FC = ({ children }) => {

  const { classes } = useCaseManagementStyles({});

  const [activeTab, setActiveTab] = useState<CaseListTabs | undefined>();

  const [filters, setFilters] = useState<CaseListFilters>({
    searchQuery: '',
    additionalParams: {
      caseType: '',
      severity: '',
      location: ''
    }
  });

  const handleDownloadReport = useCallback((_caseId: string) => {
    // TODO when API is ready
  },[]);

  const handleUnauthorizedAction = useUnauthorizedModal();

  const handleTabChange = useCallback((tab: CaseListTabs) => {
    if (!tab) {
      return;
    }
    sessionStorage.setItem('tenantCaseManagementActiveTab', tab);
    setActiveTab(tab);
  }, []);

  const interceptTabChange = useCallback((event) => {
    const selectedTab = caseTabs.find((tab) => tab.value === event);

    if (!selectedTab?.isAuthorized)  {
      return;
    }

    setFilters({
      searchQuery: '',
      additionalParams: {
        caseType: '',
        severity: '',
        location: ''
      }
    });

    handleTabChange(event);

  }, [handleTabChange]);

  const renderTab = useCallback((tab: TabProps, key: number) => {
    return (
      <Tabs.Tab
        value={ tab.value }
        key={ key }
        onClick={ !tab.isAuthorized ? handleUnauthorizedAction : undefined }
        className={ !tab.isAuthorized ? classes.unauthorizedTab : undefined }
      >
        { tab.label }
      </Tabs.Tab>
    );
  }, [classes.unauthorizedTab, handleUnauthorizedAction]);

  useEffect(() => {
    try {
      const tab = sessionStorage.getItem('tenantCaseManagementActiveTab');
      if(tab) {
        setActiveTab(tab as CaseListTabs);
      } else {
        sessionStorage.setItem('tenantCaseManagementActiveTab', CaseListTabs.Open );
        setActiveTab(CaseListTabs.Open);
      }
    } catch (error) {
      sessionStorage.setItem('tenantCaseManagementActiveTab', CaseListTabs.Open );
      setActiveTab(CaseListTabs.Open);
      logger.log(error);
    }
  }, []);
  
  return (
    <CaseListContext.Provider value={ useContextValue({
      activeTab,
      caseTabs,
      interceptTabChange,
      renderTab,
      handleDownloadReport,
      filters,
      setFilters
    }) }>
      { children }
    </CaseListContext.Provider>
  );
};