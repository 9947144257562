import { DrawerStylesParams, MantineTheme } from "@mantine/core";

export const DrawerComponentStyle = {
  defaultProps: {
    size: 'lg',
    withOverlay: false
  },
  styles: (theme: MantineTheme, params: DrawerStylesParams) => ({
    root: {
      height: 'fit-content',
      top: 'unset'
    },
    header: {
      display: params.size === 'full' ? 'none' : undefined,
      margin: params.position === 'bottom'
        ? undefined
        : '-24px',
      padding: params.position === 'bottom'
        ? undefined
        :'16px',
      marginBottom: '16px',
      position: 'relative' as const,

      '&::after': {
        transform: 'translateX(-25%)',
        content: '""',
        display: 'block' as const,
        width: '200%',
        position: 'absolute' as const,
        bottom: -1,
        borderBottom: `0.5px solid ${theme.colors.neutral[6]}`
      }
    },
    title: {
      // display: 'flex',
      // alignItems: 'center',
      width: '100%',
      flexGrow: 1
    },
    drawer: {
      padding: params.size === 'full' ? 0 : `${theme.other.spacing.lg}px !important`,
      display: 'flex',
      flexDirection: 'column' as const,
      overflowY: 'auto' as const,
      overflowX: 'hidden' as const,
      right: params.position === 'right' && params.size !== 'full'
        ? '72px'
        : undefined,

      width: (function(){
        if (params.position === 'right') {
          if (params.size === 'full') {
            // return '100%';
            return 'calc(100% - var(--navigation-sidebar-closed-width))';
          }
        }

        if (params.size === 'lg') {
          return '385px';
        }

        return undefined;
      })(),
      [theme.fn.smallerThan('md')]: {
        padding: params.size === 'full' ? 0 : `${theme.other.spacing.md}px !important`,
        width: (function(){
          if (params.position === 'right') {
            if (params.size === 'full') {
              return 'calc(100% - var(--navigation-sidebar-closed-width))';
            }
          }

          if (params.size === 'lg') {
            if (params.position === 'right') {
              return '320px';
            }
          }

          return undefined;
        })()
      }
    },
    body: {
      height: '100%'
    }
  })
};
