/* eslint-disable react/jsx-props-no-spreading */
import { Select, Stepper, Textarea } from "@mantine/core";
import { closeAllModals } from "@mantine/modals";
import { FC, useContext } from "react";
import { useSelector } from "react-redux";

import { addErrorMessages } from "@/common/components/FormError/FormError.component";
import ModalAccentedBlock from "@/common/components/ModalAccentedBlock/ModalAccentedBlock.component";
import ModalControlGroup from "@/common/components/ModalControlGroup/ModalControlGroup.component";
import { RootStateWithLoading } from "@/core/store";
import ProfileLoadingOverlayComponent from "@/tenant-context/control-profile/components/ui/ProfileLoadingOverlay/ProfileLoadingOverlay.component";
import { CloseCaseModalContext } from "@/tenant-context/employee-app-action-response-center/components/modals/CloseCaseModal/CloseCaseModal.container";
import { useCloseCaseModalStyles } from "@/tenant-context/employee-app-action-response-center/components/modals/CloseCaseModal/CloseCaseModal.style";

export const createSelectItem = (label: string) => ({
  label: label,
  value: label
});

const CloseCaseModal: FC = () => {
  const { classes } = useCloseCaseModalStyles();

  const isLoading = useSelector((state: RootStateWithLoading) => state.loading.models.eaArc);

  const {
    emergecyReason,
    handleEmergencyReasonChange,
    handleIsAnyoneInjured,
    handleIsFalseAlarm,
    handleIsNotAnyoneInjured,
    handleIsNotFalseAlarm,
    nextStepWithValidation,
    step,
    formControls: {
      register,
      formState: {
        errors
      }
    },
    onSubmit,
    setStep,
    maxStep,
    isFalseAlarm,
    isAnyoneInjured,
    whatHappened
  } = useContext(CloseCaseModalContext);

  const getWhatHappenedData = () => {
    if (!whatHappened || whatHappened.length === 0) { 
      if (!isFalseAlarm) {
        return ['False Alarm','Vehicle Issue or Collision','Sickness or Injury','Crime','Lost or Trapped','Fire'].map(createSelectItem);
      } else {
        return ['Vehicle Issue or Collision','Sickness or Injury','Crime','Lost or Trapped','Fire'].map(createSelectItem);
      }
    } else {
      return whatHappened
        .sort((a, b) => a.displayOrder - b.displayOrder)
        .map((whatHappenedItem) => createSelectItem(whatHappenedItem.name));
    }
  };

  return (
    <form onSubmit={ onSubmit }>
      <ProfileLoadingOverlayComponent
        isLoading={ isLoading }
      />

      <Stepper
        active={ step }
        styles={ {
          steps: {
            marginInline: 120
          }
        } }
        size="sm"
        onStepClick={ setStep }
        className={ classes.closeCaseStepper }
      >
        <Stepper.Step
          completedIcon={ 1 }
          label="False Alarm"
          allowStepSelect={ 0 <= maxStep }
        >
          <ModalAccentedBlock
            accentedPrefix=""
          >
            Was this a false alarm?
          </ModalAccentedBlock>

          <ModalControlGroup
            isPrimarySubmit={ false }
            primaryActionLabel="No"
            onPrimary={ handleIsNotFalseAlarm }

            secondaryLabel="Yes"
            onSecondary={ handleIsFalseAlarm }

            additionalLabel="Cancel"
            onAdditional={ closeAllModals }

            isCancelAdditional
          />
        </Stepper.Step>

        <Stepper.Step
          completedIcon={ 2 }
          label="Was Anyone Injured"
          allowStepSelect={ !isFalseAlarm && (1 <= maxStep) }
        >
          <ModalAccentedBlock
            accentedPrefix=""
          >
            Was anyone injured?
          </ModalAccentedBlock>

          <ModalControlGroup
            isPrimarySubmit={ false }
            primaryActionLabel="No"
            onPrimary={ handleIsNotAnyoneInjured }

            secondaryLabel="Yes"
            onSecondary={ handleIsAnyoneInjured }

            additionalLabel="Cancel"
            onAdditional={ closeAllModals }

            isCancelAdditional
          />
        </Stepper.Step>

        <Stepper.Step
          completedIcon={ 3 }
          label="More Details"
          allowStepSelect={ 2 <= maxStep }
        >
          <ModalAccentedBlock
            accentedPrefix=""
          >
            More details
          </ModalAccentedBlock>

          <div className={ classes.form }>
            { isAnyoneInjured && <Select
              label="What happened?"
              data={ getWhatHappenedData() }
              defaultValue={ isFalseAlarm
                ? createSelectItem('False Alarm').value
                : undefined }
              value={ emergecyReason }
              onChange={ handleEmergencyReasonChange }
              error={ errors.emergency_reason?.message }
            /> }

            <Textarea
              placeholder="More Details"
              { ...register('details', addErrorMessages('Details', {
                required: true
              })) }
              error={ errors.details?.message }
            />

            <ModalControlGroup
              isPrimarySubmit={ false }
              primaryActionLabel="Next"
              onPrimary={ nextStepWithValidation }
              secondaryLabel="Cancel"
              onSecondary={ closeAllModals }
              isCancelSecondary
            />
          </div>
        </Stepper.Step>

        <Stepper.Step
          completedIcon={ 4 }
          label="Close Case"
          allowStepSelect={ 3 <= maxStep }
        >
          <ModalAccentedBlock
            accentedPrefix="Please Note"
          >
            Closing a case is permanent and cannot be undone.
          </ModalAccentedBlock>

          <ModalControlGroup
            isPrimarySubmit={ true }
            primaryActionLabel="Submit"
            // onPrimary={ nextStepWithValidation }
            secondaryLabel="Cancel"
            onSecondary={ closeAllModals }
            isCancelSecondary
          />
        </Stepper.Step>
      </Stepper>
    </form>
  );
};

export default CloseCaseModal;