import { Box, Divider, Flex } from '@mantine/core';
import { FC } from 'react';

import LineClamp from '@/common/components/LineClamp';
import { ReactComponent as DataMinrIcon } from "@/common/icons/dataminr.svg";
import { ReactComponent as RisklineIcon } from "@/common/icons/riskline.svg";
import { ReactComponent as FactalIcon } from "@/common/icons/RiskProviders/factal.svg";
import { ReactComponent as HealixIcon } from "@/common/icons/RiskProviders/healix.svg";
import { ReactComponent as MaxSecurityIcon } from "@/common/icons/RiskProviders/max-security.svg";
import { getTimeDurationFromCurrentDate } from "@/common/util/format/date";
import CountryFlag from "@/tenant-context/common/components/CountryFlag";
import LocalErrorBoundary from '@/tenant-context/common/components/LocalErrorBoundary';
import NumberHighlightedTextComponent from '@/tenant-context/common/components/NumberHighlightedText';
import RiskAlertBasicData from "@/tenant-context/visualisation-risk-alerts/components/RiskAlertBasicData";
import {
  useRiskAlertPopupStyles
} from '@/tenant-context/visualisation-risk-alerts/components/RiskAlertPopup/RiskAlertPopup.styles';
import { DataMinrExternalData } from "@/tenant-context/visualisation-risk-alerts/types/data-minr.types";
import { FactalExternalData } from '@/tenant-context/visualisation-risk-alerts/types/factal';
import { HealixExternalData } from '@/tenant-context/visualisation-risk-alerts/types/healix';
import { MaxSecurityExternalData } from '@/tenant-context/visualisation-risk-alerts/types/max-security';
import { BSOCExternalData } from "@/tenant-context/visualisation-risk-alerts/types/risk-alert-response";
import {
  RiskAlert,
  RiskAlertEvent,
  RiskAlertExternalData,
  RiskAlertJsonMeta } from "@/tenant-context/visualisation-risk-alerts/types/risk-alerts";
import { RiskConnectorExternalData } from '@/tenant-context/visualisation-risk-alerts/types/risk-alerts-generic';

type ExternalDataType = 
  | RiskAlertExternalData 
  | DataMinrExternalData 
  | FactalExternalData 
  | MaxSecurityExternalData 
  | HealixExternalData 
  | BSOCExternalData;

type ProviderSource = 'factal' | 'dataminr' | 'riskline' | 'healix' | 'max-security' | 'bsoc';

type Props = {
  json: { meta: RiskAlertJsonMeta; alert: RiskAlert; externalData?: ExternalDataType },
  riskAlertData: RiskAlertEvent<ExternalDataType>,
  isImpacted: boolean,
  source: ProviderSource
}

export const RiskAlertPopupContent: FC<Props> = ({
  json,
  riskAlertData,
  isImpacted: _isImpacted,
  source
}) => {
  const { classes } = useRiskAlertPopupStyles();
  const mappedRiskAlertData: RiskAlertEvent<RiskConnectorExternalData> = {
    ...riskAlertData,
    json: {
      ...riskAlertData.json,
      alert: {
        ...riskAlertData.json.alert,
        start_date: new Date(riskAlertData?.json.alert.start_date).toLocaleDateString(),
        end_date: new Date(riskAlertData?.json.alert.end_date).toLocaleDateString()
      }
    }
  };

  const locationText = () => {
    switch (source) {
    case 'factal':
      return json.alert?.location_explanation;
    case 'dataminr':
      return (json.externalData as DataMinrExternalData)?.eventLocation?.name;
    case 'riskline':
      return `${ json.alert.location_explanation?.split(",")[0] || '' }, ${ json.alert.countries[0]?.name }`;
    case 'max-security':
      return (json.externalData as MaxSecurityExternalData)?.affected_area || json.alert.location_explanation;
    case 'healix':
      return json.alert?.countries[0]?.name;
    case 'bsoc':
      return `${(json.externalData as RiskAlertExternalData)?.region}, ${(json.externalData as RiskAlertExternalData)?.province}`;
    default:
      return json.alert?.location_explanation;
    }
  };

  const getProviderIcon = () => {
    switch (source) {
    case 'factal':
      return <FactalIcon className={ classes.riskAlerProviderIcon }/>;
    case 'dataminr':
      return <DataMinrIcon className={ classes.riskAlerProviderIcon }/>;
    case 'riskline':
      return <RisklineIcon className={ classes.riskAlerProviderIcon }/>;
    case 'healix':
      return <HealixIcon className={ classes.riskAlerProviderIcon }/>;
    case 'max-security':
      return <MaxSecurityIcon className={ classes.riskAlerProviderIcon }/>;
    default:
      return null;
    }
  };

  const getProviderName = () => {
    switch (source) {
    case 'factal':
      return 'Factal';
    case 'dataminr':
      return 'Dataminr';
    case 'riskline':
      return 'Riskline Risk';
    case 'healix':
      return 'Healix';
    case 'max-security':
      return 'MAX Security';
    default:
      return '';
    }
  };

  const getProviderSection = () => {
    if (source === 'bsoc') {
      return null;
    }

    return (
      <Box>
        <Divider
          className={ classes.divider }
        />
        <Box className={ classes.riskAlertProvider }>
          <Box className={ classes.issuedBy }>Issued by</Box>
          { getProviderIcon() }
          <Box className={ classes.riskAlertProviderName }> { getProviderName() }</Box>
        </Box>
      </Box>
    );
  };

  const content = (
    <Box className={ classes.popupContainer }>
      <Flex direction="column" gap="sm">
        <Box className={ classes.popupHeader }>
          <Flex>
            { json.alert.countries?.map((country, index) => (
              <CountryFlag
                key={ `${country.iso_code}${index}` }
                width={ 65 }
                height={ 50 }
                countryCode={ country.iso_code }
              />
            )) }
          </Flex>
          { locationText() }
        </Box>
        <Box className={ classes.riskAlertTitle }>
          <LineClamp numberOfLines={ 2 }>
            { json.alert.title }
          </LineClamp>
          <NumberHighlightedTextComponent
            numberFw={ 'bold' }
            numberFs={ 'sm' }
            alphaFs={ 12 }
            text={ json.alert.start_date !== undefined
              ? `${ getTimeDurationFromCurrentDate(new Date(json.alert.start_date)) } AGO`
              : '' }
          />
        </Box>
      </Flex>

      <RiskAlertBasicData riskAlertData={ mappedRiskAlertData }/>

      { getProviderSection() }
    </Box>
  );

  return (
    <LocalErrorBoundary>
      { content }
    </LocalErrorBoundary>
  );
}; 
