import React from "react";

import { ReactComponent as MapBox } from "@/common/icons/RiskProviders/mapbox-brand.svg";

type MetaDataItem = {
  label: string;
  value: React.ReactNode;
};

const MapBoxLogo = () => (
  <a href="https://www.mapbox.com/" target="_blank" title="Powered by Mapbox"  rel="noreferrer">
    <MapBox width={ 72 } />
  </a>
);

export const trafficLayerMetadata: MetaDataItem[] = [
  { label: 'Data source:', value: (
    <MapBoxLogo />
  ) },
  { label: 'Coverage:', value: 'Global' },
  { label: 'Update frequency:', value: '15 minutes' }
];
