import { Box } from "@mantine/core";
import { FC, ReactNode } from "react";

import { useWeatherStaticStyles } from "@/tenant-context/visualisation-weather/components/WeatherLegendStatic.styles";

type Props = {
  children?: ReactNode;
}

/**
 * This is simple component which renders the weather legend as image
 *
 * Please try to use this approach
 * */
export const WeatherLegendStatic: FC<Props> = ({ children }) => {

  const { classes } = useWeatherStaticStyles();

  if (!children) {
    return null;
  }
  
  return (
    <Box className={ classes.root }>
      { children }
    </Box>
  );
};
