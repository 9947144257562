

import { createModel } from "@rematch/core";

import { Dispatch, RootModel } from "@/core/store";
import { getRiskImpactEvents } from "@/tenant-context/visualisation-risk-details/api/risk-details";
import { ImpactedArea, RiskImpactEvent } from '@/tenant-context/visualisation-risk-details/types/risk-impact';

export type RiskDetailsState = {
    currentRiskAlertImpactEvent?: RiskImpactEvent,
    isRiskImpactCircleEnabled:boolean,
    popupLineEnabled:boolean,
    impactedAreaDetails?: {
      impactedAreas?: ImpactedArea[];
      selectedImpactedAreaId?: string;
    }
  }

const riskDetailsModel = ({
  name:'riskDetails',
  state:{
    isRiskImpactCircleEnabled:false,
    currentRiskAlertImpactEvent: undefined,
    popupLineEnabled:false,
    impactedAreaDetails: undefined
  } as RiskDetailsState,

  reducers:{
    SET_CURRENT_RISK_IMPACT(
      state:RiskDetailsState,
      selectedRiskAlertImpact:RiskImpactEvent
    ):RiskDetailsState {
      return {
        ...state,
        isRiskImpactCircleEnabled:true,
        currentRiskAlertImpactEvent:selectedRiskAlertImpact
      };
    },

    RESET_CURRENT_IMPACT(state:RiskDetailsState):RiskDetailsState {
      return {
        ...state,
        isRiskImpactCircleEnabled:false,
        currentRiskAlertImpactEvent:undefined
      };
    },

    TOGGLE_POPUP_LINE(state:RiskDetailsState, enablePopupLine:boolean){
      return {
        ...state,
        popupLineEnabled:enablePopupLine
      };
    },

    SET_SELECTED_IMPACT_AREA_DETAILS(
      state:RiskDetailsState,
      impactedAreaDetails?: {
        impactedAreas?: ImpactedArea[];
        selectedImpactedAreaId?: string;
      }
    ) {
      return {
        ...state,
        impactedAreaDetails: impactedAreaDetails
      };
    }
  },
  effects: (dispatch: Dispatch) => ({
    async getImpactDataForActiveRiskAlert(
      options: { alertId: string, impactAreaId?: string, impactedAreas?: ImpactedArea[] }
    ): Promise<void> {
      const { alertId } = options;
      const impactData = await getRiskImpactEvents(alertId);
      dispatch.bigMap.setCurrentBigMapPopup("Risk");
      dispatch.riskDetails.SET_CURRENT_RISK_IMPACT(impactData);
    },
    resetCurrentImpact(): void {
      dispatch.bigMap.setCurrentBigMapPopup("None");
      dispatch.riskDetails.RESET_CURRENT_IMPACT();
      dispatch.riskDetails.SET_SELECTED_IMPACT_AREA_DETAILS(undefined);
    }
  })
});

export const riskDetails = createModel<RootModel>()(
  riskDetailsModel
);
