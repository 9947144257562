/* eslint-disable max-lines */
import { MapboxMap, MapRef } from "react-map-gl";

// Extreme risk level icons
import ExtremeBase from "@/common/icons/markers/RiskEvents/Extreme/Base.png";
import ExtremeConflict from "@/common/icons/markers/RiskEvents/Extreme/Conflict.png";
import ExtremeCrime from "@/common/icons/markers/RiskEvents/Extreme/Crime.png";
import DataMinrExtremeBase from "@/common/icons/markers/RiskEvents/Extreme/Dataminr.png";
import ExtremeBaseLg from "@/common/icons/markers/RiskEvents/Extreme/extreme-base-large.png";
import ExtremeHighImpactExplosiveActivity from "@/common/icons/markers/RiskEvents/Extreme/HighImpactExplosiveActivity.png";
import ExtremeIDFUAVActivity from "@/common/icons/markers/RiskEvents/Extreme/IDFUAVActivity.png";
import ExtremeIED from "@/common/icons/markers/RiskEvents/Extreme/IED.png";
import ExtremeMedical from "@/common/icons/markers/RiskEvents/Extreme/Medical.png";
import ExtremeNatural from "@/common/icons/markers/RiskEvents/Extreme/Natural.png";
import ExtremePolitical from "@/common/icons/markers/RiskEvents/Extreme/Political.png";
import ExtremeSAF from "@/common/icons/markers/RiskEvents/Extreme/SAF.png";
import ExtremeSecurityOperations from "@/common/icons/markers/RiskEvents/Extreme/SecurityOperations.png";
import ExtremeTravelSafety from "@/common/icons/markers/RiskEvents/Extreme/TravelSafety.png";
import ExtremeProtestsAndGatherings from "@/common/icons/markers/RiskEvents/Extreme/Unrest.png";
// High risk level icons
import HighBase from "@/common/icons/markers/RiskEvents/High/Base.png";
import HighConflict from "@/common/icons/markers/RiskEvents/High/Conflict.png";
import HighCrime from "@/common/icons/markers/RiskEvents/High/Crime.png";
import DataMinrHighBase from "@/common/icons/markers/RiskEvents/High/Dataminr.png";
import HighBaseLg from "@/common/icons/markers/RiskEvents/High/high-base-large.png";
import HighHighImpactExplosiveActivity from "@/common/icons/markers/RiskEvents/High/HighImpactExplosiveActivity.png";
import HighIDFUAVActivity from "@/common/icons/markers/RiskEvents/High/IDFUAVActivity.png";
import HighIED from "@/common/icons/markers/RiskEvents/High/IED.png";
import HighMedical from "@/common/icons/markers/RiskEvents/High/Medical.png";
import HighNatural from "@/common/icons/markers/RiskEvents/High/Natural.png";
import HighPolitical from "@/common/icons/markers/RiskEvents/High/Political.png";
import HighSAF from "@/common/icons/markers/RiskEvents/High/SAF.png";
import HighSecurityOperations from "@/common/icons/markers/RiskEvents/High/SecurityOperations.png";
import HighTravelSafety from "@/common/icons/markers/RiskEvents/High/TravelSafety.png";
import HighProtestsAndGatherings from "@/common/icons/markers/RiskEvents/High/Unrest.png";
// Low risk level icons
import LowBase from "@/common/icons/markers/RiskEvents/Low/Base.png";
import LowConflict from "@/common/icons/markers/RiskEvents/Low/Conflict.png";
import LowCrime from "@/common/icons/markers/RiskEvents/Low/Crime.png";
import LowBaseLg from "@/common/icons/markers/RiskEvents/Low/low-base-large.png";
import LowMedical from "@/common/icons/markers/RiskEvents/Low/Medical.png";
import LowNatural from "@/common/icons/markers/RiskEvents/Low/Natural.png";
import LowPolitical from "@/common/icons/markers/RiskEvents/Low/Political.png";
import LowTravelSafety from "@/common/icons/markers/RiskEvents/Low/TravelSafety.png";
import LowProtestsAndGatherings from "@/common/icons/markers/RiskEvents/Low/Unrest.png";
import ActiveShooterBlack from "@/common/icons/markers/RiskEvents/MaxSecurity/Black/active_shooter.png";
import AirStrikeBlack from "@/common/icons/markers/RiskEvents/MaxSecurity/Black/air_strike.png";
import ArbitraryDetentionBlack from "@/common/icons/markers/RiskEvents/MaxSecurity/Black/arbitrary_detention.png";
import ArmedConflictBlack from "@/common/icons/markers/RiskEvents/MaxSecurity/Black/armed_conflict.png";
import CivilUnrestBlack from "@/common/icons/markers/RiskEvents/MaxSecurity/Black/civil_unrest.png";
import conflict_terrorism from "@/common/icons/markers/RiskEvents/MaxSecurity/Black/conflict_terrorism.png";
import CrimeBlack from "@/common/icons/markers/RiskEvents/MaxSecurity/Black/crime.png";
import CyberBlack from "@/common/icons/markers/RiskEvents/MaxSecurity/Black/cyber.png";
import DevelopingBlack from "@/common/icons/markers/RiskEvents/MaxSecurity/Black/developing.png";
import EarthquakeBlack from "@/common/icons/markers/RiskEvents/MaxSecurity/Black/earthquake.png";
import EconomicsBlack from "@/common/icons/markers/RiskEvents/MaxSecurity/Black/economics.png";
import FireBlack from "@/common/icons/markers/RiskEvents/MaxSecurity/Black/fire.png";
import HealthBlack from "@/common/icons/markers/RiskEvents/MaxSecurity/Black/health.png";
import HolidayBlack from "@/common/icons/markers/RiskEvents/MaxSecurity/Black/holiday.png";
import KidnapAndRansomBlack from "@/common/icons/markers/RiskEvents/MaxSecurity/Black/kidnap_and_ransom.png";
import LaborActionBlack from "@/common/icons/markers/RiskEvents/MaxSecurity/Black/labor_action.png";
import LargeScaleEventsBlack from "@/common/icons/markers/RiskEvents/MaxSecurity/Black/large_scale_events.png";
import MaritimeIncidentsBlack from "@/common/icons/markers/RiskEvents/MaxSecurity/Black/maritime_incidents.png";
import MilitancyTerrorismBlack from "@/common/icons/markers/RiskEvents/MaxSecurity/Black/militancy_terrorism.png";
import OrganisedCrimeBlack from "@/common/icons/markers/RiskEvents/MaxSecurity/Black/organised_crime.png";
import PiracyBlack from "@/common/icons/markers/RiskEvents/MaxSecurity/Black/piracy.png";
import PoliticsBlack from "@/common/icons/markers/RiskEvents/MaxSecurity/Black/politics.png";
import ProtestBlack from "@/common/icons/markers/RiskEvents/MaxSecurity/Black/protest.png";
import RocketBlack from "@/common/icons/markers/RiskEvents/MaxSecurity/Black/rocket.png";
import SecurityOperationsBlack from "@/common/icons/markers/RiskEvents/MaxSecurity/Black/security_operations.png";
import SupplyChainDisruptionsBlack from "@/common/icons/markers/RiskEvents/MaxSecurity/Black/supply_chain_disruptions.png";
import TravelBlack from "@/common/icons/markers/RiskEvents/MaxSecurity/Black/travel.png";
import VolcanoBlack from "@/common/icons/markers/RiskEvents/MaxSecurity/Black/volcano.png";
import WeatherBlack from "@/common/icons/markers/RiskEvents/MaxSecurity/Black/weather.png";
import ActiveShooterWhite from "@/common/icons/markers/RiskEvents/MaxSecurity/White/active_shooter.png";
import AirStrikeWhite from "@/common/icons/markers/RiskEvents/MaxSecurity/White/air_strike.png";
import ArbitraryDetentionWhite from "@/common/icons/markers/RiskEvents/MaxSecurity/White/arbitrary_detention.png";
import ArmedConflictWhite from "@/common/icons/markers/RiskEvents/MaxSecurity/White/armed_conflict.png";
import CivilUnrestWhite from "@/common/icons/markers/RiskEvents/MaxSecurity/White/civil_unrest.png";
import CrimeWhite from "@/common/icons/markers/RiskEvents/MaxSecurity/White/crime.png";
import CyberWhite from "@/common/icons/markers/RiskEvents/MaxSecurity/White/cyber.png";
import DevelopingWhite from "@/common/icons/markers/RiskEvents/MaxSecurity/White/developing.png";
import EarthquakeWhite from "@/common/icons/markers/RiskEvents/MaxSecurity/White/earthquake.png";
import EconomicsWhite from "@/common/icons/markers/RiskEvents/MaxSecurity/White/economics.png";
import FireWhite from "@/common/icons/markers/RiskEvents/MaxSecurity/White/fire.png";
import HealthWhite from "@/common/icons/markers/RiskEvents/MaxSecurity/White/health.png";
import HolidayWhite from "@/common/icons/markers/RiskEvents/MaxSecurity/White/holiday.png";
import KidnapAndRansomWhite from "@/common/icons/markers/RiskEvents/MaxSecurity/White/kidnap_and_ransom.png";
import LaborActionWhite from "@/common/icons/markers/RiskEvents/MaxSecurity/White/labor_action.png";
import LargeScaleEventsWhite from "@/common/icons/markers/RiskEvents/MaxSecurity/White/large_scale_events.png";
import MaritimeIncidentsWhite from "@/common/icons/markers/RiskEvents/MaxSecurity/White/maritime_incidents.png";
import MilitancyTerrorismWhite from "@/common/icons/markers/RiskEvents/MaxSecurity/White/militancy_terrorism.png";
import OrganisedCrimeWhite from "@/common/icons/markers/RiskEvents/MaxSecurity/White/organised_crime.png";
import PiracyWhite from "@/common/icons/markers/RiskEvents/MaxSecurity/White/piracy.png";
import PoliticsWhite from "@/common/icons/markers/RiskEvents/MaxSecurity/White/politics.png";
import ProtestWhite from "@/common/icons/markers/RiskEvents/MaxSecurity/White/protest.png";
import RocketWhite from "@/common/icons/markers/RiskEvents/MaxSecurity/White/rocket.png";
import SecurityOperationsWhite from "@/common/icons/markers/RiskEvents/MaxSecurity/White/security_operations.png";
import SupplyChainDisruptionsWhite from "@/common/icons/markers/RiskEvents/MaxSecurity/White/supply_chain_disruptions.png";
import TravelWhite from "@/common/icons/markers/RiskEvents/MaxSecurity/White/travel.png";
import VolcanoWhite from "@/common/icons/markers/RiskEvents/MaxSecurity/White/volcano.png";
import WeatherWhite from "@/common/icons/markers/RiskEvents/MaxSecurity/White/weather.png";
// Medium risk level icons
import MediumBase from "@/common/icons/markers/RiskEvents/Medium/Base.png";
import MediumConflict from "@/common/icons/markers/RiskEvents/Medium/Conflict.png";
import MediumCrime from "@/common/icons/markers/RiskEvents/Medium/Crime.png";
import DataMinrMediumBase from "@/common/icons/markers/RiskEvents/Medium/Dataminr.png";
import MediumMedical from "@/common/icons/markers/RiskEvents/Medium/Medical.png";
import MediumBaseLg from "@/common/icons/markers/RiskEvents/Medium/medium-base-large.png";
import MediumNatural from "@/common/icons/markers/RiskEvents/Medium/Natural.png";
import MediumPolitical from "@/common/icons/markers/RiskEvents/Medium/Political.png";
import MediumTravelSafety from "@/common/icons/markers/RiskEvents/Medium/TravelSafety.png";
import MediumProtestsAndGatherings from "@/common/icons/markers/RiskEvents/Medium/Unrest.png";
// Moderate risk level icons
import ModerateBase from "@/common/icons/markers/RiskEvents/Moderate/Base.png";
import ModerateConflict from "@/common/icons/markers/RiskEvents/Moderate/Conflict.png";
import ModerateCrime from "@/common/icons/markers/RiskEvents/Moderate/Crime.png";
import ModerateMedical from "@/common/icons/markers/RiskEvents/Moderate/Medical.png";
import ModerateBaseLg from "@/common/icons/markers/RiskEvents/Moderate/moderate-base-large.png";
import ModerateNatural from "@/common/icons/markers/RiskEvents/Moderate/Natural.png";
import ModeratePolitical from "@/common/icons/markers/RiskEvents/Moderate/Political.png";
import ModerateTravelSafety from "@/common/icons/markers/RiskEvents/Moderate/TravelSafety.png";
import ModerateProtestsAndGatherings from "@/common/icons/markers/RiskEvents/Moderate/Unrest.png";
import RiskAlertImpacted from "@/common/icons/markers/RiskEvents/RiskAlertImpacted.png";
//other
import RiskAlertOutline from "@/common/icons/RiskAlertOutline.svg";
import { MapIcon } from "@/common/types/icon";
import useMapIcons from "@/tenant-context/common/hooks/useMapIcons";

const riskAlertIcons: Readonly<MapIcon[]> = [
  {
    name: 'RiskAlertIcon_LowBase',
    image:  LowBase
  },
  {
    name: 'RiskAlertIcon_LowCrime',
    image: LowCrime
  },
  {
    name: 'RiskAlertIcon_LowConflict',
    image: LowConflict
  },
  {
    name: 'RiskAlertIcon_LowTravelSafety',
    image: LowTravelSafety
  },
  {
    name: 'RiskAlertIcon_LowMedical',
    image: LowMedical
  },
  {
    name: 'RiskAlertIcon_LowNatural',
    image: LowNatural
  },
  {
    name: 'RiskAlertIcon_LowPolitical',
    image: LowPolitical
  },
  {
    name: 'RiskAlertIcon_LowProtestsAndGatherings',
    image: LowProtestsAndGatherings
  },
  // Moderate
  {
    name: 'RiskAlertIcon_ModerateBase',
    image:  ModerateBase
  },
  {
    name: 'RiskAlertIcon_ModerateCrime',
    image: ModerateCrime
  },
  {
    name: 'RiskAlertIcon_ModerateConflict',
    image: ModerateConflict
  },
  {
    name: 'RiskAlertIcon_ModerateTravelSafety',
    image: ModerateTravelSafety
  },
  {
    name: 'RiskAlertIcon_ModerateMedical',
    image: ModerateMedical
  },
  {
    name: 'RiskAlertIcon_ModerateNatural',
    image: ModerateNatural
  },
  {
    name: 'RiskAlertIcon_ModeratePolitical',
    image: ModeratePolitical
  },
  {
    name: 'RiskAlertIcon_ModerateProtestsAndGatherings',
    image: ModerateProtestsAndGatherings
  },
  // Medium
  {
    name: 'RiskAlertIcon_MediumBase',
    image:  MediumBase
  },
  {
    name: 'RiskAlertIcon_MediumCrime',
    image: MediumCrime
  },
  {
    name: 'RiskAlertIcon_MediumConflict',
    image: MediumConflict
  },
  {
    name: 'RiskAlertIcon_MediumTravelSafety',
    image: MediumTravelSafety
  },
  {
    name: 'RiskAlertIcon_MediumMedical',
    image: MediumMedical
  },
  {
    name: 'RiskAlertIcon_MediumNatural',
    image: MediumNatural
  },
  {
    name: 'RiskAlertIcon_MediumPolitical',
    image: MediumPolitical
  },
  {
    name: 'RiskAlertIcon_MediumProtestsAndGatherings',
    image: MediumProtestsAndGatherings
  },
  // High
  {
    name: 'RiskAlertIcon_HighBase',
    image:  HighBase
  },
  {
    name: 'RiskAlertIcon_HighCrime',
    image: HighCrime
  },
  {
    name: 'RiskAlertIcon_HighConflict',
    image: HighConflict
  },
  {
    name: 'RiskAlertIcon_HighTravelSafety',
    image: HighTravelSafety
  },
  {
    name: 'RiskAlertIcon_HighMedical',
    image: HighMedical
  },
  {
    name: 'RiskAlertIcon_HighNatural',
    image: HighNatural
  },
  {
    name: 'RiskAlertIcon_HighPolitical',
    image: HighPolitical
  },
  {
    name: 'RiskAlertIcon_HighProtestsAndGatherings',
    image: HighProtestsAndGatherings
  },
  {
    name: 'RiskAlertIcon_HighIED',
    image: HighIED
  },
  {
    name: 'RiskAlertIcon_HighSAF',
    image: HighSAF
  },
  {
    name: 'RiskAlertIcon_HighHighImpactExplosiveActivity',
    image: HighHighImpactExplosiveActivity
  },
  {
    name: 'RiskAlertIcon_HighIDFUAVActivity',
    image: HighIDFUAVActivity
  },
  {
    name: 'RiskAlertIcon_HighSecurityOperations',
    image: HighSecurityOperations
  },
  // Extreme
  {
    name: 'RiskAlertIcon_ExtremeBase',
    image: ExtremeBase
  },
  {
    name: 'RiskAlertIcon_ExtremeCrime',
    image: ExtremeCrime
  },
  {
    name: 'RiskAlertIcon_ExtremeConflict',
    image: ExtremeConflict
  },
  {
    name: 'RiskAlertIcon_ExtremeTravelSafety',
    image: ExtremeTravelSafety
  },
  {
    name: 'RiskAlertIcon_ExtremeMedical',
    image: ExtremeMedical
  },
  {
    name: 'RiskAlertIcon_ExtremeNatural',
    image: ExtremeNatural
  },
  {
    name: 'RiskAlertIcon_ExtremePolitical',
    image: ExtremePolitical
  },
  {
    name: 'RiskAlertIcon_ExtremeProtestsAndGatherings',
    image: ExtremeProtestsAndGatherings
  },
  {
    name: 'RiskAlertIcon_ExtremeIED',
    image: ExtremeIED
  },
  {
    name: 'RiskAlertIcon_ExtremeSAF',
    image: ExtremeSAF
  },
  {
    name: 'RiskAlertIcon_ExtremeHighImpactExplosiveActivity',
    image: ExtremeHighImpactExplosiveActivity
  },
  {
    name: 'RiskAlertIcon_ExtremeIDFUAVActivity',
    image: ExtremeIDFUAVActivity
  },
  {
    name: 'RiskAlertIcon_ExtremeSecurityOperations',
    image: ExtremeSecurityOperations
  },
  //Impacted
  {
    name:'RiskAlertIcon_RiskAlertImpacted',
    image:RiskAlertImpacted
  },
  {
    name:'RiskAlertIcon_RiskAlertImpactedOutline',
    image:RiskAlertOutline
  },
  // DataMinr
  {
    name: 'RiskAlertIcon_ExtremeDataMinrBase',
    image: DataMinrExtremeBase
  },
  {
    name: 'RiskAlertIcon_HighDataMinrBase',
    image: DataMinrHighBase
  },
  {
    name: 'RiskAlertIcon_MediumDataMinrBase',
    image: DataMinrMediumBase
  },
  // MAX Security
  {
    name: 'RiskAlertIcon_ExtremeTriangleBase',
    image: ExtremeBaseLg
  },
  {
    name: 'RiskAlertIcon_HighTriangleBase',
    image: HighBaseLg
  },
  {
    name: 'RiskAlertIcon_MediumTriangleBase',
    image: MediumBaseLg
  },
  {
    name: 'RiskAlertIcon_LowTriangleBase',
    image: LowBaseLg
  },
  {
    name: 'RiskAlertIcon_ModerateTriangleBase',
    image: ModerateBaseLg
  },
  {
    name: 'RiskAlertIcon_MaxSecurity_ActiveShooter_White',
    image: ActiveShooterWhite
  },
  {
    name: 'RiskAlertIcon_MaxSecurity_AirStrike_White',
    image: AirStrikeWhite
  },
  {
    name: 'RiskAlertIcon_MaxSecurity_ArbitraryDetention_White',
    image: ArbitraryDetentionWhite
  },
  {
    name: 'RiskAlertIcon_MaxSecurity_ArmedConflict_White',
    image: ArmedConflictWhite
  },
  {
    name: 'RiskAlertIcon_MaxSecurity_CivilUnrest_White',
    image: CivilUnrestWhite
  },
  {
    name: 'RiskAlertIcon_MaxSecurity_Crime_White',
    image: CrimeWhite
  },
  {
    name: 'RiskAlertIcon_MaxSecurity_Cyber_White',
    image: CyberWhite
  },
  {
    name: 'RiskAlertIcon_MaxSecurity_Developing_White',
    image: DevelopingWhite
  },
  {
    name: 'RiskAlertIcon_MaxSecurity_Earthquake_White',
    image: EarthquakeWhite
  },
  {
    name: 'RiskAlertIcon_MaxSecurity_Economics_White',
    image: EconomicsWhite
  },
  {
    name: 'RiskAlertIcon_MaxSecurity_Fire_White',
    image: FireWhite
  },
  {
    name: 'RiskAlertIcon_MaxSecurity_Health_White',
    image: HealthWhite
  },
  {
    name: 'RiskAlertIcon_MaxSecurity_Holiday_White',
    image: HolidayWhite
  },
  {
    name: 'RiskAlertIcon_MaxSecurity_KidnapAndRansom_White',
    image: KidnapAndRansomWhite
  },
  {
    name: 'RiskAlertIcon_MaxSecurity_LaborAction_White',
    image: LaborActionWhite
  },
  {
    name: 'RiskAlertIcon_MaxSecurity_LargeScaleEvents_White',
    image: LargeScaleEventsWhite
  },
  {
    name: 'RiskAlertIcon_MaxSecurity_MaritimeIncidents_White',
    image: MaritimeIncidentsWhite
  },
  {
    name: 'RiskAlertIcon_MaxSecurity_MilitancyTerrorism_White',
    image: MilitancyTerrorismWhite
  },
  {
    name: 'RiskAlertIcon_MaxSecurity_OrganisedCrime_White',
    image: OrganisedCrimeWhite
  },
  {
    name: 'RiskAlertIcon_MaxSecurity_Piracy_White',
    image: PiracyWhite
  },
  {
    name: 'RiskAlertIcon_MaxSecurity_Politics_White',
    image: PoliticsWhite
  },
  {
    name: 'RiskAlertIcon_MaxSecurity_Protest_White',
    image: ProtestWhite
  },
  {
    name: 'RiskAlertIcon_MaxSecurity_Rocket_White',
    image: RocketWhite
  },
  {
    name: 'RiskAlertIcon_MaxSecurity_SecurityOperations_White',
    image: SecurityOperationsWhite
  },
  {
    name: 'RiskAlertIcon_MaxSecurity_SupplyChainDisruptions_White',
    image: SupplyChainDisruptionsWhite
  },
  {
    name: 'RiskAlertIcon_MaxSecurity_Travel_White',
    image: TravelWhite
  },
  {
    name: 'RiskAlertIcon_MaxSecurity_Volcano_White',
    image: VolcanoWhite
  },
  {
    name: 'RiskAlertIcon_MaxSecurity_Weather_White',
    image: WeatherWhite
  },
  {
    name: 'RiskAlertIcon_MaxSecurity_ActiveShooter',
    image: ActiveShooterBlack
  },
  {
    name: 'RiskAlertIcon_MaxSecurity_AirStrike',
    image: AirStrikeBlack
  },
  {
    name: 'RiskAlertIcon_MaxSecurity_ArbitraryDetention',
    image: ArbitraryDetentionBlack
  },
  {
    name: 'RiskAlertIcon_MaxSecurity_ArmedConflict',
    image: ArmedConflictBlack
  },
  {
    name: 'RiskAlertIcon_MaxSecurity_CivilUnrest',
    image: CivilUnrestBlack
  },
  {
    name: 'RiskAlertIcon_MaxSecurity_Crime',
    image: CrimeBlack
  },
  {
    name: 'RiskAlertIcon_MaxSecurity_Cyber',
    image: CyberBlack
  },
  {
    name: 'RiskAlertIcon_MaxSecurity_Developing',
    image: DevelopingBlack
  },
  {
    name: 'RiskAlertIcon_MaxSecurity_Earthquake',
    image: EarthquakeBlack
  },
  {
    name: 'RiskAlertIcon_MaxSecurity_Economics',
    image: EconomicsBlack
  },
  {
    name: 'RiskAlertIcon_MaxSecurity_Fire',
    image: FireBlack
  },
  {
    name: 'RiskAlertIcon_MaxSecurity_Health',
    image: HealthBlack
  },
  {
    name: 'RiskAlertIcon_MaxSecurity_Holiday',
    image: HolidayBlack
  },
  {
    name: 'RiskAlertIcon_MaxSecurity_KidnapAndRansom',
    image: KidnapAndRansomBlack
  },
  {
    name: 'RiskAlertIcon_MaxSecurity_LaborAction',
    image: LaborActionBlack
  },
  {
    name: 'RiskAlertIcon_MaxSecurity_LargeScaleEvents',
    image: LargeScaleEventsBlack
  },
  {
    name: 'RiskAlertIcon_MaxSecurity_MaritimeIncidents',
    image: MaritimeIncidentsBlack
  },
  {
    name: 'RiskAlertIcon_MaxSecurity_MilitancyTerrorism',
    image: MilitancyTerrorismBlack
  },
  {
    name: 'RiskAlertIcon_MaxSecurity_OrganisedCrime',
    image: OrganisedCrimeBlack
  },
  {
    name: 'RiskAlertIcon_MaxSecurity_Piracy',
    image: PiracyBlack
  },
  {
    name: 'RiskAlertIcon_MaxSecurity_Politics',
    image: PoliticsBlack
  },
  {
    name: 'RiskAlertIcon_MaxSecurity_Protest',
    image: ProtestBlack
  },
  {
    name: 'RiskAlertIcon_MaxSecurity_Rocket',
    image: RocketBlack
  },
  {
    name: 'RiskAlertIcon_MaxSecurity_SecurityOperations',
    image: SecurityOperationsBlack
  },
  {
    name: 'RiskAlertIcon_MaxSecurity_SupplyChainDisruptions',
    image: SupplyChainDisruptionsBlack
  },
  {
    name: 'RiskAlertIcon_MaxSecurity_Travel',
    image: TravelBlack
  },
  {
    name: 'RiskAlertIcon_MaxSecurity_Volcano',
    image: VolcanoBlack
  },
  {
    name: 'RiskAlertIcon_MaxSecurity_Weather',
    image: WeatherBlack
  },
  {
    name: 'RiskAlertIcon_MaxSecurity_Conflict&Terrorism',
    image: conflict_terrorism
  }
];

export const useRiskAlertIcons =
  (map: MapboxMap | MapRef | undefined, isOverrideIcons=false) => useMapIcons(riskAlertIcons, map, isOverrideIcons);
