import { createStyles } from "@mantine/core";

import { CSS_KEY } from "@/core/styles/mantine/constants";

export const usePlaybookSelectModalStyles = createStyles((theme) => ({
  wrapper: {
    maxWidth: '100%',
    marginTop: '24px'
  },
  buttonContainer: {
    marginTop: '16px',
    display: 'flex',
    width: '100%',
    justifyContent: "flex-end"
  },
  submitButtonContainer: {
    display: "flex",
    alignSelf: 'end',
    gap: '16px'
  },
  descriptionLabel:{
    marginTop: '8px',
    fontSize: 14,
    color: theme.colors.neutral[6],
    fontWeight: 500,
    display: "block"
  },
  section: {
    marginBottom: 40,
    [`& .${CSS_KEY}-Textarea-input`]:{
      minHeight: 200
    }
  },
  selectAllCheckboxContainer: {
    padding: '8px 16px',
    margin: 0
  },
  checkboxContainer: {
    padding: '16px',
    margin: '12px 0',
    backgroundColor: theme.other.semantic.surfaceBackground.tertiary
  }
}));