import { createStyles } from "@mantine/core";
import { MantineTheme } from "@mantine/styles/lib/theme";

export const useCountryRiskLevelPopupStyles = createStyles((theme: MantineTheme) => ({
  wrapper: {
    position: 'relative',
    width: 385,
    fontSize: theme.fontSizes.sm,
    maxHeight: 500,
    overflowY: "auto"
  },

  closeIcon: {
    position: 'absolute',
    right: 0,
    top: 0
  },

  countryWrapper: {
    display: "flex",
    marginBottom: 24,
    '& > div': {
      marginLeft: 16
    }
  },

  subHeading: {
    display: "flex",
    fontSize: theme.fontSizes.xs,
    fontWeight: theme.other.fw.bold,
    marginBottom: 20,
    '& >  div': {
      marginLeft: 16
    }
  },

  summaryText: {
    marginBottom: 24
  },

  divider: {
    borderColor: theme.colors.neutral[6]
  },

  riskSource: {
    display: "flex",
    alignItems: "center",
    marginTop: 8,
    fontSize: theme.fontSizes.xs,
    color: theme.colors.neutral[5],
    '& > svg': {
      width: 16,
      height: 16,
      marginRight: 8
    }
  }
}));
