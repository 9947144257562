import { ActionIcon, ActionIconProps } from "@mantine/core";
import { FC } from "react";

import { ReactComponent as FilterSettings } from "@/common/icons/filter-settings.svg";

interface Props extends ActionIconProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

export const FilterSettingsActionIcon: FC<Props> = (props) => {
  return (
    <ActionIcon
      size="md"
      w={ 48 }
      h={ 24 }
      ml="auto"
      sx={ { '&:disabled': {
        backgroundColor: 'transparent'
      } } }
      // eslint-disable-next-line react/jsx-props-no-spreading
      { ...props }
    >
      <FilterSettings />
    </ActionIcon>
  );
};
