import { PaginatedResult } from "@/common/types/reference-data";
import { callApi, TokenType } from "@/common/util/api";
import browserDatabase from "@/common/util/browser-database";
import { CONTEXT_KEY, ContextTypes } from "@/core/store/shared-data/sharedData.types";
import {
  ChecklistItem,
  CopyItemsFromPlaybookParams,
  CreateChecklistItemParams,
  CreateRoleParams,
  CreateTeamParams,
  DeleteChecklistItemParams,
  DeleteRoleParams,
  DeleteTeamParams,
  PlaybookListItem,
  Role,
  RoleListItem,
  Team
} from "@/platform-context/control-icm-management/types/CaseTypes/caseTypeTeamsAndRoles";
import { SortData } from "@/tenant-context/control-groups/types/group";

export const caseTypesTeamsAndRolesApi = {
  tokenType: browserDatabase.getItem(CONTEXT_KEY) as ContextTypes === "platform" ? "access" : "tenant",
  createTeam: function (data: CreateTeamParams, caseTypeId: string) {
    return callApi<Team>(
      `/managed/icm-service/v1/case-types/${caseTypeId}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=CreateTeam'
        },
        method: 'post',
        body: JSON.stringify(data),
        tokenType: this.tokenType as TokenType,
        withTenantId: this.tokenType === "tenant"
      }
    );
  },
  updateTeam: function (data: CreateTeamParams, caseTypeId: string, teamId: string) {
    return callApi<Team>(
      `/managed/icm-service/v1/case-types/${caseTypeId}/teams/${teamId}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=UpdateTeam'
        },
        method: 'post',
        body: JSON.stringify(data),
        tokenType: this.tokenType as TokenType,
        withTenantId: this.tokenType === "tenant"
      }
    );
  },
  getTeamListData: function(caseTypeId: string) {
    return callApi<PaginatedResult<Team>> (
      `/managed/icm-service/v1/case-types/${caseTypeId}/teams`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=ListTeam'
        },
        method: 'get',
        tokenType: this.tokenType as TokenType,
        withTenantId: this.tokenType === "tenant"
      }
    );
  },
  getTeam: function(caseTypeId: string, teamId: string) {
    return callApi<Team> (
      `/managed/icm-service/v1/case-types/${caseTypeId}/teams/${teamId}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=GetTeam'
        },
        method: 'get',
        tokenType: this.tokenType as TokenType,
        withTenantId: this.tokenType === "tenant"
      }
    );
  },
  deleteTeam: function(data: DeleteTeamParams, caseTypeId: string) {
    return callApi<void>(
      `/managed/icm-service/v1/case-types/${caseTypeId}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=DeleteTeam'
        },
        method: 'post',
        body: JSON.stringify(data),
        tokenType: this.tokenType as TokenType,
        withTenantId: this.tokenType === "tenant"
      }
    );
  },
  createRole: function (data: CreateRoleParams, caseTypeId: string) {
    return callApi<Team>(
      `/managed/icm-service/v1/case-types/${caseTypeId}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=CreateCaseTypeRole'
        },
        method: 'post',
        body: JSON.stringify(data),
        tokenType: this.tokenType as TokenType,
        withTenantId: this.tokenType === "tenant"
      }
    );
  },
  deleteRole: function(data: DeleteRoleParams, caseTypeId: string) {
    return callApi<void>(
      `/managed/icm-service/v1/case-types/${caseTypeId}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=DeleteCaseTypeRole'
        },
        method: 'post',
        body: JSON.stringify(data),
        tokenType: TokenType.ACCESS,
        withTenantId: this.tokenType === "tenant"
      }
    );
  },
  getListRolesData: function(
    caseTypeId: string,
    page = 0,
    size = 10,
    sort?: SortData,
    query?: string
  ) {
    return callApi<PaginatedResult<RoleListItem>>(
      `/managed/icm-service/v1/case-types/${caseTypeId}/case-type-roles?${sort?.colId ? 'orderBy=' + sort.colId + ':' + sort.sort + '&' : ''}size=${size}&page=${page}${query ? '&search=(' + query + ')&' : ''}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=ListCaseTypeRole'
        },
        method: 'get',
        tokenType: this.tokenType as TokenType,
        withTenantId: this.tokenType === "tenant"
      }
    );
  },
  getRole: function(caseTypeId: string, roleId: string) {
    return callApi<Role> (
      `/managed/icm-service/v1/case-types/${caseTypeId}/case-type-roles/${roleId}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=GetCaseTypeRole'
        },
        method: 'get',
        tokenType: this.tokenType as TokenType,
        withTenantId: this.tokenType === "tenant"
      }
    );
  },
  updateRole: function (data: CreateRoleParams, caseTypeId: string, roleId: string) {
    return callApi<Role>(
      `/managed/icm-service/v1/case-types/${caseTypeId}/case-type-roles/${roleId}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=UpdateCaseTypeRole'
        },
        method: 'post',
        body: JSON.stringify(data),
        tokenType: this.tokenType as TokenType,
        withTenantId: this.tokenType === "tenant"
      }
    );
  },
  createChecklistItem: function (data: CreateChecklistItemParams, caseTypeId: string) {
    return callApi<ChecklistItem>(
      `/managed/icm-service/v1/case-types/${caseTypeId}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=CreateCheckListItem'
        },
        method: 'post',
        body: JSON.stringify(data),
        tokenType: this.tokenType as TokenType,
        withTenantId: this.tokenType === "tenant"
      }
    );
  },
  deleteChecklistItem: function(data: DeleteChecklistItemParams, caseTypeId: string) {
    return callApi<void>(
      `/managed/icm-service/v1/case-types/${caseTypeId}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=DeleteCheckListItem'
        },
        method: 'post',
        body: JSON.stringify(data),
        tokenType: TokenType.ACCESS,
        withTenantId: this.tokenType === "tenant"
      }
    );
  },
  getChecklistItemListData: function(
    caseTypeId: string,
    roleRef: string,
    page = 0,
    size = 10,
    sort?: SortData,
    query?: string
  ) {
    return callApi<PaginatedResult<ChecklistItem>>(
      `/managed/icm-service/v1/case-types/${caseTypeId}/check-list-items?${sort?.colId ? 'orderBy=' + sort.colId + ':' + sort.sort + '&' : ''}size=${size}&page=${page}${query ? '&search=(' + query + ')' : ''}&roleRef=${roleRef}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=ListCheckListItem'
        },
        method: 'get',
        tokenType: this.tokenType as TokenType,
        withTenantId: this.tokenType === "tenant"
      }
    );
  },
  getChecklistItem: function(caseTypeId: string, checklistId: string) {
    return callApi<ChecklistItem> (
      `/managed/icm-service/v1/case-types/${caseTypeId}/check-list-items/${checklistId}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=GetCheckListItem'
        },
        method: 'get',
        tokenType: this.tokenType as TokenType,
        withTenantId: this.tokenType === "tenant"
      }
    );
  },
  getPlaybookItems: function() {
    return callApi<PaginatedResult<PlaybookListItem>> (
      `managed/icm-service/v1/play-books?size=99999&page=0&bindTasks=true`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=ListPlayBook'
        },
        method: 'get',
        tokenType: this.tokenType as TokenType,
        withTenantId: this.tokenType === "tenant"
      }
    );
  },
  updateChecklistItem: function (data: CreateChecklistItemParams, caseTypeId: string, checklistId: string) {
    return callApi<ChecklistItem>(
      `/managed/icm-service/v1/case-types/${caseTypeId}/check-list-items/${checklistId}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=UpdateCheckListItem'
        },
        method: 'post',
        body: JSON.stringify(data),
        tokenType: this.tokenType as TokenType,
        withTenantId: this.tokenType === "tenant"
      }
    );
  },
  copyItemsFromPlaybook: function (data: CopyItemsFromPlaybookParams, caseTypeId: string) {
    return callApi<ChecklistItem>(
      `/managed/icm-service/v1/case-types/${caseTypeId}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=CopyItemsFromPlaybook'
        },
        method: 'post',
        body: JSON.stringify(data),
        tokenType: this.tokenType as TokenType,
        withTenantId: this.tokenType === "tenant"
      }
    );
  }
};