import { LocationContext } from "../types/Lookup.types";

export const handleLocationTypeDisplay = (locationContextEnum: LocationContext): string => {
  switch (locationContextEnum) {
  case "COUNTRY":
    return "Other - Country";
  case "CITY":
    return "Other - City";
  case "HOME":
    return "Home Address";
  case "WORK":
    return "Work Location";
  case "REQUEST_FLIGHT":
  case "REQUEST_TAXI":
  case "REQUEST_HOTEL":
  case "BOOKED_FLIGHT":
  case "BOOKED_RAIL":
  case "BOOKED_HOTEL":
  case "BOOKED_TAXI":
  case "BOOKED_CAR_HIRE":
  case "PLANNED_VISIT":
  case "PLANNED_JOURNEY":
  case "MANAGED_VISIT":
  case "MANAGED_JOURNEY":
    return "Travel Itinerary";
  case "ACCESS_CONTROL_SYSTEM":
  case "PERSON_CONTROL_SYSTEM":
    return "Access Control";
  case "MUSTER_CONTROL_SYSTEM":
    return "Muster Control";
  case "MOBILE_APP_GPS_CHECK_IN":
    return "Check In (Restrata APP)";
  case "MOBILE_APP_GPS":
    return "GPS Location (Restrata APP)";
  default:
    return locationContextEnum;
  }
};

export const handleArcLocationTypeDisplay = (locationContextEnum: LocationContext): string => {
  switch (locationContextEnum) {
  case "COUNTRY":
  case "CITY":
    return "Other";
  case "HOME":
    return "Home Address";
  case "WORK":
    return "Work Location";
  case "REQUEST_FLIGHT":
    return "Requested Flight";
  case "REQUEST_TAXI":
    return "Requested Taxi";
  case "REQUEST_HOTEL":
    return "Requested Hotel";
  case "BOOKED_FLIGHT":
    return "Booked Flight";
  case "BOOKED_RAIL":
    return "Booked Train";
  case "BOOKED_HOTEL":
    return "Booked Hotel";
  case "BOOKED_TAXI":
    return "Booked Taxi";
  case "BOOKED_CAR_HIRE":
    return "Booked Car Rental";
  case "PLANNED_VISIT":
    return "Planned Visit";
  case "PLANNED_JOURNEY":
    return "Planned Journey";
  case "MANAGED_VISIT":
    return "Managed Visit";
  case "MANAGED_JOURNEY":
    return "Managed Journey";
  case "ACCESS_CONTROL_SYSTEM":
  case "PERSON_CONTROL_SYSTEM":
    return "Access Control";
  case "MUSTER_CONTROL_SYSTEM":
    return "Muster Control";
  case "MOBILE_APP_GPS_CHECK_IN":
    return "Check In (Restrata APP)";
  case "MOBILE_APP_GPS":
    return "GPS Location (Restrata APP)";
  default:
    return locationContextEnum;
  }
};
