import { ENV, Environment } from "@/core/config/env";

const {
  REACT_APP_AERIS_ID,
  REACT_APP_AERIS_KEY
} = process.env;

export const isWeatherMapEnabled = Boolean(ENV !== Environment.PROD && REACT_APP_AERIS_ID && REACT_APP_AERIS_KEY);

export const AERIS_ID = REACT_APP_AERIS_ID;
export const AERIS_KEY = REACT_APP_AERIS_KEY;

export const DEFAULT_SURFACE_LAYER_ID = 'admin-1-boundary-bg';
