import { Button, Flex, Stack } from '@mantine/core';
import React, { FC } from 'react';

import { useLegendInTree } from "@/tenant-context/control-risk-config/components/RiskProviderTreeContent/hooks";
import ProviderEvents
  from "@/tenant-context/control-risk-config/components/RiskProviderTreeContent/ProviderEvents.component";
import { ProviderCategory } from "@/tenant-context/control-risk-config/types/risk";

type Props = {
  items: Array<ProviderCategory>,
  onCategoryToggle: (event: React.ChangeEvent<HTMLInputElement>) => void,
  disabledRiskFilterTypes: Set<string>
  toggleLegendVisibility: () => void,
  isLegendVisible: boolean,
  legendItems: Array<{ color: string, label: string, level: string }>
};

export const FactalEventsComponent: FC<Props> = ({
  items,
  onCategoryToggle,
  disabledRiskFilterTypes,
  toggleLegendVisibility,
  isLegendVisible,
  legendItems
}) => {
  const { buttonAsLinkClasses } = useLegendInTree();

  return <Stack mt={ 16 } ml={ 32 + 36 }>
    { isLegendVisible &&  <Stack>
      { legendItems.map(({ color, label, level }) => (
        <Flex key={ level } gap={ 8 } display="inline-flex" align="center">
          <div
            style={ {
              backgroundColor: color,
              width: 24,
              height: 24,
              borderRadius: "50%",
              display: "inline-block"
            } }
          />
          <span>{ label }</span>
        </Flex>
      )) }
    </Stack> }
    <Button
      variant="subtle"
      compact
      classNames={ buttonAsLinkClasses }
      onClick={ toggleLegendVisibility }
    >
      { isLegendVisible ? "Hide" : "Show" } legend
    </Button>
    <ProviderEvents
      items={ items }
      onCategoryToggle={ onCategoryToggle }
      disabledRiskFilterTypes={ disabledRiskFilterTypes }
    />
  </Stack>;
};
