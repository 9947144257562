import { callApi } from '@/common/util/api';
import { CountryRiskData, CountryRiskProvider } from '@/tenant-context/control-reports/types/country-risk-reports';

export const getCountryRiskReports = (provider: string, mergeNonTenant: boolean, countryTid?: string) => 
  callApi<CountryRiskData>(
    `/report-service/v1/country-risk-content`,
    {
      method: 'get',
      queryParams: {
        tid: countryTid,
        providerName: provider.toUpperCase(),
        mergeNonTenant,
        pageNum: 0,
        pageSize: 10000,
        orderBy: `json.city:ASC`
      }
    }
  );
export const getCountryRiskReportsSummery = (provider: string, mergeNonTenant: boolean) => callApi<CountryRiskData[]>(
  `/report-service/v1/country-risk-content/summery`,
  {
    method: 'get',
    queryParams: {
      providerName: provider.toUpperCase(),
      mergeNonTenant,
      pageNum: 0,
      pageSize: 10000,
      orderBy: `json.country:ASC`
    }
  }
);
export const getCountryRiskProvidersList = () => callApi<CountryRiskProvider[]>(
  `/report-service/v1/country-risk-content/provider?pageSize=0&pageNum=0&mergeNonTenant=false&contentProviderType=risk`,
  {
    method: 'get'
  }
);
