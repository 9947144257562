import { Avatar, Box, Button, Drawer, Text } from "@mantine/core";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import { Dispatch, FC, ReactNode, RefObject, SetStateAction, useCallback, useMemo } from "react";

import BorderedDataGrid from "@/common/components/BorderedDataGrid";
import TruncatedTextWithTooltip from "@/common/components/TruncatedTextWithTooltip/TruncatedTextWithTooltip.component";
import { ReactComponent as SiteIcon } from "@/common/icons/site.svg";
import LocalErrorBoundary from "@/tenant-context/common/components/LocalErrorBoundary";
import ImpactTabGridFiltersComponent
  from "@/tenant-context/control-action-response-center/components/ImpactTab/components/ImpactTabGridFilters.component";
import { useImpactTabStyles } from "@/tenant-context/control-action-response-center/components/ImpactTab/ImpactTab.styles";
import {
  ImpactPeopleGridData,
  ImpactPeopleGridDataList
} from "@/tenant-context/control-action-response-center/components/ImpactTab/types/ImpactTab.types";
import { ReactComponent as FutureRisk } from '@/tenant-context/control-action-response-center/icons/future-risk.svg';
import { ReactComponent as HighRisk } from '@/tenant-context/control-action-response-center/icons/high-risk.svg';
import { ReactComponent as LowerRisk } from '@/tenant-context/control-action-response-center/icons/lower-risk.svg';
import { ReactComponent as MiniClock } from '@/tenant-context/control-action-response-center/icons/mini-clock.svg';
import { ArcImpactStatus } from "@/tenant-context/control-action-response-center/types/ARC.types";
import MiniProfile from "@/tenant-context/control-profile/components/ui/MiniProfile";
import { getPersonInitials } from "@/tenant-context/visualisation-people/util/getPersonInitials";

import MassCommsWidget from "./components/MassCommsWidget";
import RiskWidget from "./components/RiskWidget";
import ScrollableSlider from "./components/ScrollableSlider";
import SubImpactTab from "./components/SubImpactTab";
import { formatMCResponseStatus } from "./ImpactTab.utils";

type Props = {
  rowData: ImpactPeopleGridDataList;
  selectedImpactStatus: ArcImpactStatus | undefined;
  onSearchStringChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onStatusFilterChange: (status: ArcImpactStatus | 'ALL') => void;
  onManualStatusChangeRequest: (personId: string, personName: string, personStatus: ArcImpactStatus) => void;
  onNavigateToPersonProfileRequested: (personId: string) => void;
  handleRowExpand: (personId: string) => void;
  isProfileOpened: boolean;
  setIsProfileOpened: Dispatch<SetStateAction<boolean>>;
  onPersonTypeFilterChange: (personType: string) => void;
  onLatLocationFilterChange: (location: string) => void;
  onImpLocationFilterChange: (location: string) => void;
  onRiskFilterChange: (riskTier: string) => void;
  onAlertFilterChange: (alert: string) => void;
  isPeopleImpactedTab: boolean,
  onSubImpactTabChange: () => void,
  carouselRef: RefObject<HTMLDivElement>,
  impactedSites: Array<{ siteName: string, siteAddress: string }>,
  lastUpdateTime: string
}

const ImpactTabComponent: FC<Props> = ({
  rowData,
  selectedImpactStatus,
  onSearchStringChange,
  onStatusFilterChange,
  onManualStatusChangeRequest,
  onNavigateToPersonProfileRequested,
  isProfileOpened,
  setIsProfileOpened,
  onPersonTypeFilterChange,
  onLatLocationFilterChange,
  onImpLocationFilterChange,
  isPeopleImpactedTab,
  onSubImpactTabChange,
  carouselRef,
  impactedSites,
  onRiskFilterChange,
  onAlertFilterChange,
  lastUpdateTime
}) => {
  const { classes } = useImpactTabStyles();
  const nameCellRenderer = useCallback((params: ICellRendererParams): ReactNode => {

    return (
      <button
        className={ classes.nameCellWrapper }
        /* eslint-disable-next-line react/jsx-no-bind */
        onClick={ () => onNavigateToPersonProfileRequested(params.data.personId) }
      >
        <Text className={ classes.avatarName }>
          <Avatar className={ classes.profileThumbnail } size={ 24 } 
            src={ params.data.profilePictureThumbnailUrl || undefined }>
            { !params.data.profilePictureThumbnailUrl && getPersonInitials(params.value) }
          </Avatar>
          <TruncatedTextWithTooltip className={ classes.underlinedName } 
            fullText={ params.value } maxLength={ 32 } /></Text>
      </button>
    );

  }, [classes.avatarName, classes.nameCellWrapper, classes.profileThumbnail, 
    classes.underlinedName, onNavigateToPersonProfileRequested]);

  const latestLocationCellRenderer = useCallback(({ data }: ICellRendererParams<ImpactPeopleGridData>): ReactNode => {
    const isLocationInside = data?.islatestLocationInside === 'true' ? 'Inside Zone, ' : 'Outside Zone, ';
    const impactedLocation = `Based on ${data?.latestLocation}`; 
    return (
      <div>
        <TruncatedTextWithTooltip
          fullText={ `${isLocationInside} ${impactedLocation}` }
          maxLength={ window.innerWidth > 1870 ? 50 : 32 } />
      </div>
    );
  }, []);

  const safetyStatusCellRenderer = useCallback(({ data }: ICellRendererParams<ImpactPeopleGridData>): ReactNode => {
    if (!data) {
      return null;
    }
    const status = formatMCResponseStatus(data.safetyStatus);
    return (
      <Button
        className={ `${classes.statusChangeButton} ${classes.statusIndicatorBg}_${data.safetyStatus}` }
        variant='outline'
        size='xs'
        onClick={ () => {
          onManualStatusChangeRequest(data.personId, data.name, data.safetyStatus as ArcImpactStatus);
        } }
      >
        { status }
      </Button>

    );
  }, [classes.statusChangeButton, classes.statusIndicatorBg, onManualStatusChangeRequest]);

  const riskTierCellRenderer = useCallback(({ data }: ICellRendererParams<ImpactPeopleGridData>): ReactNode => {
    if (!data) {
      return null;
    }

    switch (data.riskTier) {
    case 'HIGH':
      return <Text className={ classes.rowRiskTier }><HighRisk /> High Risk</Text>;
    case 'LOW':
      return <Text className={ classes.rowRiskTier }><LowerRisk /> Low Risk</Text>;
    case 'FUTURE':
      return <Text className={ classes.rowRiskTier }><FutureRisk /> Future Risk</Text>;
    
    default:
      return 'N/A';
    }
  }, [classes.rowRiskTier]);

  const defaultCellRenderer = useCallback(
    (params: ICellRendererParams): ReactNode => (<div>{ params.value }</div>)
    , []
  );

  const columnDefs  = useMemo<ColDef[]>(() => {
    return [
      {
        field: 'name',
        cellRenderer: nameCellRenderer,
        sortable: true,
        minWidth: 280,
        pinned: 'left',
        flex: 1
      },
      {
        field: 'riskTier', cellRenderer: riskTierCellRenderer, sortable: true, width: 150
      },
      {
        field: 'personType', sortable: true, width: 160
      },
      {
        field: 'impactedLocation',
        headerName: 'Impact Location',
        sortable: true,
        minwidth: 220,
        maxWidth: 250
      },
      {
        field: 'islatestLocationInside',
        headerName: 'Latest Location',
        cellRenderer: latestLocationCellRenderer,
        sortable: true,
        minWidth: 220,
        flex: 1
      },
      { 
        field: 'isAlertSent', 
        cellStyle: { justifyContent: "center" },
        headerClass: classes.centeredHeader,
        headerName: 'Alert Sent', 
        cellRenderer: (params: ICellRendererParams) => params.value === 'true' ? 'YES' : 'NO',
        sortable: true,
        width: 120
      },
      {
        field: 'safetyStatus',
        headerName: 'Response Status',
        headerClass: classes.centeredHeader,
        cellClass: classes.cellWrapText + " " + classes.alignCenter,
        cellRenderer: safetyStatusCellRenderer,
        sortable: true,
        width: 170
      }
    ];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latestLocationCellRenderer, nameCellRenderer, riskTierCellRenderer, safetyStatusCellRenderer]);

  const defaultColDef = useMemo( ()=> ({
    sortable: true,
    autoHeight: true,
    resizable: true,
    cellRenderer: defaultCellRenderer
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [ classes.cellWrapText, classes.rowWrapper, defaultCellRenderer ]);

  const headerContent = <ImpactTabGridFiltersComponent
    selectedImpactStatus={ selectedImpactStatus }
    onSearchStringChange={ onSearchStringChange }
    onStatusFilterChange={ onStatusFilterChange }
    onPersonTypeFilterChange={ onPersonTypeFilterChange }
    onRiskFilterChange={ onRiskFilterChange }
    onLatLocationFilterChange={ onLatLocationFilterChange }
    onImpLocationFilterChange={ onImpLocationFilterChange }
    onAlertFilterChange={ onAlertFilterChange }
  />;

  return (
    <div className={ classes.container }>
      <SubImpactTab 
        assetCount={ impactedSites?.length ?? 0 } 
        isPeopleImpactedTab={ isPeopleImpactedTab } 
        onSubImpactTabChange={ onSubImpactTabChange } 
        peopleCount={ rowData?.length ?? 0 }/>

      <RiskWidget/>

      { isPeopleImpactedTab && <MassCommsWidget/> }

      { !isPeopleImpactedTab && impactedSites?.length > 0 ? 
        <Box ref={ carouselRef } className={ classes.carouselContainer }>
          <ScrollableSlider containerRef={ carouselRef } childWidth={ 272 } gap={ 16 }>
            { impactedSites.map(({ siteName }, index) => (
              <Box className={ classes.carouselItem } key={ index }>
                <Box display={ 'flex' } w={ '272px' } className={ classes.assetWrapper } >
                  <Box className={ classes.assetContainer }>
                    <Box className={ classes.assetTitle }>
                      <SiteIcon />
                      <Text>{ siteName }</Text>
                    </Box>
                    { /* TODO - RO-4287 */ }
                    { /* <Box className={ classes.assetInnerText }>
                      <Text >
                        Ngong Lane Ngong Road, Kenya
                      </Text>
                      <Text>
                        +254 709 090000
                      </Text>
                    </Box> */ }
                  </Box>
                  { /* <Text className={ classes.assetPeople }>
                    <People /> 280
                  </Text> */ }
                </Box>
              </Box>
            )) }
          </ScrollableSlider>
        </Box> : !isPeopleImpactedTab && (!impactedSites || impactedSites?.length === 0) ? 
          <Box className={ `${classes.carouselContainer} ${classes.emptyCarousel}` }>No Assets Impacted</Box> : null }

      <Box className={ classes.lastUpdateContainer }>
        <MiniClock />
        <Text>{ `Impact last updated:` }</Text>
        <Text opacity={ '65%' }>{ ` ${lastUpdateTime || 'N/A'}` }</Text>
      </Box> 

      <div style={ { marginTop: 10 } }>
        <LocalErrorBoundary>
          <div className={ `ag-theme-alpine-dark ag-theme-rt ${classes.impactTable}` }>
            <BorderedDataGrid<ImpactPeopleGridData>
              rowData={ rowData }
              defaultColDef={ defaultColDef }
              columnDefs={ columnDefs }
              pagination={ true }
              headerContent={ headerContent }
              paginationPageSize={ 10 }
              suppressCellFocus={ true }
              suppressRowClickSelection={ true }
              domLayout='autoHeight'
              rowClass={ classes.rowWrapper }
              /* eslint-disable-next-line react/jsx-no-bind */
              gridOptions={ {
                rowHeight: 60,
                rowStyle: {
                  boxSizing: 'border-box',
                  overflow: 'hidden',
                  maxHeight: 64
                }
              } }
            />
          </div>
        </LocalErrorBoundary>
      </div>
      <Drawer
        opened={ isProfileOpened }
        // eslint-disable-next-line react/jsx-no-bind
        onClose={ () => setIsProfileOpened(false) }
        withOverlay={ false }
        withCloseButton={ true }
        position="right"
        className={ classes.arcMiniProfile }
      >
        <MiniProfile isStandAlone/>

      </Drawer>

    </div>
  );
};

export default ImpactTabComponent;
