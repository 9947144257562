import {
  Checkbox,
  Flex,
  Stack
} from "@mantine/core";
import React, { FC } from "react";

import { ToggleableLayerType } from "@/common/components/ToggleableLayer/ToggleableLayer.config";
import { useMapLayersDrawerContext } from "@/tenant-context/control-risk-config/context/MapLayersDrawer.context";
import {
  FilterSettingsActionIcon
} from "@/tenant-context/core/controls/DataOptionsControl/components/FilterSettingsActionIcon";

import { mainLayers } from "./utils";

type Props = {
  disabledLayers: string[];
  onLayerToggle: React.ChangeEventHandler<HTMLInputElement>;
  counters: Record<string, number>;
};

const BusinessDataLayersComponent: FC<Props> = ({
  disabledLayers,
  onLayerToggle,
  counters
}) => {
  const { changeDrawerPage } = useMapLayersDrawerContext();
  return (
    <Stack spacing="md" mt={ 24 } ml={ 32 }>
      { mainLayers.map(({ type, label }) => (
        <Flex key={ type } h={ 24 } gap={ 8 } justify="space-between">
          <Checkbox
            value={ type }
            label={ label + (counters[type] ? ` (${counters[type]})` : "") }
            onChange={ onLayerToggle }
            checked={ !disabledLayers.includes(type) }
          />
          { type === ToggleableLayerType.People && (
            <FilterSettingsActionIcon
              // eslint-disable-next-line react/jsx-no-bind
              onClick={ () => changeDrawerPage(ToggleableLayerType.People) }
            />
          ) }
        </Flex>
      )) }
    </Stack>
  );
};

export default BusinessDataLayersComponent;
