import { ActionIcon, Box, Flex, Stack, Text } from '@mantine/core';
import { FC, useCallback, useMemo, useState } from 'react';

import { ReactComponent as DownloadReport } from "@/common/icons/Actions/download-doc.svg";
import { showNotification } from '@/common/util/notification';
import CountryFlag from '@/tenant-context/common/components/CountryFlag';
import {
  useCityRiskStatusCardStyles
} from '@/tenant-context/control-reports/components/CityRiskStatusCard/CityRiskStatusCard.styles';

import { RiskLineCityRiskData } from '../../types/city-risk-reports';

type Props = {
  cityRiskData: RiskLineCityRiskData;
  currentTimestamp?: number;
}

const {
  REACT_APP_MAPBOX_ACCESS_TOKEN
} = process.env as {
  REACT_APP_MAPBOX_ACCESS_TOKEN?: string
};
const RisklineCityStatusCard: FC<Props> = ({
  cityRiskData,
  currentTimestamp
}) => {

  const [isReportDownloading, setIsReportDownloading] = useState(false);

  const timeZone = useMemo(() => {
    if (!cityRiskData.localTime || (cityRiskData.localTime === "Not Available")) {
      return undefined;
    }
    const timeZoneRegex = /\b[A-Z]{3,4}\b$/;
    const match = cityRiskData.localTime.match(timeZoneRegex);

    return match ? match[0] : undefined;
  }, [cityRiskData.localTime]);

  const time = useMemo(() => {
    if (!currentTimestamp || !timeZone) {
      return undefined;
    }

    const date = new Date(currentTimestamp);
    const options: Intl.DateTimeFormatOptions = {
      timeZone,
      hour: '2-digit',
      minute: '2-digit',
      hour12: false  // 24-hour format
    };

    try {
      return new Intl.DateTimeFormat('en-US', options).format(date);
    } catch (error) {
      return undefined;
    }
  }, [currentTimestamp, timeZone]);

  const { classes } = useCityRiskStatusCardStyles({ progressColor: undefined });

  const handleCardClick = useCallback(async () => {
    if ((cityRiskData as RiskLineCityRiskData)?.attributes?.pdfDetails?.pdf){
      setIsReportDownloading(true);
      try {
        const pdfResponse = await fetch((cityRiskData as RiskLineCityRiskData).attributes.pdfDetails.pdf);
        const pdfBlob = await pdfResponse.blob();
        const blobUrl = URL.createObjectURL(pdfBlob);
        setIsReportDownloading(false);
        window.open(blobUrl, "_blank");
      } catch {
        setIsReportDownloading(false);
        showNotification({
          message: `Failed to open PDF file`,
          color: "error"
        });
      }
    }
  }, [ cityRiskData ]);
  
  const handleDirectDownload = useCallback((event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    event.stopPropagation();
    const pdfUrl = (cityRiskData as RiskLineCityRiskData)?.attributes?.pdfDetails?.pdf;
    if (!pdfUrl) {
      return;
    }
    setIsReportDownloading(true);
    const downloadLink = document.createElement("a");
    downloadLink.href = pdfUrl;
    downloadLink.setAttribute('download', `${cityRiskData?.cityName}.pdf` || 'CityReport.pdf');
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    setIsReportDownloading(false);
  }, [cityRiskData]);

  const mapImageLink = useMemo(() => {
    const bbox = (cityRiskData.coordinates && cityRiskData.coordinates.length === 4) ?
      `[${ cityRiskData.coordinates.join(',') }]`: '[-180,-85,180,85]';

    const style = 'smehmood/cl4feh10w000114pyksvp6mu9';
    return `https://api.mapbox.com/styles/v1/${ style }/static/${ bbox }/800x300?` +
      `padding=50,10,20&access_token=${ REACT_APP_MAPBOX_ACCESS_TOKEN }`;
  }, [cityRiskData.coordinates]);

  return (
    <Flex gap={ 12 } className={ classes.riskLineCardRoot } direction="column" onClick={ handleCardClick }>
      <Flex justify="space-between" align="center">
        <Flex gap={ 12 } align="center">
          <Box className={ classes.flag }>
            <CountryFlag
              countryCode={ cityRiskData.countryISOCode || '' }
            />
          </Box>
          <Stack spacing={ 0 }>
            <Text size={ 14 } weight={ 700 }>{ cityRiskData.cityName }</Text>
            <Text size={ 8 }>{ cityRiskData.countryName }</Text>
          </Stack>
        </Flex>
        <ActionIcon className={ classes.actionIcon } loading={ isReportDownloading }>
          <DownloadReport color='white' onClick={ handleDirectDownload } />
        </ActionIcon>
        { time && <Text size={ 12 } weight={ 400 }>Local time: { time }</Text> }
      </Flex>
      <img src={ mapImageLink } alt="City flag" className={ classes.mapImage }/>
    </Flex>
  );
};

export default RisklineCityStatusCard;
