import { ReferenceData } from "@/common/types/reference-data";
import { callApi } from "@/common/util/api";
import { SortData } from "@/tenant-context/control-groups/types/group";
import {
  Briefing, BriefingEditData,
  CreateBriefingData,
  DeleteBriefingParam, UpdateBriefingData
} from "@/tenant-context/control-icm-management/types/briefings";
import { PaginatedResult } from "@/tenant-context/control-profile/types/profile";

export const briefingsApi = {
  createBriefing: function (caseId: string, requestObj: CreateBriefingData) {
    return callApi<void>(
      `managed/icm-service/v1/icm-cases/${caseId}`,
      {
        method: "post",
        body: JSON.stringify(requestObj),
        headers: {
          'x-domain-model': 'CreateBriefing'
        }
      }
    );
  },
  updateBriefing: function (caseId: string, requestObj: UpdateBriefingData) {
    return callApi<void>(
      `managed/icm-service/v1/icm-cases/${caseId}`,
      {
        method: "post",
        body: JSON.stringify(requestObj),
        headers: {
          'x-domain-model': 'UpdateBriefing'
        }
      }
    );
  },
  getICMTaskStatusData: function() {
    return callApi<PaginatedResult<ReferenceData>>(
      `managed/platform-service/v1/reference-data?search=%28type=%27Icm-Briefing-Status%27%29`,
      {
        headers: {
          'Content-Type': 'application/json;'
        },
        method: 'get'
      }
    );
  },
  getICMTBriefingsReminderStatusData: function() {
    return callApi<PaginatedResult<ReferenceData>>(
      `managed/platform-service/v1/reference-data?search=%28type=%27Icm-Briefing-Reminder%27%29`,
      {
        headers: {
          'Content-Type': 'application/json;'
        },
        method: 'get'
      }
    );
  },
  getBriefingListData: function(
    caseId: string,
    page = 0,
    size = 10,
    sort?: SortData,
    query?: string
  ) {
    return callApi<PaginatedResult<Briefing>>(
      `managed/icm-service/v1/icm-cases/${caseId}/briefings?size=${size}&page=${page}${query ? `&search=${query}` : ''}${sort?.colId ? '&orderBy=' + sort.colId + ':' + sort.sort : ''}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=ListBriefing'
        },
        method: 'get'
      }
    );
  },
  deleteBriefing: function(
    caseId: string,
    requestObj: DeleteBriefingParam
  ) {
    return callApi<void>(
      `managed/icm-service/v1/icm-cases/${caseId}`,
      {
        method: "post",
        body: JSON.stringify(requestObj),
        headers: {
          'x-domain-model': 'DeleteBriefing'
        }
      }
    );
  },
  getBriefingData: function(
    caseId: string,
    briefingId: string
  ) {
    return callApi<BriefingEditData>(
      `managed/icm-service/v1/icm-cases/${caseId}/briefings/${briefingId}`,
      {
        headers: {
          'Content-Type': 'application/json;',
          'x-domain-model': 'GetBriefing'
        },
        method: 'get'
      }
    );
  }
};