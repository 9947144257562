import { ReactComponent as Travel } from  '@/common/icons/RiskProviders/MaxSecurityCategories/travel.svg';
import { ReactComponent as Volcano } from  '@/common/icons/RiskProviders/MaxSecurityCategories/volcano.svg';

export const HEALIX_RISK_CATEGORIES = [
  {
    id: 'healix/Travel',
    name: 'Travel',
    Icon: Travel
  },{
    id: 'healix/Security',
    name: 'Security',
    Icon: Volcano
  }];
