import { default as turfCircle } from '@turf/circle';
import { Units } from '@turf/helpers';
import { Feature, FeatureCollection, GeoJsonProperties, LineString, MultiPolygon, Point, Polygon } from 'geojson';

import { ImpactedArea } from '@/tenant-context/visualisation-risk-details/types/risk-impact';


const createCirclePolygon = (
  centerLat: number,
  centerLon: number,
  radiusMeters: number,
  properties: unknown
): Polygon => {
  const options = { steps: 64, units: 'kilometers' as Units, properties };
  const circle = turfCircle([centerLon, centerLat], radiusMeters, options);
  
  return circle.geometry as Polygon;
};

export const drawRiskImpactAreaFeatureCollection = (
  impactedAreas?: ImpactedArea[]
): FeatureCollection<Polygon | MultiPolygon | Point | LineString, GeoJsonProperties> => {
  if (!impactedAreas || impactedAreas.length === 0) {
    return {
      type: 'FeatureCollection',
      features: []
    };
  }

  const features = impactedAreas.map(area => {
    const { calculation, id, peopleCount, assetCount } = area;
    const { targetPoint, calculationType, appliedTo, labels } = calculation;

    // eslint-disable-next-line fp/no-let
    let geometry: Polygon | MultiPolygon;

    const properties = {
      id,
      peopleCount,
      assetCount,
      labels: Array.isArray(labels) ? labels : [labels],
      calculationType,
      appliedTo
    };
    
    if (calculationType === 'CIRCLE') {
      geometry = createCirclePolygon(targetPoint.lat, targetPoint.lon, appliedTo, properties);
    } else {
      // For POLYGON type, use the provided geoFence coordinates directly
      geometry = {
        type: 'Polygon',
        coordinates: calculation.geoFence
      };
    }

    return normalizeFeature({
      type: 'Feature' as const,
      geometry,
      properties
    });
  });

  return {
    type: 'FeatureCollection',
    features
  };
};

function normalizeFeature(
  feature: Feature<Polygon | MultiPolygon | Point | LineString, GeoJsonProperties>
): Feature<Polygon | MultiPolygon | Point | LineString, GeoJsonProperties> {
  if (feature.geometry.type === "Polygon") {
    // Determine if the polygon crosses the Prime Meridian
    feature.geometry.coordinates.forEach((ring) => {
      const crossesPrimeMeridian = ring.some((coord, i) => {
        if (i === ring.length - 1) {
          return false;
        }
        const [lon1] = coord;
        const [lon2] = ring[i + 1];
        return Math.abs(lon1 - lon2) > 180;
      });

      if (crossesPrimeMeridian) {
        // If polygon crosses Prime Meridian, normalize coordinates to [-180, 180] range
        ring.forEach((pos, index) => {
          const [lon, lat] = pos;
          const normalizedLon = ((lon + 540) % 360) - 180;
          // eslint-disable-next-line no-param-reassign
          ring[index] = [normalizedLon, lat];
        });
      }
    });
  }

  return feature;
}
