import { FC, useCallback } from "react";

import ButtonControl from "@/common/components/ButtonControl";
import DrawerControl from "@/common/components/DrawerControl";
import { DrawerControlButtonRenderer } from "@/common/components/DrawerControl/DrawerControl.component";
import {
  ToggleableContextLayers,
  ToggleableLayerType
} from "@/common/components/ToggleableLayer/ToggleableLayer.config";
import { ReactComponent as DataOptionsIcon } from '@/common/icons/data-options.svg';
import { TestableComponent } from "@/common/types/testable-component";
import MapLayersContainer from "@/tenant-context/control-risk-config/components/MapLayers";
import FactalEventsSettingsContainer
  from '@/tenant-context/control-risk-config/components/ProviderSpecificComponents/Factal/FactalEventsSettings';
import HealixEventsSettingsContainer
  from '@/tenant-context/control-risk-config/components/ProviderSpecificComponents/Healix/HealixEventsSettings';
import BSOCEventsSettingsContainer from "@/tenant-context/control-risk-config/components/RiskProviderTreeContent/BSOCEventsSettings.container";
import DataminrEventsSettingsContainer from "@/tenant-context/control-risk-config/components/RiskProviderTreeContent/DataminrEventsSettings.container";
import {
  ContextualLayerSettingsComponent
} from "@/tenant-context/control-risk-config/components/RiskProviderTreeContent/MapLayerSettings";
import PeopleSettingsContainer from "@/tenant-context/control-risk-config/components/RiskProviderTreeContent/PeopleSettings.container";
import RisklineEventsSettingsContainer from "@/tenant-context/control-risk-config/components/RiskProviderTreeContent/RisklineEventsSettings.container";
import {
  useMapLayersDrawerContext
} from "@/tenant-context/control-risk-config/context/MapLayersDrawer.context";

export const MAP_LAYERS_DRAWER_CONTROL_ID = "map-layers";

type Props = TestableComponent & {
  resetSearchState: () => void;
}

const MapLayersControlComponent: FC<Props> = ({
  resetSearchState,
  dataTestId = "right-menu-layers"
}) => {
  const { drawerPage, setRootDrawerPage } = useMapLayersDrawerContext();
  const renderTestDrawerButton: DrawerControlButtonRenderer = useCallback(
    ({ onClick }) => (
      <ButtonControl
        label="Map Layers"
        onClick={ onClick }
        data-testid={ dataTestId }
      >
        <DataOptionsIcon />
      </ButtonControl>
    ),
    [dataTestId]
  );

  const handleCloseDrawer = useCallback(() => {
    resetSearchState();
    setRootDrawerPage();
  }, [resetSearchState, setRootDrawerPage]);

  const isRootPage = drawerPage === "map-layers";

  return (
    <DrawerControl
      id={ MAP_LAYERS_DRAWER_CONTROL_ID }
      renderButton={ renderTestDrawerButton }
      title={ isRootPage ? "Map Layers" : undefined }
      isSubDrawer={ !isRootPage }
      isWithTitleBorder={ isRootPage }
      onBackButtonClick={ setRootDrawerPage }
      backButtonText="Map Layers"
      onCloseClick={ handleCloseDrawer }
    >
      { drawerPage === "map-layers" && <MapLayersContainer /> }
      { drawerPage === "riskline" && <RisklineEventsSettingsContainer /> }
      { drawerPage === "dataminr" && <DataminrEventsSettingsContainer /> }
      { drawerPage === "healix" && <HealixEventsSettingsContainer /> }
      { drawerPage === "bsoc" && <BSOCEventsSettingsContainer /> }
      { drawerPage === "factal" && <FactalEventsSettingsContainer /> }
      { drawerPage === ToggleableLayerType.People && <PeopleSettingsContainer resetSearchState={ resetSearchState } /> }
      { drawerPage in ToggleableContextLayers &&
        <ContextualLayerSettingsComponent layerType={ drawerPage as ToggleableContextLayers } /> }
    </DrawerControl>
  );
};

export default MapLayersControlComponent;
