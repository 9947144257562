import { callApi } from "@/common/util/api";
import { CaseStatusItem, PeopleStatusItem } from "@/platform-context/control-icm-management/types/CaseTypes/caseTypesConfigurations";
import { RoleListItem } from "@/platform-context/control-icm-management/types/CaseTypes/caseTypeTeamsAndRoles";
import { SeverityLevel } from "@/platform-context/control-icm-management/types/severityTypes";
import { SortData } from "@/tenant-context/control-groups/types/group";
import { CaseListTabs } from "@/tenant-context/control-icm-management/components/CaseManagement/Context/CaseList.Context";
import {
  Case,
  ListCaseTypeSeverityLevelLocation
} from "@/tenant-context/control-icm-management/types/caseList";
import { PaginatedResult } from "@/tenant-context/control-profile/types/profile";

import {
  AddCaseForm,
  AddCaseResponse,
  CaseAttachment,
  CaseAttachmentResponse,
  CaseFields,
  CaseLocationDTO,
  CaseLocationParams,
  CaseOwner, CaseTab,
  CaseTag
} from "../types/caseManagement";

export const caseManagementApi = {
  createCase: function(params: AddCaseForm) {
    return callApi<AddCaseResponse>(
      `managed/icm-service/v1/icm-cases`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=CreateICMCase'
        },
        method: 'post',
        body: JSON.stringify(params)
      }
    );
  },
  updateCase: function(params: CaseFields) {
    return callApi<AddCaseResponse>(
      `managed/icm-service/v1/icm-cases/${params.tid}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=UpdateICMCase'
        },
        method: 'post',
        body: JSON.stringify(params)
      }
    );
  },
  getCaseById: function(id: string) {
    return callApi<CaseFields>(
      `managed/icm-service/v1/icm-cases/${id}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=GetICMCase'
        },
        method: 'get'
      }
    );
  },
  getCaseListData: function (
    page = 0,
    size = 10,
    caseStatus = CaseListTabs.Open,
    isFilterEnabled: boolean,
    sort?: SortData,
    query?: string,
    locationFilter?: string
  ) {
    return callApi<PaginatedResult<Case>>(
      `managed/icm-service/v1/icm-cases?${sort?.colId ? 'orderBy=' + sort.colId + ':' + sort.sort + '&' : ''}size=${size}&page=${page}&caseStatus=${caseStatus}${query ? '&search=(' + query + ')' : ''}${locationFilter ? `&locationId=${locationFilter}`  : ''}${isFilterEnabled ? `&isFilterEnabled=${isFilterEnabled}`  : ''}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=ListICMCase'
        },
        method: 'get'
      }
    );
  },
  getCaseStatusesByCase: function(caseId: string) {
    return callApi<PaginatedResult<CaseStatusItem>>(
      `managed/icm-service/v1/icm-cases/${caseId}/case-type-statuses?page=0&size=10000`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=ListCaseTypeStatus'
        },
        method: 'get'
      }
    );
  },
  getPeopleStatusesByCase: function(caseId: string) {
    return callApi<PaginatedResult<PeopleStatusItem>>(
      `managed/icm-service/v1/icm-cases/${caseId}/people-statuses?page=0&size=10000`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=ListPeopleStatus'
        },
        method: 'get'
      }
    );
  },
  getRolesByCase: function(caseId: string) {
    return callApi<PaginatedResult<RoleListItem>>(
      `managed/icm-service/v1/icm-cases/${caseId}?page=0&size=1000`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=ListRole'
        },
        method: 'get'
      }
    );
  },
  getSeverityLevelsByCase: function(caseId: string) {
    return callApi<PaginatedResult<SeverityLevel>>(
      `managed/icm-service/v1/icm-cases/${caseId}/severity-levels?page=0&size=1000`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=ListSeverityLevel'
        },
        method: 'get'
      }
    );
  },
  getCaseOwners: function() {
    return callApi<PaginatedResult<CaseOwner>>(
      `managed/icm-service/v1/icm-cases?policyNames=Tenant_ICM-CaseManagement_FullAccess,Tenant_ICM-CaseManagement_Contributor`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=ListOwner'
        },
        method: 'get'
      }
    );
  },
  getListCaseTypeSeverityLevelLocation: function(caseStatus: CaseListTabs) {
    return callApi<ListCaseTypeSeverityLevelLocation>(
      `managed/icm-service/v1/icm-cases?caseStatus=${caseStatus}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=ListCaseTypeSeverityLevelLocation',
          method: 'get'
        }
      }
    );
  },
  getCaseTags: function() {
    return callApi<PaginatedResult<CaseTag>>(
      `managed/icm-service/v1/icm-case-tags?page=&size=`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=ListICMCaseTag'
        },
        method: 'get'
      }
    );
  },
  uploadAttachmentsForCase: function(caseId: string, attachments: CaseAttachment[]) {
    return callApi(
      `managed/icm-service/v1/icm-cases/${caseId}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=UploadAttachment'
        },
        method: 'post',
        body: JSON.stringify({ attachmentList: attachments })
      }
    );
  },
  listAttachmentsForCase: function(caseId: string) {
    return callApi<PaginatedResult<CaseAttachmentResponse>>(
      `managed/icm-service/v1/icm-cases/${caseId}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=ListAttachment'
        },
        method: 'get'
      }
    );
  },
  deleteAttachment: function(caseId: string, attachmentId: string) {
    return callApi(
      `managed/icm-service/v1/icm-cases/${caseId}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=DeleteAttachment'
        },
        method: 'post',
        body: JSON.stringify({ attachmentId })
      }
    );
  },
  createICMLocation: function(caseId: string, requestObj: CaseLocationParams) {
    return callApi<CaseLocationDTO>(
      `managed/icm-service/v1/icm-cases/${caseId}/icm-locations`,
      {
        method: "post",
        body: JSON.stringify(requestObj),
        headers: {
          'x-domain-model': 'CreateICMLocation'
        }
      }
    );
  },
  postUserPinnedCase: function(caseId: string, isPinned: boolean, tenantUserId: string) {
    return callApi(
      `managed/icm-service/v1/icm-cases/${caseId}`,
      {
        headers: {
          'Content-Type': `application/json;domain-model=${isPinned ? 'CreateUserPinnedCase' : 'DeleteUserPinnedCase'}`
        },
        method: 'post',
        body: JSON.stringify({ tenantUserId: tenantUserId })
      }
    );
  },
  listICMLocations: function(caseId: string, page = 0, size = 20) {
    return callApi<PaginatedResult<CaseLocationDTO>>(
      `managed/icm-service/v1/icm-cases/${caseId}?page=${page}&size=${size}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=ListICMLocation'
        },
        method: 'get'
      }
    );
  },
  getCaseTabs: function(caseId: string) {
    return callApi<PaginatedResult<CaseTab>>(
      `managed/icm-service/v1/icm-cases/${caseId}?page=0&size=20`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=ListFeatureAccessLevel'
        },
        method: 'get'
      }
    );
  }
};