import { FC, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Dispatch, RootState } from "@/core/store";
import LayerOpacityControlComponent
  from "@/tenant-context/visualisation-opacity/components/LayerOpacityControl.component";

type Props = {
  layerId: string;
  // 0..1
  defaultOpacity?: number;
}

const LayerOpacityControl: FC<Props> = ({ layerId, defaultOpacity }) => {
  const layerOpacity = useSelector((state: RootState) => state.dataOptions.layerOpacity);
  const opacityValue = layerOpacity[layerId] ?? defaultOpacity;

  const {
    dataOptions: {
      SET_LAYER_OPACITY
    }
  } = useDispatch<Dispatch>();

  const handleChangeOpacity = useCallback((newValue) => {
    SET_LAYER_OPACITY({ layerId, opacity: newValue });
  }, [SET_LAYER_OPACITY, layerId]);

  return (
    <LayerOpacityControlComponent initialValue={ opacityValue } onChange={ handleChangeOpacity } />
  );
};

export default LayerOpacityControl;