import { createStyles } from "@mantine/core";

export const useCloseCaseModalStyles = createStyles((_theme) => ({
  form: {
    marginTop: 24
  },
  closeCaseStepper: {
    '.restrata-Stepper-stepCompletedIcon': {
      justifyContent: 'center !important'
    }
  }
}));
