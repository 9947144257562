import { Text, Tooltip } from "@mantine/core";
import { FC, useCallback, useEffect, useRef, useState } from "react";

type Props = {
  fullText?: string;
  className?: string;
  tooltipWidth?: number | "auto";
  isMultiline?: boolean;
  isWithArrow?: boolean;
  containerWidth?: string | number; // Width of the container
  isObservingResize?: boolean; // Enable/disable width change listener
};

export const TruncatedText: FC<Props> = ({
  fullText = "",
  className = "",
  tooltipWidth = "auto",
  isMultiline = false,
  isWithArrow = false,
  containerWidth = "100%",
  isObservingResize = false
}) => {
  const textRef = useRef<HTMLDivElement>(null);
  const [isTruncated, setIsTruncated] = useState(false);

  const checkTruncation = useCallback(() => {
    if (textRef.current) {
      setIsTruncated(textRef.current.scrollWidth > textRef.current.clientWidth);
    }
  }, []);

  useEffect(() => {
    // Perform an initial truncation check
    checkTruncation();

    if (textRef.current) {
      checkTruncation();
    }

    if (!isObservingResize || !textRef.current) {
      return; // Do not attach a ResizeObserver if the listener is disabled
    }

    // Observe width changes using ResizeObserver
    const resizeObserver = new ResizeObserver(() => {
      checkTruncation();
    });

    resizeObserver.observe(textRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [checkTruncation, fullText, isObservingResize]);

  return (
    <Tooltip
      label={ fullText }
      width={ tooltipWidth }
      multiline={ isMultiline }
      withArrow={ isWithArrow }
      arrowSize={ 10 }
      withinPortal
      disabled={ !isTruncated } // Tooltip only shows when text is truncated
    >
      <Text
        ref={ textRef }
        className={ className }
        style={ {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          width: containerWidth
        } }
      >
        { fullText }
      </Text>
    </Tooltip>
  );
};

export default TruncatedText;
