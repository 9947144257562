import { ReferenceData } from "@/common/types/reference-data";
import { callApi } from "@/common/util/api";
import { TeamRole
} from "@/tenant-context/control-icm-management/types/caseManagement";
import {
  CaseTaskDTO,
  CaseTaskParams,
  CreateTaskCommentParam, DeleteTaskParam, TaskComment, TaskCount, TaskList,
  UpdateTaskParams
} from "@/tenant-context/control-icm-management/types/tasks";
import { PaginatedResult } from "@/tenant-context/control-profile/types/profile";

export const taskApi = {
  createTask: function(caseId: string, requestObj: CaseTaskParams) {
    return callApi<CaseTaskDTO>(
      `managed/icm-service/v1/icm-cases/${caseId}`,
      {
        method: "post",
        body: JSON.stringify(requestObj),
        headers: {
          'x-domain-model': 'CreateTask'
        }
      }
    );
  },
  updateTask: function(caseId: string, requestObj: UpdateTaskParams) {
    return callApi<CaseTaskDTO>(
      `managed/icm-service/v1/icm-cases/${caseId}`,
      {
        method: "post",
        body: JSON.stringify(requestObj),
        headers: {
          'x-domain-model': 'UpdateTask'
        }
      }
    );
  },
  getICMTaskPriorityData : function() {
    return callApi<PaginatedResult<ReferenceData>>(
      `managed/platform-service/v1/reference-data?search=%28type=%27Icm-Task-Priority%27%29`,
      {
        headers: {
          'Content-Type': 'application/json;'
        },
        method: 'get'
      }
    );
  },
  getICMTaskStatusData: function() {
    return callApi<PaginatedResult<ReferenceData>>(
      `managed/platform-service/v1/reference-data?search=%28type=%27Icm-Task-Status%27%29`,
      {
        headers: {
          'Content-Type': 'application/json;'
        },
        method: 'get'
      }
    );
  },
  getTask: function(
    caseId: string,
    taskId: string
  ) {
    return callApi<CaseTaskDTO>(
      `managed/icm-service/v1/icm-cases/${caseId}/tasks/${taskId}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=GetTask'
        },
        method: 'get'
      }
    );
  },
  deleteTask: function(
    caseId: string,
    requestObj: DeleteTaskParam
  ) {
    return callApi<void>(
      `managed/icm-service/v1/icm-cases/${caseId}`,
      {
        method: "post",
        body: JSON.stringify(requestObj),
        headers: {
          'x-domain-model': 'DeleteTask'
        }
      }
    );
  },
  getTaskListData: function(
    caseId: string,
    page = 0,
    size = 10,
    sort?: string | null,
    query?: string
  ) {
    return callApi<PaginatedResult<TaskList>>(
      `managed/icm-service/v1/icm-cases/${caseId}/tasks?${sort ? ('orderBy=' + sort + '&') : ''}size=${size}&page=${page}${query ? '&search=' + query : ''}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=ListTask'
        },
        method: 'get'
      }
    );
  },
  getTeamRole: function(
    caseId: string,
    teamId: string,
    page = 0,
    size = 10
  ) {
    return callApi<PaginatedResult<TeamRole>>(
      `managed/icm-service/v1/icm-cases/${caseId}?page=${page}&size=${size}&teamId=${teamId}&isAssigned=true`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=ListTeamRole'
        },
        method: 'get'
      }
    );
  },
  getTaskCount: function(
    caseId: string
  ) {
    return callApi<TaskCount>(
      `managed/icm-service/v1/icm-cases/${caseId}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=CountTaskByStatus'
        },
        method: 'get'
      }
    );
  },
  getTaskCommentList: function(
    caseId: string,
    taskId: string
  ) {
    return callApi<PaginatedResult<TaskComment>>(
      `managed/icm-service/v1/icm-cases/${caseId}/comments?taskId=${taskId}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=ListComment'
        },
        method: 'get'
      }
    );
  },
  createTaskComment: function(
    caseId: string,
    requestObj: CreateTaskCommentParam
  ) {
    return callApi<void>(
      `managed/icm-service/v1/icm-cases/${caseId}`,
      {
        method: "post",
        body: JSON.stringify(requestObj),
        headers: {
          'x-domain-model': 'CreateComment'
        }
      }
    );
  }

};