import { healixRiskLevelMap } from '@/tenant-context/common/config/risk.config';
import { HealixExternalData } from '@/tenant-context/visualisation-risk-alerts/types/healix';
import { GenericRiskAlertResponse } from '@/tenant-context/visualisation-risk-alerts/types/risk-alert-response';
import { RiskAlertEvent } from '@/tenant-context/visualisation-risk-alerts/types/risk-alerts';

export const mapRiskAlertToHealixAlert = (
  impactEngineRiskAlert: GenericRiskAlertResponse<HealixExternalData>
): RiskAlertEvent<HealixExternalData> => {
  const { request, peopleCount, assetCount, impactStatus } = impactEngineRiskAlert;
  const {
    tenantId,
    geometry,
    ext_id,
    text,
    title,
    start_date,
    end_date,
    countries,
    externalData: {
      AlertCategory,
      AlertCountries,
      IncidentRating,
      AlertMessage
    },
    source
  } = request;

  return {
    json: {
      meta: {
        company_id: tenantId,
        geojson: {
          type: "Feature",
          geometry: {
            type: 'Point',
            coordinates: [
              geometry.lon,
              geometry.lat
            ]
          },
          properties: {},
          id: 'Healix'
        },
        language: 'en_US'
      },
      alert: {
        id: ext_id,
        text: text,
        title: title,
        advice: AlertMessage,
        category: { id: `healix/${AlertCategory}`, name: AlertCategory },
        countries: countries,
        location_explanation: AlertCountries[0]?.Name,
        risk_level: healixRiskLevelMap[IncidentRating.Name] || { id: '3', name: 'Medium' },
        is_breaking: false,
        is_notice: false,
        parent_id: '',
        regions: AlertCountries.map(country => ({ code: country.IsoCode, name: country.Name })),
        start_date: start_date,
        end_date: end_date
      },
      externalData: { ...request.externalData } as HealixExternalData
    },
    meta: {
      createdTime: impactEngineRiskAlert.meta.createdTime,
      updatedTime: impactEngineRiskAlert.meta.updatedTime
    },
    source: source,
    tid: ext_id,
    isImpacted: impactStatus === 'CLOSED' ? false : peopleCount > 0 || assetCount > 0
  };
};

