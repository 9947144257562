export enum Environment {
  Dev = 'dev',
  Qa = 'qa',
  UAT = 'uat',
  PROD = 'prod',
  Demo = 'demo'
}

type Env = {
  unifiedExperienceUrl: string,
  outSystemsGlobalDashboardUrl: string,
  outSystemsSitesUrl: string,
  restEndpoint: string
}

// Not to hit process.env in runtime (for performance reasons)
// https://stackoverflow.com/a/48453906/6686446
const {
  REACT_APP_REST_ENDPOINT,
  REACT_APP_UNIFIED_EXPERIENCE_URL,
  REACT_APP_OUTSYSTEMS_GLOBAL_DASHBOARD_URL,
  REACT_APP_OUTSYSTEMS_SITES_URL
} = process.env;

export const ENV: string = (function() {

  if (!REACT_APP_REST_ENDPOINT) {
    throw new Error('No REST endpoint!');
  }

  const map: Record<string, string> = {
    'https://global-dev.restrata.net/': Environment.Dev,
    'https://global-qa.restrata.net/': Environment.Qa,
    'https://global-uat.restrata.net/': Environment.UAT,
    'https://global.restrata.net/': Environment.PROD
  };

  // Considering any other envs as DEV (For isInDevMode checks)
  return map[REACT_APP_REST_ENDPOINT] || Environment.Dev;
})();

export const environment = (function() {
  // Kept this for references until env setup is done
  const _map: Record<string, Env> = {
    [Environment.Dev]: {
      unifiedExperienceUrl: 'https://global-platform-dev.restrata.net',
      outSystemsGlobalDashboardUrl: 'https://dev.solutions.restrata.net/RestrataGlobalDashboard/JavaSSO',
      outSystemsSitesUrl: 'https://dev.solutions.restrata.net/Restrata_Portal/Sites.aspx/JavaSSO',
      restEndpoint: 'https://global-dev.restrata.net'
    },
    [Environment.Qa]: {
      unifiedExperienceUrl: 'https://global-platform-qa.restrata.net',
      outSystemsGlobalDashboardUrl: 'https://tst.solutions.restrata.net/RestrataGlobalDashboard/JavaSSO',
      outSystemsSitesUrl: 'https://tst.solutions.restrata.net/Restrata_Portal/Sites.aspx/JavaSSO',
      restEndpoint: 'https://global-qa.restrata.net'
    },
    [Environment.UAT]: {
      unifiedExperienceUrl: 'https://global-platform-uat.restrata.net',
      outSystemsGlobalDashboardUrl: 'https://detach-qa.solutions.restrata.net/RestrataGlobalDashboard/JavaSSO',
      outSystemsSitesUrl: 'https://detach-qa.solutions.restrata.net/Restrata_Portal/Sites.aspx/JavaSSO',
      restEndpoint: 'https://global-uat.restrata.net'
    },
    [Environment.PROD]: {
      unifiedExperienceUrl: 'https://global-platform.restrata.net',
      outSystemsGlobalDashboardUrl: 'https://live.solutions.restrata.net/RestrataGlobalDashboard/JavaSSO',
      outSystemsSitesUrl: 'https://live.solutions.restrata.net/Restrata_Portal/Sites.aspx/JavaSSO',
      restEndpoint: 'https://global.restrata.net'
    },
    [Environment.Demo]: {
      unifiedExperienceUrl: 'https://global-platform-demo.global-demo.restrata.net',
      outSystemsGlobalDashboardUrl: 'https://dev.solutions.restrata.net/RestrataGlobalDashboard/JavaSSO',
      outSystemsSitesUrl: 'https://dev.solutions.restrata.net/Restrata_Portal/Sites.aspx/JavaSSO',
      restEndpoint: 'https://global-demo.global-demo.restrata.net/'
    }
  };

  if (!REACT_APP_REST_ENDPOINT || !REACT_APP_UNIFIED_EXPERIENCE_URL
    || !REACT_APP_OUTSYSTEMS_GLOBAL_DASHBOARD_URL || !REACT_APP_OUTSYSTEMS_SITES_URL) {
    throw new Error('Endpoints are not configured correctly!');
  }

  return {
    unifiedExperienceUrl: REACT_APP_UNIFIED_EXPERIENCE_URL,
    outSystemsGlobalDashboardUrl: REACT_APP_OUTSYSTEMS_GLOBAL_DASHBOARD_URL,
    outSystemsSitesUrl: REACT_APP_OUTSYSTEMS_SITES_URL,
    restEndpoint: REACT_APP_REST_ENDPOINT
  };
})();
