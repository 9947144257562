export const CaseManagementPoliciesConfig = {
  CASE_MANAGEMENT_ROUTE_ACCESS: {
    policyNames: [
      'Tenant_ICM-CaseManagement_Contributor',
      'Tenant_ICM-CaseManagement_FullAccess'
    ]
  },
  EDIT_CASE_MANAGEMENT: {
    policyNames: [
      'Tenant_ICM-CaseManagement_FullAccess'
    ]
  }
};