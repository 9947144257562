import { ToggleableContextLayers } from "@/common/components/ToggleableLayer/ToggleableLayer.config";
import { DEFAULT_SURFACE_LAYER_ID } from "@/tenant-context/visualisation-weather/config/weather.config";
import { WeatherLayerItem } from "@/tenant-context/visualisation-weather/types/weather.types";

// you can add other fields, e.g. z-index/beforeId, info popup content & etc.
export const weatherLayersConfig: WeatherLayerItem[] = [
  {
    label: "Weather Alerts",
    contextualLayerType: ToggleableContextLayers.Alerts,
    options: {
      id: "alerts",
      paint: {
        opacity: .75
      }
    },
    beforeId: "r__criticalLocationLayer-cluster"
  },
  {
    label: "Radar (Precipitation)",
    contextualLayerType: ToggleableContextLayers.Radar,
    options: {
      id: "radar",
      paint: {
        opacity: .75,
        text: {
          color: "#FFF"
        }
      }
    },
    beforeId: "r__criticalLocationLayer-cluster"
  },
  {
    label: "Tropical Cyclones",
    contextualLayerType: ToggleableContextLayers.Cyclones,
    options: {
      id: "tropical-cyclones",
      paint: {
        text: {
          color: "#fff"
        }
      }
    },
    beforeId: "r__criticalLocationLayer-cluster"
  },
  {
    label: "Wave Heights",
    contextualLayerType: ToggleableContextLayers.WaveHeights,
    options: {
      id: "wave-heights",
      paint: {
        opacity: .75
      }
    },
    beforeId: DEFAULT_SURFACE_LAYER_ID
  },
  {
    label: "Surface Temperatures",
    contextualLayerType: ToggleableContextLayers.Temperature,
    options: {
      id: "temperatures",
      paint: {
        opacity: .75
      }
    },
    beforeId: DEFAULT_SURFACE_LAYER_ID
  },
  {
    label: "Wind (Speed & Direction)",
    contextualLayerType: ToggleableContextLayers.Wind,
    options: {
      id: "wind-particles"
    },
    beforeId: "r__criticalLocationLayer-cluster"
  }
];

const contextualLayerTypeToLayerConfig = new Map<ToggleableContextLayers, WeatherLayerItem>(
  weatherLayersConfig
    .map(configItem => [configItem.contextualLayerType, configItem])
);

export const getLayerConfigByContextLayer = (contextualLayerType: ToggleableContextLayers) =>
  contextualLayerTypeToLayerConfig.get(contextualLayerType);
