import { NavigateFunction } from "react-router";

import {
  REGULAR_REFRESH_TOKEN_KEY,
  REGULAR_TOKEN_KEY,
  TENANT_REFRESH_TOKEN_KEY,
  TENANT_TOKEN_KEY
} from "@/common/util/auth";
import browserDatabase from "@/common/util/browser-database";
import { ensureHTTP } from "@/common/util/common-functions";
import { environment } from "@/core/config/env";
import { NavigationSidebarItem, ProductArea } from '@/core/navigation/types/navigation';
import { getOfflineProxyToken, getOutsystemsToken } from "@/tenant-context/navigation/api/navigation";
import {
  baseUrlMap
} from "@/tenant-context/navigation/components/NavigationSidebar/NavigationSidebar.config";

export const redirectWithinRestrataProductEcosystem = async ({
  path,
  area,
  unifiedExperienceApplication,
  outsystemsApplication,
  isNotReady,
  ...params
}: NavigationSidebarItem, navigate?: NavigateFunction) => {
  if (isNotReady) {
    return;
  }
  const baseUrl = baseUrlMap[area];

  // * OutSystems
  if (area === ProductArea.OutSystems) {
    const { access_token: osToken } = await getOutsystemsToken();

    const url = `${environment.outSystemsGlobalDashboardUrl}?` +
      `Application=${outsystemsApplication}&` +
      `Token=${osToken}&` +
      'OriginApp=GLOBAL-MAP&' +
      `OriginDomain=${environment.unifiedExperienceUrl}/`;

    window.open(url, "_blank")?.focus();

  // * Unified Experience
  } else if (area === ProductArea.UnifiedExperience) {
    if (!unifiedExperienceApplication) {
      return;
    }

    const accessToken = browserDatabase.getItem(REGULAR_TOKEN_KEY);
    const tenantToken = browserDatabase.getItem(TENANT_TOKEN_KEY);
    const refreshToken = browserDatabase.getItem(REGULAR_REFRESH_TOKEN_KEY);
    const tenantRefreshToken = browserDatabase.getItem(TENANT_REFRESH_TOKEN_KEY);

    const expireInDate = new Date();
    expireInDate.setTime(expireInDate.getTime() + (2 * 60 * 1000));
    const cookieObj = {
      accessToken,
      tenantToken,
      refreshToken,
      tenantRefreshToken,
      application: unifiedExperienceApplication
    };
    const domain = window.location.hostname.split('.').slice(-2).join('.');
    document.cookie = `UnifiedAccess = ${encodeURIComponent(JSON.stringify(cookieObj))}; expires = ${expireInDate.toUTCString()}; domain = .${domain}; path =/`;

    const url = new URL(
      `third-party-sso-integration?application=${unifiedExperienceApplication}`,
      baseUrl
    );

    window.open(url.toString(), "_self")?.focus();

  // * Offline Proxy
  } else if (area === ProductArea.OfflineProxy) {
    
    if(!path){
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { siteId = "", returnPath = "" } = params.redirectParams as any;

    const { access_token: proxyToken } = await getOfflineProxyToken(siteId);

    const url = new URL(
      `third-party-sso-integration?token=${proxyToken}&&origin=${encodeURIComponent(window.location.origin)}&&returnPath=${returnPath}`,
      ensureHTTP(path)
    );

    window.open(url.toString(), "_blank")?.focus();

    // * Other
  } else if (area === ProductArea.Global) {
    if (!path) {
      return;
    }

    if (navigate) {
      navigate(path);
    }
  } else if (area === ProductArea.ThirdParty) {
    if (!path) {
      return;
    }

    const url = baseUrl
      ? new URL(path, baseUrl)
      : path;

    window.open(url.toString(), "_blank")?.focus();
  } else {
    if (!path) {
      return;
    }

    const url = baseUrl
      ? new URL(path, baseUrl)
      : path;

    window.open(url.toString(), "_self")?.focus();
  }
};

export const navigateToCrossTenant = async () => {
  await redirectWithinRestrataProductEcosystem({
    area: ProductArea.UnifiedExperience,
    unifiedExperienceApplication: 'landing-page'
  } as NavigationSidebarItem, undefined);
};
