import { EventData, MapLayerEventType } from 'mapbox-gl';
import { FC } from 'react';
import Map, { useMap,ViewState } from 'react-map-gl';

import { Products } from '@/common/types/products';
import { WORLD_MAX_BOUNDS } from '@/tenant-context/common/util/constants';
import GeofenceDataPopup from '@/tenant-context/control-draw/components/GeofenceDataPopup';
import { useGeofenceMapStyles } from '@/tenant-context/control-draw/components/GeofenceMap/GeofenceMap.styles';
import GeofenceMapStatsCards from '@/tenant-context/control-draw/components/GeofenceMapStatsCards';
import GeofenceContextProvider from '@/tenant-context/control-draw/context/Geofence.context';
import GeofenceWidget from "@/tenant-context/control-draw/controls/GeofenceWidget";
import MapboxDrawControl from '@/tenant-context/control-draw/controls/MapboxDrawControl';
import LocationGraphControl
  from '@/tenant-context/control-location-graph/controls/LocationGraphControl/LocationGraphControl.container';
import MiniProfileControl from '@/tenant-context/control-profile/controls/MiniProfileControl/MiniProfileControl.container';
import AuthenticatedMap from '@/tenant-context/core/components/AuthenticatedMap/AuthenticatedMap.component';
import IndoorPeopleLayer from '@/tenant-context/plugin-indoors/layers/IndoorPeopleLayer/IndoorPeopleLayer.component';
import { CustomerLocationLayer } from '@/tenant-context/visualisation-customer-locations/layers/CustomerLocationLayer/CustomerLocationLayer.container';
import PeopleLocationPopup from '@/tenant-context/visualisation-people/components/PeopleLocationPopup/PeopleLocationPopup.container';
import { PeopleContextProvider } from '@/tenant-context/visualisation-people/context/People.context';
import usePeopleIcons from '@/tenant-context/visualisation-people/hooks/usePeopleIcons';
import PeopleBreadcrumbLayer from '@/tenant-context/visualisation-people/layers/PeopleBreadcrumbLayer/PeopleBreadcrumbLayer.container';
import PeopleLocationLayer from '@/tenant-context/visualisation-people/layers/PeopleLocationLayer';
import GeofencePOILocationLayer from "@/tenant-context/visualisation-site/layers/GeofencePOILocationLayer";


type Props = {
  onDrawingUpdate: (evt: MapLayerEventType & EventData) => void;
  onDrawingDelete: (evt: MapLayerEventType & EventData) => void;
  onDrawingSelected: (evt: MapLayerEventType & EventData) => void;
  onDrawControlCreated?: (mapboxDraw: MapboxDraw) => void;
  viewState: ViewState | undefined;
};

const {
  REACT_APP_MAPBOX_ACCESS_TOKEN
} = process.env as {
  REACT_APP_MAPBOX_ACCESS_TOKEN?: string
};

const GeofenceMapComponent: FC<Props> = ({
  onDrawingUpdate,
  onDrawingDelete,
  onDrawingSelected,
  onDrawControlCreated,
  viewState
}) => {
  const { classes } = useGeofenceMapStyles();

  const { GeofenceMap: map } = useMap();

  usePeopleIcons(map, true);

  return (
    <div className={ classes.geofenceMapContainer }>
      <AuthenticatedMap requiredProduct={ Products.CORE_SERVICE }>
        <Map
          id="GeofenceMap"
          mapboxAccessToken={ REACT_APP_MAPBOX_ACCESS_TOKEN }
          minZoom={ 2 }
          initialViewState={ viewState || {
            latitude: 0,
            longitude: 0,
            zoom: 2.0001
          } }
          mapStyle='mapbox://styles/smehmood/cl4feh10w000114pyksvp6mu9'
          testMode
          renderWorldCopies={ false }
          maxBounds={ WORLD_MAX_BOUNDS }
          projection="mercator"
          fog={ {} }
        >

          <GeofenceContextProvider>
            <GeofenceDataPopup />
            <GeofenceMapStatsCards />
          </GeofenceContextProvider>

          { /*Controls*/ }
          <GeofenceWidget />

          <MapboxDrawControl
            onCreate={ onDrawingUpdate }
            onUpdate={ onDrawingUpdate }
            onDelete={ onDrawingDelete }
            onDrawControlCreated={ onDrawControlCreated }
            onSelectionChanged={ onDrawingSelected }
          />

          <CustomerLocationLayer />

          <PeopleContextProvider mapKey='geofenceMap'>
            <PeopleLocationLayer />
            <PeopleLocationPopup />
            <PeopleBreadcrumbLayer />
            <IndoorPeopleLayer />

            { /* Drawers without controls */ }
            <LocationGraphControl />
            <GeofencePOILocationLayer />
            <MiniProfileControl />
          </PeopleContextProvider>
        </Map>
      </AuthenticatedMap>
    </div>
  );
};

export default GeofenceMapComponent;
