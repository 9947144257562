export const MassCommsPoliciesConfig = {
  MC_ARC_INVOKE: {
    policyNames: [
      'Tenant_MassComms_ImpactEvent_FullAccess'
    ]
  },
  MC_MANUAL_INVOKE: {
    policyNames: [
      'Tenant_MassComms_Manual_FullAccess'
    ]
  },
  MC_REPORTS_PAGE: {
    policyNames: [
      'Tenant_MassComms_Reports_ReadOnly'
    ]
  },
  MC_SENT_COMMS_PAGE: {
    policyNames: [
      'Tenant_MassComms_Manual_FullAccess',
      'Tenant_MassComms_ImpactEvent_FullAccess',
      'Tenant_MassComms_Reports_ReadOnly'
    ]
  },
  MC_CREATE_PAGE: {
    policyNames: [
      'Tenant_MassComms_Manual_FullAccess',
      'Tenant_MassComms_ImpactEvent_FullAccess'
    ]
  },
  MC_CONFIG: {
    policyNames: [
      'Tenant_MassComms_Manual_FullAccess',
      'Tenant_MassComms_ImpactEvent_FullAccess',
      'Tenant_MassComms_Reports_ReadOnly'
    ]
  },
  readPersonProfileAccessPolicies: {
    policyNames: [
      'Tenant_System_People-FullProfile_Readonly',
      'Tenant_System_People-FullProfile_FullAccess',
      'Tenant_System_People-PersonalDetails_Readonly',
      'Tenant_System_People-PersonalDetails_FullAccess'
    ]
  }
};
