import { Box, Flex, Progress, Rating, SimpleGrid, Stack, Text } from '@mantine/core';
import { FC, Fragment, useMemo } from 'react';

import { ReactComponent as StarEmpty } from '@/common/icons/Components/rating-star-empty.svg';
import { ReactComponent as StarFilled } from '@/common/icons/Components/rating-star-filled.svg';
import {
  useDetailedCountryReportStyles
} from '@/tenant-context/control-reports/components/DetailedCountryReport/DetailedCountryReport.styles';
import { HealixCountryRiskData } from '@/tenant-context/control-reports/types/country-risk-reports';
import { RiskReportProvider } from '@/tenant-context/control-reports/types/risk-reports-common';
import {
  getColorForHealixTrendRating,
  getRiskColorByRiskLevel,
  getRiskLevelValueByRiskLevel, getStartRatingForEGMPV, snakeCaseToTitleCase
} from '@/tenant-context/control-reports/util/country-risk-reports';

type Props = {
  countryRiskData: HealixCountryRiskData;
};

const MaxSecurityDetailedCountryReport: FC<Props> = ({
  countryRiskData
}) => {
  const getColorByLevel = (level?: string) => {
    return getRiskColorByRiskLevel(level, RiskReportProvider.HEALIX);
  };
  const getColorByLevelForTrend = (ratingLevel?: number) => {
    return getColorForHealixTrendRating(ratingLevel);
  };

  const { classes } = useDetailedCountryReportStyles( {
    riskLevelColor: getColorByLevel(countryRiskData?.SecurityRiskRating?.toLowerCase())
  } );

  const riskLevelValue = useMemo(() => {
    return getRiskLevelValueByRiskLevel(countryRiskData?.SecurityRiskRating, RiskReportProvider.HEALIX);
  }, [countryRiskData?.SecurityRiskRating]);

  return (
    <Fragment>
      <Stack spacing="sm" className={ classes.riskLevelContainer }>
        <Flex justify="space-between">
          <Text weight={ 400 }>Risk Level</Text>
          <Text weight={ 400 } transform="capitalize">{ countryRiskData?.SecurityRiskRating || 'Not available' }</Text>
        </Flex>
        <Progress value={ riskLevelValue } color="red" size="lg" className={ classes.countryRiskLevel }/>
      </Stack>

      { countryRiskData?.Trends?.length > 0 && <Stack>
        <Text className={ classes.sectionHeading }>Trends</Text>
        <SimpleGrid cols={ 4 } spacing={ 8 }>
          { countryRiskData.Trends.map((trend, index) => (
            <Box
              key={ index }
              style={ { background: getColorByLevelForTrend(trend.Rating) } }
              className={ classes.indicatorPill }
            >
              { snakeCaseToTitleCase(trend.Text) }
            </Box>
          )) }
        </SimpleGrid>
      </Stack> }

      { countryRiskData?.SecuritySnapshot && <Stack>
        <Text className={ classes.sectionHeading }>Security snapshot</Text>
        <Text size="sm">{ countryRiskData.SecuritySnapshot }</Text>
      </Stack> }

      { countryRiskData?.PoliticalSnapshot && <Stack>
        <Text className={ classes.sectionHeading }>Political snapshot</Text>
        <Text size="sm">{ countryRiskData.PoliticalSnapshot }</Text>
      </Stack> }

      { countryRiskData?.OperationalSnapshot && <Stack>
        <Text className={ classes.sectionHeading }>Operational snapshot</Text>
        <Text size="sm">{ countryRiskData.OperationalSnapshot }</Text>
      </Stack> }

      { countryRiskData?.EvacuationWatchSnapshot && <Stack>
        <Text className={ classes.sectionHeading }>Evacuation Watch</Text>
        <Text size="sm">{ countryRiskData.EvacuationWatchSnapshot }</Text>
      </Stack> }

      { countryRiskData?.Advice?.length > 0 && <Stack>
        <Text className={ classes.sectionHeading }>Advice</Text>
        { countryRiskData.Advice.map((advice, index) => (
          <Fragment key={ index }>
            <Text className={ classes.subSectionHeading }>{ advice.SectionName }</Text>
            <div
              className={ classes.content }
              dangerouslySetInnerHTML={ { __html: advice.Text } }
            />
          </Fragment>
        )) }
      </Stack> }

      { countryRiskData?.RiskEnvironment?.length > 0 && <Stack>
        <Text className={ classes.sectionHeading }>Risk Environment</Text>
        { countryRiskData.RiskEnvironment?.map((riskEnvironment, index) => (
          <Fragment key={ index }>
            <Text className={ classes.subSectionHeading }>{ riskEnvironment.Name }</Text>
            { riskEnvironment.Sections?.map((section, index_nested) => (
              <Fragment key={ index_nested }>
                <Text className={ classes.subSectionHeading }>{ section.Type }</Text>
                <Flex gap="md" direction="column">
                  <Flex gap="sm">
                    <Text>Impact : { section.Impact }</Text>
                  </Flex>
                  <Flex gap="sm">
                    <Text>Likelihood : { section.Likelihood }</Text>
                  </Flex>
                </Flex>
                <div
                  className={ classes.content }
                  dangerouslySetInnerHTML={ { __html: section.Reasoning } }
                />
              </Fragment>
            )) }
          </Fragment>
        )) }
      </Stack> }

      { (countryRiskData?.MedicalSnapshot || countryRiskData?.Medical || countryRiskData.MedicalRiskRating) &&
        <Stack>
          <Text className={ classes.sectionHeading }>Medical</Text>

          { countryRiskData?.MedicalRiskRating && <Flex align="center" gap="md">
            <Text className={ classes.subSectionHeading }>Medical Risk Rating</Text>
            <Rating
              value={ getStartRatingForEGMPV(countryRiskData.MedicalRiskRating) }
              emptySymbol={ <StarEmpty /> }
              fullSymbol={ <StarFilled /> }
              readOnly
            />
          </Flex> }

          { countryRiskData?.MedicalSnapshot && <Stack>
            <Text className={ classes.subSectionHeading }>Medical snapshot</Text>
            <Text size="sm">{ countryRiskData.MedicalSnapshot }</Text>
          </Stack> }

          { countryRiskData?.Medical?.map((medical, index) => (
            <Stack key={ index }>
              <Text className={ classes.subSectionHeading }>{ medical.SectionName }</Text>
              <div
                className={ classes.content }
                dangerouslySetInnerHTML={ { __html: medical.Text } }
              />
            </Stack>
          )) }

        </Stack> }

      { countryRiskData?.Logistics?.length > 0 && <Stack>
        <Text className={ classes.sectionHeading }>Logistics</Text>
        { countryRiskData.Logistics?.map((riskEnvironment, index) => (
          <Fragment key={ index }>
            <Text className={ classes.subSectionHeading }>{ riskEnvironment.Name }</Text>
            { riskEnvironment.Sections?.map((section, index_nested) => (
              <Fragment key={ index_nested }>
                <Text className={ classes.subSectionHeading }>{ section.SectionName }</Text>
                <div
                  className={ classes.content }
                  dangerouslySetInnerHTML={ { __html: section.Text } }
                />
              </Fragment>
            )) }
          </Fragment>
        )) }
      </Stack> }

      { countryRiskData?.CitySpecificInfo?.length > 0 && <Stack>
        <Text className={ classes.sectionHeading }>City Specific Info</Text>
        { countryRiskData.CitySpecificInfo?.map((city, index) => (
          <Stack key={ index }>
            <Text className={ classes.subSectionHeading }>{ city.CityName }</Text>
            <div
              className={ classes.content }
              dangerouslySetInnerHTML={ { __html: city.Description } }
            />
          </Stack>
        )) }
      </Stack> }

    </Fragment>
  );
};

export default MaxSecurityDetailedCountryReport;
