import { createStyles } from "@mantine/core";


export const useBorderedGridStyles = createStyles((theme) => ({
  borderedTable: {
    borderCollapse: 'collapse',
    '& .ag-root': {
      border: `0.5px solid ${theme.colors.neutral[6]}`
    },
    '& .ag-header-cell': {
      borderRight: `0.5px solid ${theme.colors.neutral[6]} !important`, 
      borderBottom: `0.5px solid ${theme.colors.neutral[6]}`,
      fontSize: theme.fontSizes.sm 
    },
    '& .ag-header-cell:last-child': {
      borderRight: 'none !important' 
    },
    '& .ag-row': { 
      border: 'none',
      fontSize: theme.fontSizes.xs,
      backgroundColor: `${theme.colors.neutral[9]} !important` 
    },
    '& .ag-row:first-of-type .ag-cell': {
      borderWidth: '0px 0.5px 0.5px 0px',
      borderStyle: 'solid',
      borderColor: theme.colors.neutral[6]
    },
    '& .ag-row:first-of-type .ag-cell:last-of-type': {
      borderRight: 'none'
    },
    '& .ag-row .ag-cell': {
      borderWidth: '0px 0.5px 0.5px 0px',
      borderStyle: 'solid',
      borderColor: theme.colors.neutral[6],
      paddingTop: 0,
      paddingBottom: 0
    },
    '& .ag-row .ag-cell:last-of-type': {
      borderRight: 'none'
    },
    '& .ag-row:last-of-type .ag-cell': {
      borderBottom: `0.25px solid ${theme.colors.neutral[6]}`
    },
    '& .ag-header-cell-resize': {
      '&::after': {
        backgroundColor: 'transparent'
      }
    },
    '& .ag-header-cell-text': {
      textTransform: 'capitalize'
    },
    '& .ag-paging-panel': {
      marginTop: '24px'
    },
    '& .ag-pinned-left-header ': {
      borderRight: `0.5px solid ${theme.colors.neutral[6]} !important`
    },
    '& .ag-pinned-left-cols-container ': {
      borderRight: `0.5px solid ${theme.colors.neutral[6]} !important`
    }
  }
}));
