import { FC, useEffect } from "react";
import { useSelector } from "react-redux";

import { RootState } from "@/core/store";
import { useBigMapContext } from "@/tenant-context/core/context/BigMap.context";

type Props = {
  layerId: string;
}

const WeatherOpacityControl: FC<Props> = ({ layerId }) => {
  const { weatherController } = useBigMapContext();

  const layerOpacity = useSelector((state: RootState) => state.dataOptions.layerOpacity);
  const opacityValue = layerOpacity[layerId];

  useEffect(() => {
    if (!weatherController) {
      return;
    }
    weatherController.setPaintProperty(
      layerId,
      'opacity',
      opacityValue
    );
  }, [weatherController, opacityValue, layerId]);

  return null;
};

export default WeatherOpacityControl;