import { Box } from "@mantine/core";
import { AgGridReact, AgGridReactProps } from "ag-grid-react";

import { useBorderedGridStyles } from "./BorderedDataGrid.styles";


interface Props<_T, _P = Record<string, string | number | boolean>> extends AgGridReactProps {
    headerContent?: React.ReactNode | (() => React.ReactNode),
}

const BorderedDataGridComponent = <T, P = Record<string, string | number | boolean>>({
  headerContent,
  ...props
}: Props<T, P>) => {

  const { classes } = useBorderedGridStyles();

  return (
    <Box>
      { headerContent && <Box>{ headerContent }</Box> }
      <Box className={ classes.borderedTable }>
        <AgGridReact
          rowHeight={ 44 }
          headerHeight={ 44 }
          // eslint-disable-next-line react/jsx-props-no-spreading
          { ...props }
        />
      </Box>
    </Box>
  );
};

export default BorderedDataGridComponent;