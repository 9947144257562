import { ActionIcon, Box, Flex, Skeleton, Stack, Text } from '@mantine/core';
import { FC, useCallback, useMemo, useState } from "react";
import { useSelector } from 'react-redux';

import { ReactComponent as CloseIcon } from "@/common/icons/Actions/close.svg";
import { ReactComponent as Contract } from "@/common/icons/Actions/contract.svg";
import { ReactComponent as DownloadReport } from "@/common/icons/Actions/download-doc.svg";
import { ReactComponent as ExpandIcon } from "@/common/icons/Actions/expand.svg";
import { ReactComponent as RiskLine } from "@/common/icons/riskline.svg";
import { ReactComponent as Healix } from "@/common/icons/RiskProviders/healix.svg";
import { ReactComponent as MaxSecurity } from "@/common/icons/RiskProviders/max-security.svg";
import { logger } from '@/common/util/ConsoleLogger';
import { downloadURL } from "@/common/util/download";
import { showNotification } from '@/common/util/notification';
import { RootStateWithLoading } from '@/core/store';
import CountryFlag from '@/tenant-context/common/components/CountryFlag';
import { downloadPDFFromHTMLContent } from '@/tenant-context/control-reports/api/pdf-generation';
import {
  useDetailedCountryReportStyles
} from '@/tenant-context/control-reports/components/DetailedCountryReport/DetailedCountryReport.styles';
import HealixDetailedCountryReportComponent
  from '@/tenant-context/control-reports/components/DetailedCountryReport/HealixDetailedCountryReport.component';
import MaxSecurityDetailedCountryReport
  from '@/tenant-context/control-reports/components/DetailedCountryReport/MaxSecurityDetailedCountryReport.component';
import RisklineDetailedCountryReport
  from "@/tenant-context/control-reports/components/DetailedCountryReport/RisklineDetailedCountryReport.component";
import { CountryRiskData, HealixCountryRiskData } from '@/tenant-context/control-reports/types/country-risk-reports';
import { RisklineCountryRiskData } from "@/tenant-context/control-reports/types/country-risk-riskline-report";
import { RiskReportProvider } from "@/tenant-context/control-reports/types/risk-reports-common";
import {
  getRiskColorByRiskLevel, kebabCaseToTitleCase
} from '@/tenant-context/control-reports/util/country-risk-reports';
import { getHtmlContentAsStringForPDF,getRiskReportStyles } from '@/tenant-context/control-reports/util/pdf-risk-reports-templates';

type Props = {
  countryRiskData: CountryRiskData;
  onClose: () => void;
  onExpand: () => void;
  isExpanded: boolean;
};

const DetailedCountryReportComponent: FC<Props> = ({
  countryRiskData,
  onClose: handleClose,
  onExpand: handleExpand,
  isExpanded
}) => {
  const [pdfLoading, setPdfLoading] = useState(false);
  const getColorByLevel = (level?: string) => {
    return getRiskColorByRiskLevel(level);
  };
  const { classes, cx } = useDetailedCountryReportStyles( {
    riskLevelColor: getColorByLevel(countryRiskData.riskLevel?.toLowerCase())
  } );

  const isCountryDataLoading = useSelector(
    (state: RootStateWithLoading) => state.loading.effects.countryRiskReports.loadSelectedCountyReport
  );

  const provider = countryRiskData.providerName?.toUpperCase() as RiskReportProvider;
  const providerName = useMemo(() => {
    return kebabCaseToTitleCase(countryRiskData.providerName);
  }, [countryRiskData.providerName]);

  const providerIcon = useMemo(() => {
    switch (countryRiskData.providerName?.toLowerCase()) {
    case 'max-security':
      return <MaxSecurity/>;
    case 'healix':
      return <Healix/>;
    case 'riskline':
      return <RiskLine />;
    default:
      return null;
    }
  }, [countryRiskData.providerName]);

  const providerSpecificReportData = useMemo(() => {
    switch (countryRiskData.providerName?.toLowerCase()) {
    case 'max-security':
      return <MaxSecurityDetailedCountryReport countryRiskData={ countryRiskData }/>;
    case 'healix':
      return <HealixDetailedCountryReportComponent countryRiskData={ countryRiskData as HealixCountryRiskData }/>;
    case 'riskline':
      return <RisklineDetailedCountryReport
        countryRiskData={ countryRiskData as unknown as  RisklineCountryRiskData }
      />;
    default:
      return null;
    }
  }, [countryRiskData]);

  const handleDownloadMaxSecurityReport = useCallback(async () => {
    const countryReportPDFStyles = getRiskReportStyles();
    const htmlContent = getHtmlContentAsStringForPDF(['risk-report-header', 'risk-report-body'], countryReportPDFStyles);
    await downloadPDFFromHTMLContent(htmlContent, `MaxSecurity_Country_Risk_Report_${countryRiskData.countryName}.pdf`);
  }, [countryRiskData.countryName]);
  
  const downloadHandlers  = useMemo<Partial<Record<RiskReportProvider, VoidFunction>>>(() => ({
    [RiskReportProvider.RISKLINE]: () => {
      const url = (countryRiskData as unknown as  RisklineCountryRiskData).pdf;
      downloadURL(url, 'riskline-report.pdf');
      setPdfLoading(true);
      setTimeout(() => {
        setPdfLoading(false);
      }, 5000);
    },
    [RiskReportProvider.MAX_SECURITY]: () => {
      setPdfLoading(true);
      handleDownloadMaxSecurityReport()
        .catch((error) => {
          logger.error('Error downloading MaxSecurity report:', error);
          showNotification({
            message: 'Failed to download MaxSecurity report',
            color: 'error'
          });
        }).finally(() => {
          setPdfLoading(false);
        });
    }
  }), [countryRiskData, handleDownloadMaxSecurityReport]);

  return (
    <Box className={ classes.root }>
      <Flex direction="column" className={ classes.header }>
        <Flex justify="space-between" align="center">
          <Flex gap={ 12 } direction="column" id="risk-report-header">
            <Flex gap={ 12 } align="center">
              <Box className={ classes.flag }>
                <CountryFlag countryCode={ countryRiskData.countryISOCode } />
              </Box>
              <Text size={ 32 } weight={ 700 }>{ countryRiskData.countryName }</Text>
            </Flex>
            <Flex gap={ 4 }>
              <Text>Issued by:</Text>
              <Box className={ classes.riskProviderIcon }>{ providerIcon }</Box>
              <Text>{ providerName }</Text>
            </Flex>
          </Flex>

          <Flex>
            { provider in downloadHandlers && (
              <ActionIcon className={ classes.actionIcon } loading={ pdfLoading }>
                <DownloadReport color='white' onClick={ downloadHandlers[provider] }/>
              </ActionIcon>
            ) }
            <ActionIcon className={ classes.actionIcon } onClick={ handleExpand }>
              { isExpanded ? <Contract/> : <ExpandIcon/> }
            </ActionIcon>
            <ActionIcon className={ classes.actionIcon } onClick={ handleClose }><CloseIcon/></ActionIcon>
          </Flex>
        </Flex>
      </Flex>

      { !isCountryDataLoading ? (
        <Flex direction="column" gap={ 32 } className={ cx({ [classes.body]: true, [classes.bodyExpanded]: isExpanded }) } id='risk-report-body'>

          { providerSpecificReportData }

        </Flex>
      ) : (
        <Stack spacing="md" className={ classes.loadingContainer }>
          { Array.from({ length: 4 }).map((_, index) => (
            <Skeleton key={ index } height={ 100 } radius="sm" opacity={ 0.1 }/>
          )) }
        </Stack>
      ) }
    </Box>
  );
};

export default DetailedCountryReportComponent;
