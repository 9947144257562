import { Feature, FeatureCollection } from 'geojson';
import { FC } from "react";
import { Layer, Source } from "react-map-gl";

import theme from "@/core/styles/mantine/theme";

type Props = {
  impactCircleData?: Feature | FeatureCollection
  isRiskImpactCircleEnabled:boolean
};

const RiskImpactLayer: FC<Props> = ({ 
  impactCircleData,isRiskImpactCircleEnabled
}) => {

  if (!impactCircleData || !isRiskImpactCircleEnabled) {
    return null;
  }

  return (
    <Source
      id="r__risk-impact-source"
      type="geojson"
      tolerance={ 0 }
      cluster={ false }
      data={ impactCircleData }
    >
      <Layer
        id='r__risk-impact-circle-border'
        type='line'
        // eslint-disable-next-line no-magic-numbers
        paint={ { 'line-color': theme.colors?.risk?.[4],
          'line-width': 3,
          'line-dasharray': [2, 2 ] } }
      />
      <Layer
        id='r__risk-impact-circle'
        type='fill'
        paint={ {
          // eslint-disable-next-line no-magic-numbers
          "fill-color": theme.colors?.risk?.[4],
          "fill-opacity": 0.15,
          "fill-outline-color":'red'
        } }
      />
    </Source>
  );
};

export default RiskImpactLayer;
