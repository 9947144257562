import { FC, useContext, useMemo } from "react";

import { formatDateTime } from "@/tenant-context/common/util/data-standardize";
import { ArcContext } from "@/tenant-context/control-action-response-center/context/ARC/ARC.context";

import SubImpactTabComponent from "./SubImpactTab.component";

type Props = {
    peopleCount: number,
    assetCount: number,
    isPeopleImpactedTab: boolean,
    onSubImpactTabChange: () => void
}

const SubImpactTab: FC<Props> = ({ peopleCount, assetCount, isPeopleImpactedTab, onSubImpactTabChange }) => {

  const { riskAlert  } = useContext(ArcContext);


  const [startDate, endDate] = useMemo(() => {
    if (!riskAlert) {
      return ['••••-••-••', '••••-••-••'];
    }

    const { source } = riskAlert;
    
    if (source === 'bsoc') {
      return [
        new Date(riskAlert?.json.alert.start_date).toLocaleDateString().replaceAll('/', '-'),
        new Date(riskAlert?.json.alert.end_date).toLocaleDateString().replaceAll('/', '-')
      ];
    } else if (source === 'dataminr' || source === 'max-security' || source === 'healix' || source === 'factal') {
      return [
        formatDateTime(riskAlert?.json.alert.start_date, 'YYYY-MM-DD'),
        formatDateTime(riskAlert?.json.alert.end_date, 'YYYY-MM-DD')
      ];
    }

    return [riskAlert?.json.alert.start_date.toString(), riskAlert?.json.alert.end_date.toString()];
  }, [riskAlert]);

  return (
    <SubImpactTabComponent 
      peopleCount={ peopleCount } 
      assetCount={ assetCount } 
      fromDate={ startDate } 
      toDate={ endDate } 
      isPeopleImpactedTab={ isPeopleImpactedTab }
      onSubImpactTabChange={ onSubImpactTabChange }
    />
  );
};

export default SubImpactTab;
