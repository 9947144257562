import { FC } from "react";

import { useLoaderScreenStyles } from '@/core/components/LoaderScreen/LoaderScreen.styles';
import spinner from "@/core/components/SSOIntegration/images/spinner.png";

type Props = {
  isLoading: boolean,
  text?: string
}

const LoaderScreen: FC<Props> = ({ isLoading, text }) => {

  const { classes } = useLoaderScreenStyles();

  if (!isLoading) {
    return (<div/>);
  }

  return (
    <div className={ classes.loaderScreenContainer }>
      <div className={ classes.spinnerContainer }>
        <div className={ classes.spinnerTranslate }><img src={ spinner } alt={ '0' } className={ classes.spinner }/></div>
        <div className={ classes.spinnerTranslate }><img src={ spinner } alt={ '0' } className={ classes.spinner }/></div>
        <div className={ classes.spinnerTranslate }><img src={ spinner } alt={ '0' } className={ classes.spinner }/></div>
        <div className={ classes.spinnerTranslate }><img src={ spinner } alt={ '0' } className={ classes.spinner }/></div>
      </div>
      { text ? text : 'Loading requested data. Just a moment, please.' }
    </div>
  );
};

export default LoaderScreen;
