import { createModel } from "@rematch/core";

import { GridParams } from "@/common/types/ag-grid";
import { PaginatedResult } from "@/common/types/reference-data";
import { handleError } from "@/common/util/common-functions";
import { Dispatch, RootModel, RootState } from "@/core/store";

import { casePeopleApi } from "../api/casePeople";
import { CasePeopleCountResponse, CasePeopleItem, CasePerson, ICasePeopleBulkOperations, ICasePeopleContactForm, ICasePeopleLocationForm, ICasePeopleOverviewForm } from "../types/casePeople";

type CasePeopleState = {
    casePeopleList?: PaginatedResult<CasePeopleItem>
    selectedCasePerson?: CasePerson
    casePeopleCountByStatus?: CasePeopleCountResponse
};

const casePeopleDataModel = {
  name: 'casePeopleManagement',
  state: {
    casePeopleList: undefined,
    selectedCasePerson: undefined,
    casePeopleCountByStatus: undefined
  } as CasePeopleState,
  reducers: {
    SET_CASE_PEOPLE_LIST: (state: CasePeopleState, casePeopleList: CasePeopleState['casePeopleList']) => ({
      ...state,
      casePeopleList
    }),
    SET_SELECTED_CASE_PERSON: (state: CasePeopleState, selectedCasePerson: CasePeopleState['selectedCasePerson']) => ({
      ...state,
      selectedCasePerson
    }),
    SET_CASE_PEOPLE_COUNT_BY_STATUS: (state: CasePeopleState, casePeopleCountByStatus: CasePeopleState['casePeopleCountByStatus']) => ({
      ...state,
      casePeopleCountByStatus
    })
  },
  effects: (dispatch: Dispatch) => ({
    async getCasePeopleList(payload: {
      gridParams: GridParams,
  }, state: RootState): Promise<PaginatedResult<CasePeopleItem>> {

      const { search: query, personType } = payload.gridParams.additionalParams;

      // eslint-disable-next-line fp/no-let
      let searchQuery = "";

      if(query){

        const fields = ["firstName", "lastName", "company", "department", "role", "employeeId"];

        searchQuery = fields.map(field => `${field} LIKE '%${query}%'`).join(" OR ");
      }

      if(personType){
        searchQuery += `${query ? ' AND ' : ""}personType LIKE '${personType}'`;
      }

      const casePeopleData = await casePeopleApi.getCasePeopleList(
        state.caseManagement.selectedCase?.tid || "",
        payload.gridParams.page,
        payload.gridParams.size,
        payload.gridParams.sort,
        searchQuery,
        payload.gridParams.additionalParams
      );

      dispatch.casePeopleManagement.SET_CASE_PEOPLE_LIST(casePeopleData);
      return casePeopleData;
    },
    async getPersonForCase(id: string, state: RootState): Promise<CasePerson> {
      const person = await casePeopleApi.getPersonForCase(state.caseManagement.selectedCase?.tid || "", id);
      if(person){
        dispatch.casePeopleManagement.SET_SELECTED_CASE_PERSON(person);
      }

      return person;
    },
    async createPersonForCase(params: ICasePeopleOverviewForm, state: RootState): Promise<string> {
      // eslint-disable-next-line fp/no-let
      let result;
      if(state.casePeopleManagement.selectedCasePerson){
        //edit person
        result = await casePeopleApi.updatePersonForCase(
          state.caseManagement.selectedCase?.tid || "",
          state.casePeopleManagement.selectedCasePerson._id || "",
          params
        ).catch(handleError);
      } else{
        //new person
        result = await casePeopleApi.createPersonForCase(state.caseManagement.selectedCase?.tid || "", params).catch(handleError);
      }

      return result ? result._id : '';
    },
    async createPersonLocationForCase(params: ICasePeopleLocationForm, state: RootState): Promise<boolean> {
      // eslint-disable-next-line fp/no-let
      let success = true;
      // eslint-disable-next-line fp/no-let
      let result;
      if(state.casePeopleManagement.selectedCasePerson?.personLocationDto._id){
        //edit person location
        result = await casePeopleApi.updatePersonLocationForCase(
          state.caseManagement.selectedCase?.tid || "",
          { ...params, personLocationTid: state.casePeopleManagement.selectedCasePerson?.personLocationDto._id }
        ).catch(handleError);
        success = !!result;
      } else{
        //new person location
        result = await casePeopleApi.createPersonLocationForCase(
          state.caseManagement.selectedCase?.tid || "", 
          { ...params, personId: state.casePeopleManagement.selectedCasePerson?._id || "" }
        ).catch(handleError);
        success = !!result;
      }

      return success;
    },
    async createPersonContactForCase(params: ICasePeopleContactForm, state: RootState): Promise<boolean> {
      // eslint-disable-next-line fp/no-let
      let success = true;
      // eslint-disable-next-line fp/no-let
      let result;
      if(state.casePeopleManagement.selectedCasePerson?.personContactDto._id){
        //edit person contact
        result = await casePeopleApi.updatedPersonContactForCase(
          state.caseManagement.selectedCase?.tid || "",
          state.casePeopleManagement.selectedCasePerson.personContactDto._id,
          params
        ).catch(handleError);
        success = !!result;
      } else{
        //new person contact
        result = await casePeopleApi.createPersonContactForCase(
          state.caseManagement.selectedCase?.tid || "",
          { ...params, personId: state.casePeopleManagement.selectedCasePerson?._id || "" }
        ).catch(handleError);
        success = !!result;
      }

      return success;
    },

    async getCasePeopleCountByStatus(_: void, state: RootState): Promise<void> {
      const countByStatus = await casePeopleApi.getCasePeopleCountByStatus(state.caseManagement.selectedCase?.tid || "");
      if(countByStatus){
        dispatch.casePeopleManagement.SET_CASE_PEOPLE_COUNT_BY_STATUS(countByStatus);
      }
    },
    async bulkDeleteCasePeople(idList: string[], state: RootState): Promise<boolean> {
      return !!await casePeopleApi.bulkDeleteCasePeople(state.caseManagement.selectedCase?.tid || "", idList).catch(handleError);
    },
    async bulkUpdateCasePeople(params: ICasePeopleBulkOperations, state: RootState): Promise<boolean> {
      return !!await casePeopleApi.bulkUpdateCasePeople(state.caseManagement.selectedCase?.tid || "", params).catch(handleError);
    }
  })
};

export const casePeopleManagement = createModel<RootModel>()(casePeopleDataModel);
