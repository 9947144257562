import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";

import { RootState } from "@/core/store";
import { useSiteContactStyles } from "@/tenant-context/control-site/components/SiteContact/styles";

import { ReactComponent as EmailIcon } from '../../icons/email.svg';
import { ReactComponent as MobileIcon } from '../../icons/mobile.svg';

const SiteContact = () => {
  const { classes } = useSiteContactStyles();
  const siteContactProfile = useSelector((state: RootState) => state.userProfile?.siteContactProfile);
  const siteContactContacts = useMemo(() => {
    return siteContactProfile?.contacts ? siteContactProfile?.contacts : [];
  }, [siteContactProfile]);


  const contacts = useCallback(() => {
    if (siteContactContacts.length > 0) {
      return siteContactContacts.map((contact, index) => {
        return (
          <div key={ index } className={ classes.contactItem }>
            <div className={ classes.contactName }>
              { (contact.type === 'PHONE' || contact.type === 'MOBILE') &&
                <MobileIcon className={ classes.icon }/> }
              { contact.type === 'EMAIL' && <EmailIcon className={ classes.icon }/> }
              { contact.type }
            </div>
            <div className={ classes.contactValue }>
              { contact.value }
            </div>
          </div>
        );
      });
    } else {
      return (
        <div className={ classes.contactItem }>No Contact Data</div>
      );
    }
  }, [classes.contactItem, classes.contactName, classes.contactValue, classes.icon, siteContactContacts]);

  if (!siteContactProfile) {
    return (<div className={ classes.wrapper }>
      <div className={ classes.name }>
        Contact Data Is Not Available
      </div>
    </div>);
  }

  return (
    <div className={ classes.wrapper }>
      <div className={ classes.info }>
        { siteContactProfile?.profilePictureThumbnailUrl ?
          <img src={ siteContactProfile?.profilePictureThumbnailUrl } className={ classes.contactIcon } alt="contact_photo"/>
          :
          <div className={ classes.blankContactIcon }/> }
        <div className={ classes.contactInfoContainer }>
          <div className={ classes.name }>
            { siteContactProfile?.title } { siteContactProfile?.firstName } { siteContactProfile?.lastName }
          </div>
          <div className={ classes.title }>
            { siteContactProfile?.jobTitle }
          </div>
        </div>
      </div>
      { contacts() }
    </div>
  );
};

export default SiteContact;
