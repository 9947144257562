import { createStyles } from "@mantine/core";

type Props = {
  isVisible: boolean;
}

export const useWeatherLegendGlobalStyles = createStyles((theme, { isVisible }: Props) => ({
  root: {
    position: "relative",
    // the global legend is hidden by default, some of its parts(e.g. Alerts dynamic legend)
    // are coping to MapLayer drawer when it is visible
    // you can uncomment this for debug
    display: "none",
    marginBottom: theme.spacing.sm,

    '& .awxgl-control-legend': {
      display: isVisible ? 'block' : 'none',
      right: 'unset',
      left: 0,
      bottom: 0,
      backgroundColor: theme.colors.neutral[8]
    },
    '& .awxgl-legend-title': {
      color: theme.colors.white
    },
    '& .awxgl-legend-container': {
      borderTop: `1px solid ${theme.colors.neutral[6]}`
    },
    '& .awxgl-legend-item-fade-bottom': {
      background: `linear-gradient(rgba(13, 34, 50, 0), rgb(13, 34, 50))`
    },
    '& .awxgl-legend-item-fade-top': {
      background: `linear-gradient(rgb(13, 34, 50), rgba(13, 34, 50, 0))`
    }
  }
}));
