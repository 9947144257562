import {
  Checkbox,
  Flex,
  Text,
  useMantineTheme
} from "@mantine/core";
import React, { FC, useCallback } from "react";

import TreeViewItem from "@/common/components/TreeViewItem";
import { CustomColors } from "@/core/styles/mantine/palettes/types";
import { DrawerPage, useMapLayersDrawerContext } from "@/tenant-context/control-risk-config/context/MapLayersDrawer.context";
import {
  getCapitalizedProviderName,
  ProviderIcon,
  riskProvidersMeta
} from "@/tenant-context/control-risk-config/util/riskProvidersMeta";
import {
  FilterSettingsActionIcon
} from "@/tenant-context/core/controls/DataOptionsControl/components/FilterSettingsActionIcon";

import { RiskProviderItem } from "../../types/risk";
import RiskProviderTreeContent from "../RiskProviderTreeContent/RiskProviderTreeContent.component";

type Props = {
  openedTreeItems: Set<string>;
  onToggleTreeItem?: (isExpanded: boolean, itemValue: string) => void;
  riskProviders: RiskProviderItem[];
  enabledRiskProviders: Set<string>;
  onRiskProviderSwitch: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const RiskProvidersTreeComponent: FC<Props> = ({
  openedTreeItems,
  onToggleTreeItem,
  riskProviders,
  enabledRiskProviders,
  onRiskProviderSwitch
}) => {
  const theme = useMantineTheme();
  const { changeDrawerPage } = useMapLayersDrawerContext();
  const handleToggleTreeItem = useCallback(
    (
      isExpanded: boolean,
      ev: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      if (ev.currentTarget.value) {
        onToggleTreeItem?.(isExpanded, ev.currentTarget.value);
      }
    },
    [onToggleTreeItem]
  );

  const getDrawerPage = (providerId: string): DrawerPage | null => {
    if (providerId === "riskline") {
      return "riskline";
    } else if(providerId === "bsoc") {
      return "bsoc";
    } else if(providerId === "dataminr") {
      return "dataminr";
    } else if(providerId === "healix") {
      return "healix";
    } else if(providerId === "factal") {
      return "factal";
    }

    return null;
  };

  if (!riskProviders.length) {
    return (
      <Text align="center" color={ theme.colors.neutral[5] as CustomColors }>
        No risk providers found
      </Text>
    );
  }

  return (
    <>
      { riskProviders.map(({ providerName, filteredNumber }) => {
        const nameInLowerCase = providerName?.toLowerCase();
        const drawerSubPage = getDrawerPage(nameInLowerCase);
        return (
          <TreeViewItem
            key={ nameInLowerCase }
            headerElement={ <>
              <Checkbox
                h={ 24 }
                disabled={ !riskProvidersMeta.has(nameInLowerCase) }
                value={ nameInLowerCase }
                checked={ enabledRiskProviders.has(nameInLowerCase) }
                onChange={ onRiskProviderSwitch }
                label={ <Flex gap={ 8 } align="center">
                  <ProviderIcon providerId={ nameInLowerCase } />
                  <span>
                    { getCapitalizedProviderName(providerName) }{ " " }
                    { `(${filteredNumber ?? 0})` }
                  </span>
                </Flex> }
              />
              { !!drawerSubPage && (
                <FilterSettingsActionIcon
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={ () => changeDrawerPage(drawerSubPage) }
                />
              ) }
            </> }
            buttonValue={ nameInLowerCase }
            isExpanded={ openedTreeItems.has(nameInLowerCase) }
            onToggle={ handleToggleTreeItem }
            collapsedContent={ <RiskProviderTreeContent providerId={ nameInLowerCase } /> }
          />
        );
      }) }
    </>
  );
};

export default RiskProvidersTreeComponent;
