import { ArcCaseEvent, ArcPeopleImpactSummary } from "@/tenant-context/control-action-response-center/types/ARC.types";

export const generateArcCaseImpactSummary = (arcCase: ArcCaseEvent | undefined): ArcPeopleImpactSummary => {
  if (!arcCase) {
  // Setting the default state if arc case is not available yet
    return [
      { count: 0, impactStatus: 'NOT_SAFE' },
      { count: 0, impactStatus: 'NO_RESPONSE' },
      { count: 0, impactStatus: 'SAFE' },
      { count: 0, impactStatus: 'OTHER' }
    ];
  }

  const {
    responseStatusCount
  } = arcCase;

  return [
    { count: responseStatusCount.notSafeCount, impactStatus: 'NOT_SAFE' },
    { count: responseStatusCount.noResponseCount, impactStatus: 'NO_RESPONSE' },
    { count: responseStatusCount.safeCount, impactStatus: 'SAFE' },
    { count: responseStatusCount.otherCount, impactStatus: 'OTHER' }
  ];
};
