import { createModel } from "@rematch/core";

import { GridParams } from "@/common/types/ag-grid";
import { PaginatedResult, ReferenceData } from "@/common/types/reference-data";
import { handleError, handleSuccess } from "@/common/util/common-functions";
import { Dispatch, RootModel, RootState } from "@/core/store";
import { taskApi } from "@/tenant-context/control-icm-management/api/tasks";
import { teamsAndRolesApi } from "@/tenant-context/control-icm-management/api/teamsAndRoles";
import {
  CaseTaskDTO,
  CaseTaskParams, CreateTaskCommentParam, DeleteTaskParam,
  TaskComment,
  TaskCount,
  TaskList, UpdateTaskParams
} from "@/tenant-context/control-icm-management/types/tasks";

import {
  CaseFeature, TeamList, TeamRole
} from "../types/caseManagement";

type TaskState = {
    taskStatusData: PaginatedResult<ReferenceData>
    taskPriorityData: PaginatedResult<ReferenceData>
    taskList: PaginatedResult<TaskList>
    activityTaskList: PaginatedResult<TaskList>
    teamList: PaginatedResult<TeamList>
    teamRole: PaginatedResult<TeamRole>
    taskCount?: TaskCount
    activeTask?: CaseTaskDTO
    taskCommentList: PaginatedResult<TaskComment>
    isTaskLoading: boolean
};

const initialState: TaskState = {
  taskStatusData: {
    totalItems: 0,
    totalPages: 0,
    currentPage: 1,
    items: []
  },
  taskPriorityData: {
    totalItems: 0,
    totalPages: 0,
    currentPage: 1,
    items: []
  },
  taskList: {
    totalItems: 0,
    totalPages: 0,
    currentPage: 1,
    items: []
  },
  activityTaskList: {
    totalItems: 0,
    totalPages: 0,
    currentPage: 1,
    items: []
  },
  teamList: {
    totalItems: 0,
    totalPages: 0,
    currentPage: 1,
    items: []
  },
  teamRole: {
    totalItems: 0,
    totalPages: 0,
    currentPage: 1,
    items: []
  },
  taskCommentList: {
    totalItems: 0,
    totalPages: 0,
    currentPage: 1,
    items: []
  },
  isTaskLoading: false
};

const taskDataModel = {
  name: 'task',
  state: initialState as TaskState,
  reducers: {
    SET_TASK_STATUS_DATA: (state: TaskState, taskStatusData: TaskState['taskStatusData']) => ({
      ...state,
      taskStatusData
    }),
    SET_TASK_PRIORITY_DATA: (state: TaskState, taskPriorityData: TaskState['taskPriorityData']) => ({
      ...state,
      taskPriorityData
    }),
    SET_TASK_LIST_DATA: (state: TaskState, taskList: TaskState['taskList']) => ({
      ...state,
      taskList
    }),
    SET_ACTIVITY_TASK_LIST_DATA: (state: TaskState, activityTaskList: TaskState['activityTaskList']) => ({
      ...state,
      activityTaskList
    }),
    SET_TEAM_LIST_DATA: (state: TaskState, teamList: TaskState['teamList']) => ({
      ...state,
      teamList
    }),
    SET_TEAM_ROLE_DATA: (state: TaskState, teamRole: TaskState['teamRole']) => ({
      ...state,
      teamRole
    }),
    SET_TASK_COUNT: (state: TaskState, taskCount: TaskState['taskCount']) => ({
      ...state,
      taskCount
    }),
    SET_ACTIVE_TASK: (state: TaskState, activeTask: TaskState['activeTask']) => ({
      ...state,
      activeTask
    }),
    SET_TASK_COMMENT_LIST: (state: TaskState, taskCommentList: TaskState['taskCommentList']) => ({
      ...state,
      taskCommentList
    }),
    SET_IS_TASK_LOADING: (state: TaskState, isTaskLoading: TaskState['isTaskLoading']) => ({
      ...state,
      isTaskLoading
    })
  },
  effects: (dispatch: Dispatch) => ({
    async fetchTaskReferenceData(_: void, state: RootState): Promise<void>{
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const promises: Promise<any>[] = [];

      const id = state.caseManagement.selectedCase?.tid;

      dispatch.task.SET_IS_TASK_LOADING(true);

      if(!id){
        return;
      }
      promises.push(taskApi.getICMTaskStatusData());
      promises.push(taskApi.getICMTaskPriorityData());

      const results = await Promise.all(promises).catch(handleError)
        .finally(() => dispatch.task.SET_IS_TASK_LOADING(false));

      if(results){
        const [taskStatus, taskPriority] = results;
        dispatch.task.SET_TASK_STATUS_DATA(taskStatus);
        dispatch.task.SET_TASK_PRIORITY_DATA(taskPriority);
      }
    },

    async createTask(payload: {
            caseId: string,
            data: CaseTaskParams
        }, _state: RootState): Promise<CaseTaskDTO | boolean> {

      if (!payload.caseId || !payload?.data) {
        return false;
      }

      const { caseId,data } = payload;

      const task = await taskApi.createTask(caseId, data).catch(handleError);

      if(task) {
        dispatch.task.SET_ACTIVE_TASK(task);
      }

      return !!task;

    },
    async updateTask(payload: {
            caseId: string,
            data: UpdateTaskParams
        }, _state: RootState): Promise< CaseTaskDTO | boolean> {

      if (!payload.caseId || !payload?.data) {
        return false;
      }

      const { caseId,data } = payload;

      return await taskApi.updateTask(caseId, data).catch(handleError);

    },

    async deleteTask(payload: {
            caseId: string,
            data: DeleteTaskParam
        }, _state: RootState): Promise<boolean> {

      if (!payload.caseId || !payload?.data) {
        return false;
      }

      const { caseId,data } = payload;

      return await taskApi.deleteTask(caseId, data).then(() => handleSuccess()).catch(handleError);

    },

    async getTaskCount(caseId: string, _state: RootState): Promise<void> {

      if (!caseId ) {
        return;
      }

      const taskCount = await taskApi.getTaskCount(caseId).catch(handleError);

      if (!taskCount) {
        dispatch.task.SET_TASK_COUNT(initialState.taskCount);
        return;
      }

      dispatch.task.SET_TASK_COUNT(taskCount);
    },

    async getActivityTaskListData(payload: {
      caseId: string,
      sort?: string | null
    }, state: RootState): Promise<PaginatedResult<TaskList>> {

      if (!payload?.caseId) {
        return (
          initialState.activityTaskList
        );
      }

      const tenantUserId = state.userProfile?.tenantUserId;

      const getOnlyMyTasksFilter = `assigneeRef LIKE %27%25ref:${tenantUserId}%25%27`;

      const activityTaskListData = await taskApi.getTaskListData(
        payload?.caseId,
        0,
        99999,
        payload?.sort,
        getOnlyMyTasksFilter
      );

      dispatch.task.SET_ACTIVITY_TASK_LIST_DATA(activityTaskListData);

      return activityTaskListData;
    },
    async getTaskListData(payload: {
            gridParams: GridParams,
        }, _state: RootState): Promise<PaginatedResult<TaskList>> {

      const { additionalParams } = payload.gridParams;

      if (!additionalParams?.caseId) {
        return (
          initialState.taskList
        );
      }

      // eslint-disable-next-line fp/no-let
      let filterParams = '';

      Object.keys(additionalParams).forEach((key) => {
        if (key !== 'caseId' && key !== 'orderBy') {
          if (additionalParams[key]) {
            const value = key === 'teamRefId' ? `ref:${additionalParams[key]}` : additionalParams[key];
            filterParams = filterParams.length > 0 ?
              `${filterParams} AND ${key} LIKE %27%25${value}%25%27`
              :
              `${key} LIKE %27%25${value}%25%27`;
          }
        }
      });

      dispatch.task.getTaskCount(additionalParams?.caseId);

      const taskListData = await taskApi.getTaskListData(
        additionalParams?.caseId,
        payload.gridParams.page,
        payload.gridParams.size,
        additionalParams?.orderBy,
        filterParams
      );

      dispatch.task.SET_TASK_LIST_DATA(taskListData);

      return taskListData;
    },
    async getTeamListData(caseId: string, _state: RootState): Promise<PaginatedResult<TeamList>> {

      const tenantUserId = _state.userProfile?.email;

      if (!caseId || !tenantUserId) {
        return (
          initialState.teamList
        );
      }

      const teamListData = await teamsAndRolesApi.getTeamList(
        caseId,
        tenantUserId,
        CaseFeature.TASKS,
        0,
        99999
      );

      dispatch.task.SET_TEAM_LIST_DATA(teamListData);
      return teamListData;
    },
    async getTeamRoleData(payload: {
            caseId: string, teamId: string
        }, _state: RootState): Promise<PaginatedResult<TeamRole>> {

      const { caseId, teamId } = payload;

      if (!caseId || !teamId) {
        return (
          initialState.teamRole
        );
      }

      const teamRoleData = await taskApi.getTeamRole(
        caseId,
        teamId,
        0,
        99999
      );

      dispatch.task.SET_TEAM_ROLE_DATA(teamRoleData);

      return teamRoleData;
    },
    async getActiveTask(payload: {caseId: string, taskId: string}, _state: RootState): Promise<void> {

      if (!payload?.caseId || !payload?.taskId) {
        return;
      }

      const task = await taskApi.getTask(
        payload?.caseId,
        payload?.taskId
      ).catch(handleError);

      if(!task) {
        return;
      }

      dispatch.task.SET_ACTIVE_TASK(task);

    },
    async getTaskCommentList(payload: {caseId: string, taskId: string}, _state: RootState): Promise<void> {

      if (!payload?.caseId || !payload?.taskId) {
        return;
      }

      const commentList = await taskApi.getTaskCommentList(
        payload?.caseId,
        payload?.taskId
      ).catch(handleError);

      if(!commentList) {
        return;
      }

      dispatch.task.SET_TASK_COMMENT_LIST(commentList);

    },
    async getTaskUpdateModalData  (payload: {caseId: string, taskId: string}, _state: RootState): Promise<void> {

      if (!payload?.caseId || !payload?.taskId) {
        return;
      }

      const promises: Promise<unknown>[] = [];

      dispatch.task.SET_IS_TASK_LOADING(true);

      promises.push(dispatch.task.getActiveTask({ caseId: payload?.caseId, taskId: payload?.taskId }));
      promises.push(dispatch.task.getTaskCommentList({ caseId: payload?.caseId, taskId: payload?.taskId }));

      Promise.all(promises).catch(handleError)
        .finally(() => dispatch.task.SET_IS_TASK_LOADING(false));

    },
    async createTaskComment(payload: {
            caseId: string,
            data: CreateTaskCommentParam
        }, _state: RootState): Promise<boolean> {

      if (!payload.caseId || !payload?.data) {
        return false;
      }

      const { caseId,data } = payload;

      const createdTaskComment = await taskApi.createTaskComment(caseId, data).catch(handleError);

      if(createdTaskComment) {
        await dispatch.task.getTaskCommentList({ caseId: caseId, taskId: payload?.data?.taskId });
      }

      return !!createdTaskComment;
    },
    resetActiveTaskAndComments(): void {
      dispatch.task.SET_ACTIVE_TASK(initialState.activeTask);
      dispatch.task.SET_TASK_COMMENT_LIST(initialState.taskCommentList);
      dispatch.task.SET_TEAM_ROLE_DATA(initialState.teamRole);
      dispatch.task.SET_TEAM_LIST_DATA(initialState.teamList);
    }
  })
};

export const task = createModel<RootModel>()(taskDataModel);
