
import { showNotification } from "@/common/util/notification";

import { ACCESS_DENIED_STATUS, SERVER_ERROR_STATUS_UPPER_BOUNDARY, TOKEN_EXPIRED_STATUS, UNPROCESSABLE_ENTITY_STATUS } from "./api";

export function handleError(err: Error): false {

  const handledNotifications = [SERVER_ERROR_STATUS_UPPER_BOUNDARY, TOKEN_EXPIRED_STATUS,
    ACCESS_DENIED_STATUS, UNPROCESSABLE_ENTITY_STATUS];

  if(err.cause){
    if(!handledNotifications.includes(Number(err.cause))){
      showNotification({
        message: err.message,
        color: 'error'
      });
    }
  }
 
  return false;
}

export function handleSuccess(message = 'Success'): false {
  showNotification({
    message: message,
    color: 'success'
  });

  return false;
}

export const removeRef = (refValue: string): string => {
  return refValue?.split('ref:').pop() as string;
};


export const ensureHTTP = (url: string): string => {
  // eslint-disable-next-line fp/no-let
  let ensuredURL = "";
  if (!/^https?:\/\//i.test(url)) {
    ensuredURL = 'http://' + url;
  }

  return ensuredURL;
};

export const getBase64String = async (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.result) {
        resolve(reader.result.toString());
      } else {
        reject(new Error("Failed to convert file to Base64."));
      }
    };

    reader.onerror = () => {
      reject(new Error("File reading failed."));
    };

    reader.readAsDataURL(file);
  });
};

export const isLightColor = (hex: string) => {
  // Remove '#' if present
  const color = hex.replace(/^#/, '');

  // Convert to RGB
  const r = parseInt(color.substring(0, 2), 16);
  const g = parseInt(color.substring(2, 4), 16);
  const b = parseInt(color.substring(4, 6), 16);

  // Calculate luminance
  const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

  // Return true if light, false if dark
  return luminance > 128;
};