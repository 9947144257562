import { Checkbox, Flex, Stack, Text, Tooltip, useMantineTheme } from "@mantine/core";
import React, { FC } from "react";

import { ToggleableContextLayers } from "@/common/components/ToggleableLayer/ToggleableLayer.config";
import { CustomColors } from "@/core/styles/mantine/palettes/types";
import { useMapLayersDrawerContext } from "@/tenant-context/control-risk-config/context/MapLayersDrawer.context";
import {
  FilterSettingsActionIcon
} from "@/tenant-context/core/controls/DataOptionsControl/components/FilterSettingsActionIcon";
import { isWeatherMapEnabled, weatherLayersConfig } from "@/tenant-context/visualisation-weather";

type Props = {
  isLayerVisible(layerType: ToggleableContextLayers): boolean;
  onChangeContextualLayerSwitch: React.ChangeEventHandler<HTMLInputElement>;
  isRiskConnectorsPermissionAvailable?: boolean;
  isContextualLayersSubscribed: boolean;
  isTimeTravelActive?: boolean;
  hasRisklineCountryRiskSubscription: boolean;
};

const ContextualLayers: FC<Props> = ({
  isLayerVisible,
  onChangeContextualLayerSwitch,
  isRiskConnectorsPermissionAvailable = false,
  isContextualLayersSubscribed = false,
  isTimeTravelActive = false,
  hasRisklineCountryRiskSubscription
}) => {
  const theme = useMantineTheme();
  const { changeDrawerPage } = useMapLayersDrawerContext();

  return (
    <Stack spacing="md" mt={ 24 } ml={ 32 }>
      { isTimeTravelActive && (
        <Text size="md" c={ theme.colors.neutral[5] as CustomColors }>
          Cannot access when not in live mode
        </Text>
      ) }
      <Flex gap={ 8 } h={ 24 }>
        <Checkbox
          value={ ToggleableContextLayers.CountryRiskLevel }
          onChange={ onChangeContextualLayerSwitch }
          checked={ isLayerVisible(ToggleableContextLayers.CountryRiskLevel) }
          label={ <Tooltip
            hidden={ isRiskConnectorsPermissionAvailable && hasRisklineCountryRiskSubscription }
            withinPortal
            label={ (!isRiskConnectorsPermissionAvailable && "You don't have permission to view Country Risk Levels") ||
              (!hasRisklineCountryRiskSubscription && "Access this data by subscribing to Country Risk Level connector") }
          >
            <span>Country Risk Level</span>
          </Tooltip> }
          disabled={ !isRiskConnectorsPermissionAvailable || isTimeTravelActive || !hasRisklineCountryRiskSubscription }
        />
        <FilterSettingsActionIcon
          // eslint-disable-next-line react/jsx-no-bind
          onClick={ () => changeDrawerPage(ToggleableContextLayers.CountryRiskLevel) }
        />
      </Flex>
      <Flex gap={ 8 } h={ 24 }>
        <Checkbox
          checked={ isLayerVisible(ToggleableContextLayers.Traffic) }
          value={ ToggleableContextLayers.Traffic }
          onChange={ onChangeContextualLayerSwitch }
          label={ <Tooltip
            hidden={ isContextualLayersSubscribed }
            withinPortal
            label={ "Access this data by subscribing to Contextual Map Layers to unlock advanced insights." }
          >
            <span>Traffic Conditions</span>
          </Tooltip> }
          disabled={ isTimeTravelActive || !isContextualLayersSubscribed }
        />
        <FilterSettingsActionIcon
          // eslint-disable-next-line react/jsx-no-bind
          onClick={ () => changeDrawerPage(ToggleableContextLayers.Traffic) }
        />
      </Flex>
      { isWeatherMapEnabled && (
        <>
          { weatherLayersConfig.map(({ label, contextualLayerType, options }) => (
            <Flex key={ options.id } h={ 24 } gap={ 8 } justify="space-between">
              <Checkbox
                label={ <Tooltip
                  hidden={ isContextualLayersSubscribed }
                  withinPortal
                  label={ "Access this data by subscribing to Contextual Map Layers to unlock advanced insights." }
                >
                  <span>{ label }</span>
                </Tooltip> }
                value={ contextualLayerType }
                disabled={ isTimeTravelActive || !isContextualLayersSubscribed }
                onChange={ onChangeContextualLayerSwitch }
                checked={ isLayerVisible(contextualLayerType) }
              />
              <FilterSettingsActionIcon
                // eslint-disable-next-line react/jsx-no-bind
                onClick={ () => changeDrawerPage(contextualLayerType) }
              />
            </Flex>
          )) }
        </>
      ) }
    </Stack>
  );
};

export default ContextualLayers;
