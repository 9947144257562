import { Checkbox, Flex, Stack, Text } from "@mantine/core";
import React from "react";

import {
  useHealixLegend
} from "@/tenant-context/control-risk-config/components/ProviderSpecificComponents/Healix/HealixEventsSettings/HealixLegend.hooks";

type Props = {
  onRiskLevelToggle?: React.ChangeEventHandler<HTMLInputElement>;
  checkedRiskLevels: number[];
};


const HealixEventsSettingsComponent: React.FC<Props> = ({
  checkedRiskLevels,
  onRiskLevelToggle
}) => {
  const legendItems= useHealixLegend();
  return (
    <>
      <Text size={ 22 } mt={ 16 } mb={ 40 } fw={ 700 }>
        Filter Healix
      </Text>
      <Text size={ 18 } fw={ 700 } mb={ 24 }>
        Risk rating level
      </Text>
      <Stack>
        { legendItems.map(({ color, label, level }) => (
          <Checkbox
            key={ label }
            value={ level }
            checked={ checkedRiskLevels.includes(level) }
            onChange={ onRiskLevelToggle }
            label={ <Flex gap={ 8 } display="inline-flex" align="center">
              <div
                style={ {
                  backgroundColor: color,
                  width: 24,
                  height: 24,
                  borderRadius: "50%",
                  display: "inline-block"
                } }
              />
              <span>{ label }</span>
            </Flex> }
          />
        )) }
      </Stack>
    </>
  );
};

export default HealixEventsSettingsComponent;
