import { createStyles } from "@mantine/core";

export const useWeatherLegendStyles = createStyles((theme) => ({
  root: {
    position: "relative",
    width: '100%',
    right: '5px',
    height: '300px',
    overflowY: 'auto',
    overflowX: 'hidden',
    marginBottom: theme.spacing.sm,

    'img': {
      width: '100%'
    }
  }
}));
