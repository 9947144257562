import { Stack, Text } from "@mantine/core";
import { FC } from "react";

import {
  LayerMetadataComponent
} from "@/tenant-context/control-risk-config/components/RiskProviderTreeContent/MapLayerSettings/LayerMetadata.component";
import LayerOpacityControl from "@/tenant-context/visualisation-opacity/components/LayerOpacityControl.container";
import { layersMetadata, useStaticLegend, WeatherLayerItem } from "@/tenant-context/visualisation-weather";
import { WeatherLegendCopier } from "@/tenant-context/visualisation-weather/components/WeatherLegendCopier.component";
import { WeatherLegendStatic } from "@/tenant-context/visualisation-weather/components/WeatherLegendStatic.component";
import { checkIfInteractiveLegend } from "@/tenant-context/visualisation-weather/config/weather-legend.config";

type Props = {
  config: WeatherLayerItem
};

export const WeatherLayerSettingsComponent: FC<Props> = ({ config }) => {
  const { label, options, contextualLayerType } = config;
  const { id: layerId } = options;

  const items = layersMetadata[layerId];
  const { staticLegendElement, legendUnit } = useStaticLegend(contextualLayerType);
  const isInteractiveLegend = checkIfInteractiveLegend(contextualLayerType);
  const isLegendVisible = staticLegendElement || isInteractiveLegend;
  const hasOpacityControl = options.paint?.opacity !== undefined;

  return (
    <Stack spacing={ 24 }>
      <Text size={ 18 } fw={ 700 }>
        { label }
      </Text>
      { items && <LayerMetadataComponent items={ items }/> }

      { hasOpacityControl &&
        <LayerOpacityControl layerId={ layerId } defaultOpacity={ options.paint?.opacity as number } /> }

      { isLegendVisible && (
        <Stack spacing={ 16 }>
          <Text size={ 16 } fw={ 700 }>
            Layer Legend { legendUnit && `(${ legendUnit })` }
          </Text>
          { staticLegendElement && <WeatherLegendStatic>{ staticLegendElement }</WeatherLegendStatic> }
          { !staticLegendElement && isInteractiveLegend
            && <WeatherLegendCopier contextualLayerType={ contextualLayerType }/> }
        </Stack>
      ) }
    </Stack>
  );
};
