import { FC, useCallback, useContext, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getTimeDurationFromCurrentDate } from "@/common/util/format/date";
import { Dispatch, RootState } from "@/core/store";
import { ArcContext } from "@/tenant-context/control-action-response-center/context/ARC/ARC.context";
import DataMinrRiskAlertDetails
  from "@/tenant-context/control-risk-config/components/RiskAlertDetails/DataMinrRiskAlertDetails.component";
import FactalRiskAlertDetails
  from '@/tenant-context/control-risk-config/components/RiskAlertDetails/FactalRiskAlertDetails.component';
import HealixRiskAlertDetails
  from '@/tenant-context/control-risk-config/components/RiskAlertDetails/HealixRiskAlertDetails.component';
import MaxSecurityRiskAlertDetails
  from '@/tenant-context/control-risk-config/components/RiskAlertDetails/MaxSecurityRiskAlertDetails.component';

import BSOCRiskAlertDetails from "./BSOCRiskAlertDetails.component";
import RiskAlertDetails from "./RiskAlertDetails.component";

type Props = {
  onRequestToOpenArc?: (caseId: string) => void,
  isOpenedInArc?: boolean,
  onTimesAgoCalculated?: (timesAgo: string) => void;
}

const RiskAlertDetailsContainer: FC<Props> = ({
  onRequestToOpenArc,
  isOpenedInArc,
  onTimesAgoCalculated
}) => {
  const riskAlertsGeoData = useSelector((state: RootState) => state.riskAlerts.currentRiskAlert);
  const { riskAlert: arcRiskAlert } = useContext(ArcContext);
  const isArcOpen = useSelector((state: RootState) => state.arc.isArcOpen);
  const massCommsNav = useSelector((state: RootState) => state.massComms.massCommsNavigation);

  const {
    arc: {
      SET_IS_ARC_OPEN,
      SET_CURRENT_CASE_ID
    },
    massComms: {
      navigateToMassComms
    }
  } = useDispatch<Dispatch>();

  const caseId: string | undefined = useMemo(() => {
    if (!riskAlertsGeoData) {
      return undefined;
    }

    const currentCaseId = `${ riskAlertsGeoData.source }_${ riskAlertsGeoData.json.alert.id }`;
    SET_CURRENT_CASE_ID(currentCaseId);

    return currentCaseId;
  }, [ riskAlertsGeoData, SET_CURRENT_CASE_ID ]);

  useEffect(() => {
    const alert = arcRiskAlert || riskAlertsGeoData;
    const timesAgoText = alert?.json.alert.start_date !== undefined
      ? `${ getTimeDurationFromCurrentDate(new Date(alert?.json.alert.start_date)) } AGO`
      : "";

    onTimesAgoCalculated?.(timesAgoText);
  }, [ arcRiskAlert, riskAlertsGeoData, onTimesAgoCalculated ]);

  const handleRequestToToggleArc = useCallback(() => {
    if (!caseId) {
      return;
    }

    SET_IS_ARC_OPEN(!isArcOpen);

    onRequestToOpenArc?.(caseId);
  }, [caseId, isArcOpen, onRequestToOpenArc, SET_IS_ARC_OPEN]);

  const handleRequestToCloseMassComms= useCallback(() => {

    navigateToMassComms({ ...massCommsNav , isSliderOpen: false });

  },  [navigateToMassComms, massCommsNav ]);

  const renderRiskAlertDetails = useCallback(() => {
    if (riskAlertsGeoData?.source === 'riskline') {
      return <RiskAlertDetails
        riskAlert={ arcRiskAlert || riskAlertsGeoData }
        onRequestToToggleArc={ handleRequestToToggleArc }
        isArcOpen={ isArcOpen }
        isOpenedInArc={ isOpenedInArc }
        isAnotherOverlayModuleOpen={ massCommsNav.isOpen }
        onRequestToCloseComms={ handleRequestToCloseMassComms }
      />;
    } else if (riskAlertsGeoData?.source === 'bsoc') {
      return <BSOCRiskAlertDetails
        riskAlert={ arcRiskAlert || riskAlertsGeoData }
        onRequestToToggleArc={ handleRequestToToggleArc }
        isArcOpen={ isArcOpen }
        isAnotherOverlayModuleOpen={ massCommsNav.isOpen }
        onRequestToCloseComms={ handleRequestToCloseMassComms }
      />;
    } else if (riskAlertsGeoData?.source === 'dataminr') {
      return <DataMinrRiskAlertDetails
        riskAlert={ arcRiskAlert || riskAlertsGeoData }
        onRequestToToggleArc={ handleRequestToToggleArc }
        isArcOpen={ isArcOpen }
        isOpenedInArc={ isOpenedInArc }
        isAnotherOverlayModuleOpen={ massCommsNav.isOpen }
        onRequestToCloseComms={ handleRequestToCloseMassComms }
      />;
    } else if (riskAlertsGeoData?.source === 'max-security') {
      return <MaxSecurityRiskAlertDetails
        riskAlert={ arcRiskAlert || riskAlertsGeoData }
        onRequestToToggleArc={ handleRequestToToggleArc }
        isArcOpen={ isArcOpen }
        isOpenedInArc={ isOpenedInArc }
        isAnotherOverlayModuleOpen={ massCommsNav.isOpen }
        onRequestToCloseComms={ handleRequestToCloseMassComms }
      />;
    } else if (riskAlertsGeoData?.source === 'healix') {
      return <HealixRiskAlertDetails
        riskAlert={ arcRiskAlert || riskAlertsGeoData }
        onRequestToToggleArc={ handleRequestToToggleArc }
        isArcOpen={ isArcOpen }
        isOpenedInArc={ isOpenedInArc }
        isAnotherOverlayModuleOpen={ massCommsNav.isOpen }
        onRequestToCloseComms={ handleRequestToCloseMassComms }
      />;
    } else if (riskAlertsGeoData?.source === 'factal') {
      return <FactalRiskAlertDetails
        riskAlert={ arcRiskAlert || riskAlertsGeoData }
        onRequestToToggleArc={ handleRequestToToggleArc }
        isArcOpen={ isArcOpen }
        isOpenedInArc={ isOpenedInArc }
        isAnotherOverlayModuleOpen={ massCommsNav.isOpen }
        onRequestToCloseComms={ handleRequestToCloseMassComms }
      />;
    } else {
      return null;
    }
  }, [ arcRiskAlert, handleRequestToCloseMassComms, handleRequestToToggleArc, isArcOpen, isOpenedInArc,
    massCommsNav.isOpen, riskAlertsGeoData ]);

  return renderRiskAlertDetails();
};

export default RiskAlertDetailsContainer;
