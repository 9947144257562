import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { ReactComponent as OfflineIcon } from '@/common/icons/cloud-offline.svg';
import { ReactComponent as OnlineIcon } from '@/common/icons/cloud-online.svg';
import { showNotification } from "@/common/util/notification";
import { NavigationItemId, NavigationSidebarItem, ProductArea } from "@/core/navigation/types/navigation";
import { redirectWithinRestrataProductEcosystem } from "@/core/navigation/util/navigation";
import { Dispatch, RootState } from "@/core/store";
import { getSiteConfigurations } from "@/platform-context/control-site-config-management/api/siteConfigurations";
import { SiteConfigListType } from "@/platform-context/control-site-config-management/types/siteConfiguration.types";
import { getCountryNameByCode } from "@/tenant-context/common/util/country-to-iso-map";
import { DashboardRequiredPoliciesConfig } from "@/tenant-context/control-connected-sites-dashboard/config/DashboardRequiredPolicies.config";
import { LocationAddress } from "@/tenant-context/control-location-configuration/types/ManageLocations.types";
import {
  SITE_INFO_CONTROL_DRAWER_ID
} from "@/tenant-context/control-site/controls/SiteInfoControl/SiteInfoControl.component";
import { useSiteLocationPopupStyles } from "@/tenant-context/visualisation-site/components/SiteLocationPopup/SiteLocationPopup.styles";
import { SiteConnectivityStatus, SitePeopleCount } from "@/tenant-context/visualisation-site/types/site.types";

import CritLocationControlComponent from "./CritLocationControl.component";

const CritLocationControl: FC = () => {
  const [proxySiteData, setProxySiteData] = useState<SiteConfigListType>();

  const openDrawerId = useSelector((state: RootState) => state.drawer.currentlyOpenRightDrawerId);
  const locationsPeopleCount = useSelector((state: RootState) => state.sitePopup.locationsPeopleCount);
  const selectedSite = useSelector((state: RootState) => state.commonData?.selectedSite);
  const locationZonesData = useSelector((state: RootState) => state.manageLocations?.locationZonesData);
  const locationBuildingData = useSelector((state: RootState) => state.manageLocations?.locationBuildingData);
  const tenantId = useSelector((state: RootState) => state.commonData.tenantId);
  const siteWithPolicies = useSelector((state: RootState) => state.connectedSitesDashboard.siteWithPolicies);
  const enteredLocationDetails = useSelector((state: RootState) => state.manageLocations.enteredLocationDetails);
  const navigate = useNavigate();

  const {
    siteLocations: {
      processSiteConnectivity
    },
    manageLocations:{
      getLocationZonesData,
      getBuildingList,
      getLocationDetails,
      SET_LOCATION_ZONES_DATA,
      SET_LOCATION_BUILDING_DATA
    },
    userProfile: {
      getSiteContactProfileData,
      SET_SITE_CONTACT_PROFILE
    }
  } = useDispatch<Dispatch>();

  const { classes: popupClasses } = useSiteLocationPopupStyles();
  const activeSite = useMemo(() => {
    // eslint-disable-next-line fp/no-let
    let activeSiteData: SitePeopleCount = {
      code: '',
      isMusterActive: false,
      musterCount: 0,
      total: 0,
      visitorsNext24Hrs: 0,
      visitorsOnSite: 0,
      countryName: '',
      address: '',
      description: '',
      siteContactId: '',
      ...selectedSite
    };

    locationsPeopleCount.countries.forEach(
      (country) => {
        return country.sites.forEach((site) => {
          if (site.code === selectedSite?.code) {
            activeSiteData = { ...site, ...selectedSite };
          } else {
            return true;
          }
        });
      }
    );

    return activeSiteData;
  }, [locationsPeopleCount, selectedSite]);

  const siteAddressString = useMemo(() => {
    const { firstLine, secondLine, thirdLine, city } = activeSite.address as LocationAddress;
    const address = `${firstLine || ''} ${secondLine || ''} ${thirdLine || ''}`.trim() + `${city ? `, ${city}` : ''}`;
    return address.trim();
  }, [activeSite.address]);

  const isAuthorizedToManageSite = useMemo(() => siteWithPolicies
    ?.find(x=> x.siteDto.tid === selectedSite?.id)?.policies
    .some(policy => DashboardRequiredPoliciesConfig.MANAGE_SITE_ACCESS.policyNames
      .some(reqPolicy => policy.policyName === reqPolicy)) ?? false, [selectedSite?.id, siteWithPolicies]);

  const musterCount = activeSite.musterCount ?? 0;
  const isMuster = activeSite.isMusterActive ?? false;
  const peopleCount = activeSite.total ?? 0;
  const siteName = activeSite.name ?? '';
  const siteAddress = siteAddressString;
  const countryCode = (activeSite?.address as LocationAddress).country;
  const siteCountry = countryCode ? getCountryNameByCode(countryCode) || '' : '';
  const siteImgUrl = activeSite?.imageUrl ?? '';
  const siteLocType = activeSite?.locationType ?? '';
  const siteId = activeSite?.id;
  const subCategory = activeSite?.subCategory;

  const handleSiteStatus = useCallback((locationId: string) => {
    const siteStatus = processSiteConnectivity(locationId);
    if (siteStatus.status === 'Online') {
      return (<div className={ popupClasses.siteStatusContainer }><OnlineIcon /> { siteStatus.status }</div>);
    } else {
      return (<div className={ popupClasses.siteStatusContainer }><OfflineIcon />{ `${siteStatus.status} ${siteStatus.duration}` }</div>);
    }
  }, [processSiteConnectivity, popupClasses.siteStatusContainer]);

  const navigateToProxy = useCallback(()=>{
    if(!proxySiteData){
      return;
    }

    const { subdomain, locationId, proxyType } = proxySiteData;

    if(proxyType === SiteConnectivityStatus.Offline){
      const { status } = processSiteConnectivity(locationId);
      if(status === SiteConnectivityStatus.Offline){
        showNotification({
          color: "error",
          message: "This service is accessible only within the Local Area Network of the Site"
        });
  
        return;
      }
    }

    if(!subdomain){
      showNotification({
        color: "error",
        message: "Domain not specified for this Site"
      });

      return;
    }

    const returnPath = `?site=${locationId}`;

    const item = {
      area: ProductArea.OfflineProxy,
      unifiedExperienceApplication: 'dashboard',
      path: subdomain,
      title: 'Offline Proxy',
      itemId: NavigationItemId.OfflineProxy,
      redirectParams: {
        siteId: locationId,
        returnPath
      }
    } as NavigationSidebarItem;

    redirectWithinRestrataProductEcosystem(item, navigate);
  }, [navigate, processSiteConnectivity, proxySiteData]);


  useEffect(()=>{
    if(selectedSite?.id && selectedSite.id !== "all"){

      //clear state
      if(!openDrawerId){
        SET_LOCATION_ZONES_DATA(undefined);
        SET_LOCATION_BUILDING_DATA(undefined);
        return;
      }

      if(openDrawerId === SITE_INFO_CONTROL_DRAWER_ID){
        getLocationZonesData({
          gridParams:{
            page: 0,
            size: 5,
            additionalParams: {
              locationCategory: selectedSite.subCategory
            }
          },
          locationId: selectedSite.id
        });
        getBuildingList({
          gridParams:{
            page: 0,
            size: 5
          },
          locationId: selectedSite.id
        });
        if(selectedSite.subCategory) {
          getLocationDetails({
            tid: selectedSite.id,
            category: selectedSite.subCategory
          });
        }
        
        getSiteConfigurations(tenantId, 0, 999, undefined, `locationId='${selectedSite.id}'`)
          .then(data => setProxySiteData(data.items?.[0]));

      }

    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSite, openDrawerId]);

  useEffect(() => {
    if(typeof enteredLocationDetails?.poiAdditionalInfoRequest === 'string') {
      return;
    }
    const profileId = enteredLocationDetails?.poiAdditionalInfoRequest?.contactList[0]?.profileId;
    if (profileId) {
      getSiteContactProfileData(profileId);
    }

    return () => {
      SET_SITE_CONTACT_PROFILE(null);
    };
  }, [SET_SITE_CONTACT_PROFILE, enteredLocationDetails?.poiAdditionalInfoRequest, getSiteContactProfileData]);

  const handleCloseDrawer = useCallback(() => {
    SET_SITE_CONTACT_PROFILE(null);
  }, [SET_SITE_CONTACT_PROFILE]);

  if (openDrawerId !== SITE_INFO_CONTROL_DRAWER_ID) {
    return null;
  }

  return (
    <CritLocationControlComponent
      zonesCount={ locationZonesData?.totalItems || 0 }
      buildingCount={ locationBuildingData?.totalItems || 0 }
      peopleCount={ peopleCount }
      musterCount={ musterCount }
      isMuster={ isMuster }
      navigateToSite={ navigateToProxy }
      isSitesAuthorized={ isAuthorizedToManageSite }
      siteName={ siteName }
      siteAddress={ siteAddress }
      siteImageUrl={ siteImgUrl }
      siteLocationType={ siteLocType }
      siteId={ siteId }
      onSiteStatus={ handleSiteStatus }
      subCategory={ subCategory }
      siteCountry={ siteCountry }
      connectionProxyEnabled={ !!proxySiteData }
      onCloseDrawer={ handleCloseDrawer }
    />
  );
};

export default CritLocationControl;
