import { Global } from '@mantine/core';
import dayjs from "dayjs";
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
import LoaderScreen from 'packages/core/components/LoaderScreen';
import { FC } from 'react';

import { globalStyles } from '@/core/styles/global-styles';
import SessionTimeoutWarning from '@/tenant-context/common/components/SessionTimeoutWarning';

import ProviderHaven from '../ProviderHaven/ProviderHaven.component';

import './App.scss';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';

dayjs.extend(utc);
dayjs.extend(duration);

const App: FC = () => {
  return (
    <ProviderHaven>
      <Global styles={ globalStyles } />
      <LoaderScreen isGlobal/>
      <SessionTimeoutWarning />
    </ProviderHaven>
  );
};

export default App;
