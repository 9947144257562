import { TimeZone } from "@/tenant-context/control-connected-sites-dashboard/types/connectedSitesDashboard";

import { CaseStatusDto, CaseType, SeverityLevelDto } from "./caseList";

export enum CaseStage {
    Cancelled = "Cancelled",
    Completed = "Completed",
    InProgress = "In-progress",
    NotStarted = "Not Started",
}

export enum CaseStatus{
  Open = 'OPEN',
  Closed = 'CLOSED'
}

export enum CaseSeverity{
    Normal = "Normal",
    High = "High",
    Low = "Low",
}

export enum CaseFeature  {
  TASKS = 'Tasks',
  TEAMSANDROLES = 'Teams and Roles',
  THREATS = 'Threats',
  PLAYBOOKS = 'Playbooks',
  EVENTS ='Events',
  BRIEFINGS = 'Briefings',
  PEOPLE ='People',
  MEDIA ='Media',
  LOGISTICS ='Logistics',
  LINKS ='Links',
  DOCUMENTS ='Documents',
  CONTACTS ='Contacts'
}

export interface AddCaseResponse {
    tid: string
    caseNumber: string
    caseTypeId: string
    caseStatus: string
    trainingMode: boolean
}

export interface AddCaseForm {
    caseTypeTid: string
    trainingMode: boolean
}

export interface EditFormCustomFields{
    reportedDate?: Date,
    reportedTime?: Date,
    timeZone?: string
}
export interface CaseFields extends EditFormCustomFields, Partial<CaseDTO>{
    tid?: string,
    caseTypeRef?: string
    trainingMode?: boolean
    caseStatusRef: string
    summery: string
    description: string
    sourceName: string
    sourceMail: string
    sourcePhone: string
    reportedDateTime?: string
    reportedDateTimeInString?: string
    reportedTimeZone?: TimeZone
    severityLevelRef: string
    caseOwner: string
    caseOwnerTid: string
    caseOwnerRoleTid: string
    tags: string[]
    attachments: string[]
    primaryTenantLocations: string[]
    primaryICMLocations: string[]
    associatedTenantLocations: string[]
    associatedICMLocations: string[]

    //comes in the response object
    primaryTenantLocationIds?: string[]
    primaryICMLocationIds?: string[]
    associatedICMLocationIds?: string[]
    associatedTenantLocationIds?: string[]
    caseNumber?: string
    createdDate?: number
    updatedDate?: number
    updatedBy?: string
    caseOwnerRoleRef?: string
    caseStatusDto?: CaseStatusDto;
    severityLevelDto?: SeverityLevelDto;
    createdBy?: string
    createdUserTitle?: string
    caseOwnerTitle?: string
    caseStatusType?: string
}

export interface CaseDTO{
    caseType: CaseType
}

export interface CloseCaseFields{
    date: Date,
    time: string,
    reason: string,
    tid?: string
}

export interface CaseOwner {
    firstName: string
    lastName: string
    personProfileId: string
    tenantUserId: string
    userName: string
  }

export interface CaseTag{
    tags: string
    _id: string
  }

export interface CaseAttachment{
        fileName: string,
        base64EncodedFile: string
  }

export interface CaseAttachmentResponse {
    tenantId: string
    id: string
    fileName: string
    fileExtension: string
    fileNameWithExtension: string
    filePath: string
    fileSize: number
  }
export interface CaseLocationDTO {
    tid: string
    tenantId: string
    name: string
    description: string
    geoPoint: GeoPoint
    locationType: string
    icmlocation: boolean
    criticalLocation: boolean
  }
  
  interface GeoPoint {
    lat: number
    lon: number
  }
  
export interface CaseLocationParams {
    name: string
    criticalLocation: boolean
    description: string
    lat: string
    lon: string
    locationType: string
    alias: string[]
    code: string
    timeZone: TimeZone
    address: Address
    isICMLocation: boolean
    locationImage: string
  }
export interface Address {
    city: string
    country: string
    firstLine: string
    secondLine: string
    postalCode: string
    region: string
  }

export interface TeamList  {
    description: string
    reportsTo: string
    teamName: string
    tenantId: string
    _id: string
}

export interface TeamRole  {
    crossTeamEnabled: string
    roleName: string
    teamRoleId: string
    roleAssignmentId: string
    tenantId: string
    userId: string
    tenantUserId: string
    userName: string
    userType: string
    _id: string
}

export type CaseTab = {
    tenantUserId: string,
    featureRef: string,
    name: string,
    customName: string,
    permissionType: string,
}