import { Button, Flex, useMantineTheme } from "@mantine/core";
import { FC, useCallback } from "react";

import { Popup } from "@/common/components/Popup/Popup.components";
import { useCurrentPopup } from "@/tenant-context/common/hooks/useMapPopupList";
import { useRiskAlertPopupStyles } from "@/tenant-context/visualisation-risk-alerts/components/RiskAlertPopup/RiskAlertPopup.styles";
import {
  RiskAlertPopupContent
} from '@/tenant-context/visualisation-risk-alerts/components/RiskAlertPopup/RiskAlertPopupContent.component';
import { RiskAlertEvent } from "@/tenant-context/visualisation-risk-alerts/types/risk-alerts";

type Props = {
  popupId: string;
  onClosePopup(): void;
  onClickRiskDetails(riskEvent: RiskAlertEvent): void;
};

export const RiskAlertPopup: FC<Props> = ({
  popupId,
  onClosePopup,
  onClickRiskDetails
}) => {
  const theme = useMantineTheme();
  const { classes } = useRiskAlertPopupStyles();
  const { popup, closePopup } = useCurrentPopup<RiskAlertEvent>("bigMapPopups", popupId);

  const handleClosePopup = useCallback(
    () => {
      closePopup();
      onClosePopup();
    },
    [closePopup, onClosePopup]
  );

  const handleDetailsClick = useCallback(
    () => {
      if (!popup) {
        return;
      }
      onClickRiskDetails(popup.data);
      handleClosePopup();
    },
    [popup, onClickRiskDetails, handleClosePopup]
  );

  if (!popup) {
    return null;
  }

  const [longitude, latitude] = popup.position;
  const riskAlertData = popup.data;

  if (!riskAlertData) {
    return null;
  }

  return (
    <Popup
      longitude={ longitude }
      latitude={ latitude }
      padding="16px 0"
      offset={ 20 }
      backgroundColor={ theme.colors.neutral[8] }
      onClose={ handleClosePopup }
      closeOnClick={ false }
      closeButton={ true }
    >
      { /* TODO: When tested successfully remove other provider specific popups from the codebase */ }
      { riskAlertData.source && [
        'factal', 'dataminr', 'riskline', 'healix', 'max-security', 'bsoc'
      ].includes(riskAlertData.source) && (
        <RiskAlertPopupContent
          json={ riskAlertData.json }
          riskAlertData={ riskAlertData }
          isImpacted={ riskAlertData.isImpacted }
          source={ riskAlertData.source }
        />) }

      <Flex gap="xl" justify="end" pt={ 24 } mx="md">
        <Button size="md" variant="subtle" onClick={ handleDetailsClick } className={ classes.moreDetailsButton }>
          More Details
        </Button>
      </Flex>
    </Popup>
  );
};

export default RiskAlertPopup;
