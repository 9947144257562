import React from "react";

import { ReactComponent as RisklineSvg } from "@/common/icons/RiskProviders/riskline-brand.svg";

type MetaDataItem = {
  label: string;
  value: React.ReactNode;
};

const RiskLineLogo = () => (
  <a href="https://riskline.com/" target="_blank" title="Powered by Riskline"  rel="noreferrer">
    <RisklineSvg width={ 81.99 } />
  </a>
);

export const countryRiskLayerMetadata: MetaDataItem[] = [
  { label: 'Data source:', value: (
    <RiskLineLogo />
  ) },
  { label: 'Coverage:', value: 'Global' },
  { label: 'Update frequency:', value: '1 hour' }
];
