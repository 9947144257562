import { PaginatedResult } from "@/common/types/reference-data";
import { callApi } from "@/common/util/api";
import { CreateTeamParams, Team } from "@/platform-context/control-icm-management/types/CaseTypes/caseTypeTeamsAndRoles";

import { CaseFeature } from "../types/caseManagement";
import { TaskList } from "../types/tasks";
import { AssignRoleFields, AssignUserRoleFields, CaseUserAccessLevel, RoleChecklistItem, RoleEventItem, RoleItem, TeamItem } from "../types/teamsAndRoles";

export const teamsAndRolesApi = {
  getTeamList: function(
    caseId: string,
    userId: string,
    feature: CaseFeature,
    page = 0,
    size = 1000
  ) {
    return callApi<PaginatedResult<TeamItem>>(
      `managed/icm-service/v1/icm-cases/${caseId}?page=${page}&size=${size}&userId=${userId}&feature=${feature}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=ListTeam'
        },
        method: 'get'
      }
    );
  },
  getRolesListForTeam: function(
    caseId: string,
    teamId: string,
    searchQuery?: string,
    isAssigned = false,
    page = 0,
    size = 1000
  ) {
    return callApi<PaginatedResult<RoleItem>>(
      `managed/icm-service/v1/icm-cases/${caseId}?page=${page}&size=${size}&teamId=${teamId}&isAssigned=${isAssigned}${searchQuery ? '&assignedUser=' + searchQuery + '&assignedRole=' + searchQuery : ''}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=ListTeamRole'
        },
        method: 'get'
      }
    );
  },
  getRolesListForCase: function(
    caseId: string,
    userId: string,
    feature: string,
    page = 0,
    size = 1000
  ) {
    return callApi<PaginatedResult<RoleItem>>(
      `managed/icm-service/v1/icm-cases/${caseId}?page=${page}&size=${size}&userId=${userId}&feature=${feature}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=ListCaseRole'
        },
        method: 'get'
      }
    );
  },
  createTeamForCase: function(caseId: string, params: CreateTeamParams) {
    return callApi<Team>(
      `managed/icm-service/v1/icm-cases/${caseId}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=CreateTeam'
        },
        method: 'post',
        body: JSON.stringify(params)
      }
    );
  },
  updateTeamForCase: function(caseId: string, params: CreateTeamParams) {
    return callApi<Team>(
      `managed/icm-service/v1/icm-cases/${caseId}/teams/${params._id}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=UpdateTeam'
        },
        method: 'post',
        body: JSON.stringify(params)
      }
    );
  },
  deleteTeamFromCase: function(caseId: string, teamId: string) {
    return callApi(
      `managed/icm-service/v1/icm-cases/${caseId}/teams/${teamId}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=DeleteTeam'
        },
        method: 'delete'
      }
    );
  },
  addRoleForCaseTeam: function(caseId: string, params: AssignRoleFields) {
    return callApi<RoleItem>(
      `managed/icm-service/v1/icm-cases/${caseId}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=CreateRoleAssignment'
        },
        method: 'post',
        body: JSON.stringify(params)
      }
    );
  },
  assignRoleForUser: function(caseId: string, params: AssignUserRoleFields & {roleId: string}) {
    return callApi(
      `managed/icm-service/v1/icm-cases/${caseId}/role-assignments/${params.roleId}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=AssignUserRole'
        },
        method: 'post',
        body: JSON.stringify(params)
      }
    );
  },
  reAssignRoleForUser: function(caseId: string, params: AssignUserRoleFields & {roleAssignmentTid: string}) {
    return callApi(
      `managed/icm-service/v1/icm-cases/${caseId}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=ReAssignUserRole'
        },
        method: 'post',
        body: JSON.stringify(params)
      }
    );
  },
  unAssignRoleFromUser: function(caseId: string, roleId: string) {
    return callApi(
      `managed/icm-service/v1/icm-cases/${caseId}/role-assignments/${roleId}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=UnAssignUserRole'
        },
        method: 'post',
        body: JSON.stringify({})
      }
    );
  },
  deleteRoleFromCase: function(caseId: string, roleId: string) {
    return callApi(
      `managed/icm-service/v1/icm-cases/${caseId}/role-assignments/${roleId}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=DeleteRoleAssignment'
        },
        method: 'delete'
      }
    );
  },
  getRoleAssignmentDetails: function(caseId: string, roleAssignmentId: string) {
    return callApi(
      `managed/icm-service/v1/icm-cases/${caseId}/role-assignments/${roleAssignmentId}?page=0&size=1000`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=GetRoleAssignment'
        }
      }
    );
  },
  getUserAccessLevel: function(caseId: string, userId: string, feature: CaseFeature) {
    return callApi<CaseUserAccessLevel>(
      `managed/icm-service/v1/icm-cases/${caseId}?userId=${userId}&feature=${feature}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=GetUserAccessLevel'
        }
      }
    );
  },
  getRoleEvents: function(caseId: string, roleId: string) {
    return callApi<PaginatedResult<RoleEventItem>>(
      `managed/icm-service/v1/icm-cases/${caseId}?page=0&size=1000&roleAssignmentId=${roleId}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=ListRoleAssignmentHistory'
        }
      }
    );
  },
  getRoleTasks: function(caseId: string, roleId: string) {
    return callApi<PaginatedResult<TaskList>>(
      `managed/icm-service/v1/icm-cases/${caseId}?page=0&size=1000&roleAssignmentId=${roleId}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=ListRoleAssignmentTask'
        }
      }
    );
  },
  getRoleChecklist: function(caseId: string, roleId: string) {
    return callApi<PaginatedResult<RoleChecklistItem>>(
      `managed/icm-service/v1/icm-cases/${caseId}?page=0&size=1000&roleAssignmentId=${roleId}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=ListRoleAssignedCheckListItems'
        }
      }
    );
  },
  updateChecklist: function(caseId: string, roleId: string, checkedListItemStatusMap: Record<string, boolean>) {
    return callApi(
      `managed/icm-service/v1/icm-cases/${caseId}/role-assignments/${roleId}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=UpdateRoleAssignedCheckListItem'
        },
        method: 'post',
        body: JSON.stringify({ checkedListItemStatusMap })
      }
    );
  }
};