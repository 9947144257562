import { FC, Fragment, useContext, useEffect, useMemo, useState } from 'react';
import { useMap } from "react-map-gl";
import { useSelector } from "react-redux";

import { RootState } from "@/core/store";
import { formatNumberToDecimalPoints } from "@/tenant-context/common/util/data-standardize";
import { ArcCaseEvent } from "@/tenant-context/control-action-response-center/types/ARC.types";
import { BigMapContext } from "@/tenant-context/core/context/BigMap.context";
import { ImpactedArea } from '@/tenant-context/visualisation-risk-details/types/risk-impact';
import { calculateOffset } from '@/tenant-context/visualisation-risk-details/util/calculateOffset';

import RiskImpactPopup from './RiskImpactPopup.component';

type Props = {
  arcCaseEvent?:ArcCaseEvent
};

const RiskImpactPopupContainer: FC<Props> = ({
  arcCaseEvent
}) => {
  const riskImpactData = useSelector((state: RootState) => state.riskDetails.currentRiskAlertImpactEvent);
  const currentBigMapPopup = useSelector((state: RootState) => state.bigMap.currentBigMapPopup);

  const isRiskImpactPopupShown = currentBigMapPopup === "Risk"
    || arcCaseEvent !== undefined ;

  const { bigMapZoomLevel } = useContext(BigMapContext);
  const { impactZoneMap: map } = useMap();

  const impactPosition = !arcCaseEvent ? riskImpactData?.request.geometry : arcCaseEvent.geometry;
  const impactRadius = formatNumberToDecimalPoints(((!arcCaseEvent
    ? riskImpactData?.calculation.appliedTo
    : arcCaseEvent.calculation.appliedTo) || 0), 3);
  const assetsCount = !arcCaseEvent ? riskImpactData?.assetCount : arcCaseEvent.totalAssetCount;
  const peopleCount = !arcCaseEvent ? riskImpactData?.peopleCount : arcCaseEvent.totalPeopleCount;

  const [currentMapZoom, setCurrentMapZoom] = useState(0);

  const impactedAreas = useMemo<(ImpactedArea & { offset?: number })[]>(() => {
    if (riskImpactData?.impactedAreas && (riskImpactData?.impactedAreas.length > 0)) {
      return riskImpactData?.impactedAreas.map((impactedArea) => ({
        ...impactedArea,
        offset: calculateOffset(
          impactedArea,
          currentMapZoom,
          bigMapZoomLevel,
          arcCaseEvent
        )
      }));
    }

    return [];
  }, [riskImpactData?.impactedAreas, arcCaseEvent, currentMapZoom, bigMapZoomLevel]);

  useEffect(() => {
    if (!map) {
      return;
    }
    map.on('zoom', (_) => {
      setCurrentMapZoom(map.getZoom());
    });

    return () => {
      map.off('zoom', () => {
        setCurrentMapZoom(map.getZoom());
      });
    };
  }, [map]);

  const getPopUpOffset = useMemo(
    () => {
      if(!impactRadius || !impactPosition){
        return;
      }

      // Pop up offset should be dynamic and it should always just above the drawn impact cicle
      // Mapping meters to pixels at given zoom level

      // 78271.484 is m/px ratio through equator (Latitude 0) when zool level is 0 (reference https://docs.mapbox.com/help/glossary/zoom-level/)
      // appliedTo is multiplied by 1000 to get the distance in meters
      // 1 / cos(phi), uses to obtain the correct meter to pixel ratio for any latitude

      const zoomLevel = arcCaseEvent ? currentMapZoom : bigMapZoomLevel;
      // eslint-disable-next-line no-magic-numbers
      return (impactRadius * 1000) /
       // eslint-disable-next-line no-magic-numbers
       (78271.484 / 2 ** zoomLevel) / Math.cos(impactPosition.lat * Math.PI / 180);
    },
    [impactRadius, impactPosition, bigMapZoomLevel, arcCaseEvent, currentMapZoom]
  );

  const showRadius = useMemo(() => riskImpactData?.request?.source !== 'factal', [riskImpactData]);

  return (
    <Fragment>
      { impactedAreas?.map((area, index) => (
        <RiskImpactPopup
          key={ index.toString() }
          isShown={ isRiskImpactPopupShown }
          assetCount = { area.assetCount }
          impactRadius = { area.calculation.appliedTo }
          peopleCount = { area.peopleCount }
          impactPositionData = {  area.calculation.targetPoint }
          popUpOffset = { area.offset }
          showRadius = { area.calculation.calculationType === 'CIRCLE' }
        />
      )) }
      { !impactedAreas.length && (
        <RiskImpactPopup
          isShown={ isRiskImpactPopupShown }
          assetCount = { assetsCount }
          impactRadius = { impactRadius }
          peopleCount = { peopleCount }
          impactPositionData = {  impactPosition }
          popUpOffset = { getPopUpOffset }
          showRadius = { showRadius }
        />
      ) }
    </Fragment>
  );
};

export default RiskImpactPopupContainer;
