/* eslint-disable react/jsx-no-bind */
import { ActionIcon, Badge, Box, Button, Divider, Flex, Tabs, Text } from "@mantine/core";
import { FC, Fragment, MouseEventHandler, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as RightArrowIcon } from "@/common/icons/Arrows/right-arrow.svg";
import { ReactComponent as ChevronLeft } from "@/common/icons/chevron-left.svg";
import { ReactComponent as MaxSecurity } from "@/common/icons/RiskProviders/max-security.svg";
import { getTimeDurationFromCurrentDate } from '@/common/util/format/date';
import { Dispatch, RootState } from '@/core/store';
import CountryFlag from "@/tenant-context/common/components/CountryFlag/CountryFlag.component";
import NumberHighlightedTextComponent from '@/tenant-context/common/components/NumberHighlightedText/NumberHighlightedText.component';
import { getCountryIsoCode } from '@/tenant-context/common/util/country-to-iso-map';
import { isValidCountryName, isValidISOCountryCode } from '@/tenant-context/common/util/country-to-iso-map';
import RelatedEventList
  from '@/tenant-context/control-risk-config/components/RiskAlertDetails/RelatedEventList.component';
import { RiskAlertDetailsStyles } from "@/tenant-context/control-risk-config/components/RiskAlertDetails/RiskAlertDetails.styles";
import { addTargetBlankToHTMLLinks } from "@/tenant-context/control-risk-config/util/generalize-related-event";
import RiskAlertBasicData from "@/tenant-context/visualisation-risk-alerts/components/RiskAlertBasicData";
import { MaxSecurityExternalData } from '@/tenant-context/visualisation-risk-alerts/types/max-security';
import { RiskAlertEvent } from "@/tenant-context/visualisation-risk-alerts/types/risk-alerts";
import { RiskConnectorExternalData } from '@/tenant-context/visualisation-risk-alerts/types/risk-alerts-generic';
import { RiskImpactEvent } from "@/tenant-context/visualisation-risk-details/types/risk-impact";

type Props = {
  riskAlert: RiskAlertEvent<RiskConnectorExternalData>,
  riskImpactData?: RiskImpactEvent,
  onRequestToToggleArc: MouseEventHandler<HTMLButtonElement>,
  onRequestToCloseComms: MouseEventHandler<HTMLButtonElement>,
  isArcOpen: boolean;
  isOpenedInArc?: boolean;
  isAnotherOverlayModuleOpen: boolean;
}

const MaxSecurityRiskAlertDetails: FC<Props> = ({
  riskAlert,
  onRequestToToggleArc,
  onRequestToCloseComms,
  isArcOpen,
  isOpenedInArc = false,
  isAnotherOverlayModuleOpen
}) => {
  const { classes  } = RiskAlertDetailsStyles({ isInArc: isOpenedInArc, isOverlayOpen: isAnotherOverlayModuleOpen });

  const {
    relatedEventsLazyLoadedList ,
    relatedEventsCount,
    selectedEventAlertId
  } = useSelector((state: RootState) => state.eventTimeLine);

  const { eventTimeLine: {
    RESET_EVENT_TIMELINE,
    loadRelatedEventsLazyLoadedList
  } } = useDispatch<Dispatch>();

  const [currentTab, setCurrentTab] = useState<'details' | 'events'>('details');
  const [isSeeMore, setIsSeeMore] = useState<boolean>(false);

  const handleTabChange = useCallback((tab: 'details' | 'events') => setCurrentTab(tab), []);

  const toggleSeeMore = useCallback(() => {
    setIsSeeMore(!isSeeMore);
  }, [isSeeMore]);

  const renderSection = useCallback((section, index) => (
    <Fragment key={ index }>
      <h2 className={ classes.sectionTitle }>{ section.section_title }</h2>
      <div
        className={ classes.sectionContent }
        dangerouslySetInnerHTML={ { __html: addTargetBlankToHTMLLinks(section.section_content) } }
      />
    </Fragment>
  ), [classes.sectionContent, classes.sectionTitle]);

  useEffect(() => {
    if (!riskAlert.tid) {
      return;
    }

    loadRelatedEventsLazyLoadedList({ parentEventId: riskAlert.tid, provider: 'max-security' });

    return () => {
      loadRelatedEventsLazyLoadedList(undefined);
      RESET_EVENT_TIMELINE();
    };
  }, [RESET_EVENT_TIMELINE, loadRelatedEventsLazyLoadedList, riskAlert.tid]);

  const mappedRiskAlert: RiskAlertEvent<RiskConnectorExternalData> = {
    ...riskAlert,
    json: {
      ...riskAlert.json,
      alert: {
        ...riskAlert.json.alert,
        start_date: new Date(riskAlert?.json.alert.start_date).toLocaleDateString(),
        end_date: new Date(riskAlert?.json.alert.end_date).toLocaleDateString()
      }
    }
  };

  const countryISOCode = useMemo(() => {
    if (!riskAlert) {
      return undefined;
    }

    if (isValidISOCountryCode(riskAlert?.json.alert.countries[0].iso_code)) {
      return riskAlert?.json.alert.countries[0].iso_code;
    }

    if (isValidCountryName(riskAlert?.json.alert.location_explanation)) {
      return getCountryIsoCode(riskAlert?.json.alert.location_explanation);
    }

    return undefined;
  }, [riskAlert]);

  return (
    <div className={ classes.container }>
      { (isArcOpen || isAnotherOverlayModuleOpen) && <Flex justify={ 'space-between' } mb={ 'md' }>
        { (isArcOpen || isAnotherOverlayModuleOpen) && (
          <div>
            <Flex gap={ 2 } align={ 'flex-end' }>
              <NumberHighlightedTextComponent
                numberFw={ 'bold' }
                numberFs={ 'sm' }
                alphaFs={ 12 }
                text={ riskAlert?.json.alert.start_date !== undefined
                  ? `${getTimeDurationFromCurrentDate(new Date(riskAlert?.json.alert.start_date))} AGO`
                  : '' }
              />
            </Flex>
          </div>
        ) }

        { isArcOpen && (
          <div>
            <ActionIcon size={ 20 } onClick={ onRequestToToggleArc } variant={ 'subtle' }>
              <RightArrowIcon />
            </ActionIcon>
          </div>
        ) }

        { isAnotherOverlayModuleOpen && (
          <div className={ classes.innerArrow }>
            <ActionIcon size={ 20 } onClick={ onRequestToCloseComms }>
              <ChevronLeft />
            </ActionIcon>
          </div>
        ) }
      </Flex> }

      <Flex direction={ 'column' } className={ classes.innerContainer }>
        <Flex direction={ 'column' } gap={ 32 } mb={ 48 }>
          <Flex direction={ 'column' } gap={ 8 }>
            { countryISOCode && (
              <div className={ classes.popupHeader }>
                <Flex justify={ 'center' } align={ 'center' } h={ 48 } w={ 48 } className={ classes.flag }>
                  <Box>
                    <CountryFlag
                      width={ 80 }
                      height={ 80 }
                      countryCode={ countryISOCode }
                    />
                  </Box>
                </Flex>
              </div>
            ) }

            <div className={ classes.countryInfo }>
              { (riskAlert.json.externalData as MaxSecurityExternalData)?.affected_area ||
                riskAlert?.json.alert.location_explanation }
            </div>

            <Text className={ classes.riskAlertTitle } lineClamp={ 2 }>
              { riskAlert?.json.alert.title }
            </Text>
          </Flex>

          <Box>
            <RiskAlertBasicData riskAlertData={ mappedRiskAlert } isOnSideDrawer/>
          </Box>
        </Flex>

        <Tabs onTabChange={ handleTabChange } value={ currentTab } className={ classes.detailsTabsRoot }>
          <Tabs.List grow>
            <Tabs.Tab value="details">
              Details
            </Tabs.Tab>
            <Tabs.Tab value="events">
              Related Events
              <Badge size="sm" variant={ 'filled' } className={ classes.badge } radius={ 'md' }>{ relatedEventsCount }</Badge>
            </Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value={ 'details' }>
            <div className={ classes.riskDescription }>
              <Text className={ classes.riskDescriptionText } lineClamp={ 10 }>
                { isSeeMore ?
                  (riskAlert?.json.externalData as MaxSecurityExternalData).sections
                    .map((section, index) => renderSection(section, index)) :
                  (riskAlert?.json.externalData as MaxSecurityExternalData).sections
                    .slice(0, 2)
                    .map((section, index) => renderSection(section, index)) }
              </Text>
              { ((riskAlert?.json.externalData as MaxSecurityExternalData).sections.length > 2) &&
                <Button variant={ 'subtle' } onClick={ toggleSeeMore } className={ classes.seeMoreButton }>
                  { isSeeMore ? 'See less' : 'See more' }
                </Button> }
            </div>

            <Divider
              className={ classes.divider }
            />

            <Flex gap={ 'xs' } justify={ 'flex-start' } align={ 'center' } mt={ 4 }>
              <div className={ classes.issuedBy }>Issued by</div>

              <MaxSecurity className={ classes.riskAlerProviderIcon }/>

              <div className={ classes.riskAlertProviderName }> Max Security</div>
            </Flex>
          </Tabs.Panel>
          <Tabs.Panel value={ 'events' } className={ classes.relatedEventsTabsPanel }>
            <RelatedEventList
              riskAlertTid={ riskAlert.tid }
              relatedEventsList={ relatedEventsLazyLoadedList }
              relatedEventsCount={ relatedEventsCount }
              selectedEventAlertId={ selectedEventAlertId }
              isInArc={ isOpenedInArc }
              provider={ 'max-security' }
              isRelatedEventsTabSelected={ currentTab === 'events' }
            />
          </Tabs.Panel>
        </Tabs>
      </Flex>
    </div>
  );
};

export default MaxSecurityRiskAlertDetails;
