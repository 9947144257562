import { createStyles } from "@mantine/core";


export const useScrollableSliderStyles = createStyles((_theme, childGap: number) => ({
  carouselInnerContainer: {
    display: 'flex',
    gap: childGap
  },
  carouselWrapper: {
    overflow: 'hidden',
    '.restrata-Carousel-controls': {
      paddingLeft: '0px',
      paddingRight: '0px'
    }
  }
}));