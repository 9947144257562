import { createModel } from "@rematch/core";

import { PaginatedResult } from "@/common/types/reference-data";
import { handleError } from "@/common/util/common-functions";
import { Dispatch, RootModel, RootState } from "@/core/store";
import { CreateTeamParams, Team } from "@/platform-context/control-icm-management/types/CaseTypes/caseTypeTeamsAndRoles";

import { caseManagementApi } from "../api/caseManagement";
import { teamsAndRolesApi } from "../api/teamsAndRoles";
import { CaseFeature, CaseOwner } from "../types/caseManagement";
import { TaskList } from "../types/tasks";
import { AssignRoleFields, AssignUserRoleFields, CaseUserAccessLevel, RoleChecklistItem, RoleEventItem, RoleItem, TeamItem } from "../types/teamsAndRoles";

type TeamsAndRolesState = {
    teamListData?: PaginatedResult<TeamItem>,
    teamRolesListData?: PaginatedResult<RoleItem>,
    caseRolesListData?: PaginatedResult<RoleItem>, 
    selectedTeam?: TeamItem,
    assigningUserList?: PaginatedResult<CaseOwner>,
    selectedRole?: RoleItem,
    userAccessLevel?: CaseUserAccessLevel,
    roleEvents?: PaginatedResult<RoleEventItem>,
    roleTasks?: PaginatedResult<TaskList>,
    roleChecklists?: PaginatedResult<RoleChecklistItem>
};

const teamsAndRolesDataModel = {
  name: 'teamsAndRolesManagement',
  state: {
    teamListData: undefined,
    teamRolesListData: undefined,
    caseRolesListData: undefined,
    selectedTeam: undefined,
    assigningUserList: undefined,
    selectedRole: undefined,
    userAccessLevel: undefined,
    roleEvents: undefined,
    roleTasks: undefined,
    roleChecklists: undefined
  } as TeamsAndRolesState,
  reducers: {
    SET_TEAMS_LIST: (state: TeamsAndRolesState, teamListData: TeamsAndRolesState['teamListData']) => ({
      ...state,
      teamListData
    }),
    SET_TEAM_ROLES_LIST: (state: TeamsAndRolesState, teamRolesListData: TeamsAndRolesState['teamRolesListData']) => ({
      ...state,
      teamRolesListData
    }),
    SET_CASE_ROLES_LIST: (state: TeamsAndRolesState, caseRolesListData: TeamsAndRolesState['caseRolesListData']) => ({
      ...state,
      caseRolesListData
    }),
    SET_SELECTED_TEAM: (state: TeamsAndRolesState, selectedTeam: TeamsAndRolesState['selectedTeam']) => ({
      ...state,
      selectedTeam
    }),
    SET_ASSIGNING_USER_LIST: (state: TeamsAndRolesState, assigningUserList: TeamsAndRolesState['assigningUserList']) => ({
      ...state,
      assigningUserList
    }),
    SET_SELECTED_ROLE: (state: TeamsAndRolesState, selectedRole: TeamsAndRolesState['selectedRole']) => ({
      ...state,
      selectedRole
    }),
    SET_USER_ACCESS_LEVEL: (state: TeamsAndRolesState, userAccessLevel: TeamsAndRolesState['userAccessLevel']) => ({
      ...state,
      userAccessLevel
    }),
    SET_ROLE_EVENTS: (state: TeamsAndRolesState, roleEvents: TeamsAndRolesState['roleEvents']) => ({
      ...state,
      roleEvents
    }),
    SET_ROLE_TASKS: (state: TeamsAndRolesState, roleTasks: TeamsAndRolesState['roleTasks']) => ({
      ...state,
      roleTasks
    }),
    SET_ROLE_CHECKLISTS: (state: TeamsAndRolesState, roleChecklists: TeamsAndRolesState['roleChecklists']) => ({
      ...state,
      roleChecklists
    })
  },
  effects: (dispatch: Dispatch) => ({
    async getTeamListData(payload: {
      userId: string,
  }, state: RootState): Promise<PaginatedResult<TeamItem>> {

      const caseId = state.caseManagement.selectedCase?.tid || "";

      const teamListData = await teamsAndRolesApi.getTeamList(
        caseId,
        payload.userId,
        CaseFeature.TEAMSANDROLES
      );

      dispatch.teamsAndRolesManagement.SET_TEAMS_LIST(teamListData);
      return teamListData;
    },
    async getTeamRolesListData(payload: {
        teamId: string,
        searchQuery: string
  }, state: RootState): Promise<PaginatedResult<RoleItem>> {

      const caseId = state.caseManagement.selectedCase?.tid || "";

      const teamRolesListData = await teamsAndRolesApi.getRolesListForTeam(
        caseId,
        payload.teamId,
        payload.searchQuery
      );

      dispatch.teamsAndRolesManagement.SET_TEAM_ROLES_LIST(teamRolesListData);
      return teamRolesListData;
    },
    async getCaseRolesListData(payload: {
        userId: string,
        feature: CaseFeature
    }, state: RootState): Promise<PaginatedResult<RoleItem>> {

      const caseId = state.caseManagement.selectedCase?.tid || "";

      const caseRolesListData = await teamsAndRolesApi.getRolesListForCase(
        caseId,
        payload.userId,
        payload.feature
      );

      dispatch.teamsAndRolesManagement.SET_CASE_ROLES_LIST(caseRolesListData);
      return caseRolesListData;
    },
    async createTeamForCase(params: CreateTeamParams, state: RootState): Promise<Team | false> {
      const caseId = state.caseManagement.selectedCase?.tid || "";
      return await teamsAndRolesApi.createTeamForCase(caseId, params).catch(handleError);
    },
    async updateTeamForCase(params: CreateTeamParams, state: RootState): Promise<Team | false> {
      const caseId = state.caseManagement.selectedCase?.tid || "";
      return await teamsAndRolesApi.updateTeamForCase(caseId, params).catch(handleError);
    },
    async deleteTeamFromCase(teamId: string, state: RootState): Promise<boolean> {
      const caseId = state.caseManagement.selectedCase?.tid || "";
      return !!await teamsAndRolesApi.deleteTeamFromCase(caseId, teamId).catch(handleError);
    },
    async fetchUsersForRoleAssigning(_: void): Promise<void>{
      const data = await caseManagementApi.getCaseOwners();
      if(data){
        dispatch.teamsAndRolesManagement.SET_ASSIGNING_USER_LIST(data);
      }
    },
    async addRoleForCaseTeam(params: AssignRoleFields, state: RootState): Promise<boolean> {
      const caseId = state.caseManagement.selectedCase?.tid || "";
      return !!await teamsAndRolesApi.addRoleForCaseTeam(caseId, params).catch(handleError);
    },
    async assignRoleForUser(params: AssignUserRoleFields & {roleId: string}, state: RootState): Promise<boolean> {
      const caseId = state.caseManagement.selectedCase?.tid || "";
      return !!await teamsAndRolesApi.assignRoleForUser(caseId, params).catch(handleError);
    },
    async reAssignRoleForUser(params: AssignUserRoleFields & {roleAssignmentTid: string}, state: RootState)
    : Promise<boolean> {
      const caseId = state.caseManagement.selectedCase?.tid || "";
      return !!await teamsAndRolesApi.reAssignRoleForUser(caseId, params).catch(handleError);
    },
    async unAssignRoleFromUser(roleId: string, state: RootState): Promise<boolean> {
      const caseId = state.caseManagement.selectedCase?.tid || "";
      return !!await teamsAndRolesApi.unAssignRoleFromUser(caseId, roleId).catch(handleError);
    },
    async deleteRoleFromCase(roleId: string, state: RootState): Promise<boolean> {
      const caseId = state.caseManagement.selectedCase?.tid || "";
      return !!await teamsAndRolesApi.deleteRoleFromCase(caseId, roleId).catch(handleError);
    },
    async getUserAccessLevel(params: {
      userId?: string,
      feature?: CaseFeature
    }, state: RootState): Promise<void>{
      const caseId = state.caseManagement.selectedCase?.tid || "";

      if(!params.feature){
        dispatch.teamsAndRolesManagement.SET_USER_ACCESS_LEVEL(undefined);
        return;
      }

      const data = await teamsAndRolesApi.getUserAccessLevel(
        caseId, 
        params.userId ??(state.profile.loggedUser?.email || ""),
        params.feature
      );

      if(data){
        dispatch.teamsAndRolesManagement.SET_USER_ACCESS_LEVEL(data);
      }
    },
    async getRoleEvents(_: void, state: RootState): Promise<void>{
      const caseId = state.caseManagement.selectedCase?.tid || "";

      if(!state.teamsAndRolesManagement.selectedRole){
        return;
      }

      const data = await teamsAndRolesApi.getRoleEvents(
        caseId, 
        state.teamsAndRolesManagement.selectedRole?.roleAssignmentId
      );

      if(data){
        dispatch.teamsAndRolesManagement.SET_ROLE_EVENTS(data);
      }
    },
    async getRoleTasks(_: void, state: RootState): Promise<void>{
      const caseId = state.caseManagement.selectedCase?.tid || "";

      if(!state.teamsAndRolesManagement.selectedRole){
        return;
      }

      const data = await teamsAndRolesApi.getRoleTasks(
        caseId, 
        state.teamsAndRolesManagement.selectedRole?.roleAssignmentId
      );

      if(data){
        dispatch.teamsAndRolesManagement.SET_ROLE_TASKS(data);
      }
    },
    async getRoleChecklist(_: void, state: RootState): Promise<void>{
      const caseId = state.caseManagement.selectedCase?.tid || "";

      if(!state.teamsAndRolesManagement.selectedRole){
        return;
      }

      const data = await teamsAndRolesApi.getRoleChecklist(
        caseId, 
        state.teamsAndRolesManagement.selectedRole?.roleAssignmentId
      );

      if(data){
        dispatch.teamsAndRolesManagement.SET_ROLE_CHECKLISTS(data);
      }
    },
    async updateChecklist(checkedListItemStatusMap: Record<string, boolean>, state: RootState): Promise<boolean> {
      const caseId = state.caseManagement.selectedCase?.tid || "";
      const roleId = state.teamsAndRolesManagement.selectedRole?.roleAssignmentId || "";
      return !!await teamsAndRolesApi.updateChecklist(caseId, roleId, checkedListItemStatusMap)
        .catch(handleError);
    }
  })
};

export const teamsAndRolesManagement = createModel<RootModel>()(teamsAndRolesDataModel);
