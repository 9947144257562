import { logger } from "@/common/util/ConsoleLogger";
import { genericColorPalette } from "@/core/styles/mantine/palettes";

export function getHtmlContentAsStringForPDF(elementIds: string[], instanceSpecificCss?: string): string {
  const htmlContent = elementIds.map((id) => {
    const element = document.getElementById(id);
    if (!element) {
      throw new Error(`Element with id ${id} not found.`);
    }

    return element.outerHTML;
  }).join('');
  
  return getHTMLTemplateForPDF(htmlContent, instanceSpecificCss);
}

export const getHTMLTemplateForPDF = (htmlContent: string, instanceSpecificCss?: string) => {
  const htmlContentAsString = `
      <!DOCTYPE html>
      <html>
      <head>
        <meta charset="UTF-8">
        <title>PDF Export</title>
        <style>

          body {
            display: flex;
            flex-direction: column;
            gap: 24px;
            background-color: #fff !important;
          }

          * {
            color:rgb(47, 47, 47) !important;
          }

          ${instanceSpecificCss ?? ''}

          @page {
            size: A4;
            margin: 15mm;
            background-color: #fff !important;
            scale: 0.5; /* Scales the content to 80% of its original size */
            transform-origin: top left; /* Ensures scaling starts from the top-left corner */
          }
          
          @media print {
            .page-break {
              page-break-before: always;
            }

            body {
              height: auto !important;
              overflow: visible !important;
            }

            * {
              position: static !important;
            }

            html, body {
              height: auto;
              max-height: none;
              overflow: visible;
            }
          }

          ${getAllCssAsString()}
        </style>
      </head>
      <body style="overflow: auto !important;">
          ${htmlContent}
      </body>
      </html>
  `;
  
  return htmlContentAsString;
};

const getAllCssAsString = (isWithMediaQuery = false): string => {
  // Array to store CSS rules
  const cssRules: string[] = [];
  
  // Iterate through all stylesheet links in the document
  try {
    // Select all stylesheet links
    const stylesheets = document.styleSheets;
  
    Array.from(stylesheets).forEach((stylesheet) => {
      try {
        // Try to access the rules of the stylesheet
        const rules = stylesheet.cssRules;
                
        if (rules) {
          Array.from(rules).forEach((rule) => {
            if (rule instanceof CSSStyleRule) {
              cssRules.push(rule.cssText);
            } else if (isWithMediaQuery && (rule instanceof CSSMediaRule)) {
              cssRules.push(`@media ${rule.conditionText} { ${rule.cssText} }`);
            } else if (rule instanceof CSSKeyframesRule) {
              cssRules.push(rule.cssText);
            } else if (rule instanceof CSSFontFaceRule) {
              cssRules.push(rule.cssText);
            } else if (rule instanceof CSSSupportsRule) {
              cssRules.push(rule.cssText);
            }
          });
        }
      } catch (ruleAccessError) {
        logger.warn(`Could not access rules for stylesheet at ${stylesheet.href}`, ruleAccessError);
      }
    });
  
    const inlineStyleTags = document.getElementsByTagName('style');
    Array.from(inlineStyleTags).forEach((styleTag) => {
      cssRules.push(styleTag.innerHTML);
    });
  
  } catch (error) {
    logger.error('Error retrieving CSS stylesheets:', error);
    return '';
  }
  
  return cssRules.join('\n\n');
};

export const getRiskReportStyles = () => {
  const fontColor = genericColorPalette.neutral[6];
  const backgroundColor = genericColorPalette.white[0];
  const headerBackgroundColor = genericColorPalette.primary[0];

  return `
    #risk-report-header {
      background-color: ${headerBackgroundColor} !important;
      padding: 16px !important;
      border-radius: 5px !important;
      color: ${backgroundColor} !important; 

      * {
        color: ${backgroundColor} !important; 
      }
    }

    #risk-report-body {
      .restrata-SimpleGrid-root {
        svg {
          path {
            fill: ${backgroundColor} !important;
          }
        }

        * {
          color: ${backgroundColor} !important;
        }
      }

      .restrata-Stack-root {
        & > div {
          color: ${fontColor} !important;
          font-size: 14px !important;
        }
      }

      svg:not(.restrata-Rating-root svg, .restrata-SimpleGrid-root svg) {
        path {
          fill: ${fontColor} !important;
        }
      }

      p, li {
        color: ${fontColor} !important;
        font-size: 14px !important;

        span {
          color: ${fontColor} !important;
          font-size: 14px !important;
        }
      }

      margin: 0 !important;
      padding: 0 !important;
      padding-bottom: 150px !important;

      /* Add these styles for rating alignment */
      .restrata-Rating-root {
        display: inline-flex !important;
        align-items: flex-end !important;
        transform: translateY(-10px) !important;
      }
      
      .restrata-Rating-root svg {
        display: inline-block !important;
        vertical-align: middle !important;
      }

      /* Ensure consistent star sizing */
      .restrata-Rating-root svg {
        width: 20px !important;
        height: 20px !important;
      }
    }
  `;
};
