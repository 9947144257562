import { logger } from "@/common/util/ConsoleLogger";

import { PDFEndpointResponse } from "../types/pdf-report-generation";

const PDF_ENDPOINT_URL = 'https://api.pdfendpoint.com/v1/convert';
const PDF_ENDPOINT_TOKEN = process.env.REACT_APP_PDF_ENDPOINT_TOKEN;
const DEFAULT_FILE_NAME = 'report.pdf';

export const downloadPDFFromHTMLContent = async (htmlContent: string, fileName = DEFAULT_FILE_NAME): Promise<void> => {
  const pdfResponse = await generatePDFFromHtml(htmlContent, fileName);
  const pdfUrl = pdfResponse.data.url;
  await downloadPDF(pdfUrl, fileName);
};

const generatePDFFromHtml = async (html: string, fileName = DEFAULT_FILE_NAME): Promise<PDFEndpointResponse> => {
  if (!PDF_ENDPOINT_TOKEN) {
    throw new Error('PDF_ENDPOINT_TOKEN is not defined');
  }

  try {
    const response = await fetch(PDF_ENDPOINT_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${PDF_ENDPOINT_TOKEN}`
      },
      body: JSON.stringify({
        html: html,
        fileame: fileName,
        margin_top: "1cm",
        margin_bottom: "1cm",
        margin_right: "1cm",
        margin_left: "1cm",
        no_backgrounds: false
      })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();

  } catch (error) {
    logger.error('Error generating PDF:', error);
    throw error;
  }
};

const downloadPDF = async (url: string, fileName = DEFAULT_FILE_NAME): Promise<void> => {
  try {
    // Fetch the PDF
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    
    // Get the blob from the response
    const blob = await response.blob();
    
    // Create a blob URL
    const blobUrl = URL.createObjectURL(blob);
    
    // Create a download link
    const downloadLink = document.createElement('a');
    downloadLink.href = blobUrl;
    downloadLink.download = fileName;
    
    // Append to the document, click, and remove
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    
    // Revoke the blob URL
    URL.revokeObjectURL(blobUrl);
  } catch (error) {
    logger.error('Error downloading or opening PDF:', error);
    throw error;
  }
};