import { INDOORS_VISIBILITY_MIN_ZOOM } from "@f/map-gl-indoor/IndoorLayer";
import { bbox } from "@turf/turf";
import { Feature, GeoJsonProperties, Point } from "geojson";
import React, { FC, useCallback, useEffect } from "react";
import { useMap } from "react-map-gl";
import { useDispatch, useSelector } from "react-redux";

import useLayerListener from "@/common/hooks/useLayerListener";
import { Dispatch, RootState } from "@/core/store";
import { BigMapPopupIds, useMapPopupList } from "@/tenant-context/common/hooks/useMapPopupList";
import { getClickedOnFeatures, getClusteredFeatures } from "@/tenant-context/common/util/map-click";
import { SITE_INFO_CONTROL_DRAWER_ID } from "@/tenant-context/control-site/controls/SiteInfoControl/SiteInfoControl.component";
import useLocationsMarkerIcons from "@/tenant-context/visualisation-customer-locations/hooks/useLocationsMarkerIcons";
import { SiteLocationSnapshotProperties } from "@/tenant-context/visualisation-site/types/site.types";
import { WidgetSite } from "@/tenant-context/widget-overview/types/site.types";

import { CriticalLocationLayerConfig } from "../../config/CriticalLocationLayerConfig";
import CriticalLocationLayerComponent from "./CriticalLocationLayer.component";


const CriticalLocationLayer: FC = () => {
  const { openPopup, closePopup, getPopupData } = useMapPopupList('bigMapPopups');

  const { current: map } = useMap();

  const {
    drawer: {
      openRightDrawer
    },
    sitePopup: {
      SET_SITE_POPUP_CONTENT
    },
    site: {
      setSiteGeoJson,
      SET_GEO_JSON
    },
    commonData: {
      SET_SELECTED_SITE
    }
  } = useDispatch<Dispatch>();

  useLocationsMarkerIcons(map);

  const geoData = useSelector((state: RootState) => state.siteLocations.geoData);
  const siteGeoJson = useSelector((state: RootState) => state.site?.geoJson);

  // Site layer click handler
  useLayerListener(
    'click',
    [
      ...CriticalLocationLayerConfig.map(layer => layer.id)
    ],
    useCallback(
      (evt) => {
        const { layerFeatures } = getClickedOnFeatures(
          evt,
          [
            ...CriticalLocationLayerConfig.map(layer => layer.id)
          ]
        );
  
        if (!layerFeatures.length) {
          return;
        }
        const feature = layerFeatures[0];
        if (feature?.properties?.id) {
          setSiteGeoJson(feature?.properties?.id);
        }
        const { coordinates } = (feature.geometry as Point);

        const selectedSite: WidgetSite = {
          name: feature.properties?.name ?? '',
          location: feature.properties?.countryName ?? '',
          lngLat: [coordinates[0], coordinates[1]],
          id: feature.properties?.id ?? '',
          code: feature.properties?.code ?? '',
          countryName: feature.properties?.countryName ?? '',
          address: typeof feature?.properties?.address === 'string' ? JSON.parse(feature?.properties?.address) : feature?.properties?.address ?? "",
          description: feature.properties?.description ?? '',
          isoCountryCode: feature.properties?.isoCountryCode ?? '',
          imageUrl: feature.properties?.imageUrl ?? '',
          locationType: feature.properties?.locationType ?? '',
          subCategory: feature.properties?.subCategory
        };
  
        SET_SELECTED_SITE(selectedSite);
  
        SET_SITE_POPUP_CONTENT(feature.properties as SiteLocationSnapshotProperties);

        openPopup({
          id: `${BigMapPopupIds.LocationMarker}/${feature.properties?.id}`,
          position: coordinates,
          data: feature
        });
      },
      [SET_SELECTED_SITE, SET_SITE_POPUP_CONTENT, openPopup, setSiteGeoJson]
    )
  );

  // Cluster layer click handler
  useLayerListener(
    'click',
    'r__criticalLocationLayer-cluster',
    useCallback(
      (evt) => {
        if (!map || !geoData) {
          return;
        }

        const features = getClusteredFeatures(map, evt, geoData, ['r__criticalLocationLayer-cluster']);

        const feature = features[0];

        const { coordinates } = (feature.geometry as Point);
        openPopup({
          id: BigMapPopupIds.LocationCluster,
          position: coordinates,
          data: features
        });
      },

      [geoData, map, openPopup]
    )
  );

  useEffect(() => {
    if (siteGeoJson) {
      const bbox_ = bbox(siteGeoJson) as [number, number, number, number];
      map?.fitBounds(bbox_, { padding: 200 });
      openRightDrawer(SITE_INFO_CONTROL_DRAWER_ID);
    }
  }, [siteGeoJson, map, openRightDrawer]);

  //prevents map from zooming in when navigated from another page
  useEffect(()=>{
    return () => {
      SET_GEO_JSON(undefined);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Cluster layer click handler
  useLayerListener(
    'click',
    'siteLocationSnapshotLayer-cluster',
    useCallback((evt) => {
      if (!map || !geoData) {
        return;
      }

      const features = getClusteredFeatures(map, evt, geoData, ['siteLocationSnapshotLayer-cluster']);

      const feature = features[0];
      const { coordinates } = (feature.geometry as Point);
      openPopup({
        id: BigMapPopupIds.LocationCluster,
        position: coordinates,
        data: features
      });
    }, [map, geoData, openPopup])
  );

  const handleClosePopup = useCallback(
    () => {
      closePopup(BigMapPopupIds.LocationCluster);
    },
    [closePopup]
  );

  const handleLocationHover = useCallback(
    () => {
      if(map){
        map.getCanvas().style.cursor = 'pointer';
      }
    },
    [map]
  );

  const handleClickLocationInCluster = useCallback((feature: Feature<Point, GeoJsonProperties>) => {
    const { coordinates } = (feature.geometry as Point);

    const selectedSite: WidgetSite = {
      name: feature.properties?.name ?? '',
      location: feature.properties?.countryName ?? '',
      lngLat: [coordinates[0], coordinates[1]],
      id: feature.properties?.id ?? '',
      code: feature.properties?.code ?? '',
      countryName: feature.properties?.countryName ?? '',
      address: typeof feature?.properties?.address === 'string' ? JSON.parse(feature?.properties?.address) : feature?.properties?.address ?? "",
      description: feature.properties?.description ?? '',
      isoCountryCode: feature.properties?.isoCountryCode ?? '',
      imageUrl: feature.properties?.imageUrl ?? '',
      locationType: feature.properties?.locationType ?? '',
      subCategory: feature.properties?.subCategory
    };

    SET_SELECTED_SITE(selectedSite);

    SET_SITE_POPUP_CONTENT(feature.properties as SiteLocationSnapshotProperties);

    closePopup(BigMapPopupIds.LocationCluster);
    openPopup({
      id: `${BigMapPopupIds.LocationMarker}/${feature.properties?.id}`,
      position: coordinates,
      data: feature
    });
  }, [SET_SELECTED_SITE, SET_SITE_POPUP_CONTENT, closePopup, openPopup]);

  useLayerListener(
    "mouseenter",
    [
      ...CriticalLocationLayerConfig.map((layer) => layer.id),
      "r__criticalLocationLayer-cluster",
      "siteLocationSnapshotLayer-cluster"
    ],
    handleLocationHover
  );

  const handleLocationLeave = useCallback(() => {
    if (map) {
      map.getCanvas().style.cursor = "default";
    }
  }, [map]);

  const handleZoomTo = useCallback<React.MouseEventHandler<HTMLButtonElement>>((ev) => {
    const popupId = ev.currentTarget.getAttribute('data-popup-id');
    if (!popupId) {
      return;
    }
    const feature = getPopupData<Feature<Point, GeoJsonProperties>>(popupId);
    if (!feature || !map) {
      return;
    }
    const [lng, lat] = feature.geometry.coordinates;
    map?.flyTo({
      center: [lng, lat],
      zoom: INDOORS_VISIBILITY_MIN_ZOOM
    });
  }, [getPopupData, map]);

  const handleClickMoreDetails = useCallback<React.MouseEventHandler<HTMLButtonElement>>((ev) => {
    const popupId = ev.currentTarget.getAttribute('data-popup-id');
    if (!popupId) {
      return;
    }
    
    const feature = getPopupData<Feature<Point, GeoJsonProperties>>(popupId);

    if (!feature) {
      return;
    } 
    
    const { coordinates } = (feature.geometry as Point);

    const selectedSite: WidgetSite = {
      name: feature.properties?.name ?? '',
      location: feature.properties?.countryName ?? '',
      lngLat: [coordinates[0], coordinates[1]],
      id: feature.properties?.id ?? '',
      code: feature.properties?.code ?? '',
      countryName: feature.properties?.countryName ?? '',
      address: typeof feature?.properties?.address === 'string' ? JSON.parse(feature?.properties?.address) : "",
      description: feature.properties?.description ?? '',
      isoCountryCode: feature.properties?.isoCountryCode ?? '',
      imageUrl: feature.properties?.imageUrl ?? '',
      locationType: feature.properties?.locationType ?? '',
      subCategory: feature.properties?.subCategory
    };

    SET_SELECTED_SITE(selectedSite);
    setSiteGeoJson(feature?.properties?.id);
    openRightDrawer(SITE_INFO_CONTROL_DRAWER_ID);
  }, [SET_SELECTED_SITE, getPopupData, openRightDrawer, setSiteGeoJson]);

  useLayerListener(
    "mouseleave",
    [
      ...CriticalLocationLayerConfig.map((layer) => layer.id),
      "r__criticalLocationLayer-cluster",
      "siteLocationSnapshotLayer-cluster"
    ],
    handleLocationLeave
  );

  return (
    <CriticalLocationLayerComponent
      geoData={ geoData }
      siteGeoJson={ siteGeoJson }
      onClickClosePopup={ handleClosePopup }
      onClickLocationItem={ handleClickLocationInCluster }
      onClickZoomTo={ handleZoomTo }
      onClickMoreDetails={ handleClickMoreDetails }
    />
  );
};

export default CriticalLocationLayer;
