import { useCallback } from "react";
import { FC } from "react";
import { useDispatch } from "react-redux";

import ButtonControl from "@/common/components/ButtonControl";
import DrawerControl from "@/common/components/DrawerControl";
import { DrawerControlButtonRenderer } from "@/common/components/DrawerControl/DrawerControl.component";
import { ReactComponent as SearchIcon } from '@/common/icons/search.svg';
import { TestableComponent } from "@/common/types/testable-component";
import { Dispatch } from "@/core/store";
import GlobalSearch from "@/tenant-context/control-search/components/GlobalSearch";

const GlobalSearchControl: FC<TestableComponent> = ({ dataTestId = "right-menu-global-search" }) => {
  const {
    search: {
      SET_SEARCH_RESULTS_VISIBILITY,
      CLEAR_SEARCH_RESULTS
    }
  } = useDispatch<Dispatch>();

  const renderButton: DrawerControlButtonRenderer = useCallback(({ onClick }) => (
    <ButtonControl
      onClick={ onClick }
      label='Search'
      data-testid={ dataTestId }
    >
      <SearchIcon />
    </ButtonControl>
  ), [dataTestId]);

  const handleCloseClick = useCallback(() => {
    SET_SEARCH_RESULTS_VISIBILITY(false);
    CLEAR_SEARCH_RESULTS();
  }, [CLEAR_SEARCH_RESULTS, SET_SEARCH_RESULTS_VISIBILITY]);

  return (
    <DrawerControl
      id='global-search-control'
      title='Search'
      renderButton={ renderButton }
      onCloseClick={ handleCloseClick }
    >
      <GlobalSearch />
    </DrawerControl>
  );
};

export default GlobalSearchControl;
