import { createStyles } from "@mantine/core";

// Overriding the default styles of mantine's SegmentedControl
export const useSegmentedControlStyles = createStyles((theme) => ({
  root: {
    backgroundColor: theme.colors.royalBlue[3]
  },
  active: {
    backgroundColor: theme.colors.royalBlue[3]
  },
  label: {
    backgroundColor: 'transparent',
    color: theme.white
  },
  labelActive: {
    color: `${theme.black} !important`,
    backgroundColor: theme.white
  }
}));

export const useButtonAsLinkStyles = createStyles(() => ({
  root: {
    width: 'max-content',
    height: '24px'
  }
}));
