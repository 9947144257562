/* eslint-disable no-magic-numbers, react/jsx-props-no-spreading */
import { Group } from "@visx/group";
import { Pie } from "@visx/shape";
import { FC, useCallback, useMemo } from "react";

import { genericColorPalette } from "@/core/styles/mantine/palettes";
import AnimatedPie
  from "@/tenant-context/control-action-response-center/components/ImpactSummary/ImpactedSummaryPieChart/AnimatedPie.component";
import {
  ArcImpactStatus,
  ArcPeopleImpactItem,
  ArcPeopleImpactSummary } from "@/tenant-context/control-action-response-center/types/ARC.types";
import { getRiskColorByRiskLevel } from "@/tenant-context/control-reports/util/country-risk-reports";

import { useMcArcPieChartStyles } from "./McArcPieChart.styles";

type Props = {
  summaryData: ArcPeopleImpactSummary;
  width: number;
  height: number;
  isAnimated?: boolean;
}

const McArcPieChart: FC<Props> = ({
  summaryData,
  width,
  height,
  isAnimated = true
}) => {
  const { classes } = useMcArcPieChartStyles();

  const totalCount = useMemo(
    () => summaryData.reduce((acc, { count }) => acc + count, 0),
    [ summaryData ]
  );

  // color scales
  const getStatusColor = useCallback((status: ArcImpactStatus) => {
    const statusColorMap: Partial<Record<ArcImpactStatus, string>> = {
      SAFE: genericColorPalette.success[2],
      NO_RESPONSE: getRiskColorByRiskLevel('medium'),
      NOT_SAFE: getRiskColorByRiskLevel('extreme'),
      OTHER: 'grey'
    };
    
    return statusColorMap[status];
  }, []);

  // accessor functions
  const getImpactCount = useCallback((d: ArcPeopleImpactItem) => d.count, []);
  const getImpactStatus = useCallback((arc) => arc.data.impactStatus, []);
  const getColor = useCallback((arc): string => getStatusColor(arc.data.impactStatus) as string, [ getStatusColor ]);

  if (width < 10) {
    return null;
  }

  const centerX = width / 2; 
  const centerY = height / 2;
  const maxRadius = Math.min(width, height) / 2;
  const outerRadius = maxRadius;
  const innerRadius = maxRadius * 0.8;

  return (
    <svg width={ width } height={ height }>
      <Group top={ centerY } left={ centerX }>
        <Pie
          data={ summaryData }
          pieValue={ getImpactCount }
          outerRadius={ outerRadius }
          innerRadius={ innerRadius }
          cornerRadius={ 0 }
          padAngle={ 0.005 }
        >
          { (pie) => (
            <>
              { /*Pie Arcs*/ }
              <AnimatedPie
                { ...pie }
                animate={ isAnimated }
                getKey={ getImpactStatus }
                getColor={ getColor }
              />

              { /*Count text*/ }
              <text
                y="2"
                textAnchor={ "middle" }
                className={ classes.count }
              >
                { totalCount }
              </text>

              { /*People text*/ }
              <text
                x="-16"
                y="18"
                className={ classes.peopleText }
              >
                People
              </text>
            </>
          ) }
        </Pie>
      </Group>
    </svg>
  );

};

export default McArcPieChart;
