import { Box, Button, CopyButton, Flex, Menu } from "@mantine/core";
import { FC, useCallback, useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { openConfirmationModal } from "@/common/components/Modal/ConfirmationModal";
import usePermission from "@/common/hooks/usePermission";
import { ReactComponent as IconChevronDown } from '@/common/icons/chevron-down-solid-white.svg';
import { ReactComponent as IconChevronUp } from '@/common/icons/chevron-up-solid-white.svg';
import { RouterConfig } from "@/core/components/Router/Router.config";
import { Dispatch, RootState, store } from "@/core/store";
import { useArcHeaderStyles } from "@/tenant-context/control-action-response-center/components/ArcHeader/ArcHeader.style";
import { ArcContext } from "@/tenant-context/control-action-response-center/context/ARC/ARC.context";
import LiveFeedButton from '@/tenant-context/control-live-feed/components/LiveFeedButton';
import { MassCommsPoliciesConfig } from "@/tenant-context/control-mass-comms/config/mass-comms.policies";
import { massCommsDefaultState } from "@/tenant-context/control-mass-comms/store/MassComms.model";

import { ArcCasePoliciesConfig } from "../../config/arc-case.policies";
import ArcHeaderTabs from "./ArcHeaderTabs/ArcHeaderTabs.component";

const ArcHeader: FC = () => {
  const { classes } = useArcHeaderStyles();
  const { routes } = RouterConfig;
  const { arcCase, isArcOpenInDetachedView, handleCloseArcCase } = useContext(ArcContext);
  const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false);

  const isMcArcInvocationAvailable = usePermission(MassCommsPoliciesConfig.MC_ARC_INVOKE);
  const isAuthorizedForArc = usePermission(ArcCasePoliciesConfig.ARC_CASE_BY_ID);


  const tenantId = store.getState().commonData?.tenantId;
  const url = `${window.location.protocol}//${window.location.host}${routes.arc(arcCase?.caseId)}?tenantId=${tenantId}`;
  const {
    massCommsNavigation
  } = useSelector((state: RootState) => state.massComms);

  const openArcInDetachedMode = useCallback(() => {
    window.open(url, '_blank');
  }, [url]);

  const {
    massComms: {
      navigateToMassComms,
      RESET_MASS_COMMS_TO_DEFAULT_STATE
    },
    arc: {
      SET_IS_ARC_OPEN
    }
  } = useDispatch<Dispatch>();

  const openMassComms = useCallback(() => {
    RESET_MASS_COMMS_TO_DEFAULT_STATE(massCommsDefaultState);
    SET_IS_ARC_OPEN(false);
    navigateToMassComms({ ...massCommsNavigation, isOpen: true, fromArc: true });
  }, [ RESET_MASS_COMMS_TO_DEFAULT_STATE, SET_IS_ARC_OPEN, navigateToMassComms, massCommsNavigation ]);

  const openCloseArcPrompt = useCallback(() => {
    openConfirmationModal({
      text: "Are you sure you want to close this case? Closing the case will change the case status to closed and remove the risk alert from the global map.",
      title: "Confirm Case Close",
      onConfirm: handleCloseArcCase,
      closeOnClickOutside: false
    });
  }, [handleCloseArcCase]);

  return (
    <Box className={ classes.headerContainer }>
      <Flex direction={ 'row' } className={ classes.header }>
        <Flex align={ 'flex-end' }>
          <ArcHeaderTabs />
        </Flex>
        <Box className={ classes.rightContainer }>
          <Flex gap={ 20 } direction={ 'row' } align={ 'center' }>
            <Menu opened={ isMenuOpened } onChange={ setIsMenuOpened } position="bottom-end" withinPortal>
              <Menu.Target>
                <Button
                  className={ classes.menuTitle }
                  size="lg"
                  rightIcon={ isMenuOpened ? <IconChevronUp /> : <IconChevronDown /> }
                >
                  Actions
                </Button>
              </Menu.Target>
              <Menu.Dropdown className={ classes.menuDropdown }>
                <Menu.Item
                  onClick={ openMassComms }
                  className={ classes.menuControlItem }
                  disabled={ !isMcArcInvocationAvailable }
                >
                  Send Communication
                </Menu.Item>
                { !isArcOpenInDetachedView && (
                  <Menu.Item
                    onClick={ openArcInDetachedMode }
                    className={ classes.menuControlItem }
                  >
                    Open in new window
                  </Menu.Item> ) }
                <CopyButton value={ url }>
                  { ({ copy }) => (
                    <Menu.Item
                      onClick={ copy }
                      className={ classes.menuControlItem }
                    >
                      Share
                    </Menu.Item>
                  ) }
                </CopyButton>
                <Menu.Item
                  className={ classes.menuControlItem }
                  disabled={ !isAuthorizedForArc || arcCase?.status === 'CLOSED' }
                  onClick={ openCloseArcPrompt }>
                  Close Case
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
            <LiveFeedButton/>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};

export default ArcHeader;
