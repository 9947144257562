import { Models, Plugin } from "@rematch/core";
import { init, RematchDispatch, RematchRootState } from "@rematch/core";
import loadingPlugin, { ExtraModelsFromLoading } from "@rematch/loading";
import persistPlugin from "@rematch/persist";
import storage from "redux-persist/lib/storage";

import { dataOptions } from "@/core/store/data-options/data-options.model";
import {
  drawer
} from '@/core/store/drawer/drawer.model';
import { caseTypes } from "@/platform-context/control-icm-management/store/caseTypes.model";
import { icmEvents } from "@/platform-context/control-icm-management/store/icmEvents.model";
import { playbooks } from "@/platform-context/control-icm-management/store/playbook.model";
import { severities } from "@/platform-context/control-icm-management/store/severity.model";
import { situationTypes } from "@/platform-context/control-icm-management/store/situationTypes.model";
import { siteConfiguration } from "@/platform-context/control-site-config-management/store/SiteConfiguration.model";
import { serviceConfiguration } from "@/platform-context/control-system-admin/store/serviceConfiguration.model";
import {
  assetLocations
} from "@/tenant-context/asset-location-snapshots/store/asset-location/asset-location.model";
import { arc } from "@/tenant-context/control-action-response-center/store/Arc.model";
import {
  connectedSitesDashboard
} from "@/tenant-context/control-connected-sites-dashboard/store/connectedSitesDashboard.model";
import { geofence } from "@/tenant-context/control-draw/store/geofence.model";
import { groups } from "@/tenant-context/control-groups/store/groups/groups.model";
import { assetsManagement } from "@/tenant-context/control-icm-management/store/assetsManagement.model";
import { briefings } from "@/tenant-context/control-icm-management/store/briefings.model";
import { caseManagement } from "@/tenant-context/control-icm-management/store/caseManagement.model";
import { casePeopleManagement } from "@/tenant-context/control-icm-management/store/casePeopleManagement.model";
import { task } from "@/tenant-context/control-icm-management/store/task.model";
import { teamsAndRolesManagement } from "@/tenant-context/control-icm-management/store/teamsAndRolesManagement.model";
import { integrationMapping } from "@/tenant-context/control-integrations-mapping/store/IntegrationMapping.model";
import { liveFeed } from "@/tenant-context/control-live-feed/store/live-feed.model";
import { manageLocations } from "@/tenant-context/control-location-configuration/store/ManageLocations.model";
import { locationGraph } from "@/tenant-context/control-location-graph/store/location-graph/location-graph.model";
import { lookupTool } from '@/tenant-context/control-lookup-tool/store/LookupTool.model';
import { massComms } from "@/tenant-context/control-mass-comms/store/MassComms.model";
import { massCommsReport } from "@/tenant-context/control-mass-comms/store/MassCommsReport.model";
import { profile } from "@/tenant-context/control-profile/store/profile/profile.model";
import { userProfile } from "@/tenant-context/control-profile/store/user-profile.model";
import { rankingSettings } from "@/tenant-context/control-ranking-settings/store/control-ranking-settings/control-ranking-settings.model";
import { cityRiskReports } from '@/tenant-context/control-reports/store/city-risk-reports';
import { countryRiskReports } from '@/tenant-context/control-reports/store/country-risk-reports';
import { eventTimeLine } from "@/tenant-context/control-risk-config/store/event-timeline.model";
import { riskTimeline } from "@/tenant-context/control-risk-timeline/store/risk-timeline.model";
import {
  autocomplete
} from '@/tenant-context/control-search/store/autocomplete/autocomplete.model';
import {
  search
} from '@/tenant-context/control-search/store/search/search.model';
import { site } from "@/tenant-context/control-site/store/site/site.model";
import { tableauDashboard } from '@/tenant-context/control-tableau/store/tableau-dashboard.model';
import { tenantAccessCard } from "@/tenant-context/control-tenant-admin/store/tenantAccessCard.model";
import { travel } from "@/tenant-context/control-travel/store/travel-itinerary";
import { travellersSearch } from "@/tenant-context/control-travellers-search/store/TravellersSearch.model";
import { visitorManagement } from "@/tenant-context/control-visitor-management/store/visitorManagement.model";
import {
  bigMap
} from "@/tenant-context/core/store/big-map/big-map.model";
import { commonData } from "@/tenant-context/core/store/common-data/common-data.model";
import {
  mapPopup
} from "@/tenant-context/core/store/map-popup/map-popup.model";
import { mapSearch } from "@/tenant-context/core/store/map-search/map-search.modal";
import { globalPeople } from "@/tenant-context/core/store/people/global-people.model";
import { reverseGeocoding } from "@/tenant-context/core/store/reverse-geocoding/reverse-geocoding.model";
import { riskEventFilterDataOptions } from '@/tenant-context/core/store/risk-events/risk-events.model';
import { eaArc } from '@/tenant-context/employee-app-action-response-center/store/eaArc.model';
import { playbook } from "@/tenant-context/employee-app-action-response-center/store/playbook.model";
import { indoor } from '@/tenant-context/plugin-indoors/store/indoor/indoor.model';
import { commonLocations } from "@/tenant-context/visualisation-common-locations/store/common-locations.model";
import { customerLocations } from "@/tenant-context/visualisation-customer-locations/store/customer-locations.model";
import { peopleBreadcrumbs } from "@/tenant-context/visualisation-people/store/people-breadcrumbs/people-breadcrumbs.model";
import {
  peopleLocations
} from "@/tenant-context/visualisation-people/store/people-locations/people-locations.model";
import { riskAlerts } from "@/tenant-context/visualisation-risk-alerts/store/risk-alerts/risk-alerts.model";
import { riskDetails } from "@/tenant-context/visualisation-risk-details/store/risk-impact/risk-details.model";
import { siteLocations } from "@/tenant-context/visualisation-site/store/site-locations/site-locations.model";
import { sitePopup } from "@/tenant-context/visualisation-site/store/site-locations/site-popup.model";
import { countryTravel } from "@/tenant-context/visualisation-travel/store/country-travel/country-travel.model";
import { countryRiskLevels } from "@/tenant-context/visualize-country-risk/store/country-risk-level/country-risk-level.model";

import { sharedData } from "./shared-data/shared-data.model";

export interface RootModel extends Models<RootModel> {
  bigMap: typeof bigMap,
  mapPopup: typeof mapPopup,
  assetLocations: typeof assetLocations,
  drawer: typeof drawer,
  search: typeof search,
  autocomplete: typeof autocomplete,
  indoor: typeof indoor,
  peopleLocations: typeof peopleLocations,
  dataOptions: typeof dataOptions,
  riskEventFilterDataOptions: typeof riskEventFilterDataOptions,
  siteLocations: typeof siteLocations,
  peopleBreadcrumbs: typeof peopleBreadcrumbs,
  commonData: typeof commonData,
  customerLocations: typeof customerLocations,
  rankingSettings: typeof rankingSettings,
  locationGraph: typeof locationGraph,
  riskAlerts: typeof riskAlerts,
  commonLocations: typeof commonLocations,
  countryRiskLevels: typeof countryRiskLevels,
  riskDetails: typeof riskDetails,
  riskTimeline: typeof riskTimeline,
  arc: typeof arc,
  massComms: typeof massComms,
  massCommsReport: typeof massCommsReport,
  profile: typeof profile,
  groups: typeof groups,
  travel: typeof travel,
  userProfile: typeof userProfile,
  eaArc: typeof eaArc,
  geofence: typeof geofence,
  playbook: typeof playbook,
  sitePopup: typeof sitePopup,
  site: typeof site,
  globalPeople: typeof globalPeople,
  mapSearch: typeof mapSearch,
  sharedData: typeof sharedData
  situationTypes: typeof situationTypes,
  severities: typeof severities
  icmEvents: typeof icmEvents,
  serviceConfiguration: typeof serviceConfiguration,
  tenantAccessCard: typeof tenantAccessCard
  manageLocations: typeof manageLocations,
  visitorManagement: typeof visitorManagement
  liveFeed: typeof liveFeed,
  lookupTool: typeof lookupTool,
  countryTravel: typeof countryTravel,
  reverseGeocoding: typeof reverseGeocoding,
  travellersSearch: typeof travellersSearch,
  siteConfiguration: typeof siteConfiguration
  connectedSitesDashboard: typeof connectedSitesDashboard
  eventTimeLine: typeof eventTimeLine
  tableauDashboard: typeof tableauDashboard
  countryRiskReports: typeof countryRiskReports
  cityRiskReports: typeof cityRiskReports
  integrationMapping: typeof integrationMapping
  caseTypes: typeof caseTypes
  assetsManagement: typeof assetsManagement
  caseManagement: typeof caseManagement
  task: typeof task
  playbooks: typeof playbooks,
  teamsAndRolesManagement: typeof teamsAndRolesManagement
  briefings: typeof briefings,
  casePeopleManagement: typeof casePeopleManagement
}

export const models: RootModel = {
  bigMap,
  mapPopup,
  assetLocations,
  drawer,
  search,
  autocomplete,
  indoor,
  peopleLocations,
  dataOptions,
  siteLocations,
  peopleBreadcrumbs,
  commonData,
  customerLocations,
  commonLocations,
  rankingSettings,
  locationGraph,
  riskAlerts,
  riskEventFilterDataOptions,
  countryRiskLevels,
  riskDetails,
  riskTimeline,
  arc,
  massComms,
  massCommsReport,
  profile,
  groups,
  playbook,
  travel,
  // TODO deprecate
  userProfile,
  eaArc,
  geofence,
  sitePopup,
  site,
  globalPeople,
  mapSearch,
  sharedData,
  situationTypes,
  icmEvents,
  severities,
  serviceConfiguration,
  tenantAccessCard,
  manageLocations,
  visitorManagement,
  liveFeed,
  lookupTool,
  countryTravel,
  reverseGeocoding,
  travellersSearch,
  siteConfiguration,
  connectedSitesDashboard,
  eventTimeLine,
  tableauDashboard,
  countryRiskReports: countryRiskReports,
  cityRiskReports,
  integrationMapping,
  caseTypes,
  assetsManagement,
  caseManagement,
  playbooks,
  teamsAndRolesManagement,
  task,
  briefings,
  casePeopleManagement
};

type  FullModel = ExtraModelsFromLoading<RootModel>;

export const store = init<RootModel, FullModel>({
  plugins: [
    loadingPlugin(),
    persistPlugin({
      key: 'root',
      storage,
      whitelist: ['autocomplete']
    }) as Plugin<RootModel, FullModel>
  ],

  models,

  redux: {
    devtoolOptions: {
      trace: true
    }
  }
});

export type Store = typeof store;
export type Dispatch = RematchDispatch<RootModel>;
export type RootState = RematchRootState<RootModel>;
export type RootStateWithLoading = RematchRootState<RootModel, FullModel>;
