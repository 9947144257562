import { Button, Flex, Stack } from '@mantine/core';
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';

import { HEALIX_RISK_CATEGORIES } from '@/core/config/RiskProviders.config';
import { Dispatch, RootState } from '@/core/store';
import { semanticColorPalette } from '@/core/styles/mantine/palettes';
import { RiskProviders } from "@/tenant-context/common/types/risk";

import { ProviderCategory } from "../../types/risk";
import { useDisabledRiskFilterTypes, useToggleCategoryFilter } from './hooks';
import ProviderEvents from "./ProviderEvents.component";

const HealixEventsContainer: FC = () => {
  const [legendVisible, setLegendVisible] = useState(false);
  const riskAlertsGeoData = useSelector((state: RootState) => state.riskAlerts.geoData);
  const {
    riskEventFilterDataOptions: {
      SET_HEALIX_CATEGORIES
    }
  } = useDispatch<Dispatch>();

  const legendItems = useMemo(() => [
    { color: semanticColorPalette.risk.extreme, label: "Extreme", level: 5 },
    { color: semanticColorPalette.risk.high, label: "High", level: 4 },
    { color: semanticColorPalette.risk.medium, label: "Medium", level: 3 },
    { color: semanticColorPalette.risk.low, label: "Low", level: 1 }
  ], []);

  const toggleLegendVisibility = useCallback((): void => {
    setLegendVisible((visible) => !visible);
  }, []);

  const items = useMemo<Array<ProviderCategory>>(
    () =>
      HEALIX_RISK_CATEGORIES.map((category) => ({
        ...category,
        categoryId: category.id
      })),
    []
  );

  const extractedRiskCategories = useMemo(() => {
    const healixAlerts = riskAlertsGeoData.features.filter((alert) => alert.properties.source === RiskProviders.healix);
    const categories = healixAlerts.map((alert) => alert.properties.json.alert.category.name);
    const distinctCategories = Array.from(new Set(categories));
    return distinctCategories.map((category) => ({
      name: category,
      id: `${ RiskProviders.healix }/${ category }`,
      categoryId: `${ RiskProviders.healix }/${ category }`
    }));
  }, [riskAlertsGeoData]);

  useEffect(() => {
    SET_HEALIX_CATEGORIES(extractedRiskCategories);
  }, [extractedRiskCategories, SET_HEALIX_CATEGORIES]);

  const disabledRiskFilterTypes = useDisabledRiskFilterTypes();

  const handleSwitchToggle = useToggleCategoryFilter(RiskProviders.healix);

  return (
    <Stack mt={ 16 } ml={ 32 + 36 }>
      { legendVisible &&  <Stack>
        { legendItems.map(({ color, label, level }) => (
          <Flex key={ level } gap={ 8 } display="inline-flex" align="center">
            <div
              style={ {
                backgroundColor: color,
                width: 24,
                height: 24,
                borderRadius: "50%",
                display: "inline-block"
              } }
            />
            <span>{ label }</span>
          </Flex>
        )) }
      </Stack> }
      <Button
        variant="subtle"
        compact
        onClick={ toggleLegendVisibility }
      >
        { legendVisible ? "Hide" : "Show" } legend
      </Button>
      <ProviderEvents
        items={ extractedRiskCategories || items }
        onCategoryToggle={ handleSwitchToggle }
        disabledRiskFilterTypes={ disabledRiskFilterTypes }
      />
    </Stack>
  );
};

export default HealixEventsContainer;
