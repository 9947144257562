import { createModel } from "@rematch/core";

import { GridParams } from "@/common/types/ag-grid";
import { PaginatedResult, ReferenceData } from "@/common/types/reference-data";
import { handleError, handleSuccess } from "@/common/util/common-functions";
import { Dispatch, RootModel, RootState } from "@/core/store";
import { briefingsApi } from "@/tenant-context/control-icm-management/api/briefings";
import { teamsAndRolesApi } from "@/tenant-context/control-icm-management/api/teamsAndRoles";
import {
  Briefing, BriefingEditData,
  CreateBriefingData, DeleteBriefingParam, UpdateBriefingData
} from "@/tenant-context/control-icm-management/types/briefings";
import { CaseFeature, TeamList } from "@/tenant-context/control-icm-management/types/caseManagement";

type BriefingsState = {
  isBriefingLoading: boolean
  statusData: PaginatedResult<ReferenceData>
  reminderData: PaginatedResult<ReferenceData>
  teamListData: PaginatedResult<TeamList>
  briefingsListData: PaginatedResult<Briefing>
  nextBriefing?: Briefing
  currentBriefing?: BriefingEditData
}

const initialState: BriefingsState = {
  isBriefingLoading: false,
  statusData: {
    totalItems: 0,
    totalPages: 0,
    currentPage: 1,
    items: []
  },
  reminderData: {
    totalItems: 0,
    totalPages: 0,
    currentPage: 1,
    items: []
  },
  teamListData: {
    totalItems: 0,
    totalPages: 0,
    currentPage: 1,
    items: []
  },
  briefingsListData: {
    totalItems: 0,
    totalPages: 0,
    currentPage: 1,
    items: []
  }
};

const briefingsDataModel = {
  name: 'briefings',
  state: initialState as BriefingsState,
  reducers: {
    SET_IS_BRIEFING_LOADING: (state: BriefingsState, isBriefingLoading: BriefingsState['isBriefingLoading']) => ({
      ...state,
      isBriefingLoading
    }),
    SET_STATUS_DATA: (state: BriefingsState, statusData: BriefingsState['statusData']) => ({
      ...state,
      statusData
    }),
    SET_REMINDER_DATA: (state: BriefingsState, reminderData: BriefingsState['reminderData']) => ({
      ...state,
      reminderData
    }),
    SET_TEAM_LIST_DATA: (state: BriefingsState, teamListData: BriefingsState['teamListData']) => ({
      ...state,
      teamListData
    }),
    SET_BRIEFINGS_LIST_DATA: (state: BriefingsState, briefingsListData: BriefingsState['briefingsListData']) => ({
      ...state,
      briefingsListData
    }),
    SET_NEXT_BRIEFING: (state: BriefingsState, nextBriefing: BriefingsState['nextBriefing']) => ({
      ...state,
      nextBriefing
    }),
    SET_CURRENT_BRIEFING: (state: BriefingsState, currentBriefing: BriefingsState['currentBriefing']) => ({
      ...state,
      currentBriefing
    })
  },
  effects: (dispatch: Dispatch) => ({
    async fetchBriefingsReferenceData(caseId: string, state: RootState): Promise<void>{
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const promises: Promise<any>[] = [];

      const tenantUserId = state.userProfile?.email;

      if (!caseId || !tenantUserId) {
        return; 
      }

      dispatch.briefings.SET_IS_BRIEFING_LOADING(true);

      promises.push(briefingsApi.getICMTaskStatusData());
      promises.push(briefingsApi.getICMTBriefingsReminderStatusData());
      promises.push(teamsAndRolesApi.getTeamList(caseId, tenantUserId, CaseFeature.BRIEFINGS, 0, 99999));

      const results = await Promise.all(promises).catch(handleError)
        .finally(() => dispatch.briefings.SET_IS_BRIEFING_LOADING(true));

      if(results){
        const [statusData, reminderData, teamList] = results;
        dispatch.briefings.SET_STATUS_DATA(statusData);
        dispatch.briefings.SET_REMINDER_DATA(reminderData);
        dispatch.briefings.SET_TEAM_LIST_DATA(teamList);
      }
    },
    async getBriefingData(payload: {
      caseId: string,
      briefingId: string
    }, _state: RootState): Promise<BriefingEditData | boolean> {

      if (!payload.caseId || !payload?.briefingId) {
        return false;
      }

      const { caseId,briefingId } = payload;

      dispatch.briefings.SET_IS_BRIEFING_LOADING(true);

      const res = await briefingsApi.getBriefingData(caseId, briefingId).catch(handleError);

      if (res) {
        dispatch.briefings.SET_CURRENT_BRIEFING(res);
      }

      dispatch.briefings.SET_IS_BRIEFING_LOADING(false);

      return true;
    },

    async createBriefing(payload: {
            caseId: string,
            data: CreateBriefingData
        }, _state: RootState): Promise<boolean> {

      if (!payload.caseId || !payload?.data) {
        return false;
      }

      const { caseId,data } = payload;

      dispatch.briefings.SET_IS_BRIEFING_LOADING(true);

      const res = await briefingsApi.createBriefing(caseId, data).catch(handleError);

      if (res) {
        handleSuccess();
      }

      dispatch.briefings.SET_IS_BRIEFING_LOADING(false);

      return true;
    },

    async updateBriefing(payload: {
      caseId: string,
      data: UpdateBriefingData
    }, _state: RootState): Promise<boolean> {

      if (!payload.caseId || !payload?.data) {
        return false;
      }

      const { caseId,data } = payload;

      dispatch.briefings.SET_IS_BRIEFING_LOADING(true);

      const res = await briefingsApi.updateBriefing(caseId, data).catch(handleError);

      if (res) {
        handleSuccess();
      }

      dispatch.briefings.SET_IS_BRIEFING_LOADING(false);

      return true;
    },

    async deleteBriefing(payload: {
      caseId: string,
      data: DeleteBriefingParam
    }, _state: RootState): Promise<boolean> {

      if (!payload.caseId || !payload?.data) {
        return false;
      }

      const { caseId,data } = payload;

      return await briefingsApi.deleteBriefing(caseId, data).then(() => handleSuccess()).catch(handleError);

    },
    async getBriefingsListData(payload: {
      gridParams: GridParams
    }, _state: RootState): Promise<PaginatedResult<Briefing>> {
      const query = payload?.gridParams?.additionalParams?.filters?.wildSearch;
      const assignedTeamRefId = payload?.gridParams?.additionalParams?.filters?.assignedTeamRefId;
      const dateFromParam = payload?.gridParams?.additionalParams?.filters?.dateFrom;
      const dateToParam = payload?.gridParams?.additionalParams?.filters?.dateTo;

      const wildSearchQuery = (queryString?: string) =>  queryString ? `(briefingId LIKE '%${queryString}%' OR description LIKE '%${queryString}%')` : '';

      const teamsQuery = (teamId?: string) => teamId ? `assignedTeamRefId LIKE '%${assignedTeamRefId}%'` : "";

      const briefingsDateTimeQuery = (dateFrom?: Date | null, dateTo?: Date | null) => {
        if (dateFrom && dateTo) {
          return `(briefingDateTime >= '${dateFrom.getTime()}' AND briefingDateTime <= '${dateTo.getTime()}')`;
        }
        if(dateFrom && !dateTo) {
          return `(briefingDateTime >= '${dateFrom.getTime()}')`;
        }
        if (!dateFrom && dateTo) {
          return `(briefingDateTime <= '${dateTo.getTime()}')`;
        }

        return '';
      };

      if (!payload?.gridParams?.additionalParams?.caseId) {
        return initialState.briefingsListData;
      }

      // eslint-disable-next-line fp/no-let
      let searchString = '';

      if (wildSearchQuery(query)) {
        if (searchString.length > 0) {
          searchString = `${searchString} AND ${wildSearchQuery(query)}`;
        } else {
          searchString = wildSearchQuery(query);
        }
      }

      if (teamsQuery(assignedTeamRefId)) {
        if (searchString.length > 0) {
          searchString =`${searchString} AND ${teamsQuery(assignedTeamRefId)}`;
        } else {
          searchString = teamsQuery(assignedTeamRefId);
        }
      }

      if (briefingsDateTimeQuery(dateFromParam, dateToParam)) {
        if (searchString.length > 0) {
          searchString =`${searchString} AND ${briefingsDateTimeQuery(dateFromParam, dateToParam)}`;
        } else {
          searchString = briefingsDateTimeQuery(dateFromParam, dateToParam);
        }
      }

      // eslint-disable-next-line no-param-reassign
      payload.gridParams.searchQuery = encodeURIComponent(searchString);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const promises: Promise<any>[] = [];

      promises.push(
        briefingsApi.getBriefingListData(
          payload.gridParams.additionalParams.caseId,
          payload.gridParams.page,
          payload.gridParams.size,
          payload.gridParams.sort,
          payload.gridParams?.searchQuery
        ),
        briefingsApi.getBriefingListData(
          payload.gridParams.additionalParams.caseId,
          0,
          2,
          {  colId: 'briefingDateTime', sort: 'asc' },
          `(briefingDateTime >=${Date.now()})`
        )
      );

      const results = await Promise.all(promises).catch(handleError);

      if(results){
        const [briefingsListData, nextBriefing] = results;
        dispatch.briefings.SET_BRIEFINGS_LIST_DATA(briefingsListData);
        dispatch.briefings.SET_NEXT_BRIEFING(nextBriefing?.items[0]);

        return briefingsListData;
      }

      return initialState.briefingsListData;
    }
  })
};

export const briefings = createModel<RootModel>()(briefingsDataModel);