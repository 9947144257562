import { callApi } from "@/common/util/api";
import { RiskProviders } from "@/tenant-context/control-risk-config/types/risk";
import { DataMinrWatchList } from "@/tenant-context/visualisation-risk-alerts/types/data-minr.types";
import { Topic } from '@/tenant-context/visualisation-risk-alerts/types/factal';

export const getAllRiskAlertProviders = () => callApi<RiskProviders>(
  "asset-impact-service/v1/asset-impact-events/connector-provider",
  {
    method: 'get',
    queryParams: {
      pageNum: 0,
      pageSize: 1000, // skipping the pagination
      orderBy: 'connectorName:DESC',
      query:""
    }
  }
);

export const getDataminrWatchlist = (tenantId: string) => callApi<{
  watchlists: {
    TOPIC: DataMinrWatchList[]
  }
}>(
  `/data-minr-connector-service/v1/${tenantId}/watchlists`,
  {
    method: 'get'
  }
);

export const getFactalTopics = (tenantId: string) => callApi<{
  topics: Topic[]
}>(
  `/factal-connector-service/v1/${tenantId}/topics`,
  {
    method: 'get',
    headers: {
      'X-API-Key': '-'
    }
  }
);
