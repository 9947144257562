import {
  ToggleableContextLayers,
  ToggleableLayerType
} from "@/common/components/ToggleableLayer/ToggleableLayer.config";
import {
  DEFAULT_COUNTRY_RISK_FILL_OPACITY
} from "@/tenant-context/visualize-country-risk/layers/CountryRiskLevelLayer/CountryRiskLevelLayer.container";

export type ContextualLayersOptions = Record<ToggleableContextLayers, boolean>;

export const defaultContextualLayersOptions = Object.fromEntries(
  Object.values(ToggleableContextLayers).map((layerType) => [layerType, false])
) as ContextualLayersOptions;

export type DataOptionsState = {
  disabledLayerTypes: ToggleableLayerType[];
  contextLayerOptions: ContextualLayersOptions;
  layerOpacity: Record<string, number | undefined>;
}

export const drawerState: DataOptionsState = {
  disabledLayerTypes: [
    ToggleableLayerType.TravelCountriesView,
    ToggleableLayerType.TravelCountryDetailsView
  ],
  contextLayerOptions: { ...defaultContextualLayersOptions },
  layerOpacity: {
    [ToggleableContextLayers.CountryRiskLevel]: DEFAULT_COUNTRY_RISK_FILL_OPACITY
  }
};
