import { createModel } from "@rematch/core";

import {
  MaxSecurityToggleableFilterType,
  RiskBSOCToggleableFilterType,
  RisklineToggleableFilterType
} from "@/common/components/ToggleableFilter/ToggleableFilter.config";
import { Dispatch, RootModel, RootState } from "@/core/store";
import { RiskProviders } from "@/tenant-context/common/types/risk";
import { getDataminrWatchlist, getFactalTopics } from "@/tenant-context/core/api/riskProviders";
import { DataMinrWatchList } from "@/tenant-context/visualisation-risk-alerts/types/data-minr.types";
import { FactalTopic } from "@/tenant-context/visualisation-risk-alerts/types/factal";


export const MIN_RISK_LEVEL = 1;
export const MAX_RISK_LEVEL = 5;

const defaultRiskLevelFilters: Array<number> = [];
// eslint-disable-next-line fp/no-loops, fp/no-let
for(let i = MIN_RISK_LEVEL; i <= MAX_RISK_LEVEL; i++){
  defaultRiskLevelFilters.push(i);
}

export type RiskDataFilterOptionsState = {
  disabledRiskFilterTypes: string[],
  isGlobalCategoryToggleOnRiskline: boolean
  isGlobalCategoryToggleOnBSOC: boolean
  isGlobalCategoryToggleOnDataMinr: boolean
  isGlobalCategoryToggleOnMaxSecurity: boolean
  isGlobalCategoryToggleOnHealix: boolean
  isGlobalCategoryToggleOnFactal: boolean
  riskLevelFilters: {
    riskline: number[]
    dataminr: number[]
    healix: number[]
    factal: number[]
  }
  riskLevelBSOCFilters: {
    nonCritical: boolean
    critical: boolean
  }
  dataMinrWatchlist: DataMinrWatchList[]
  healixCategories: { name: string, id: string, categoryId: string }[]
  factalTopics: FactalTopic[]
}

const riskEventFilterDataOptionsModal = {
  name: 'riskEventFilterDataOptions',
  state: {
    disabledRiskFilterTypes: [],
    isGlobalCategoryToggleOnRiskline: true,
    isGlobalCategoryToggleOnBSOC: true,
    isGlobalCategoryToggleOnDataMinr: true,
    isGlobalCategoryToggleOnMaxSecurity: true,
    isGlobalCategoryToggleOnHealix: true,
    isGlobalCategoryToggleOnFactal: true,
    riskLevelFilters: {
      riskline: [...defaultRiskLevelFilters],
      dataminr: [...defaultRiskLevelFilters],
      healix: [...defaultRiskLevelFilters],
      factal: [...defaultRiskLevelFilters]
    },
    riskLevelBSOCFilters: {
      nonCritical: true,
      critical: true
    },
    dataMinrWatchlist: [],
    healixCategories: [],
    factalTopics: []
  } as RiskDataFilterOptionsState,
  reducers: {
    ENABLE_FILTER(state: RiskDataFilterOptionsState, filterToEnable: string) {
      const { disabledRiskFilterTypes } = state;
      const updatedFilters = disabledRiskFilterTypes.filter(
        (filter) => filter !== filterToEnable
      );

      return {
        ...state,
        disabledRiskFilterTypes: updatedFilters,
        isGlobalCategoryToggleOn: true
      };
    },

    DISABLE_FILTER(state: RiskDataFilterOptionsState, filterToDisable: string) {
      const { disabledRiskFilterTypes } = state;

      const updatedFilters = Array.from(
        new Set([...disabledRiskFilterTypes, filterToDisable])
      );

      const isAllCategoriesDisabled = updatedFilters.length === Object.values(RisklineToggleableFilterType).length;

      return {
        ...state,
        isGlobalCategoryToggleOn: !isAllCategoriesDisabled,
        disabledRiskFilterTypes: updatedFilters
      };
    },

    ENABLE_GLOBAL_CATEGORY_TOGGLE(state: RiskDataFilterOptionsState, _providerId: string) {
      const providerId = _providerId.toLowerCase();

      if (providerId === "riskline") {
        return {
          ...state,
          isGlobalCategoryToggleOnRiskline: true
        };
      } else if (providerId === "bsoc") {
        return {
          ...state,
          isGlobalCategoryToggleOnBSOC: true
        };
      } else if (providerId === "dataminr") {
        return {
          ...state,
          isGlobalCategoryToggleOnDataMinr: true
        };
      } else if (providerId === "max-security") {
        return {
          ...state,
          isGlobalCategoryToggleOnMaxSecurity: true
        };
      } else if (providerId === "healix") {
        return {
          ...state,
          isGlobalCategoryToggleOnHealix: true
        };
      } else if (providerId === "factal") {
        return {
          ...state,
          isGlobalCategoryToggleOnFactal: true
        };
      }

      return state;
    },

    ENABLE_BSOC_LEVEL_FILTER(state: RiskDataFilterOptionsState, filterToEnable: string) {
      const { riskLevelBSOCFilters } = state;

      return {
        ...state,
        riskLevelBSOCFilters: { ...riskLevelBSOCFilters, [filterToEnable]: true },
        isGlobalCategoryToggleOn: true
      };
    },

    DISABLE_BSOC_LEVEL_FILTER(state: RiskDataFilterOptionsState, filterToDisable: string) {
      const { riskLevelBSOCFilters } = state;

      return {
        ...state,
        riskLevelBSOCFilters: { ...riskLevelBSOCFilters, [filterToDisable]: false },
        isGlobalCategoryToggleOn: true
      };
    },

    /** TODO: delete this reducer when we dont use RangeSlider for changing risk level */
    CHANGE_RANGE_FILTER(state:RiskDataFilterOptionsState, riskRange: number []){
      const newRiskLevelFilter: Array<number> = [];
      const [min, max] = riskRange;
      // eslint-disable-next-line fp/no-loops, fp/no-let
      for(let i = min; i <= max; i++){
        newRiskLevelFilter.push(i);
      }
      
      return {
        ...state,
        riskLevelFilters: {
          ...state.riskLevelFilters,
          riskline: newRiskLevelFilter
        }
      };
    },

    SET_RISK_LEVEL_FILTER(
      state: RiskDataFilterOptionsState,
      {
        riskLevel,
        checked,
        provider
      }: {
        riskLevel: number;
        checked: boolean;
        provider: RiskProviders.riskline | RiskProviders.dataminr | RiskProviders.healix | RiskProviders.factal;
      }
    ) {
      const newRiskLevelFilter = new Set(state.riskLevelFilters[provider]);
      if (checked) {
        newRiskLevelFilter.add(riskLevel);
      } else {
        newRiskLevelFilter.delete(riskLevel);
      }

      return {
        ...state,
        riskLevelFilters: {
          ...state.riskLevelFilters,
          [provider]: Array.from(newRiskLevelFilter)
        }
      };
    },

    SET_ALL_RISK_LEVEL_FILTER(
      state: RiskDataFilterOptionsState,
      provider: RiskProviders.riskline | RiskProviders.dataminr | RiskProviders.healix
    ) {
      return {
        ...state,
        riskLevelFilters: {
          ...state.riskLevelFilters,
          [provider]: [...defaultRiskLevelFilters]
        }
      };
    },

    ENABLE_GLOBAL_CATEGORY_FILTER_RISKLINE(state: RiskDataFilterOptionsState){
      return {
        ...state,
        isGlobalCategoryToggleOnRiskline:true,
        disabledRiskFilterTypes:state.disabledRiskFilterTypes
          .filter((filter) => !(Object.values(RisklineToggleableFilterType) as Array<string>).includes(filter))
      };
    },

    DISABLE_GLOBAL_CATEGORY_FILTER_RISKLINE(state: RiskDataFilterOptionsState){
      return {
        ...state,
        isGlobalCategoryToggleOnRiskline:false,
        disabledRiskFilterTypes: [...state.disabledRiskFilterTypes ,...Object.values(RisklineToggleableFilterType)]
      };
    },

    ENABLE_GLOBAL_CATEGORY_FILTER_BSOC(state: RiskDataFilterOptionsState){
      return {
        ...state,
        isGlobalCategoryToggleOnBSOC:true,
        disabledRiskFilterTypes:
          state.disabledRiskFilterTypes.filter((filter) => (!(filter in RiskBSOCToggleableFilterType)))
      };
    },

    DISABLE_GLOBAL_CATEGORY_FILTER_BSOC(state: RiskDataFilterOptionsState){
      return {
        ...state,
        isGlobalCategoryToggleOnBSOC:false,
        disabledRiskFilterTypes: [...state.disabledRiskFilterTypes ,...Object.values(RiskBSOCToggleableFilterType)]
      };
    },

    ENABLE_GLOBAL_CATEGORY_FILTER_DATAMINR(state: RiskDataFilterOptionsState){
      return {
        ...state,
        isGlobalCategoryToggleOnDataMinr:true,
        disabledRiskFilterTypes:
          state.disabledRiskFilterTypes.filter((filter) =>
            (!(state.dataMinrWatchlist.findIndex(({ id }) => id.toString() === filter) > -1)))
      };
    },

    DISABLE_GLOBAL_CATEGORY_FILTER_DATAMINR(state: RiskDataFilterOptionsState){
      return {
        ...state,
        isGlobalCategoryToggleOnDataMinr:false,
        disabledRiskFilterTypes: [
          ...state.disabledRiskFilterTypes,
          ...state.dataMinrWatchlist.map(({ id }) => id.toString())
        ]
      };
    },

    ENABLE_GLOBAL_CATEGORY_FILTER_MAX_SECURITY(state: RiskDataFilterOptionsState){
      return {
        ...state,
        isGlobalCategoryToggleOnMaxSecurity:true,
        disabledRiskFilterTypes: state.disabledRiskFilterTypes.filter((filterType) =>
          (!(Object.values(MaxSecurityToggleableFilterType) as string[]).includes(filterType)))
      };
    },

    DISABLE_GLOBAL_CATEGORY_FILTER_MAX_SECURITY(state: RiskDataFilterOptionsState){
      return {
        ...state,
        isGlobalCategoryToggleOnMaxSecurity:false,
        disabledRiskFilterTypes: [
          ...state.disabledRiskFilterTypes,
          ...Object.values(MaxSecurityToggleableFilterType)
        ]
      };
    },

    ENABLE_GLOBAL_CATEGORY_FILTER_HEALIX(state: RiskDataFilterOptionsState){
      return {
        ...state,
        isGlobalCategoryToggleOnHealix: true,
        disabledRiskFilterTypes: state.disabledRiskFilterTypes.filter((filter) =>
          (state.healixCategories.findIndex(({ id }) => id === filter) <= -1))
      };
    },

    DISABLE_GLOBAL_CATEGORY_FILTER_HEALIX(state: RiskDataFilterOptionsState){
      return {
        ...state,
        isGlobalCategoryToggleOnHealix: false,
        disabledRiskFilterTypes: Array.from(new Set([
          ...state.disabledRiskFilterTypes,
          ...state.healixCategories.map(({ id }) => id)
        ]))
      };
    },

    ENABLE_GLOBAL_CATEGORY_FILTER_FACTAL(state: RiskDataFilterOptionsState){
      return {
        ...state,
        isGlobalCategoryToggleOnFactal: true,
        disabledRiskFilterTypes:
          state.disabledRiskFilterTypes.filter((filter) =>
            (!(state.factalTopics.findIndex(({ id }) => id === filter) > -1)))
      };
    },

    DISABLE_GLOBAL_CATEGORY_FILTER_FACTAL(state: RiskDataFilterOptionsState){
      return {
        ...state,
        isGlobalCategoryToggleOnFactal: false,
        disabledRiskFilterTypes: [...state.disabledRiskFilterTypes, ...state.factalTopics.map(topic => topic.id)]
      };
    },

    SET_DATAMINR_WATCHLIST(state: RiskDataFilterOptionsState, watchlist: DataMinrWatchList[]){
      return {
        ...state,
        dataMinrWatchlist: watchlist
      };
    },

    SET_FACTAL_TOPICS(state: RiskDataFilterOptionsState, topics: FactalTopic[]){
      return {
        ...state,
        factalTopics: topics
      };
    },

    SET_HEALIX_CATEGORIES(
      state: RiskDataFilterOptionsState,
      categories: { name: string, id: string, categoryId: string }[]
    ) { // New setter for Healix categories
      return {
        ...state,
        healixCategories: categories
      };
    }
  },
  effects: (dispatch: Dispatch) => ({
    async getDataminrWatchlist(_: void, state: RootState): Promise<void> {
      const { tenantId } = state.commonData;

      const watchlistResponse = await getDataminrWatchlist(tenantId);

      if (!watchlistResponse.watchlists) {
        return;
      }

      dispatch.riskEventFilterDataOptions.SET_DATAMINR_WATCHLIST(watchlistResponse.watchlists.TOPIC);
    },

    async getFactalTopics(_: void, state: RootState): Promise<void> {
      const { tenantId } = state.commonData;

      const topicsResponse = await getFactalTopics(tenantId);

      if (!topicsResponse.topics) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        topicsResponse.topics = [{ slug: 'fire', name: 'Fire', kind: 'tag', category: 'emergency' }, { slug: 'shooting', name: 'Shooting', kind: 'tag', category: 'crime' }, { slug: 'protest', name: 'Protest', kind: 'vertical', category: 'civil-unrest' }
        ];
      }

      // Map topic data to FactalTopic format
      const factalTopics: FactalTopic[] = topicsResponse.topics
        .filter(topic => topic.kind === 'tag' || topic.kind === 'vertical')
        .map(topic => ({
          id: `factal/${topic.slug}`,
          name: topic.name,
          slug: topic.slug,
          kind: topic.kind,
          category: topic.category
        }));

      dispatch.riskEventFilterDataOptions.SET_FACTAL_TOPICS(factalTopics);
    }
  })
};

export const riskEventFilterDataOptions = createModel<RootModel>()(riskEventFilterDataOptionsModal);
