import { Stack, Text } from "@mantine/core";
import React, { FC } from "react";

import { ToggleableContextLayers } from "@/common/components/ToggleableLayer/ToggleableLayer.config";
import {
  LayerMetadataComponent
} from "@/tenant-context/control-risk-config/components/RiskProviderTreeContent/MapLayerSettings/LayerMetadata.component";
import {
  WeatherLayerSettingsComponent
} from "@/tenant-context/control-risk-config/components/RiskProviderTreeContent/MapLayerSettings/WeatherLayerSettings.component";
import RisklineLegendComponent
  from "@/tenant-context/control-risk-config/components/RiskProviderTreeContent/RisklineLegend.component";
import LayerOpacityControl from "@/tenant-context/visualisation-opacity/components/LayerOpacityControl.container";
import { trafficLayerMetadata } from "@/tenant-context/visualisation-traffic/config/traffic-layer.config";
import { getLayerConfigByContextLayer } from "@/tenant-context/visualisation-weather";
import { countryRiskLayerMetadata } from "@/tenant-context/visualize-country-risk/config/country-risk-metadata.config";

type Props = {
  layerType: ToggleableContextLayers
}

type MetaDataItem = {
  label: string;
  value: React.ReactNode;
};

type LayerConfig = Partial<Record<string, {
  label: string;
  metadata: MetaDataItem[];
  legend?: React.ReactNode;
}>>;

const layerConfig: LayerConfig = {
  [ToggleableContextLayers.CountryRiskLevel]: {
    label: 'Country Risk Level',
    metadata: countryRiskLayerMetadata,
    legend: <RisklineLegendComponent />
  },
  [ToggleableContextLayers.Traffic]: {
    label: 'Traffic Conditions',
    metadata: trafficLayerMetadata
  }
};

export const ContextualLayerSettingsComponent: FC<Props> = ({ layerType }) => {
  const weatherLayerConfig = getLayerConfigByContextLayer(layerType);

  if(weatherLayerConfig) {
    return <WeatherLayerSettingsComponent config={ weatherLayerConfig } />;
  }
  
  const { label, metadata: metadataItems, legend } = layerConfig[layerType] ?? {};

  const isVisibleOpacityControl = layerType === ToggleableContextLayers.CountryRiskLevel;

  return (
    <Stack spacing={ 24 }>
      <Text size={ 18 } fw={ 700 }>
        { label }
      </Text>

      { metadataItems && <LayerMetadataComponent items={ metadataItems } /> }

      { isVisibleOpacityControl && <LayerOpacityControl layerId={ layerType } /> }

      { legend && (
        <Stack spacing={ 24 }>
          <Text size={ 16 } fw={ 700 }>
            Layer Legend
          </Text>
          { legend }
        </Stack>
      ) }
    </Stack>
  );
};
