import React from "react";

import xweatherSvg from '@/common/icons/RiskProviders/vaisala-xweather-logo-light.svg';

type LayerId = string;

type MetaDataItem = {
  label: string;
  value: React.ReactNode;
};

const XWeatherLogo = () => (
  <a href="https://www.xweather.com/" target="_blank" title="Powered by Vaisala Xweather" rel="noreferrer">
    <img src={ xweatherSvg } alt="Vaisala Xweather" height="40" />
  </a>
);

export const layersMetadata: Record<LayerId, Array<MetaDataItem>> = {
  alerts: [
    { label: 'Data source:', value: <XWeatherLogo /> },
    { label: 'Coverage:', value: 'US, Canada, Europe' },
    { label: 'Update frequency:', value: '2 minutes' }
  ],
  radar: [
    { label: 'Data source:', value: <XWeatherLogo /> },
    { label: 'Coverage:', value: 'Global' },
    { label: 'Update frequency:', value: '5 min' }
  ],
  'tropical-cyclones': [
    { label: 'Data source:', value: <XWeatherLogo /> },
    { label: 'Coverage:', value: 'Global' },
    { label: 'Update frequency:', value: '1-6 hours' }
  ],
  'wave-heights': [
    { label: 'Data source:', value: <XWeatherLogo /> },
    { label: 'Coverage:', value: 'Global' },
    { label: 'Update frequency:', value: '6 hours' }
  ],
  'temperatures': [
    { label: 'Data source:', value: <XWeatherLogo /> },
    { label: 'Coverage:', value: 'Global' },
    { label: 'Update frequency:', value: '1 hour' }
  ],
  'wind-particles': [
    { label: 'Data source:', value: <XWeatherLogo /> },
    { label: 'Coverage:', value: 'Global' },
    { label: 'Update frequency:', value: '1 hour' }
  ]
};
