import { FC, useEffect } from "react";
import Map, {
  MapboxEvent,
  MapLayerMouseEvent,
  NavigationControl,
  useMap,
  ViewStateChangeEvent
} from 'react-map-gl';
import { useSelector } from "react-redux";

import ToggleableLayer from "@/common/components/ToggleableLayer";
import {
  ToggleableLayerType
} from "@/common/components/ToggleableLayer/ToggleableLayer.config";
import ControlContainer from "@/common/controls/ControlContainer";
import { ENV, Environment } from "@/core/config/env";
import { RootState } from "@/core/store";
import { INITIAL_MAP_VIEW } from "@/tenant-context/common/util/constants";
import DeveloperToolsControl
  from "@/tenant-context/control-developer-tools/controls/DeveloperToolsControl/DeveloperToolsControl.component";
import DrawControl from "@/tenant-context/control-draw/controls/DrawControl";
import LiveFeedControl from "@/tenant-context/control-live-feed/controls/LiveFeedControl";
import LocationGraphControl from "@/tenant-context/control-location-graph/controls/LocationGraphControl";
import MiniProfileControl from "@/tenant-context/control-profile/controls/MiniProfileControl/";
import RankingSettingsControl from "@/tenant-context/control-ranking-settings/controls/RankingSettingsControl";
import RiskEventsControl from "@/tenant-context/control-risk-config/controls/RiskEventsControl";
import RiskDateFilterTriggerControl from "@/tenant-context/control-risk-timeline/controls/RiskDateFilterTriggerControl";
import RiskTimelineDrawerControl from "@/tenant-context/control-risk-timeline/controls/RiskTimelineDrawer";
import RiskTimelineTriggerControl from "@/tenant-context/control-risk-timeline/controls/RiskTimelineTriggerControl";
import GlobalSearchControl from "@/tenant-context/control-search/controls/GlobalSearchControl";
import CritLocationControl from "@/tenant-context/control-site/controls/CritLocationControl";
import {
  TravellersSearchContextProvider
} from "@/tenant-context/control-travellers-search/context/TravellersSearchContext/TravellersSearch.context";
import TravellersSearchControl from "@/tenant-context/control-travellers-search/controls/TravellersSearchControl";
import BasemapLayerToggleControl from "@/tenant-context/core/controls/BasemapLayerToggleControl/";
// import DataOptionsControl from "@/tenant-context/core/controls/DataOptionsControl";
import MapLayersControlComponent from "@/tenant-context/core/controls/MapLayersControl";
import NavigateToGlobalControl from "@/tenant-context/core/controls/NavigateToGlobalControl";
import IndoorPeopleLayer from "@/tenant-context/plugin-indoors/layers/IndoorPeopleLayer";
import IndoorsPlugin from '@/tenant-context/plugin-indoors/plugins/IndoorsPlugin';
import SelectedGeofenceDataPopup from '@/tenant-context/visualisation-geofences/components/SelectedGeofenceDataPopup';
import VisualizedGeofenceDrawer from '@/tenant-context/visualisation-geofences/components/VisualizedGeofenceDrawer';
import GeofenceVisualizeContextProvider from '@/tenant-context/visualisation-geofences/context/GeofenceVisualize.context';
import GeofenceLayer from '@/tenant-context/visualisation-geofences/layers/GeofenceLayer';
import PeopleLocationPopup from "@/tenant-context/visualisation-people/components/PeopleLocationPopup";
import { PeopleContextProvider } from "@/tenant-context/visualisation-people/context/People.context";
import usePeopleIcons from "@/tenant-context/visualisation-people/hooks/usePeopleIcons";
import PeopleBreadcrumbLayer from "@/tenant-context/visualisation-people/layers/PeopleBreadcrumbLayer";
import PeopleLocationLayer from "@/tenant-context/visualisation-people/layers/PeopleLocationLayer";
import PeopleTravelLocationLayer from "@/tenant-context/visualisation-people/layers/PeopleTravelLocationLayer";
import RiskAlertPopup from "@/tenant-context/visualisation-risk-alerts/components/RiskAlertPopup";
import { RiskContextProvider } from "@/tenant-context/visualisation-risk-alerts/context/Risk.context";
import RiskAlertLayer from "@/tenant-context/visualisation-risk-alerts/layers/RiskAlertLayer";
import RiskImpactPopup from "@/tenant-context/visualisation-risk-details/components/RiskImpactPopup";
import RiskImpactLayer from "@/tenant-context/visualisation-risk-details/layers/RiskImpactLayer";
import { SiteInitContextProvider } from "@/tenant-context/visualisation-site/context/SiteInit.context";
import CriticalLocationLayer from "@/tenant-context/visualisation-site/layers/CriticalLocationLayer";
import {  TrafficLayer } from "@/tenant-context/visualisation-traffic/layers";
import CountryTravelLayer from "@/tenant-context/visualisation-travel/layers/CountryTravelLayer";
import {
  isWeatherMapEnabled, WeatherLegendGlobalComponent,
  WeatherMapLayersContainer
} from "@/tenant-context/visualisation-weather";
import CountryRiskLevelLayerComponent from "@/tenant-context/visualize-country-risk/layers/CountryRiskLevelLayer";
import { OverviewWidget } from "@/tenant-context/widget-overview/controls/OverviewWidget";


const {
  REACT_APP_MAPBOX_ACCESS_TOKEN
} = process.env as {
  REACT_APP_MAPBOX_ACCESS_TOKEN?: string
};

type Props = {
  onMove?: (e: ViewStateChangeEvent) => void,
  onLoad?: (e: MapboxEvent<undefined>) => void,
  onClick?: (e: MapLayerMouseEvent) => void,
  isZoomedIn: boolean,
  isGlobe: boolean,
  isFullscreenOverlayOpen: boolean,
  mapUrl: string,
  isArcOpen: boolean,
  isMassCommsOpen: boolean
};

const BigMapComponent: FC<Props> = ({
  onMove,
  onLoad,
  onClick,
  isZoomedIn,
  isGlobe,
  isFullscreenOverlayOpen,
  isArcOpen,
  mapUrl,
  isMassCommsOpen
}: Props) => {
  // Expose map as a global object for testing purposes
  const { AssetLocationSnapshotMap: map } = useMap();
  const isInDevMode = (ENV === Environment.Dev || ENV === Environment.Qa);

  const isGlobalCategoryToggleOn = useSelector(
    (state: RootState) => state.riskEventFilterDataOptions.isGlobalCategoryToggleOnRiskline
  );

  // Disable right click actions as they are confusing
  useEffect(() => {
    if (!map) {
      return;
    }

    map.getMap().dragRotate.disable();
    map.getMap().touchZoomRotate.disableRotation();
    map.getCanvas().style.cursor = 'default';
  }, [map]);

  useEffect(() => {
    if (!map || !isInDevMode) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.bigMap = map;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  usePeopleIcons(map);
  return (
    <Map
      id="AssetLocationSnapshotMap"
      onLoad={ onLoad }
      onMove={ onMove }
      onMoveEnd={ onMove }
      onClick={ onClick }
      mapboxAccessToken={ REACT_APP_MAPBOX_ACCESS_TOKEN }
      minZoom={ 2 }
      initialViewState={ INITIAL_MAP_VIEW }
      mapStyle={ mapUrl }
      reuseMaps
      testMode={ isInDevMode }
      renderWorldCopies={ true }
      projection={ isGlobe ? 'globe' : 'mercator' }
      fog={ {} }
    >
      { /* Indoors */ }
      <IndoorsPlugin />

      { /* no-op temporarily removed */ }
      { /* <IndoorsSiteLayer /> */ }

      { /* Layers */ }
      { /* tenant locations */ }
      { /* <CustomerLocationLayer /> */ }
      { /* restrata managed locations */ }
      { /* <CommonLocationLayer /> */ }
      { /* travel locations */ }
      <PeopleTravelLocationLayer />
      <ToggleableLayer type={ ToggleableLayerType.Locations }>
        <SiteInitContextProvider>
          <CriticalLocationLayer />
        </SiteInitContextProvider>
      </ToggleableLayer>

      <RiskContextProvider>
        <RiskAlertLayer />
        <RiskImpactLayer />
        <RiskAlertPopup />
        <RiskImpactPopup />
      </RiskContextProvider>

      <PeopleContextProvider mapKey="bigMapPopups">
        <PeopleLocationLayer />
        <PeopleLocationPopup />
        <PeopleBreadcrumbLayer />
        <IndoorPeopleLayer />

        { /* Drawers without controls */ }
        <LocationGraphControl />
        <MiniProfileControl />
        <CritLocationControl />
      </PeopleContextProvider>

      <CountryRiskLevelLayerComponent />

      <TrafficLayer />

      { isWeatherMapEnabled && <WeatherMapLayersContainer /> }

      { /* Controls */ }
      <OverviewWidget />

      <ControlContainer
        containerId="mid-right-control-container"
        position="top-right"
        layout="vertical"
      >
        { !isFullscreenOverlayOpen && (
          <>
            <GlobalSearchControl />
            <TravellersSearchContextProvider>
              <TravellersSearchControl />
              <CountryTravelLayer />
              { /* <DataOptionsControl /> */ }
              <BasemapLayerToggleControl />
              <MapLayersControlComponent />
            </TravellersSearchContextProvider>
            <RankingSettingsControl />
            <DrawControl />
            { isInDevMode && <DeveloperToolsControl /> }
          </>
        ) }

        { /* If not rendered - ARC not rendered */ }
        { (!isFullscreenOverlayOpen || isArcOpen || isMassCommsOpen) && (
          <RiskEventsControl />
        ) }
      </ControlContainer>

      { !isFullscreenOverlayOpen && (<ControlContainer
        position="bottom-left"
        containerId="bottom-left-control-container"
        layout="vertical"
        styles={ {
          order: 3
        } }
      >
        { /* <ToggleableLayer type={ ToggleableContextLayers.CountryRiskLevel } layerType="contextual-layer">
          <RiskLevelLegendControl />
        </ToggleableLayer> */ }
        { /* <ToggleableLayer type={ ToggleableLayerType.TravelCountriesView }>
          <RiskLevelLegendControl />
        </ToggleableLayer>
        <ToggleableLayer type={ ToggleableLayerType.TravelCountryDetailsView }>
          <RiskLevelLegendControl />
        </ToggleableLayer> */ }
        <div className="miniMap-and-risk-timeline-controls-wrapper">
          <RiskTimelineDrawerControl />
          { isGlobalCategoryToggleOn && <RiskTimelineTriggerControl /> }
          <RiskDateFilterTriggerControl
            isMapZoomedIn={ isZoomedIn }
          />
        </div>
        <WeatherLegendGlobalComponent />

      </ControlContainer>) }

      { !isFullscreenOverlayOpen && (<NavigationControl
        position="bottom-right"
        showCompass={ false }
        style={ { order: 5 } }
      />) }

      { !isFullscreenOverlayOpen && (<ControlContainer
        position="bottom-right"
        containerId={ 'bottom-right-control-container' }
        layout="vertical"
      >
        { isZoomedIn && (
          <NavigateToGlobalControl />
        ) }
      </ControlContainer>) }

      <GeofenceVisualizeContextProvider>
        <GeofenceLayer />
        <SelectedGeofenceDataPopup />
        <VisualizedGeofenceDrawer />
      </GeofenceVisualizeContextProvider>

      <LiveFeedControl />
    </Map>
  );
};

export default BigMapComponent;
