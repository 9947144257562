import { Image as MantineImage } from "@mantine/core";

import { ToggleableContextLayers } from "@/common/components/ToggleableLayer/ToggleableLayer.config";
import radarImg from "@/tenant-context/visualisation-weather/images/radar-legend.png";
import surfaceTemperatureImg from "@/tenant-context/visualisation-weather/images/surface-temperature-legend.png";
import waveHeightsImg from "@/tenant-context/visualisation-weather/images/wave-heights.png";
import { ReactComponent as WindDirectionsSvg } from "@/tenant-context/visualisation-weather/images/winds-directions-legend.svg";

import { ReactComponent as TropicalCyclonesLegendSvg } from "../images/cyclones-legend.svg";

/** this func copy legend elements from global legend controller */
export function copyAlertsLegend(targetContainerId: string, targetImageClassName: string) {
  // find the canvas from xweather legend on the map
  const sourceCanvasEl = document.querySelector<HTMLCanvasElement>("#awxgl-legend-alerts #gl-canvas");
  const targetContainer = document.getElementById(targetContainerId);

  if (sourceCanvasEl && targetContainer) {
    const image = new Image();
    image.src = sourceCanvasEl.toDataURL("image/png"); // Get base64 image from canvas
    image.alt = "Alerts captured from map";
    image.className = targetImageClassName;
    // eslint-disable-next-line fp/no-loops
    while (targetContainer.firstChild) {
      targetContainer.removeChild(targetContainer.firstChild);
    }
    targetContainer?.append(image);

    return true;
  }

  return false;
}

const interactiveLayerTypes = new Set([ToggleableContextLayers.Alerts]);

export const checkIfInteractiveLegend = (contextualLayerType: ToggleableContextLayers) => {
  return interactiveLayerTypes.has(contextualLayerType);
};

const legendImages: Partial<Record<string, JSX.Element>> = {
  [ToggleableContextLayers.Cyclones]: <TropicalCyclonesLegendSvg style={ { transform: 'translateX(-4px)' } } />,
  [ToggleableContextLayers.Radar]: <MantineImage src={ radarImg } />,
  [ToggleableContextLayers.Temperature]: <MantineImage src={ surfaceTemperatureImg } />,
  [ToggleableContextLayers.WaveHeights]: <MantineImage src={ waveHeightsImg } />,
  [ToggleableContextLayers.Wind]: <WindDirectionsSvg />
};

const legendUnit: Partial<Record<ToggleableContextLayers, string>> = {
  [ToggleableContextLayers.Temperature]: '°C',
  [ToggleableContextLayers.WaveHeights]: 'm',
  [ToggleableContextLayers.Wind]: 'mph'
};

export const useStaticLegend = (contextualLayerType: ToggleableContextLayers) => {
  return {
    staticLegendElement: legendImages[contextualLayerType],
    legendUnit: legendUnit[contextualLayerType]
  };
};
