import { points, pointsWithinPolygon } from "@turf/turf";
import { FeatureCollection, Polygon } from "geojson";

import { GeoPoint } from "@/tenant-context/common/types/location";

export const filterOutGeoPermission = (
  geoPoint: GeoPoint,
  currentUserGeoBoundary: FeatureCollection<Polygon> | null | undefined
): boolean => {
  if (!currentUserGeoBoundary || currentUserGeoBoundary.features.length === 0) {
    return true;
  }

  const filteredPoints = pointsWithinPolygon(points([[
    geoPoint.lon,
    geoPoint.lat
  ]]), currentUserGeoBoundary);

  return !(filteredPoints.features.length === 0);
};
