import { createStyles } from "@mantine/core";
import { MantineTheme } from "@mantine/styles/lib/theme";

import { CSS_KEY } from '@/core/styles/mantine/constants';

export const useMassCommsStepperStyles = createStyles((_theme: MantineTheme) => ({
  steps: {
    width: '600px',
    [`.${CSS_KEY}-Stepper-step:disabled`]: {
      cursor: 'default'
    }
  }
}));
