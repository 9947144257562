import { Accordion, Box, Flex, Progress, SimpleGrid, Stack, Text, Tooltip } from "@mantine/core";
import { FC, Fragment, useMemo } from 'react';

import {
  useDetailedCountryReportStyles
} from '@/tenant-context/control-reports/components/DetailedCountryReport/DetailedCountryReport.styles';
import { RisklineCountryRiskData } from "@/tenant-context/control-reports/types/country-risk-riskline-report";
import { RiskReportProvider } from "@/tenant-context/control-reports/types/risk-reports-common";
import {
  anyCaseToCapitalizedWords,
  getRiskColorByRiskLevel,
  getRiskLevelValueByRiskLevel, snakeCaseToTitleCase
} from "@/tenant-context/control-reports/util/country-risk-reports";

type Props = {
  countryRiskData: RisklineCountryRiskData;
};

const RisklineDetailedCountryReport: FC<Props> = ({
  countryRiskData
}) => {
  const getColorByLevel = (level?: string) => {
    return getRiskColorByRiskLevel(level, RiskReportProvider.RISKLINE);
  };

  const overallRisklevel = typeof countryRiskData.risk_level === 'string' ? countryRiskData.risk_level : countryRiskData.risk_level.name;

  const { classes } = useDetailedCountryReportStyles( {
    riskLevelColor: getColorByLevel(overallRisklevel.toLowerCase())
  } );

  const riskLevelValue = useMemo(() => {
    return getRiskLevelValueByRiskLevel(overallRisklevel, RiskReportProvider.RISKLINE);
  }, [overallRisklevel]);

  return (
    <Fragment>
      <Stack spacing="sm" className={ classes.riskLevelContainer }>
        <Flex justify="space-between">
          <Text weight={ 400 }>Risk Level</Text>
          <Text weight={ 400 } transform="capitalize">{ overallRisklevel || 'Not available' }</Text>
        </Flex>
        <Progress value={ riskLevelValue } color="red" size="lg" className={ classes.countryRiskLevel }/>
      </Stack>

      { countryRiskData.chapters.length > 0 && <Stack>
        <Text className={ classes.sectionHeading }>Indicators</Text>
        <SimpleGrid cols={ 4 } spacing={ 8 }>
          { countryRiskData.chapters.map(({ title, risk_level }, index) => (
            <Tooltip
              key={ index }
              withArrow
              label={ `${snakeCaseToTitleCase(title)} | Risk Level: ${anyCaseToCapitalizedWords(risk_level) || 'N/A'}` }
              multiline
              classNames={ { tooltip: classes.tooltip } }
            >
              <Box
                style={ { backgroundColor: getColorByLevel(risk_level) } }
                className={ classes.indicatorPill }
              >
                <Text truncate>{ snakeCaseToTitleCase(title) }</Text>
              </Box>
            </Tooltip>
          )) }

          { /* Special indicators */ }
          { countryRiskData.security_risk_level && (
            <Tooltip
              key={ 'security_risk_level' }
              withArrow
              label={ `Security Risk | Risk Level: ${anyCaseToCapitalizedWords(countryRiskData.security_risk_level.name) || 'N/A'}` }
              multiline
              classNames={ { tooltip: classes.tooltip } }
            >
              <Box
                style={ { backgroundColor: getColorByLevel(countryRiskData.security_risk_level.name) } }
                className={ classes.indicatorPill }
              >
                <Text truncate>{ snakeCaseToTitleCase('Security Risk') }</Text>
              </Box>
            </Tooltip>
          ) }
        </SimpleGrid>
      </Stack> }

      { countryRiskData.summary && <Stack>
        <Text className={ classes.sectionHeading }>Summary</Text>
        <div className={ classes.text } dangerouslySetInnerHTML={ { __html: countryRiskData.summary.summary } } />
      </Stack> }

      <Accordion>
        { countryRiskData.chapters.map((chapter) => {
          return (
            <Accordion.Item key={ chapter.chapter_id } value={ String(chapter.chapter_id) }>
              <Stack  spacing="md">
                <Accordion.Control>
                  <Text className={ classes.sectionHeading }>{ chapter.title }</Text>
                </Accordion.Control>

                <Accordion.Panel>
                  <Accordion>
                    { chapter.sections.filter(section => section.text).map((section) => (
                      <Accordion.Item
                        key={ `${section.chapter_id}/${section.subcategory_id}/${section.title}` }
                        value={ String(section.subcategory_id) }
                      >
                        <Stack  spacing="md">
                          <Accordion.Control>
                            <Text className={ classes.subSectionHeading }>{ section.title }</Text>
                          </Accordion.Control>
                          <Accordion.Panel>
                            <div
                              className={ classes.content }
                              dangerouslySetInnerHTML={ { __html: section.text ?? '' } }
                            />
                          </Accordion.Panel>
                        </Stack>
                      </Accordion.Item>
                    )) }
                  </Accordion>
                </Accordion.Panel>
              </Stack>
            </Accordion.Item>
          );
        }) }
      </Accordion>

    </Fragment>
  );
};

export default RisklineDetailedCountryReport;
