import { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import useDrawer from "@/common/hooks/useDrawer";
import { Dispatch, RootState } from "@/core/store";
import RiskTimelineDrawerComponent
  from "@/tenant-context/control-risk-timeline/controls/RiskTimelineDrawer/RiskTimelineDrawer.component";

const RiskTimelineDrawerControl: FC = () => {

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const {
    geoData: riskAlertsGeoData,
    showOnlyImpactedRiskEvents
  } = useSelector((state: RootState) => state.riskAlerts);

  const disabledRiskFilterTypes = useSelector(
    (state: RootState) => state.riskEventFilterDataOptions.disabledRiskFilterTypes
  );
  const riskLevelFilters = useSelector(
    (state: RootState) => state.riskEventFilterDataOptions.riskLevelFilters.riskline
  );

  const {
    riskTimeline: {
      startTime,
      endTime,
      chartRiskEvents,
      filterDataRange,
      isFilterTimelineByMapViewPortChecked
    },
    bigMap: {
      currentViewPort
    }
  } = useSelector((state: RootState) => state);

  const {
    riskTimeline: {
      fetchChartData,
      SET_FILTER_TIMELINE_BY_MAP_VIEWPORT,
      SET_FILTER_DATE_RANGE
    },
    riskAlerts: {
      clearCurrentRiskAlerts,
      subscribeToRiskAlerts
    }
  } = useDispatch<Dispatch>();

  const {
    isOpen
  } = useDrawer('risk-timeline-graph-control', 'bottom');

  const fetchRiskAlertData = useCallback(() => {
    fetchChartData({
      riskAlertsGeoData,
      startTime,
      endTime,
      showOnlyImpactedRiskEvents,
      disabledRiskCategories: disabledRiskFilterTypes,
      riskLevelFilters
    });
  }, [
    disabledRiskFilterTypes,
    startTime,
    endTime,
    fetchChartData,
    riskAlertsGeoData,
    riskLevelFilters,
    showOnlyImpactedRiskEvents
  ]);

  useEffect(() => {
    fetchRiskAlertData();
  }, [
    riskAlertsGeoData,
    fetchChartData,
    startTime,
    endTime,
    showOnlyImpactedRiskEvents,
    disabledRiskFilterTypes,
    riskLevelFilters,
    fetchRiskAlertData
  ]);

  useEffect(() => {
    fetchRiskAlertData();
  }, [currentViewPort, fetchRiskAlertData, isFilterTimelineByMapViewPortChecked]);

  const handleFilterTimelineByMapViewPortChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      checked
    } = event.currentTarget;

    SET_FILTER_TIMELINE_BY_MAP_VIEWPORT(checked);
  }, [SET_FILTER_TIMELINE_BY_MAP_VIEWPORT]);

  const handleRiskEventFilterClose = useCallback(() => {
    // Switch to live mode only if date range is selected
    if (filterDataRange[0] && filterDataRange[1]) {
      SET_FILTER_DATE_RANGE([null, null]);
      clearCurrentRiskAlerts();
      subscribeToRiskAlerts();
    }
  }, [ SET_FILTER_DATE_RANGE, clearCurrentRiskAlerts, filterDataRange, subscribeToRiskAlerts ]);

  const onDrawerVariantChanged = useCallback(() => {
    setIsExpanded((prev) => !prev);
  }, []);

  useEffect(() => {
    if (!isOpen) {
      handleRiskEventFilterClose();
    }
  }, [ handleRiskEventFilterClose, isOpen ]);

  return (
    <RiskTimelineDrawerComponent
      riskAlertData={ chartRiskEvents }
      onFilterTimelineByMapViewPortChange={ handleFilterTimelineByMapViewPortChange }
      isFilterTimelineByMapViewPortChecked={ isFilterTimelineByMapViewPortChecked }
      onCloseClick={ handleRiskEventFilterClose }
      onDrawerVariantChanged={ onDrawerVariantChanged }
      isExpanded={ isExpanded }
    />
  );
};

export default RiskTimelineDrawerControl;
