import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Dispatch, RootState } from "@/core/store";
import { RiskProviders } from "@/tenant-context/common/types/risk";
import { useMapLayersDrawerContext } from "@/tenant-context/control-risk-config/context/MapLayersDrawer.context";
import { RiskProviderItem } from "@/tenant-context/control-risk-config/types/risk";
import { useTOCCountersCalc } from "@/tenant-context/visualisation-risk-alerts/hooks/useTOCCountersCalc";

import { riskProvidersMeta } from "../../util/riskProvidersMeta";
import RiskProvidersTreeComponent from "./RiskProvidersTree.component";

const RiskProvidersTreeContainer = () => {
  const { openedTreeItems, handleToggleTreeItem } = useMapLayersDrawerContext();
  const riskAlertsGeoData = useSelector(
    (state: RootState) => state.riskAlerts.geoData
  );
  const subscribedProviderList = useSelector(
    (state: RootState) => state.commonData.riskProviders
  );

  const {
    riskEventFilterDataOptions: {
      isGlobalCategoryToggleOnRiskline,
      isGlobalCategoryToggleOnBSOC,
      isGlobalCategoryToggleOnDataMinr,
      isGlobalCategoryToggleOnMaxSecurity,
      isGlobalCategoryToggleOnHealix,
      isGlobalCategoryToggleOnFactal
    }
  } = useSelector((state: RootState) => state);

  const {
    riskEventFilterDataOptions: {
      ENABLE_GLOBAL_CATEGORY_FILTER_RISKLINE,
      DISABLE_GLOBAL_CATEGORY_FILTER_RISKLINE,
      ENABLE_GLOBAL_CATEGORY_FILTER_BSOC,
      DISABLE_GLOBAL_CATEGORY_FILTER_BSOC,
      ENABLE_GLOBAL_CATEGORY_FILTER_DATAMINR,
      DISABLE_GLOBAL_CATEGORY_FILTER_DATAMINR,
      ENABLE_GLOBAL_CATEGORY_FILTER_MAX_SECURITY,
      DISABLE_GLOBAL_CATEGORY_FILTER_MAX_SECURITY,
      SET_ALL_RISK_LEVEL_FILTER,
      ENABLE_GLOBAL_CATEGORY_FILTER_HEALIX,
      DISABLE_GLOBAL_CATEGORY_FILTER_HEALIX,
      ENABLE_GLOBAL_CATEGORY_FILTER_FACTAL,
      DISABLE_GLOBAL_CATEGORY_FILTER_FACTAL
    }
  } = useDispatch<Dispatch>();

  const enabledRiskProviders = useMemo(() => {
    const byProvider = {
      riskline: isGlobalCategoryToggleOnRiskline,
      bsoc: isGlobalCategoryToggleOnBSOC,
      dataminr: isGlobalCategoryToggleOnDataMinr,
      "max-security": isGlobalCategoryToggleOnMaxSecurity,
      healix: isGlobalCategoryToggleOnHealix,
      factal: isGlobalCategoryToggleOnFactal
    };

    return new Set(
      Object.entries(byProvider)
        .filter(([_, enabled]) => enabled)
        .map(([provider]) => provider)
    );
  }, [
    isGlobalCategoryToggleOnMaxSecurity,
    isGlobalCategoryToggleOnBSOC,
    isGlobalCategoryToggleOnDataMinr,
    isGlobalCategoryToggleOnRiskline,
    isGlobalCategoryToggleOnHealix,
    isGlobalCategoryToggleOnFactal
  ]);

  const onRiskProviderSwitch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.stopPropagation();
      const { value, checked } = event.currentTarget;

      if (value === "riskline") {
        if (checked) {
          SET_ALL_RISK_LEVEL_FILTER(RiskProviders.riskline);
          ENABLE_GLOBAL_CATEGORY_FILTER_RISKLINE();
        } else {
          DISABLE_GLOBAL_CATEGORY_FILTER_RISKLINE();
        }
      } else if (value === "bsoc") {
        if (checked) {
          ENABLE_GLOBAL_CATEGORY_FILTER_BSOC();
        } else {
          DISABLE_GLOBAL_CATEGORY_FILTER_BSOC();
        }
      } else if (value === "dataminr") {
        if (checked) {
          SET_ALL_RISK_LEVEL_FILTER(RiskProviders.dataminr);
          ENABLE_GLOBAL_CATEGORY_FILTER_DATAMINR();
        } else {
          DISABLE_GLOBAL_CATEGORY_FILTER_DATAMINR();
        }
      } else if (value === "max-security") {
        if (checked) {
          ENABLE_GLOBAL_CATEGORY_FILTER_MAX_SECURITY();
        } else {
          DISABLE_GLOBAL_CATEGORY_FILTER_MAX_SECURITY();
        }
      } else if (value === "healix") {
        if (checked) {
          ENABLE_GLOBAL_CATEGORY_FILTER_HEALIX();
        } else {
          DISABLE_GLOBAL_CATEGORY_FILTER_HEALIX();
        }
      } else if (value === "factal") {
        if (checked) {
          ENABLE_GLOBAL_CATEGORY_FILTER_FACTAL();
        } else {
          DISABLE_GLOBAL_CATEGORY_FILTER_FACTAL();
        }
      }
    },
    [
      SET_ALL_RISK_LEVEL_FILTER,
      ENABLE_GLOBAL_CATEGORY_FILTER_RISKLINE,
      DISABLE_GLOBAL_CATEGORY_FILTER_RISKLINE,
      ENABLE_GLOBAL_CATEGORY_FILTER_BSOC,
      DISABLE_GLOBAL_CATEGORY_FILTER_BSOC,
      ENABLE_GLOBAL_CATEGORY_FILTER_DATAMINR,
      DISABLE_GLOBAL_CATEGORY_FILTER_DATAMINR,
      ENABLE_GLOBAL_CATEGORY_FILTER_MAX_SECURITY,
      DISABLE_GLOBAL_CATEGORY_FILTER_MAX_SECURITY,
      ENABLE_GLOBAL_CATEGORY_FILTER_HEALIX,
      DISABLE_GLOBAL_CATEGORY_FILTER_HEALIX,
      ENABLE_GLOBAL_CATEGORY_FILTER_FACTAL,
      DISABLE_GLOBAL_CATEGORY_FILTER_FACTAL
    ]
  );

  const counters = useTOCCountersCalc(riskAlertsGeoData);

  const formattedRiskProviders: RiskProviderItem[] = useMemo(
    () =>
      subscribedProviderList
        ? subscribedProviderList.map((provider) => {
          const providerIcon = riskProvidersMeta.get(
            provider.providerName
          )?.Icon;
          const filteredNumber = counters.get(provider.providerName)?.filtered;

          return {
            ...provider,
            filteredNumber,
            Icon: providerIcon
          };
        })
        : [],
    [counters, subscribedProviderList]
  );

  return (
    <RiskProvidersTreeComponent
      riskProviders={ formattedRiskProviders }
      enabledRiskProviders={ enabledRiskProviders }
      openedTreeItems={ openedTreeItems }
      onRiskProviderSwitch={ onRiskProviderSwitch }
      onToggleTreeItem={ handleToggleTreeItem }
    />
  );
};

export default RiskProvidersTreeContainer;
