import { semanticColorPalette } from '@/core/styles/mantine/palettes';

export const healixRiskLevelMap: Record<string, { id: string, name: string }> = {
  'Low': { id: '1', name: 'Low' },
  'Medium': { id: '3', name: 'Medium' },
  'High': { id: '4', name: 'High' },
  'Extreme': { id: '5', name: 'Extreme' }
};

export const factalRiskLevelMap: Record<string, { id: string, vendorLevel: string, name: string, color: string }> = {
  '1': { id: '1', vendorLevel: 'Severity 1', name: 'Low', color: semanticColorPalette.risk.low },
  '2': { id: '2', vendorLevel: 'Severity 2', name: 'Moderate', color: semanticColorPalette.risk.moderate },
  '3': { id: '3', vendorLevel: 'Severity 3', name: 'Medium', color: semanticColorPalette.risk.medium },
  '4': { id: '4', vendorLevel: 'Severity 4', name: 'High', color: semanticColorPalette.risk.high },
  '5': { id: '5', vendorLevel: 'Severity 5', name: 'Extreme', color: semanticColorPalette.risk.extreme }
};
