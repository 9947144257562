import { ID_COMBINE_DELIMITER } from '@/tenant-context/common/config/consts.config';
import { factalRiskLevelMap } from '@/tenant-context/common/config/risk.config';
import { extractCountryCode, getCountryIsoCode } from '@/tenant-context/common/util/country-to-iso-map';
import { FactalExternalData } from '@/tenant-context/visualisation-risk-alerts/types/factal';
import { GenericRiskAlertResponse } from '@/tenant-context/visualisation-risk-alerts/types/risk-alert-response';
import { RiskAlertEvent } from '@/tenant-context/visualisation-risk-alerts/types/risk-alerts';

export const mapRiskAlertToFactalAlert = (
  impactEngineRiskAlert: GenericRiskAlertResponse<FactalExternalData>
): Array<RiskAlertEvent<FactalExternalData>> => {
  const {
    request,
    peopleCount,
    assetCount,
    impactStatus,
    impactedAreas
  } = impactEngineRiskAlert;

  const {
    tenantId,
    eventType,
    ext_id,
    text,
    title,
    start_date,
    end_date,
    countries,
    externalData: {
      severity,
      topics
    },
    source
  } = request;

  const otherCategories = topics
    .filter(topic => topic.topic.kind === 'tag' || topic.topic.kind === 'vertical')
    .map(topic => ({ id: `factal/${topic.topic.slug}`, name: topic.topic.name }));

  return impactedAreas.map((impactedArea) => ({
    json: {
      meta: {
        company_id: tenantId,
        geojson: {
          type: "Feature",
          geometry: {
            type: 'Point',
            coordinates: [
              impactedArea.calculation.targetPoint.lon,
              impactedArea.calculation.targetPoint.lat
            ]
          },
          properties: {}
        },
        language: 'en_US'
      },
      alert: {
        id: ext_id,
        text: text,
        title: title,
        advice: text,
        category: { id: `factal/${eventType}`, name: eventType },
        other_categories: otherCategories,
        countries: countries.map(country => ({
          name: country.name,
          iso_code: country.iso_code ?? getCountryIsoCode(country.name) ?? extractCountryCode(country.name) ?? ''
        })),
        location_explanation: countries.map(country => country.name).join(', '),
        risk_level: factalRiskLevelMap[severity] || { id: '5', name: 'Extreme' },
        is_breaking: false,
        is_notice: false,
        parent_id: '',
        regions: countries.map(country => ({ code: country.iso_code, name: country.name })),
        start_date: start_date,
        end_date: end_date
      },
      externalData: { ...request.externalData } as FactalExternalData
    },
    meta: {
      createdTime: impactEngineRiskAlert.meta.createdTime,
      updatedTime: impactEngineRiskAlert.meta.updatedTime
    },
    source: source,
    tid: [ext_id, impactedArea.id].join(ID_COMBINE_DELIMITER),
    isImpacted: impactStatus === 'CLOSED' ? false : peopleCount > 0 || assetCount > 0
  }));
};
