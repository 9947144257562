import { createStyles } from "@mantine/core";

export const useWeatherStaticStyles = createStyles(() => ({
  root: {
    '& svg': {
      width: '100%'
    }
  }
}));

