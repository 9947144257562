import { createStyles, MantineTheme } from "@mantine/core";

import { genericColorPalette } from "@/core/styles/mantine/palettes";

export const useWeatherLayerPopupStyles = createStyles((theme: MantineTheme) => ({
  wrapper: {
    minWidth: 232
  },
  title: {
  },
  body: {
    width: 'max-content',
    minWidth: '100%',
    backgroundColor: genericColorPalette.dark[12],
    padding: 12
  },
  bodyText: {
  }
}));
