/* eslint-disable max-lines */
import { createStyles } from "@mantine/core";
import { MantineTheme } from "@mantine/styles/lib/theme";

import { CSS_KEY } from "@/core/styles/mantine/constants";
import { genericColorPalette } from "@/core/styles/mantine/palettes";

export const useSelectRecipientsPageStyles = createStyles((theme: MantineTheme) => ({
  accordianContainer: {
    width: '896px',
    height: 'max-content',
    minHeight: '-webkit-fill-available'
  },
  selectedItemsContainer: {
    height: 'max-content',
    minHeight: '-webkit-fill-available'
  },
  areaHidden: {
    display: 'none'
  },
  resultsCount: {
    marginBottom: '24px',
    fontSize: theme.fontSizes.sm
  },
  sectionHeader: {
    fontWeight: theme.other.fw.semibold,
    fontSize: theme.headings.sizes.h6.fontSize,
    lineHeight: theme.other.lh.s,
    marginBottom: '48px'
  },
  collapsableImpactDetails: {
    padding: '20px 20px'
  },
  tableColumns: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr 10%',
    paddingRight: '24px'
  },
  tableRow: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr 10%',
    height: '56px',
    width: 'calc(100% - 20px)',
    backgroundColor: theme.colors.neutral[9],
    marginTop: '17px',
    border: `0.5px solid ${theme.colors.neutral[6]}`,
    alignItems: 'center'
  },
  tableRowSelected: {
    backgroundColor: theme.colors.neutral[7],
    border: `0.5px solid ${theme.colors.neutral[7]}`

  },
  rowContainer: {
    height: '300px',
    overflowY: 'auto'
  },
  innerWrapper: {
    overflow: 'visible',
    boxSizing: 'border-box'
  },
  actionItem: {
    justifySelf: 'center',
    marginLeft: '0px',
    cursor: 'pointer'
  },
  tableItem: {
    marginLeft: '16px'
  },
  columnContainer: {
    fontWeight: theme.other.fw.regular,
    fontSize: theme.fontSizes.xs,
    color: theme.colors.neutral[5],
    display: 'flex',
    marginLeft: '16px',
    alignItems: 'center',
    svg: {
      marginLeft: '8px'
    }
  },
  peopleTabInner: {
    marginTop: '48px',
    input: {
      height: '48px'
    },
    [`.${CSS_KEY}-TextInput-icon`]: {
      color: theme.colors.neutral[4]
    },
    [`.${CSS_KEY}-InputWrapper-root`]: {
      paddingRight: '24px'
    }
  },
  collapsableRecipientSelect: {
    padding: '0px 0px 24px 24px'
  },
  radioItemInactive: {
    border: `1px solid ${theme.colors.neutral[5]}`,
    cursor: 'pointer',
    label: {
      cursor: 'pointer',
      color: theme.white
    },
    [`.${CSS_KEY}-Radio-inner input`]: {
      cursor: 'pointer'
    }
  },
  radioItemActive: {
    border: `1px solid ${theme.colors.neutral[6]}`,
    backgroundColor: theme.colors.neutral[9],
    label: {
      cursor: 'pointer',
      color: theme.colors.neutral[6],
      fontWeight: theme.other.fw.bold
    },
    [`.${CSS_KEY}-Radio-inner input`]: {
      cursor: 'pointer'
    }
  },
  radioGroup: {
    gap: '16px',
    paddingTop: '0px',
    label: {
      fontSize: theme.fontSizes.md
    },
    svg: {
      color: theme.colors.neutral[9]
    },
    [`.${CSS_KEY}-Stack-root`]: {
      paddingTop: '0px',
      gap: '25px'
    }
  },

  searchBoxWithHeadingWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'top',
    '*': {
      transition: '0.3s ease-out'
    },
    svg: {
      path: {
        fill: `${ theme.white }  !important`
      }
    }
  },

  searchBoxWrapperExpanded: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 'auto',
    border: `1px solid  ${theme.colors.neutral[5]}`,
    padding: '0 16px',
    input: {
      backgroundColor: 'transparent !important',
      padding: 0,
      border: 'none',
      width: 340,
      height: 48,
      transition: '0.3s ease-out'
    }
  },

  searchBoxWrapperShrink: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 'auto',
    border: '1px solid transparent',
    padding: '0 16px',
    input: {
      backgroundColor: 'transparent !important',
      padding: 0,
      border: 'none',
      paddingRight: 1,
      width: 0,
      height: 48,
      transition: '0.3s ease-out'
    }
  },

  channelCountWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 40,
    padding: '0 0 0 16px',
    border: `1px solid ${ theme.colors.neutral[6] }`
  },

  totalRecipientCountDiv: {
    display: 'flex',
    color: theme.colors.neutral[5],
    fontWeight: theme.other.fw.semibold
  },

  totalRecipientCount: {
    color: theme.other.semantic.accessibility.outer,
    marginLeft: 16
  },

  channelCountItemWrapper: {
    display: 'flex',
    height: '100%'
  },

  channelCountItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 128,
    backgroundColor: theme.colors.neutral[7],
    fontWeight: theme.other.fw.bold,
    marginLeft: 1,

    '& > svg': {
      height: 16,
      width: 16,
      marginRight: 8
    }
  },
  pillContainer: {
    display: 'flex',
    paddingTop: '24px',
    flexWrap: 'wrap',
    gap: 8,
    span: {
      fontSize: theme.fontSizes.sm,
      textTransform: "capitalize",
      fontWeight: theme.other.fw.regular
    },
    path: {
      fill: theme.white
    },
    [`.${CSS_KEY}-Badge-root`]: {
      border: `1px solid ${theme.white}`
    }
  },
  groupTabInner: {
    marginTop: '48px',
    input: {
      height: '48px'
    },
    [`.${CSS_KEY}-TextInput-icon`]: {
      color: theme.colors.neutral[4]
    },
    [`.${CSS_KEY}-InputWrapper-root`]: {
      marginBottom: '0px',
      marginRight: '24px'
    }
  },
  channelCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',

    '& > *:not(:first-child)': {
      marginLeft: 8
    }
  },
  impactColor: {
    width: '44px',
    textAlign: 'center'
  },
  impactLabel: {

  },
  truncatedText: {
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  impactPeopleContainer: {
    lineHeight: 3,
    marginLeft: '32px'
  },
  impactOther: {
    width: '44px',
    textAlign: 'center',
    path: {
      fill: theme.colors.neutral[4]
    }
  },
  impactNoReponse: {
    width: '44px',
    textAlign: 'center',
    path: {
      fill: genericColorPalette.warning[0]
    }
  },
  impactOk: {
    width: '44px',
    textAlign: 'center',
    path: {
      fill: genericColorPalette.success[0]
    }
  },
  impactAllRow: {
    display: 'flex',
    [`.${CSS_KEY}-Checkbox-root .${CSS_KEY}-Checkbox-inner`]: {
      'svg path': {
        fill: theme.colors.neutral[9]
      }
    },
    input: {
      backgroundColor: theme.colors.neutral[5]
    },
    'input:checked:hover': {
      backgroundColor: theme.other.semantic.accessibility.outer,
      cursor: 'pointer'
    },
    'input:checked, input:hover': {
      backgroundColor: theme.other.semantic.accessibility.outer,
      cursor: 'pointer'
    }
  },
  checkItemRow : {
    display: 'flex',
    [`.${CSS_KEY}-Checkbox-root .${CSS_KEY}-Checkbox-inner`]: {
      'svg path': {
        fill: theme.colors.neutral[9]
      }
    },
    input: {
      backgroundColor: theme.colors.neutral[5]
    },
    'input:checked:hover': {
      backgroundColor: theme.colors.neutral[5],
      cursor: 'pointer'
    },
    'input:checked, input:hover': {
      backgroundColor: theme.colors.neutral[5],
      cursor: 'pointer',
      border: 'none'
    }
  },
  listViewWrapper: {
    height: 'max-content',
    minHeight: '-webkit-fill-available'
  },
  recipientsTable: {
    '.ag-center-cols-container': {
      maxHeight: '470px',
      overflowY: 'auto',
      overflowX: 'hidden'
    },
    '.ag-center-cols-clipper': {
      height: 'auto !important'
    },
    '.ag-root-wrapper-body': {
      minHeight: '535px'
    }
  },
  recipientContainer: {
    display: 'grid',
    gridTemplateColumns: '896px 1fr',
    gap: '16px',
    minHeight: '500px',
    overflowY: 'auto',
    overflowX: 'hidden',
    height: 'inherit'
  },
  loadingRow: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: 24,

    '& > div': {
      display: 'flex',
      border: `1px solid ${ theme.colors.neutral[7] }`,
      marginTop: 4,
      marginRight: 4,
      flex: 1,
      padding: 8
    }
  },
  noRecords: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 24,
    height: 40,
    border: `1px solid ${ theme.colors.neutral[7] }`,
    color: theme.colors.neutral[5]
  },
  peopleSelectTab: {
    button: {
      '&[data-active]': {
        backgroundColor: theme.colors.neutral[9] + '!important'
      },
      '&:hover': {
        backgroundColor: `${theme.colors.neutral[9]} !important`
      }
    }
  },
  agGridCellWrapWidth: {
    '.ag-cell-wrapper': {
      width: '100%'
    }
  }
}));
