import { FC } from "react";
import { ErrorBoundary } from 'react-error-boundary';

import LocalErrorBoundaryFallbackComponent from './LocalErrorBoundaryFallback.component';

type Props = {
  onReset?: () => void;
}

const LocalErrorBoundaryComponent: FC<Props> = ({
  children,
  onReset
}) => {
  return (
    <ErrorBoundary
      onReset={ onReset }
      FallbackComponent={ LocalErrorBoundaryFallbackComponent }
    >
      { children }
    </ErrorBoundary>
  );
};

export default LocalErrorBoundaryComponent;
