import React, { FC, RefObject, useEffect, useState } from "react";

import ScrollableSliderComponent from "./ScrollableSlider.component";

type Props = {
    children: React.ReactNode | React.ReactNode[],
    childWidth: number,
    gap: number,
    containerRef: RefObject<HTMLDivElement>
}

const ScrollableSlider: FC<Props> = ({ children, childWidth, gap = 16, containerRef }) => {

  const childArray = React.Children.toArray(children);
  const [isScrollable, setIsScrollable] = useState<boolean>(false);

  useEffect(() => {
    const checkContainerScrollable = () => {
      if(containerRef.current){
        const parentWidth = containerRef.current.clientWidth;
        const childrenWidth = childArray?.length * (childWidth + gap);
        setIsScrollable(childrenWidth > parentWidth);
      }
    };

    checkContainerScrollable();
    window.addEventListener("parentResize", checkContainerScrollable);
    return () => window.removeEventListener("parentResize", checkContainerScrollable);
  }, [childArray?.length, childWidth, containerRef, gap]);

  return (
    <ScrollableSliderComponent 
      // eslint-disable-next-line react/no-children-prop
      children={ childArray }
      isScrollable={ isScrollable }
      childWidth={ childWidth }
      gap={ gap }
    />
  );
};

export default ScrollableSlider;
