import { createModel } from "@rematch/core";

import { GridParams } from "@/common/types/ag-grid";
import { handleError, handleSuccess } from "@/common/util/common-functions";
import { Dispatch, RootModel, RootState } from "@/core/store";
import {
  caseTypesConfigurationsApi
} from "@/platform-context/control-icm-management/api/CaseTypes/caseTypesConfigurations";
import {
  caseTypesCustomerAccessApi
} from "@/platform-context/control-icm-management/api/CaseTypes/caseTypesCustomerAccess";
import {
  caseTypesTeamsAndRolesApi
} from "@/platform-context/control-icm-management/api/CaseTypes/caseTypesTeamsAndRoles";
import {
  AccessibleCustomer, AllowedCustomerSet,
  ICMSubscribedTenants
} from "@/platform-context/control-icm-management/types/CaseTypes/caseTypesCustomerAccess";
import { PaginatedResult } from "@/tenant-context/control-profile/types/profile";

import { caseFeaturesApi } from "../api/CaseTypes/caseFeatures";
import { caseGuideApi } from "../api/CaseTypes/caseGuide";
import { caseTypesListOverviewApi } from "../api/CaseTypes/caseTypesListOverview";
import { CaseFeature } from "../types/CaseTypes/caseFeatures";
import { CaseGuideForm } from "../types/CaseTypes/caseGuide";
import { CaseTypeForm, CaseTypeItem, CaseTypesList, ICloneCaseType } from "../types/CaseTypes/caseTypeListOverview";
import {
  CaseStatusDeleteItem,
  CaseStatusItem,
  PeopleStatusItem, PriorityDeleteItem,
  PriorityItem
} from "../types/CaseTypes/caseTypesConfigurations";
import {
  ChecklistItem, CopyItemsFromPlaybookParams, CreateChecklistItemParams,
  CreateRoleParams,
  CreateTeamParams, DeleteChecklistItemParams, DeleteRoleParams,
  DeleteTeamParams, PlaybookListItem,
  Role, RoleListItem,
  Team
} from "../types/CaseTypes/caseTypeTeamsAndRoles";

type CaseTypesState = {
    caseTypesListData?: PaginatedResult<CaseTypesList>,
    caseTypeRecord?: CaseTypeItem,
    caseGuideRecord?: CaseGuideForm,
    caseFeatureList?: PaginatedResult<CaseFeature>
    peopleStatusListData?: PaginatedResult<PeopleStatusItem>
    caseStatusListData?: PaginatedResult<CaseStatusItem>
    peopleStatus?: PeopleStatusItem
    priorityListData?: PaginatedResult<PriorityItem>
    priorityRecord?: PriorityItem
    customerAccessListData?: PaginatedResult<AccessibleCustomer>
    icmTenantsListData?: PaginatedResult<ICMSubscribedTenants>,
    icmTenantsListDataExceptSelected?: PaginatedResult<ICMSubscribedTenants>,
    isCustomerAccessTenantsDataLoading: boolean
    teamsListData?: PaginatedResult<Team>,
    activeTeam?: Team
    isTeamLoading: boolean
    rolesListData: PaginatedResult<RoleListItem>
    activeRole?: Role
    checklistListData?: PaginatedResult<ChecklistItem>
    activeChecklistItem?: ChecklistItem
    playbookList?: PaginatedResult<PlaybookListItem>
};

const caseTypesDataModel = {
  name: 'caseTypes',
  state: {
    caseTypesListData: undefined,
    caseTypeRecord: undefined,
    caseGuideRecord: undefined,
    caseFeatureList: undefined,
    peopleStatusListData: undefined,
    caseStatusListData: undefined,
    peopleStatus: undefined,
    priorityListData: undefined,
    priorityRecord: undefined,
    isCustomerAccessTenantsDataLoading: false,
    customerAccessListData: {
      totalItems: 0,
      totalPages: 0,
      currentPage: 1,
      items: []
    },
    icmTenantsListData: {
      totalItems: 0,
      totalPages: 0,
      currentPage: 1,
      items: []
    },
    icmTenantsListDataExceptSelected: {
      totalItems: 0,
      totalPages: 0,
      currentPage: 1,
      items: []
    },
    teamsListData:  {
      totalItems: 0,
      totalPages: 0,
      currentPage: 1,
      items: []
    },
    rolesListData:  {
      totalItems: 0,
      totalPages: 0,
      currentPage: 1,
      items: []
    },
    checklistListData:  {
      totalItems: 0,
      totalPages: 0,
      currentPage: 1,
      items: []
    },
    playbookList: {
      totalItems: 0,
      totalPages: 0,
      currentPage: 1,
      items: []
    },
    isTeamLoading: false
  } as CaseTypesState,
  reducers: {
    SET_CASE_TYPE_LIST: (state: CaseTypesState, caseTypesListData: CaseTypesState['caseTypesListData']) => ({
      ...state,
      caseTypesListData
    }),
    SET_CASE_TYPE_RECORD: (state: CaseTypesState, caseTypeRecord: CaseTypesState['caseTypeRecord']) => ({
      ...state,
      caseTypeRecord
    }),
    SET_CASE_GUIDE_RECORD: (state: CaseTypesState, caseGuideRecord: CaseTypesState['caseGuideRecord']) => ({
      ...state,
      caseGuideRecord
    }),
    SET_PEOPLE_STATUS_LIST: (state: CaseTypesState, peopleStatusListData: CaseTypesState['peopleStatusListData']) => ({
      ...state,
      peopleStatusListData
    }),
    SET_CASE_STATUS_LIST: (state: CaseTypesState, caseStatusListData: CaseTypesState['caseStatusListData']) => ({
      ...state,
      caseStatusListData
    }),
    SET_PEOPLE_STATUS: (state: CaseTypesState, peopleStatus: CaseTypesState['peopleStatus']) => ({
      ...state,
      peopleStatus
    }),
    SET_CASE_FEATURE_LIST: (state: CaseTypesState, caseFeatureList: CaseTypesState['caseFeatureList']) => ({
      ...state,
      caseFeatureList
    }),
    SET_PRIORITY_LIST: (state: CaseTypesState, priorityListData: CaseTypesState['priorityListData']) => ({
      ...state,
      priorityListData
    }),
    SET_PRIORITY_RECORD: (state: CaseTypesState, priorityRecord: CaseTypesState['priorityRecord']) => ({
      ...state,
      priorityRecord
    }),
    SET_CUSTOMER_ACCESS_LIST_DATA: (state: CaseTypesState, customerAccessListData: CaseTypesState['customerAccessListData']) => ({
      ...state,
      customerAccessListData
    }),
    SET_ICM_TENANTS_LIST_DATA: (state: CaseTypesState, icmTenantsListData: CaseTypesState['icmTenantsListData']) => ({
      ...state,
      icmTenantsListData
    }),
    SET_ICM_TENANTS_LIST_DATA_EXCEPT_SELECTED: (state: CaseTypesState, icmTenantsListDataExceptSelected: CaseTypesState['icmTenantsListDataExceptSelected']) => ({
      ...state,
      icmTenantsListDataExceptSelected
    }),
    SET_IS_CUSTOMER_ACCESS_TENANTS_DATA_LOADING: (state: CaseTypesState, isCustomerAccessTenantsDataLoading: CaseTypesState['isCustomerAccessTenantsDataLoading']) => ({
      ...state,
      isCustomerAccessTenantsDataLoading
    }),
    SET_IS_TEAM_LOADING: (state: CaseTypesState, isTeamLoading: CaseTypesState['isTeamLoading']) => ({
      ...state,
      isTeamLoading
    }),
    SET_TEAMS_LIST_DATA: (state: CaseTypesState, teamsListData: CaseTypesState['teamsListData']) => ({
      ...state,
      teamsListData
    }),
    SET_ACTIVE_TEAM: (state: CaseTypesState, activeTeam: CaseTypesState['activeTeam']) => ({
      ...state,
      activeTeam
    }),
    SET_ROLES_LIST: (state: CaseTypesState, rolesListData: CaseTypesState['rolesListData']) => ({
      ...state,
      rolesListData
    }),
    SET_ACTIVE_ROLE: (state: CaseTypesState, activeRole: CaseTypesState['activeRole']) => ({
      ...state,
      activeRole
    }),
    SET_CHECKLIST_LIST: (state: CaseTypesState, checklistListData: CaseTypesState['checklistListData']) => ({
      ...state,
      checklistListData
    }),
    SET_ACTIVE_CHECKLIST_ITEM: (state: CaseTypesState, activeChecklistItem: CaseTypesState['activeChecklistItem']) => ({
      ...state,
      activeChecklistItem
    }),
    SET_PLAYBOOK_LIST: (state: CaseTypesState, playbookList: CaseTypesState['playbookList']) => ({
      ...state,
      playbookList
    })
  },
  effects: (dispatch: Dispatch) => ({
    async getCaseTypesListData(payload: {
      gridParams: GridParams,
  }, _state: RootState): Promise<PaginatedResult<CaseTypesList>> {

      const { searchQuery, additionalParams } = payload.gridParams;

      // eslint-disable-next-line fp/no-let
      let additionalParamsQuery = '';
      if(searchQuery){
      // eslint-disable-next-line no-param-reassign
        payload.gridParams.searchQuery = `name LIKE %27%25${searchQuery}%25%27`;
      }

      if(additionalParams?.isEnabled){
        // eslint-disable-next-line no-param-reassign
        additionalParamsQuery = `isEnabled=true`;
      }

      const caseTypesData = await caseTypesListOverviewApi.getCaseTypesListData(
        payload.gridParams.page,
        payload.gridParams.size,
        payload.gridParams.sort,
        payload.gridParams.searchQuery,
        additionalParamsQuery
      );

      dispatch.caseTypes.SET_CASE_TYPE_LIST(caseTypesData);
      return caseTypesData;
    },
    async saveCaseType(params: CaseTypeForm, _state: RootState): Promise<boolean> {
      // eslint-disable-next-line fp/no-let
      let result;
      if(params._id){
        result = await caseTypesListOverviewApi.updateCaseType(params).catch(handleError);
      } else{
        result = await caseTypesListOverviewApi.saveCaseType(params).catch(handleError);
      }

      return !!result;
    },
    async cloneCaseType(params: ICloneCaseType, _state: RootState): Promise<boolean> {
      return !!await caseTypesListOverviewApi.cloneCaseType(params).catch(handleError);
    },
    async deleteCaseType(caseTypeId: string, _state: RootState): Promise<boolean> {
      return !!await caseTypesListOverviewApi.deleteCaseType(caseTypeId).catch(handleError);
    },
    async getCaseTypeById(id: string, _state: RootState): Promise<CaseTypeItem> {
      const caseType = await caseTypesListOverviewApi.getCaseTypeById(id);
      dispatch.caseTypes.SET_CASE_TYPE_RECORD(caseType);
      return caseType;
    },
    async getCaseGuide(_: void, state: RootState): Promise<CaseGuideForm> {
      const caseType = await caseGuideApi.getCaseGuide(state.caseTypes.caseTypeRecord?._id || "");
      dispatch.caseTypes.SET_CASE_GUIDE_RECORD(caseType.items[0]);
      return caseType.items[0];
    },
    async saveCaseGuide(params: CaseGuideForm, state: RootState): Promise<boolean> {
      const caseTypeId = state.caseTypes.caseTypeRecord?._id || "";
      const result = params._id ? await caseGuideApi.updateCaseGuide(caseTypeId, params).catch(handleError)
        :  await caseGuideApi.saveCaseGuide(caseTypeId, params).catch(handleError);

      return !!result;
    },
    async getCaseFeatureListById(caseTypeId: string, _state: RootState): Promise<PaginatedResult<CaseFeature>> {
      const caseFeatureList = await caseFeaturesApi.getCaseFeatureListById(caseTypeId || "");
      dispatch.caseTypes.SET_CASE_FEATURE_LIST(caseFeatureList);
      return caseFeatureList;
    },
    async updateCaseFeature(params: CaseFeature, state: RootState): Promise<boolean> {
      const result = await caseFeaturesApi.updateCaseFeature((state.caseTypes.caseTypeRecord?._id || ""), [params])
        .catch(handleError);

      return !!result;
    },
    async getListPeopleStatusData(payload: {
          gridParams: GridParams
      }, _state: RootState): Promise<PaginatedResult<PeopleStatusItem>> {

      const caseTypeId = payload.gridParams.additionalParams?.caseTypeId;

      if(!caseTypeId) {
        return {
          totalItems: 0,
          totalPages: 0,
          currentPage: 1,
          items: []
        };
      }

      const query = payload.gridParams.searchQuery;
      if(query){
        // eslint-disable-next-line no-param-reassign
        payload.gridParams.searchQuery = `statusName LIKE %27%25${query}%25%27`;
      }

      const peopleStatusData = await caseTypesConfigurationsApi.getListPeopleStatusData(
        caseTypeId,
        payload.gridParams.page,
        payload.gridParams.size,
        payload.gridParams.sort,
        payload.gridParams.searchQuery
      );

      dispatch.caseTypes.SET_PEOPLE_STATUS_LIST(peopleStatusData);
      return peopleStatusData;
    },
    async createPeopleStatus(
      params: {data: PeopleStatusItem, caseTypeId: string},
      _state: RootState
    ): Promise<boolean> {

      if (!params.caseTypeId) {
        return false;
      }
      const result = await caseTypesConfigurationsApi.createPeopleStatus(params.data, params.caseTypeId)
        .then(() => handleSuccess('People status created successfully'))
        .then(() => true)
        .catch(handleError);

      return !!result;
    },
    async updatePeopleStatus(
      params: {data: PeopleStatusItem, caseTypeId: string, caseTypeStatusId: string},
      _state: RootState
    ): Promise<boolean> {

      if (!params.caseTypeId || !params.caseTypeStatusId ) {
        return false;
      }
      const result = await caseTypesConfigurationsApi.updatePeopleStatus(
        params.data,
        params.caseTypeId,
        params.caseTypeStatusId
      )
        .then(() => handleSuccess('People status updated successfully'))
        .then(() => true)
        .catch(handleError);

      return !!result;
    },
    async getPeopleStatusById(
      params: {caseTypeId: string, caseTypeStatusId: string},
      _state: RootState
    ): Promise<PeopleStatusItem | undefined> {
      const result = await caseTypesConfigurationsApi.getPeopleStatusById(
        params.caseTypeId,
        params.caseTypeStatusId
      ).catch(handleError);

      if(result) {
        dispatch.caseTypes.SET_PEOPLE_STATUS(result);
        return result;
      }

    },
    async deletePeopleStatus(
      params: {data: CaseStatusDeleteItem, caseTypeId: string},
      _state: RootState
    ): Promise<void> {
      await caseTypesConfigurationsApi.deletePeopleStatus(params.data, params.caseTypeId)
        .then(() => handleSuccess('People status deleted successfully'))
        .catch(handleError);
    },
    async createCaseTypeStatus(
      params: {data: CaseStatusItem, caseTypeId: string},
      _state: RootState
    ): Promise<boolean> {

      if (!params.caseTypeId) {
        return false;
      }
      const result = await caseTypesConfigurationsApi.createCaseStatus(params.data, params.caseTypeId)
        .then(() => handleSuccess('Case status created successfully'))
        .then(() => true)
        .catch(handleError);

      return !!result;
    },
    async updateCaseStatus(
      params: {data: CaseStatusItem, caseTypeId: string, caseTypeStatusId: string},
      _state: RootState
    ): Promise<boolean> {

      if (!params.caseTypeId || !params.caseTypeStatusId ) {
        return false;
      }
      const result = await caseTypesConfigurationsApi.updateCaseStatus(
        params.data,
        params.caseTypeId,
        params.caseTypeStatusId
      )
        .then(() => handleSuccess('Case status updated successfully'))
        .then(() => true)
        .catch(handleError);

      return !!result;
    },
    async getCaseStatusById(
      params: {caseTypeId: string, caseTypeStatusId: string},
      _state: RootState
    ): Promise<PeopleStatusItem | undefined> {
      const result = await caseTypesConfigurationsApi.getCaseStatusById(
        params.caseTypeId,
        params.caseTypeStatusId
      ).catch(handleError);

      if(result) {
        dispatch.caseTypes.SET_PEOPLE_STATUS(result);
        return result;
      }

    },
    async deleteCaseStatus(
      params: {data: CaseStatusDeleteItem, caseTypeId: string},
      _state: RootState
    ): Promise<void> {
      await caseTypesConfigurationsApi.deleteCaseStatus(params.data, params.caseTypeId)
        .then(() => handleSuccess('Case status deleted successfully'))
        .catch(handleError);
    },
    async getListCaseStatusData(payload: {
      gridParams: GridParams
    }, _state: RootState): Promise<PaginatedResult<CaseStatusItem>> {

      const caseTypeId = payload.gridParams.additionalParams?.caseTypeId;

      if(!caseTypeId) {
        return {
          totalItems: 0,
          totalPages: 0,
          currentPage: 1,
          items: []
        };
      }

      const query = payload.gridParams.searchQuery;
      if(query){
        // eslint-disable-next-line no-param-reassign
        payload.gridParams.searchQuery = `statusName LIKE %27%25${query}%25%27`;
      }

      const caseStatusData = await caseTypesConfigurationsApi.getListCaseStatusData(
        caseTypeId,
        payload.gridParams.page,
        payload.gridParams.size,
        payload.gridParams.sort,
        payload.gridParams.searchQuery
      );

      dispatch.caseTypes.SET_CASE_STATUS_LIST(caseStatusData);
      return caseStatusData;
    },
    async createPriority(
      params: {data: PriorityItem, caseTypeId: string},
      _state: RootState
    ): Promise<boolean> {

      if (!params.caseTypeId) {
        return false;
      }
      const result = await caseTypesConfigurationsApi.createPriority(params.data, params.caseTypeId)
        .then(() => handleSuccess('Priority created successfully'))
        .then(() => true)
        .catch(handleError);

      return !!result;
    },
    async updatePriority(
      params: {data: PriorityItem, caseTypeId: string, priorityId: string},
      _state: RootState
    ): Promise<boolean> {

      if (!params.caseTypeId || !params.priorityId ) {
        return false;
      }
      const result = await caseTypesConfigurationsApi.updatePriority(
        params.data,
        params.caseTypeId,
        params.priorityId
      )
        .then(() => handleSuccess('Priority updated successfully'))
        .then(() => true)
        .catch(handleError);

      return !!result;
    },
    async getPriorityById(
      params: {caseTypeId: string, priorityId: string},
      _state: RootState
    ): Promise<PriorityItem | undefined> {
      const result = await caseTypesConfigurationsApi.getPriorityById(
        params.caseTypeId,
        params.priorityId
      ).catch(handleError);

      if(result) {
        dispatch.caseTypes.SET_PRIORITY_RECORD(result);
        return result;
      }

    },
    async deletePriority(
      params: {data: PriorityDeleteItem, caseTypeId: string},
      _state: RootState
    ): Promise<void> {
      await caseTypesConfigurationsApi.deletePriority(params.data, params.caseTypeId)
        .then(() => handleSuccess('Priority deleted successfully'))
        .catch(handleError);
    },
    async getPriorityListData(payload: {
      gridParams: GridParams
    }, _state: RootState): Promise<PaginatedResult<PriorityItem>> {

      const caseTypeId = payload.gridParams.additionalParams?.caseTypeId;

      if(!caseTypeId) {
        return {
          totalItems: 0,
          totalPages: 0,
          currentPage: 1,
          items: []
        };
      }

      const query = payload.gridParams.searchQuery;
      if(query){
        // eslint-disable-next-line no-param-reassign
        payload.gridParams.searchQuery = `priorityName LIKE %27%25${query}%25%27`;
      }

      const result = await caseTypesConfigurationsApi.getPriorityListData(
        caseTypeId,
        payload.gridParams.page,
        payload.gridParams.size,
        payload.gridParams.sort,
        payload.gridParams.searchQuery
      );

      dispatch.caseTypes.SET_PRIORITY_LIST(result);
      return result;
    },
    async getCustomerAccessListData(
      caseTypeId: string,
      _state: RootState
    ): Promise<PaginatedResult<AccessibleCustomer>> {

      if(!caseTypeId) {
        return {
          totalItems: 0,
          totalPages: 0,
          currentPage: 1,
          items: []
        };
      }

      const result = await caseTypesCustomerAccessApi.getAccessibleCustomerList(caseTypeId);

      dispatch.caseTypes.SET_CUSTOMER_ACCESS_LIST_DATA(result);
      return result;
    },
    async getICMTenantsListData(
      caseTypeId: string,
      _state: RootState
    ): Promise<PaginatedResult<ICMSubscribedTenants>> {

      const result = await caseTypesCustomerAccessApi.getICMSubscribedTenants(caseTypeId);

      dispatch.caseTypes.SET_ICM_TENANTS_LIST_DATA(result);
      return result;
    },
    async getICMTenantsListDataExceptSelected(
      caseTypeId: string,
      _state: RootState
    ): Promise<PaginatedResult<ICMSubscribedTenants>> {

      const result = await caseTypesCustomerAccessApi.getICMSubscribedTenantsExceptSelected(caseTypeId);

      dispatch.caseTypes.SET_ICM_TENANTS_LIST_DATA_EXCEPT_SELECTED(result);
      return result;
    },
    async getCustomerAccessTenantsData(
      caseTypeId: string,
      _state: RootState
    ): Promise<void> {
      dispatch.caseTypes.SET_IS_CUSTOMER_ACCESS_TENANTS_DATA_LOADING(true);
      Promise.all([
        dispatch.caseTypes.getCustomerAccessListData(caseTypeId),
        dispatch.caseTypes.getICMTenantsListDataExceptSelected(caseTypeId),
        dispatch.caseTypes.getICMTenantsListData(caseTypeId)
      ]).finally(() => dispatch.caseTypes.SET_IS_CUSTOMER_ACCESS_TENANTS_DATA_LOADING(false));
    },
    async createAccessibleCustomerList(
      params: {data: AllowedCustomerSet, caseTypeId: string},
      _state: RootState
    ): Promise<boolean> {

      if (!params.caseTypeId) {
        return false;
      }
      const result = await caseTypesCustomerAccessApi.createAccessibleCustomerList(params.data, params.caseTypeId)
        .then(() => handleSuccess())
        .then(() => true)
        .catch(handleError);

      return !!result;
    },
    async updateAccessibleCustomerList(
      params: {data: AllowedCustomerSet, caseTypeId: string},
      _state: RootState
    ): Promise<boolean> {

      if (!params.caseTypeId) {
        return false;
      }
      const result = await caseTypesCustomerAccessApi.updateAccessibleCustomerList(params.data, params.caseTypeId)
        .then(() => handleSuccess())
        .then(() => true)
        .catch(handleError);

      return !!result;
    },
    async getTeamsListData(
      caseTypeId: string,
      _state: RootState
    ): Promise<PaginatedResult<Team>> {

      if(!caseTypeId) {
        return {
          totalItems: 0,
          totalPages: 0,
          currentPage: 1,
          items: []
        };
      }

      const result = await caseTypesTeamsAndRolesApi.getTeamListData(caseTypeId);

      if(result) {
        dispatch.caseTypes.SET_TEAMS_LIST_DATA(result);
        return result;
      }

      return {
        totalItems: 0,
        totalPages: 0,
        currentPage: 1,
        items: []
      };
    },
    async createTeam(
      params: {data: CreateTeamParams, caseTypeId: string},
      _state: RootState
    ): Promise<Team | boolean> {

      if (!params.caseTypeId) {
        return false;
      }
      dispatch.caseTypes.SET_IS_TEAM_LOADING(true);
      const result = await caseTypesTeamsAndRolesApi.createTeam(params.data, params.caseTypeId).catch(handleError);
      dispatch.caseTypes.SET_IS_TEAM_LOADING(false);

      if(result) {
        handleSuccess('Team created successfully');
        dispatch.caseTypes.SET_ACTIVE_TEAM(result);
      }

      return result;
    },
    async updateTeam(
      params: {data: CreateTeamParams, caseTypeId: string, teamId: string},
      _state: RootState
    ): Promise<boolean> {

      if (!params.caseTypeId || !params.teamId) {
        return false;
      }
      dispatch.caseTypes.SET_IS_TEAM_LOADING(true);
      const result = await caseTypesTeamsAndRolesApi.updateTeam(params.data, params.caseTypeId, params.teamId)
        .catch(handleError);

      dispatch.caseTypes.SET_IS_TEAM_LOADING(false);

      if(result) {
        handleSuccess('Team updated successfully');
        dispatch.caseTypes.SET_ACTIVE_TEAM(result);
      }

      return !!result;
    },
    async getTeam(
      params: { caseTypeId: string, teamId: string},
      _state: RootState
    ): Promise<boolean> {

      if (!params.caseTypeId || !params.teamId) {
        return false;
      }
      dispatch.caseTypes.SET_IS_TEAM_LOADING(true);
      const result = await caseTypesTeamsAndRolesApi.getTeam(params.caseTypeId, params.teamId).catch(handleError);
      dispatch.caseTypes.SET_IS_TEAM_LOADING(false);

      if(result) {
        dispatch.caseTypes.SET_ACTIVE_TEAM(result);
      }

      return !!result;
    },
    async deleteTeam(
      params: { data: DeleteTeamParams, caseTypeId: string},
      _state: RootState
    ): Promise<boolean> {

      if (!params.caseTypeId || !params.data) {
        return false;
      }
      dispatch.caseTypes.SET_IS_TEAM_LOADING(true);
      const result = await caseTypesTeamsAndRolesApi.deleteTeam(params.data, params.caseTypeId).catch(handleError);

      if(result) {
        handleSuccess('Team deleted successfully');
        const res = await dispatch.caseTypes.getTeamsListData(params.caseTypeId);
        if (res) {
          dispatch.caseTypes.SET_ACTIVE_TEAM(res?.items.length ? res.items[0] : undefined);
        }
      }
      dispatch.caseTypes.SET_IS_TEAM_LOADING(false);
      return !!result;
    },
    async createRole(
      params: {data: CreateRoleParams, caseTypeId: string},
      _state: RootState
    ): Promise<Team | boolean> {

      if (!params.caseTypeId) {
        return false;
      }
      dispatch.caseTypes.SET_IS_TEAM_LOADING(true);
      const result = await caseTypesTeamsAndRolesApi.createRole(params.data, params.caseTypeId).catch(handleError);
      dispatch.caseTypes.SET_IS_TEAM_LOADING(false);

      if(result) {
        handleSuccess('Role created successfully');
      }

      return result;
    },
    async deleteRole(
      params: { data: DeleteRoleParams, caseTypeId: string},
      _state: RootState
    ): Promise<boolean> {

      if (!params.caseTypeId || !params.data) {
        return false;
      }
      dispatch.caseTypes.SET_IS_TEAM_LOADING(true);
      const result = await caseTypesTeamsAndRolesApi.deleteRole(params.data, params.caseTypeId).catch(handleError);

      if(result) {
        handleSuccess('Role deleted successfully');
      }
      dispatch.caseTypes.SET_IS_TEAM_LOADING(false);
      return !!result;
    },
    async getListRolesData(payload: {
      gridParams: GridParams
    }, _state: RootState): Promise<PaginatedResult<RoleListItem>> {

      const caseTypeId = payload.gridParams.additionalParams?.caseTypeId;
      const activeTeamId = payload.gridParams.additionalParams?.activeTeamId;

      if(!caseTypeId || !activeTeamId ) {
        return {
          totalItems: 0,
          totalPages: 0,
          currentPage: 1,
          items: []
        };
      }

      // eslint-disable-next-line no-param-reassign
      payload.gridParams.searchQuery = `teamRef LIKE %27%25${activeTeamId}%25%27`;

      const data = await caseTypesTeamsAndRolesApi.getListRolesData(
        caseTypeId,
        payload.gridParams.page,
        payload.gridParams.size,
        payload.gridParams.sort,
        payload.gridParams.searchQuery
      );

      dispatch.caseTypes.SET_ROLES_LIST(data);
      return data;
    },
    async updateRole(
      params: {data: CreateRoleParams, caseTypeId: string, roleId: string},
      _state: RootState
    ): Promise<boolean> {

      if (!params.caseTypeId || !params.roleId) {
        return false;
      }
      dispatch.caseTypes.SET_IS_TEAM_LOADING(true);
      const result = await caseTypesTeamsAndRolesApi.updateRole(params.data, params.caseTypeId, params.roleId)
        .catch(handleError);

      dispatch.caseTypes.SET_IS_TEAM_LOADING(false);

      if(result) {
        handleSuccess('Role updated successfully');
        dispatch.caseTypes.SET_ACTIVE_ROLE(result);
      }

      return !!result;
    },
    async getRole(
      params: { caseTypeId: string, roleId: string},
      _state: RootState
    ): Promise<boolean> {

      if (!params.caseTypeId || !params.roleId) {
        return false;
      }
      dispatch.caseTypes.SET_IS_TEAM_LOADING(true);
      const result = await caseTypesTeamsAndRolesApi.getRole(params.caseTypeId, params.roleId).catch(handleError);
      dispatch.caseTypes.SET_IS_TEAM_LOADING(false);

      if(result) {
        dispatch.caseTypes.SET_ACTIVE_ROLE(result);
      }

      return !!result;
    },
    async createChecklistItem(
      params: {data: CreateChecklistItemParams, caseTypeId: string},
      _state: RootState
    ): Promise<ChecklistItem | boolean> {

      if (!params.caseTypeId || !params.data?.roleRef) {
        return false;
      }
      dispatch.caseTypes.SET_IS_TEAM_LOADING(true);
      const result = await caseTypesTeamsAndRolesApi.createChecklistItem(params.data, params.caseTypeId)
        .catch(handleError);

      dispatch.caseTypes.SET_IS_TEAM_LOADING(false);

      if(result) {
        handleSuccess('Checklist item created successfully');
      }

      return result;
    },
    async deleteChecklistItem(
      params: { data: DeleteChecklistItemParams, caseTypeId: string},
      _state: RootState
    ): Promise<boolean> {

      if (!params.caseTypeId || !params.data) {
        return false;
      }
      dispatch.caseTypes.SET_IS_TEAM_LOADING(true);
      const result = await caseTypesTeamsAndRolesApi.deleteChecklistItem(params.data, params.caseTypeId)
        .catch(handleError);

      if(result) {
        handleSuccess('Checklist item deleted successfully');
      }
      dispatch.caseTypes.SET_IS_TEAM_LOADING(false);
      return !!result;
    },
    async getChecklistListData(payload: {
      gridParams: GridParams
    }, _state: RootState): Promise<PaginatedResult<ChecklistItem>> {

      const { caseTypeId, roleRef }= payload.gridParams.additionalParams;

      if(!caseTypeId ) {
        return {
          totalItems: 0,
          totalPages: 0,
          currentPage: 1,
          items: []
        };
      }

      // eslint-disable-next-line no-param-reassign
      const query = payload.gridParams.searchQuery;
      if(query){
        // eslint-disable-next-line no-param-reassign
        payload.gridParams.searchQuery = `itemName LIKE %27%25${query}%25%27`;
      }

      const data = await caseTypesTeamsAndRolesApi.getChecklistItemListData(
        caseTypeId,
        roleRef,
        payload.gridParams.page,
        payload.gridParams.size,
        payload.gridParams.sort,
        payload.gridParams.searchQuery
      );

      dispatch.caseTypes.SET_CHECKLIST_LIST(data);
      return data;
    },
    async updateChecklistItem(
      params: {data: CreateChecklistItemParams, caseTypeId: string, checklistId: string},
      _state: RootState
    ): Promise<boolean> {

      if (!params.caseTypeId || !params.checklistId) {
        return false;
      }
      dispatch.caseTypes.SET_IS_TEAM_LOADING(true);
      const result = await caseTypesTeamsAndRolesApi.updateChecklistItem(
        params.data,
        params.caseTypeId,
        params.checklistId
      ).catch(handleError);

      dispatch.caseTypes.SET_IS_TEAM_LOADING(false);

      if(result) {
        handleSuccess('Checklist Item updated successfully');
        dispatch.caseTypes.SET_ACTIVE_CHECKLIST_ITEM(result);
      }

      return !!result;
    },
    async getPlaybookItems(): Promise<boolean> {

      const result = await caseTypesTeamsAndRolesApi.getPlaybookItems()
        .catch(handleError);

      if(result) {
        dispatch.caseTypes.SET_PLAYBOOK_LIST(result);
      }

      return !!result;
    },

    async getChecklistItem(
      params: { caseTypeId: string, checklistId: string},
      _state: RootState
    ): Promise<boolean> {

      if (!params.caseTypeId || !params.checklistId) {
        return false;
      }
      dispatch.caseTypes.SET_IS_TEAM_LOADING(true);
      const result = await caseTypesTeamsAndRolesApi.getChecklistItem(params.caseTypeId, params.checklistId)
        .catch(handleError);

      dispatch.caseTypes.SET_IS_TEAM_LOADING(false);

      if(result) {
        dispatch.caseTypes.SET_ACTIVE_CHECKLIST_ITEM(result);
      }

      return !!result;
    },
    async copyItemsFromPlaybook(
      params: {data: CopyItemsFromPlaybookParams, caseTypeId: string},
      _state: RootState
    ): Promise<boolean> {

      if (!params.caseTypeId) {
        return false;
      }
      dispatch.caseTypes.SET_IS_TEAM_LOADING(true);
      const result = await caseTypesTeamsAndRolesApi.copyItemsFromPlaybook(
        params.data,
        params.caseTypeId
      ).catch(handleError);

      dispatch.caseTypes.SET_IS_TEAM_LOADING(false);

      if(result) {
        handleSuccess('Item copied successfully');
      }

      return !!result;
    }
  })
};

export const caseTypes = createModel<RootModel>()(caseTypesDataModel);
