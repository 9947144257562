/* eslint-disable react/no-multi-comp */
import { FC, ReactElement } from "react";
import { useSelector } from "react-redux";

import LoaderScreen from "@/core/components/LoaderScreen/LoaderScreen.component";
import { RootState } from "@/core/store";
import AccessPermissions
  from "@/platform-context/control-icm-management/components/CaseTypes/Details/components/TeamsAndRoles/components/AccessPermissions";
import Checklist
  from "@/platform-context/control-icm-management/components/CaseTypes/Details/components/TeamsAndRoles/components/Checklist";
import Overview
  from "@/platform-context/control-icm-management/components/CaseTypes/Details/components/TeamsAndRoles/components/Overview";
import {
  RoleDetailsTabs
} from "@/platform-context/control-icm-management/components/CaseTypes/Details/components/TeamsAndRoles/components/RoleDetails/RoleDetails.context";

type Props = {
    activeTab: RoleDetailsTabs
}

const RowDetailsTabContent: FC<Props> = ({
  activeTab
}) => {
  const isLoading = useSelector((state: RootState) => state.caseTypes?.isTeamLoading);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderTabContent: Record<RoleDetailsTabs, () => ReactElement<any, any> | null> = {
    [RoleDetailsTabs.Overview]: () => ( <Overview /> ),
    [RoleDetailsTabs.AccessPermissions]: () => ( <AccessPermissions /> ),
    [RoleDetailsTabs.Checklist]: () => ( <Checklist /> )
  };

  if(isLoading) {
    return <LoaderScreen isLoading />;
  }

  return (
    <>
      { renderTabContent[activeTab]() }
    </>
  );
};

export default RowDetailsTabContent;
