import { createStyles } from "@mantine/core";
import { MantineTheme } from "@mantine/styles/lib/theme";

import { genericColorPalette } from "@/core/styles/mantine/palettes";
import { getRiskColorByRiskLevel } from "@/tenant-context/control-reports/util/country-risk-reports";


export const useImpactTabStyles = createStyles((theme: MantineTheme) => ({
  container:{
    padding: '0 24px'
  },
  cellWrapText:{
    height: 'auto'
  },
  rowWrapper:{
    alignItems: 'start !important'
  },

  nameCellWrapper: {
    all: 'unset',
    display: "flex",
    alignItems: "center",
    cursor: "pointer",

    '& > *:not(:last-child)': {
      padding: 5,
      marginRight: 10
    }
  },

  avatarName: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px'
  },

  profileThumbnail: {
    div: {
      fontSize: '9px'
    }
  },

  filterWrapper: {
    display: 'flex',
    gap: '8px',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    input: {
      minHeight: '32px'
    },
    "& input::placeholder": {
      fontSize: theme.fontSizes.sm
    },
    "& .mantine-Select-item": {
      fontSize: theme.fontSizes.sm
    }
  },

  impactSearch:{
    minWidth: '330px',
    flex: 1
  },

  impactSelect: {
    width: '160px',
    minWidth: '160px'
  },

  dropdownFilterWrapper: {
    display: 'flex',

    '& > *:not(:last-child)': {
      marginRight: 20
    }
  },

  expandIcon: {
    all: 'unset',
    position: 'absolute',
    top: 14,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer'
  },

  expandedRow: {
    whiteSpace: 'pre-wrap',
    lineHeight: '150%'
  },

  statusChangeButton:{
    display: 'flex',
    alignItems: 'center',
    padding: '2px 6px !important',
    minWidth: '96px',
    borderRadius: '6px',
    maxHeight: '30px !important',
    div:{
      justifyContent: 'center'
    }
  },

  textWrap:{
    display: "flex",
    flexDirection: 'column',
    fontSize: 12
  },

  dimmed:{
    color: theme.colors.neutral[5]
  },
  accented:{
    color: theme.other.semantic.accessibility.outer,
    fontSize: 14
  },
  underline:{
    borderBottom: `0.5px solid ${theme.colors.neutral[6]}`
  },
  impactTable: {
    width: '100%'
  },
  alignCenter:{
    justifyContent: "center",
    height: '100%'
  },
  statusIndicatorBg: {
    '&_NOT_SAFE': {
      border: `1px solid ${getRiskColorByRiskLevel('extreme')} !important`,
      span: {
        color: `${getRiskColorByRiskLevel('extreme')} !important`
      }
    },
    '&_NO_RESPONSE': {
      border: `1px solid ${getRiskColorByRiskLevel('medium')} !important`,
      span: {
        color: `${getRiskColorByRiskLevel('medium')} !important`
      }
    },
    '&_SAFE': {
      border: `1px solid ${genericColorPalette.success[2]} !important`,
      span:{
        color: `${genericColorPalette.success[2]} !important`
      }
    },
    '&_OTHER': {
      border: `1px solid grey !important`,
      span: {
        color: 'grey !important'
      }
    }
  },
  carouselContainer: {
    marginTop: '12px',
    backgroundColor: theme.colors.neutral[8],
    borderRadius: '12px',
    padding: '16px',
    display: 'flex',
    width: '100%'
  },
  emptyCarousel:{
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '112px',
    fontWeight: theme.other.fw.bold,
    fontSize: theme.fontSizes.sm
  },
  assetTitle: {
    display: 'flex',
    gap: '13px',
    alignItems: 'center',
    fontSize: theme.fontSizes.sm
  },
  assetWrapper: {
    display: 'flex',
    width: '272px',
    justifyContent: 'space-between'
  },
  assetContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  assetInnerText: {
    fontSize: theme.other.fs.xxs,
    color: theme.white,
    opacity: '64%',
    lineHeight: '125%',
    marginTop: '6px'
  },
  assetPeople: {
    fontWeight: theme.other.fw.bold,
    fontSize: theme.headings.sizes.h4.fontSize,
    display: 'flex',
    alignItems: 'center',
    padding: '8px',
    'svg' : {
      marginRight: '4px'
    }
  },
  carouselItem: {
    padding: '8px 16px',
    borderRadius: '8px',
    backgroundColor: theme.colors.alphaLightShades[1],
    height: '80px',
    display: 'flex'
  },
  rowRiskTier: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    svg: {
      width: '16px',
      height: '16px'
    }
  },
  centeredHeader: {
    justifyContent: 'center'
  },
  underlinedName: {
    textDecoration: 'underline',
    textUnderlineOffset: '3px'
  },
  lastUpdateContainer: {
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.fontSizes.xs,
    gap: '6px',
    marginTop: '12px',
    marginBottom: '24px'
  },
  impactSelectItems: {
    fontSize: theme.fontSizes.sm
  },
  arcMiniProfile: {
    '.restrata-Button-root': {
      marginTop: '10px',
      minHeight: '48px'
    }
  }
}));
