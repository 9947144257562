import { Flex } from '@mantine/core';
import { FC } from "react";
import { Popup } from "react-map-gl";

import { ReactComponent as RiskImpactAssetIcon } from "@/common/icons/riskImpactAssetIcon.svg";
import { ReactComponent as RiskImpactDistanceIcon } from "@/common/icons/riskImpactDistanceIcon.svg";
import { ReactComponent as RiskImpactPeopleIcon } from "@/common/icons/riskImpactPeopleIcon.svg";
import { ImpactPosition } from "@/tenant-context/visualisation-risk-details/types/risk-impact";

import { useRiskImpactPopupStyles } from "./RiskImpactPopup.styles";

type Props = {
  isShown: boolean
  impactPositionData?: ImpactPosition,
  impactRadius?: number,
  assetCount?: number,
  peopleCount?: number,
  popUpOffset?: number,
  showRadius?: boolean
}

export const RiskImpactPopup: FC<Props> = ({
  isShown,
  impactPositionData,
  impactRadius,
  assetCount,
  peopleCount,
  popUpOffset,
  showRadius = true
}) => {
  const { classes } = useRiskImpactPopupStyles();
  if (!isShown || !impactPositionData || !popUpOffset) {
    return null;
  }

  return (
    <Popup
      longitude={ impactPositionData.lon }
      latitude={ impactPositionData.lat }
      offset={ popUpOffset }
      closeButton={ false }
      closeOnClick={ false }
      className = { classes.popUp }>
      <Flex gap="sm" className={ classes.popupContainer }>
        { showRadius && (
          <Flex gap="xs" align="center" className={ classes.riskImpactPopupContentItem }>
            <RiskImpactDistanceIcon />
            { impactRadius } km
          </Flex>
        ) }
        <Flex gap="xs" align="center" className={ classes.riskImpactPopupContentItem }>
          <RiskImpactPeopleIcon />
          { peopleCount }
        </Flex>
        <Flex gap="xs" align="center" className={ classes.riskImpactPopupContentItem }>
          <RiskImpactAssetIcon/>
          { assetCount }
        </Flex>
      </Flex>
    </Popup>
  );
};

export default RiskImpactPopup;
