import { Box, Flex, Text } from '@mantine/core';
import { FC, Fragment, useCallback, useMemo } from "react";

import { ReactComponent as CriticalRiskIcon } from "@/common/icons/bsocRiskLevelCriticalIcon.svg";
import { ReactComponent as NonCriticalRiskIcon } from "@/common/icons/bsocRiskLevelNonCriticalIcon.svg";
import { ReactComponent as RiskAlertLevelIcon } from "@/common/icons/riskAlertLevelIcon.svg";
import { ReactComponent as RiskAlertLevelTriangle } from "@/common/icons/RiskProviders/risk-level-triangle.svg";
import { capitalizeWord } from '@/common/util/format/string';
import theme from "@/core/styles/mantine/theme";
import { factalRiskLevelMap } from '@/tenant-context/common/config/risk.config';
import { parseDateToDay } from '@/tenant-context/common/util/data-standardize';
import { RiskCategories } from "@/tenant-context/visualisation-risk-alerts/components/RiskAlertBasicData/RiskAlertBasicData.config";
import { bsocRiskAlertCategories } from '@/tenant-context/visualisation-risk-alerts/configs/bsocRiskAlertCategories';
import { DataMinrExternalData } from "@/tenant-context/visualisation-risk-alerts/types/data-minr.types";
import { HealixExternalData } from '@/tenant-context/visualisation-risk-alerts/types/healix';
import { MaxSecurityExternalData } from '@/tenant-context/visualisation-risk-alerts/types/max-security';
import { RiskAlertEvent, RiskAlertExternalData } from '@/tenant-context/visualisation-risk-alerts/types/risk-alerts';
import { RiskConnectorExternalData } from '@/tenant-context/visualisation-risk-alerts/types/risk-alerts-generic';

import { useRiskAlertBasicDataStyles } from "./RiskAlertBasicData.styles";

type Props = {
    riskAlertData?: RiskAlertEvent<RiskConnectorExternalData>
    isOnSideDrawer?: boolean
}

export const RiskAlertBasicData: FC<Props> = ({
  riskAlertData,
  isOnSideDrawer
}) => {
  const riskSource = riskAlertData?.source || 'riskline';
  const getRiskLevelColor = useCallback(
    (value?: string) => {
      if (!value) {
        return '';
      }

      // TODO: wait until the risk level icons of factal are ready
      // if (riskSource === 'factal') {
      //   return factalRiskLevelMap?.[value]?.color || '';
      // }

      return  theme.colors?.risk?.[parseInt(value)-1] || '';
    },
    []
  );

  const getRiskLevelTextColor = useCallback(
    (value?: string) => {
      return  (value === '2' || value === '3') ? theme.colors?.black?.[0] : theme.colors?.white?.[0];
    },
    []
  );

  const getRiskCategoryIcon = useCallback((categoryId?:string) => {
    if (riskSource === 'bsoc') {
      return !categoryId ? "" : bsocRiskAlertCategories.find((one) => one.categorySigact === categoryId)?.Icon;
    }

    return !categoryId ? "" : RiskCategories.find((one) => one.categoryId === categoryId)?.Icon;
  },[riskSource]);

  const getReadableDate = useCallback((input) => {
    return parseDateToDay(input)?.format('YYYY-MM-DD') || '----/--/--';
  }, []);

  const getReadableDateAndTime = useCallback((input) => {
    return parseDateToDay(input)?.format('YYYY-MM-DD HH:mm') || '----/--/-- --:--';
  }, []);

  const { classes } = useRiskAlertBasicDataStyles(
    {
      riskLevelTextColor: getRiskLevelTextColor(riskAlertData?.json.alert.risk_level.id),
      riskLevelIconColor: getRiskLevelColor(riskAlertData?.json.alert.risk_level.id),
      isOnTheRight: isOnSideDrawer
    }
  );

  const riskAlertLevelIcon = useMemo(() => {
    if (riskSource === 'max-security') {
      const riskCategoryIcon = getRiskCategoryIcon(riskAlertData?.json.alert.category.id);

      return <div className={ classes.riskLevelIconRoot }>
        <RiskAlertLevelTriangle className={ classes.riskLevelTriangle }/>
        <div className={ classes.riskCategoryIcon }>{ riskCategoryIcon }</div>
      </div>;
    }

    if (riskSource === 'bsoc') {
      const value = (riskAlertData?.json.externalData as RiskAlertExternalData)?.critical_incident;
      if (!value) {
        return (
          <div className={ classes.riskCriticalityRow }>
            <NonCriticalRiskIcon /> &nbsp; Non-Critical Event
          </div>) ;
      }

      return value ?
        (
          <div className={ classes.riskCriticalityRow }>
            <CriticalRiskIcon /> &nbsp; Critical Event
          </div>
        )
        :
        (
          <div className={ classes.riskCriticalityRow }>
            <NonCriticalRiskIcon /> &nbsp; Non-Critical Event
          </div>
        );
    }

    if (riskSource === 'healix' || riskSource === 'factal') {
      return <div className={ classes.riskLevelIconRoot }>
        <RiskAlertLevelIcon className={ classes.riskLevelIcon }/>
      </div>;
    }

    return <Fragment>
      <RiskAlertLevelIcon className={ classes.riskLevelIcon }/>
      { riskSource !== 'dataminr' && <div className={ classes.riskLevelIdText }>{ riskAlertData?.json.alert.risk_level.id } </div> }
    </Fragment>;
  }, [
    riskSource,
    classes.riskLevelIcon,
    classes.riskLevelIdText,
    classes.riskLevelIconRoot,
    classes.riskLevelTriangle,
    classes.riskCategoryIcon,
    classes.riskCriticalityRow,
    riskAlertData?.json.alert.risk_level.id,
    riskAlertData?.json.alert.category.id,
    riskAlertData?.json.externalData,
    getRiskCategoryIcon
  ]);

  const riskLevel = () => {
    if (riskSource === 'dataminr') {
      return (riskAlertData?.json.externalData as DataMinrExternalData).alertType.name;
    } else if (riskSource === 'factal') {
      if (!riskAlertData?.json.alert.risk_level.id) {
        return '';
      }

      return factalRiskLevelMap?.[riskAlertData.json.alert.risk_level.id]?.vendorLevel || '';
    } else if (riskSource === 'bsoc') {
      return '';
    } else {
      return riskAlertData?.json.alert.risk_level.name;
    }
  };

  const categorySection = useMemo(() => {
    if (riskSource === 'dataminr') {
      return (
        <Flex direction={ 'column' } className={ classes.riskInfoRowDataminr }>
          { (riskAlertData?.json.externalData as DataMinrExternalData).categories.map((category, index) => (
            <span key={ `${category.name}-${index}` }>{ category.name }</span>
          )) }
        </Flex>
      );
    }

    if (riskSource === 'max-security') {
      return (
        <div className={ classes.riskInfoRow }>
          { (riskAlertData?.json.externalData as MaxSecurityExternalData).incident_type }
        </div>
      );
    }

    if (riskSource === 'healix') {
      return (
        <Flex direction="column" align="flex-start" gap="xs" className={ classes.riskInfoRow }>
          { riskAlertData?.json?.alert?.category.name }
        </Flex>
      );
    }

    if (riskSource === 'factal') {
      const categories = riskAlertData?.json?.alert?.other_categories;

      return (
        <Flex direction="column" align="flex-start" gap={ 4 } className={ classes.riskInfoRow }>
          { categories?.map((category) => (
            <span key={ category.id }>{ capitalizeWord(category.name) }</span>
          )) }
        </Flex>
      );
    }

    if (riskSource === 'bsoc') {
      const externalData = riskAlertData?.json.externalData as RiskAlertExternalData;

      return <Flex direction="column" className={ classes.riskInfoRow } gap={ 4 } align="flex-start">
        <Flex gap={ 4 }>
          { getRiskCategoryIcon(externalData?.sigact) || null }
          <Box>{ externalData?.sigact?.split(/(?=[A-Z])/).join(' ').toUpperCase() }</Box>
        </Flex>
        <Text weight="bold" className={ classes.boldText }>{ externalData?.trend_code }</Text>
      </Flex>;
    }

    return (
      <div className={ classes.riskInfoRow }>
        <span>{ getRiskCategoryIcon(riskAlertData?.json.alert.category.id) }</span>
        { riskAlertData?.json?.alert?.category.name }
      </div>
    );
  }, [
    classes.boldText,
    classes.riskInfoRow,
    classes.riskInfoRowDataminr,
    getRiskCategoryIcon,
    riskAlertData?.json.alert.category.id,
    riskAlertData?.json.alert.category.name,
    riskAlertData?.json.alert?.other_categories,
    riskAlertData?.json.externalData,
    riskSource
  ]);

  return(
    <Flex direction="column" gap="xl">
      <Flex p={ 'md' } className={ classes.riskAlertStatusContainer } pos={ 'relative' }>
        <Box className={ classes.riskAlertStatusBar }/>
        <Flex direction={ 'column' } className={ classes.riscAlertStatusInnerGrid }>
          <div className={ classes.riskCriticalityRow }>
            { riskAlertLevelIcon }
            <div className={ classes.riskLevelName }>
              { riskLevel() }
            </div>
          </div>

          { categorySection }
        </Flex>

      </Flex>

      <Box>
        { riskSource !== 'bsoc' ? <Flex align={ 'center' } justify={ isOnSideDrawer ? 'flex-start' : 'center' } className={ classes.risInfoEffectFrom }>
          <Text>Effective from  &nbsp;</Text>
          <Text fw={ 700 }>  { getReadableDate(riskAlertData?.json.alert.start_date) } </Text>
          &nbsp;
          until   &nbsp;
          <Text fw={ 700 }>{ getReadableDate(riskAlertData?.json.alert.end_date) } </Text>
        </Flex> : <Flex align={ 'center' } justify={ isOnSideDrawer ? 'flex-start' : 'center' } className={ classes.risInfoEffectFrom }>
          <Text>Time of incident  &nbsp;</Text>
          <Text fw={ 700 }>
            { (getReadableDate((riskAlertData?.json.externalData as RiskAlertExternalData)?.incident_date)) }
          </Text>
        </Flex> }

        { riskSource === 'dataminr' && <Flex mt={ 8 } align={ 'center' } justify={ isOnSideDrawer ? 'flex-start' : 'center' } className={ classes.risInfoEffectFrom }>
          <Text>From Watchlist</Text>
          <Text>
            { (riskAlertData?.json.externalData as DataMinrExternalData).watchlistsMatchedByType.map((item) => (
              <span key={ item.id } className={ classes.watchListTypeText }>{ item.name }</span>
            )) }
          </Text>
        </Flex> }

        { (riskSource === 'healix') && isOnSideDrawer && <Flex direction="column">
          <Flex mt={ 8 } align={ 'center' } justify={ isOnSideDrawer ? 'flex-start' : 'center' } className={ classes.risInfoEffectFrom }>
            <Text>Published:&nbsp;</Text>
            <Text>
              { getReadableDateAndTime((riskAlertData?.json?.externalData as HealixExternalData)?.AlertDate) }
            </Text>
          </Flex>
          <Flex mt={ 8 } align={ 'center' } justify={ isOnSideDrawer ? 'flex-start' : 'center' } className={ classes.risInfoEffectFrom }>
            <Text>Last update:&nbsp;</Text>
            <Text>
              { getReadableDateAndTime((riskAlertData?.json?.externalData as HealixExternalData)?.LastUpdatedDate) }
            </Text>
          </Flex>
        </Flex> }
      </Box>
    </Flex>
  );
};

export default RiskAlertBasicData;


