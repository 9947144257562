import { createModel } from "@rematch/core";
import { FeatureCollection, Polygon } from "geojson";

import { getTenantIdFromToken } from "@/common/util/api";
import { DECODED_TENANT_TOKEN_KEY, TENANT_TOKEN_KEY } from "@/common/util/auth";
import browserDatabase from "@/common/util/browser-database";
import { RouterConfig } from '@/core/components/Router/Router.config';
import { Dispatch, RootModel, RootState } from "@/core/store";
import { Location } from "@/tenant-context/common/types/location";
import { RiskProviderItem } from "@/tenant-context/control-risk-config/types/risk";
import { getSiteConnectors } from "@/tenant-context/control-site/api/site";
import { Connector } from "@/tenant-context/control-site/types/site";
import {
  ISiteDto,
  SessionSite,
  SITE_SESSION_KEY
} from "@/tenant-context/control-visitor-management/types/visitorManagement";
import { getAllCountries } from "@/tenant-context/core/api/countries";
import { getAllLocationHierarchy } from "@/tenant-context/core/api/locations";
import { getAllRiskAlertProviders } from "@/tenant-context/core/api/riskProviders";
import { getSessionTimeout } from '@/tenant-context/core/api/session';
import { MILLISECONDS_IN_MINUTE } from '@/tenant-context/core/config/consts';
import { UserGeoPermission, UserGeoPermissionResponse } from "@/tenant-context/core/types/geo-permission";
import { getFormattedTenantSubscribedProducts } from "@/tenant-context/navigation/api/navigation";
import { SubscribedProducts } from "@/tenant-context/navigation/types/user";
import { Address } from "@/tenant-context/visualisation-site/types/site.types";
import { ALL_COUNTRIES } from "@/tenant-context/widget-overview/components/CountryInfoBox/CountryInfoBox.container";
import { ALL_SITES_CODE } from "@/tenant-context/widget-overview/components/SitesInfoBox/SiteInfoBox.container";
import { WidgetSite } from "@/tenant-context/widget-overview/types/site.types";

import { getUserGeoPermissionBoundaries } from "../../api/geo-permission";
import { excludedProvidernames } from "../../components/utlis/common-utils";

type EnabledConnectorService = Pick<Connector, 'adapterName'>;

type CommonData = {
  allCountries: Location[],
  selectedCountry?: Location,
  riskProviders: RiskProviderItem[],
  allLocations: Location[],
  selectedSite?: WidgetSite,
  tenantId: string,
  showLoaderScreen: boolean,
  isNavigationExpanded: boolean,
  isLegacyModeOn: undefined | boolean,
  tenantSubscribedProducts: SubscribedProducts[],
  loadTenantSubscribedProducts: () => void,
  isSubscribedProductsLoaded: boolean,
  shouldRefreshSitesFlag: boolean,
  currentSite?: ISiteDto,
  sessionTimeout?: number,
  userGeoPermission?: FeatureCollection<Polygon> | null,
  enabledRiskConnectorServices: Record<string, EnabledConnectorService>
};

const commonDataModel = {
  name: 'commonData',
  state: {
    allCountries: [] as Location[],
    allLocations: [] as Location[],
    selectedCountry: undefined,
    selectedSite: undefined,
    tenantId: browserDatabase.getItem('TENANT'),
    showLoaderScreen: false,
    isNavigationExpanded: false,
    isLegacyModeOn: undefined,
    isSubscribedProductsLoaded: false,
    shouldRefreshSitesFlag: false,
    currentSite: undefined,
    sessionTimeout: 0,
    userGeoPermission: undefined,
    enabledRiskConnectorServices: {}
  } as CommonData,
  reducers: {
    SET_COUNTRIES: (state: CommonData, countries: Location[]) => ({
      ...state,
      allCountries: countries
    }),
    SET_SELECTED_COUNTRY: (state: CommonData, country: Location | undefined) => ({
      ...state,
      selectedCountry: country
    }),
    SET_SELECTED_SITE: (state: CommonData, site: WidgetSite | undefined) => ({
      ...state,
      selectedSite: site
    }),
    SET_TENANT_ID: (state: CommonData, tenantId: string) => ({
      ...state,
      tenantId
    }),
    SET_LEGACY_MODE: (state: CommonData, isLegacy: boolean) => ({
      ...state,
      isLegacyModeOn: isLegacy
    }),
    SET_ENABLED_RISK_CONNECTOR_SERVICES: (state: CommonData, connectors: Record<string, EnabledConnectorService>) => ({
      ...state,
      enabledRiskConnectorServices: connectors
    }),
    SET_RISK_PROVIDERS: (state: CommonData, riskProviders: RiskProviderItem[]) => ({
      ...state,
      riskProviders
    }),
    SET_ALL_LOCATIONS: (state: CommonData, locations: Location[]) => ({
      ...state,
      allLocations: locations
    }),
    SET_SHOW_LOADER_SCREEN: (state: CommonData, showLoaderScreen: boolean) => ({
      ...state,
      showLoaderScreen: showLoaderScreen
    }),
    SET_IS_NAVIGATION_EXPANDED: (state: CommonData, isNavigationExpanded: boolean) => ({
      ...state,
      isNavigationExpanded: isNavigationExpanded
    }),
    SET_TENANT_SUBSCRIBED_PRODUCTS: (state: CommonData, tenantSubscribedProducts: SubscribedProducts[]) => ({
      ...state,
      tenantSubscribedProducts: tenantSubscribedProducts
    }),
    SET_TENANT_SUBSCRIBED_PRODUCTS_LOADED: (state: CommonData, isLoaded: boolean) => ({
      ...state,
      isSubscribedProductsLoaded: isLoaded
    }),
    SET_SHOULD_REFRESH_SITES_FLAG: (state: CommonData, shouldRefreshSitesFlag: boolean) => ({
      ...state,
      shouldRefreshSitesFlag: shouldRefreshSitesFlag
    }),
    SET_CURRENT_SITE: (state: CommonData, currentSite: ISiteDto) => ({
      ...state,
      currentSite
    }),
    SET_SESSION_TIMEOUT: (state: CommonData, sessionTimeout: number | undefined) => ({
      ...state,
      sessionTimeout
    }),
    SET_USER_GEO_PERMISSION: (state: CommonData, userGeoPermission: FeatureCollection<Polygon> | null) => ({
      ...state,
      userGeoPermission
    })
  },
  effects: (dispatch: Dispatch) => ({
    async loadAllCountries(): Promise<Location[]> {
      const countries = await getAllCountries();

      dispatch.commonData.SET_COUNTRIES(countries);

      return countries;
    },

    setTenant(params: { tenantId: string, refreshBrowser: boolean }): void {
      const tenantId = params.tenantId || getTenantIdFromToken();

      if (!tenantId) {
        throw new Error('Tenant ID is not defined');
      }

      browserDatabase.setItem('TENANT', tenantId);
      dispatch.commonData.SET_TENANT_ID(tenantId);

      if (params.refreshBrowser) {
        window.location.reload();
      }
    },

    async loadAllRiskProviders(): Promise<RiskProviderItem[]> {
      const riskProviders = await getAllRiskAlertProviders();
      const filteredRiskProviders = (riskProviders?.items as Array<RiskProviderItem>)
        ?.filter((provider, index, providerArr) =>
          index === providerArr.findIndex((item) => item.providerName === provider.providerName)
          && !excludedProvidernames.includes(provider.providerName));

      dispatch.commonData.SET_RISK_PROVIDERS(filteredRiskProviders);

      if (riskProviders.items.find((provider) => (provider as RiskProviderItem).providerName === "dataminr") !== undefined) {
        dispatch.riskEventFilterDataOptions.getDataminrWatchlist();
      }

      return riskProviders.items;
    },

    async loadAllLocations(): Promise<Location[]> {
      const locations = await getAllLocationHierarchy();

      dispatch.commonData.SET_ALL_LOCATIONS(locations);

      return locations;
    },

    async loadLegacyModeCheck(_: void, state: RootState): Promise<void> {
      const {
        commonData: {
          isLegacyModeOn
        }
      } = state;

      if (isLegacyModeOn === undefined) {
        const config = await getSiteConnectors();

        dispatch.commonData.SET_LEGACY_MODE(config.legacyEnabled);

        const enabledConnectors = config.riskConnectorServices
          .reduce((acc, curr: Connector) => {
            if (curr.enabled) {
              acc[curr.connectorName] = {
                adapterName: curr.adapterName
              };
            }

            return acc;
          }, {} as Record<string, EnabledConnectorService>);

        dispatch.commonData.SET_ENABLED_RISK_CONNECTOR_SERVICES(enabledConnectors);

        dispatch.tableauDashboard.SET_ANALYTICS_ENABLED(config.riskConnectorServices.some((connector) => {
          if (connector.adapterName === 'bsoc') {
            const tableauIntegrationEnabled = connector.configs?.tableauIntegrationEnabled;
            if (typeof tableauIntegrationEnabled === 'string') {
              return tableauIntegrationEnabled === 'true';
            }

            return tableauIntegrationEnabled;
          }

          return false;
        }));
      }
    },
    async startLoading(): Promise<void> {
      dispatch.commonData.SET_SHOW_LOADER_SCREEN(true);
    },

    async loadingComplete(): Promise<void> {
      dispatch.commonData.SET_SHOW_LOADER_SCREEN(false);
    },
    async loadTenantSubscribedProducts(_: void, state: RootState): Promise<void> {
      const {
        commonData: {
          tenantId
        }
      } = state;
      const accessToken = browserDatabase.getItem(TENANT_TOKEN_KEY);
      try {
        const subscribedProducts = await getFormattedTenantSubscribedProducts(accessToken as string, tenantId);
        dispatch.commonData.SET_TENANT_SUBSCRIBED_PRODUCTS(subscribedProducts);
        dispatch.commonData.SET_TENANT_SUBSCRIBED_PRODUCTS_LOADED(true);
      } catch {
        dispatch.commonData.SET_TENANT_SUBSCRIBED_PRODUCTS_LOADED(true);
      }
    },
    getCurrentSite(_: void, state: RootState): ISiteDto {
      // eslint-disable-next-line fp/no-let
      let { currentSite } = state.commonData;

      if (!currentSite) {
        const sessionSite = browserDatabase.getItem(SITE_SESSION_KEY) as SessionSite;

        currentSite = {
          name: sessionSite?.siteName || "",
          tid: sessionSite?.siteId || ""
        };
      }

      return currentSite;
    },
    async loadSessionConfig(_: void, __: RootState): Promise<void> {
      const isPublicURL = RouterConfig.publicURLs().includes(window.location.pathname);
      if (isPublicURL) {
        return;
      }

      try {
        const sessionTimeout = (await getSessionTimeout()).configValue;
        const sessionTimeoutInt = parseInt(sessionTimeout as string, 10);

        dispatch.commonData.SET_SESSION_TIMEOUT(sessionTimeoutInt * MILLISECONDS_IN_MINUTE);
      } catch (error) {
        dispatch.commonData.SET_SESSION_TIMEOUT(undefined);
      }
    },
    async loadUserGeoPermission(_: void, state: RootState): Promise<void> {
      const userId = browserDatabase.getItem<{userId: string}>(DECODED_TENANT_TOKEN_KEY)?.userId;
      if (!userId) {
        return;
      }

      const geoPermissionResponse: UserGeoPermissionResponse = await getUserGeoPermissionBoundaries(userId);
      if (geoPermissionResponse.geofenceResponseList && geoPermissionResponse.geofenceResponseList.length > 0) {
        const userGeoPermission = geoPermissionResponse.geofenceResponseList
          .reduce((acc: FeatureCollection<Polygon>, curr: UserGeoPermission) => {
            if (curr.geoJson) {
              const geoJson = JSON.parse(curr.geoJson);
              acc.features.push(...geoJson.features);
            }

            return acc;
          }, {
            type: 'FeatureCollection',
            features: []
          });

        dispatch.commonData.SET_USER_GEO_PERMISSION(userGeoPermission);
      } else {
        dispatch.commonData.SET_USER_GEO_PERMISSION(null);
      }
    },
    async resetSiteListCount(country: Location | undefined, state: RootState): Promise<void> {
      const {
        siteLocations: {
          geoData
        },
        sitePopup: {
          locationsPeopleCount
        }
      } = state;

      if (country?.id !== ALL_COUNTRIES){
        const filteredSites = geoData?.features?.filter(site => (site?.properties?.address as Address)
          ?.country === country?.isoCountryCode)?.length;
        const countryCount: WidgetSite = {
          name: `All (${(filteredSites ?? 0).toString()})`,
          location: '',
          lngLat: [0, 0],
          id: 'all',
          code: ALL_SITES_CODE,
          musterCount: locationsPeopleCount?.musters,
          isoCountryCode: ALL_SITES_CODE
        };

        dispatch.commonData.SET_SELECTED_SITE(countryCount);
      } else {
        const allCountries = geoData?.features?.length;
        const allCountObj: WidgetSite = {
          name: `All (${(allCountries ?? 0).toString()})`,
          location: '',
          lngLat: [0, 0],
          id: 'all',
          code: ALL_SITES_CODE,
          musterCount: locationsPeopleCount?.musters,
          isoCountryCode: ALL_SITES_CODE
        };

        dispatch.commonData.SET_SELECTED_SITE(allCountObj);
      }

    }
  })
};

export const commonData = createModel<RootModel>()(commonDataModel);
