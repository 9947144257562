import { createStyles } from "@mantine/core";
import { MantineTheme } from "@mantine/styles/lib/theme";

export const useMcArcPieChartStyles = createStyles((theme: MantineTheme) => ({
  count: {
    fontSize: 20,
    fill: theme.white,
    fontWeight: theme.other.fw.bold
  },
  peopleText: {
    fontSize: theme.other.fs.xxs,
    fill: theme.white,
    opacity: '40%'
  }
}));
