import { Legend } from "@aerisweather/mapsgl";
import { ActionIcon, Box, Button, Flex } from "@mantine/core";
import { useId } from "@mantine/hooks";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useSelector } from "react-redux";

import { ToggleableContextLayers } from "@/common/components/ToggleableLayer/ToggleableLayer.config";
import { ReactComponent as CloseIcon } from "@/common/icons/close.svg";
import { RootState } from "@/core/store";
import { useBigMapContext } from "@/tenant-context/core/context/BigMap.context";
import { weatherLayersConfig } from "@/tenant-context/visualisation-weather";
import { useWeatherLegendGlobalStyles } from "@/tenant-context/visualisation-weather/components/WeatherLegendGlobal.styles";

/**
 * The component with legend to display on the map (all legends)
 */
export const WeatherLegendGlobalComponent: FC = () => {
  const id = useId();
  const containerId = `weather-legend-${id}`;
  const { weatherController } = useBigMapContext();
  const legendControlRef = useRef<HTMLElement>();

  const [isVisible, setIsVisible] = useState(true);
  const toggleVisibility = useCallback(() => setIsVisible(prev => !prev), []);

  const contextualLayersOptions = useSelector((state: RootState) => state.dataOptions.contextLayerOptions);
  const isAnyWeatherLayerEnabled = useMemo<boolean>(() => {
    const allWeatherTypes = new Set(weatherLayersConfig
      .map(({ contextualLayerType }) => contextualLayerType));

    const enabledLayers =  Object.entries(contextualLayersOptions).filter(([layerType, isEnabled]) =>
      isEnabled && allWeatherTypes.has(layerType as ToggleableContextLayers));

    return enabledLayers.length > 0;
  }, [contextualLayersOptions]);

  const { classes } = useWeatherLegendGlobalStyles({ isVisible: isAnyWeatherLayerEnabled && isVisible });

  useEffect(() => {
    const elem = document.getElementById(containerId);

    if(!elem) {
      return;
    }

    const legendControl = weatherController?.addLegendControl(elem, {
      metric: true,
      toggleOnClick: true,
      width: 320
    });

    legendControl?.on('add', (event: Event) => {
      const { legend } = event as Event & { legend: Legend };
      if (!legend) {
        return;
      }
      // console.log({event});
      legend.update({
        text: {
          color: '#fff'
        }
      });
    });

    if (!legendControlRef.current) {
      legendControlRef.current = legendControl?.container;
    }
    
    return () => {
      if (legendControlRef.current) {
        legendControlRef.current = undefined;

      }
      weatherController?.removeLegendControl();
    };
  }, [containerId, weatherController]);

  return (
    <Box id={ containerId } className={ classes.root }>
      { isAnyWeatherLayerEnabled && (
        <>
          { !isVisible && (
            <Button size='sm' onClick={ toggleVisibility }>
              { isVisible ? 'Hide' : 'Show' } weather legend
            </Button>
          ) }
          { legendControlRef.current && createPortal(
            <Flex direction='row-reverse' mb={ 4 }>
              <ActionIcon size={ 14 } onClick={ toggleVisibility } variant='subtle'>
                <CloseIcon />
              </ActionIcon>
            </Flex>,
            legendControlRef.current,
            String(isAnyWeatherLayerEnabled)
          ) }
        </>
      ) }
    </Box>
  );
};
