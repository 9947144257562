import { PaginatedResult } from "@/common/types/reference-data";
import { callApi } from "@/common/util/api";
import { SortData } from "@/tenant-context/control-groups/types/group";

import { CasePeopleCountResponse, CasePeopleItem, CasePeopleListFilters, CasePerson, CasePersonContact, CasePersonLocation, ICasePeopleBulkOperations, ICasePeopleContactForm, ICasePeopleLocationForm, ICasePeopleOverviewForm } from "../types/casePeople";

export const casePeopleApi = {
  getCasePeopleList: function(
    caseId: string,
    page = 0,
    size = 10,
    sort?: SortData,
    searchQuery?: string,
    additionalParams?: CasePeopleListFilters
  ) {
    return callApi<PaginatedResult<CasePeopleItem>>(
      `managed/icm-service/v1/icm-cases/${caseId}?page=${page}&size=${size}&${sort?.colId ? 'orderBy=' + sort.colId + ':' + sort.sort + '&' : ''}${additionalParams?.locationTid ? 'locationTid=' + additionalParams.locationTid + "&" : ""}${additionalParams?.peopleStatusTid ? 'peopleStatusTid=' + additionalParams.peopleStatusTid + "&" : ""}${searchQuery ? 'search=' +encodeURIComponent(searchQuery) : ""}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=ListPerson'
        },
        method: 'get'
      }
    );
  },
  getPersonForCase: function(
    caseId: string,
    personId: string
  ) {
    return callApi<CasePerson>(
      `managed/icm-service/v1/icm-cases/${caseId}/people/${personId}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=GetPerson'
        },
        method: 'get'
      }
    );
  },
  createPersonForCase: function(caseId: string, params: ICasePeopleOverviewForm) {
    return callApi<{_id: string}>(
      `managed/icm-service/v1/icm-cases/${caseId}/people`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=CreatePerson'
        },
        method: 'post',
        body: JSON.stringify(params)
      }
    );
  },
  updatePersonForCase: function(caseId: string, personId: string, params: ICasePeopleOverviewForm) {
    return callApi<{_id: string}>(
      `managed/icm-service/v1/icm-cases/${caseId}/people/${personId}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=UpdatePerson'
        },
        method: 'post',
        body: JSON.stringify(params)
      }
    );
  },
  getPersonLocationForCase: function(
    caseId: string,
    personId: string
  ) {
    return callApi<CasePersonLocation>(
      `managed/icm-service/v1/icm-cases/${caseId}/person-locations/${personId}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=GetPersonLocation'
        },
        method: 'get'
      }
    );
  },
  createPersonLocationForCase: function(caseId: string, params: ICasePeopleLocationForm) {
    return callApi(
      `managed/icm-service/v1/icm-cases/${caseId}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=CreatePersonLocation'
        },
        method: 'post',
        body: JSON.stringify(params)
      }
    );
  },
  updatePersonLocationForCase: function(caseId: string, params: ICasePeopleLocationForm) {
    return callApi(
      `managed/icm-service/v1/icm-cases/${caseId}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=UpdatePersonLocation'
        },
        method: 'post',
        body: JSON.stringify(params)
      }
    );
  },
  getPersonContactForCase: function(
    caseId: string,
    personId: string
  ) {
    return callApi<CasePersonContact>(
      `managed/icm-service/v1/icm-cases/${caseId}/person-contacts/${personId}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=GetContactDetails'
        },
        method: 'get'
      }
    );
  },
  createPersonContactForCase: function(caseId: string, params: ICasePeopleContactForm) {
    return callApi(
      `managed/icm-service/v1/icm-cases/${caseId}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=CreateContactDetails'
        },
        method: 'post',
        body: JSON.stringify(params)
      }
    );
  },
  updatedPersonContactForCase: function(caseId: string, personContactId: string, params: ICasePeopleContactForm) {
    return callApi(
      `managed/icm-service/v1/icm-cases/${caseId}/person-contacts/${personContactId}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=UpdateContactDetails'
        },
        method: 'post',
        body: JSON.stringify(params)
      }
    );
  },
  getCasePeopleCountByStatus: function(caseId: string) {
    return callApi<CasePeopleCountResponse>(
      `managed/icm-service/v1/icm-cases/${caseId}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=GetPeopleCountByStatus'
        },
        method: 'get'
      }
    );
  },
  bulkDeleteCasePeople: function(caseId: string, idList: string[]) {
    return callApi(
      `managed/icm-service/v1/icm-cases/${caseId}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=BulkDeletePeople'
        },
        method: 'post',
        body: JSON.stringify({
          idList
        })
      }
    );
  },
  bulkUpdateCasePeople: function(caseId: string, params: ICasePeopleBulkOperations) {
    return callApi(
      `managed/icm-service/v1/icm-cases/${caseId}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=BulkUpdatePeople'
        },
        method: 'post',
        body: JSON.stringify(params)
      }
    );
  }
};