/* eslint-disable max-lines */
import ConditionalComponent from 'packages/tenant-context/common/components/ConditionalComponent';
import UpProcessesLocationsList from "packages/tenant-context/control-location-configuration/components/LocationList/UnProcessedLocationsList";
import { FC, lazy } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import { Products } from "@/common/types/products";
import { REGULAR_TOKEN_KEY, TENANT_TOKEN_KEY } from "@/common/util/auth";
import browserDatabase from "@/common/util/browser-database";
import LoaderScreen from '@/core/components/LoaderScreen/LoaderScreen.component';
import { RouterConfig } from "@/core/components/Router/Router.config";
import ProtectedProfileElement from "@/core/components/RouterRoutes/ProtectedProfileElement.component";
import ProtectedRouteElement from "@/core/components/RouterRoutes/ProtectedRouteElement.component";
import RouterOverlay from "@/core/components/RouterRoutes/RouterOverlay.component";
import SSOIntegration from "@/core/components/SSOIntegration";
import UnauthorizedPageComponent from '@/core/components/UnauthorizedPage';
import { environment } from "@/core/config/env";
import { RootState } from '@/core/store';
import RoleDetails
  from "@/platform-context/control-icm-management/components/CaseTypes/Details/components/TeamsAndRoles/components/RoleDetails";
import { SeverityLevelsTenantRequiredPoliciesConfig
} from "@/platform-context/control-icm-management/components/SeverityLevels/config/SeverityLevelsRequiredPolicies.config";
import { CaseTypeRequiredPoliciesConfig } from '@/platform-context/control-icm-management/config/CaseTypesRequiredPolicies.config';
import { PlaybookRequiredPoliciesConfig } from '@/platform-context/control-icm-management/config/PlaybookRequiredPoliciesConfig';
import { SituationTypePoliciesConfig } from "@/platform-context/control-icm-management/config/SituationTypeRequiredPolicies.config";
import { ArcCasePoliciesConfig } from "@/tenant-context/control-action-response-center/config/arc-case.policies";
import { DashboardRequiredPoliciesConfig } from "@/tenant-context/control-connected-sites-dashboard/config/DashboardRequiredPolicies.config";
import { GeofenceRequiredPoliciesConfig } from "@/tenant-context/control-draw/config/GeofenceRequiredPolicies.config";
import { PeopleGroupsPoliciesConfig } from "@/tenant-context/control-groups/config/people-groups.policies";
import { AssetsManagementPoliciesConfig } from '@/tenant-context/control-icm-management/config/AssetsManagementRequiredPolicies.config';
import {
  CaseManagementPoliciesConfig
} from "@/tenant-context/control-icm-management/config/CaseManagementRequiredPolicies.config";
import { IntegrationMappingPoliciesConfig } from "@/tenant-context/control-integrations-mapping/config/integration-mapping.policies";
import { LocationConfigurationPoliciesConfig } from "@/tenant-context/control-location-configuration/config/location-configuration.policies";
import { LookupPoliciesConfig } from "@/tenant-context/control-lookup-tool/configs/Lookup.policies";
import { MassCommsPoliciesConfig } from "@/tenant-context/control-mass-comms/config/mass-comms.policies";
import {
  ProfileRequiredPoliciesConfig,
  ProfileRoutePoliciesConfig
} from "@/tenant-context/control-profile/config/ProfileRequiredPolicies.config";
import CityRiskReportsPage from '@/tenant-context/control-reports/components/CityRiskReportsPage';
import CountryRiskReportsPage from '@/tenant-context/control-reports/components/CountryRiskReportsPage';
import { ThreatReportPolicies } from '@/tenant-context/control-reports/config/threat-report-policies';
import { TableauDashboardConfig } from '@/tenant-context/control-tableau/config/tableau-dashboard.config';
import { TenantAccessCardRequiredPoliciesConfig } from "@/tenant-context/control-tenant-admin/config/TenantAccessCardRequiredPolicies.config";
import { VisitorManagementRequiredPoliciesConfig } from "@/tenant-context/control-visitor-management/config/VisitorManagementRequiredPolicies.config";
import BigMap from "@/tenant-context/core/components/BigMap";
import { Showcase } from "@/tenant-context/showcase/Showcase.component";

import AuthenticatedMap from "../AuthenticatedMap/AuthenticatedMap.component";
const SeverityLevelsManagement = lazy(() => import('@/platform-context/control-icm-management/components/SeverityLevels/components/SeverityLevelsManagement'));
const SeverityLevels = lazy(() => import('@/platform-context/control-icm-management/components/SeverityLevels/SeverityLevels.container'));

const SentCommsList = lazy(() => import('@/tenant-context/control-mass-comms/components/SentCommsList/SentCommsList.container'));

const TableauDashboard = lazy(() => import('@/tenant-context/control-tableau/components/TableauDashboard'));
const ExternalRedirect = lazy(() => import("@/common/components/ExternalRedirect/ExternalRedirect.component"));
const ARC = lazy(() => import("@/tenant-context/control-action-response-center/components/ARC"));
const ConnectedSitesDashboard = lazy(() => import("@/tenant-context/control-connected-sites-dashboard/components"));
const LocationManagement = lazy(() => import("@/tenant-context/control-location-management/components"));
const GeofenceManageControl = lazy(() => import("@/tenant-context/control-draw/controls/GeofenceManageControl"));
const ControlGroups = lazy(() => import("@/tenant-context/control-groups/components/ControlGroups"));
const ControlGroupsInfo = lazy(() => import("@/tenant-context/control-groups/components/ControlGroupsInfo"));
const LocationsList = lazy(() => import("@/tenant-context/control-location-configuration/components/LocationList/LocationsList"));
const LocationDetails = lazy(() => import("@/tenant-context/control-location-configuration/components/AddLocations"));
const LookupTool = lazy(() => import("@/tenant-context/control-lookup-tool/components/ui/LookupTool"));
const MassCommsEmailTemplate = lazy(() => import("@/tenant-context/control-mass-comms/components/CreateCommsPage/Delivery-Templates/EmailTemplate/EmailTemplate.component"));
const MassComms = lazy(() => import("@/tenant-context/control-mass-comms/components/MassComms"));
const MassCommsReports = lazy(() => import("@/tenant-context/control-mass-comms/components/MassCommsReports/MassCommsReports.component"));
const MassCommsResponse = lazy(() => import("@/tenant-context/control-mass-comms/components/MassCommsResponse"));
const MassCommsSmsResponse = lazy(() => import("@/tenant-context/control-mass-comms/components/MassCommsSmsResponse"));
const MassCommsTemplates = lazy(() => import("@/tenant-context/control-mass-comms/components/Templates/MassCommsTemplates"));
const BigProfileInitialiser = lazy(() => import("@/tenant-context/control-profile/components/ui/BigProfileInitialiser"));
const CompaniesManagement = lazy(() => import("@/tenant-context/control-profile/components/ui/CompaniesManagement"));
const CompanyDetails = lazy(() => import("@/tenant-context/control-profile/components/ui/CompaniesManagement/components/CompanyDetailes"));
const ProfileList = lazy(() => import("@/tenant-context/control-profile/components/ui/ProfileList"));
const UnProcessedProfileList = lazy(() => import("@/tenant-context/control-profile/components/ui/ProfileList/components/UnProcessedProfileList"));
const AccessCardLayoutDetails = lazy(() => import("@/tenant-context/control-tenant-admin/components/AccessCardLayout"));
const PrintJobDetails = lazy(() => import("@/tenant-context/control-tenant-admin/components/PrintJobDetails"));
const PrintJobList = lazy(() => import("@/tenant-context/control-tenant-admin/components/PrintJobList"));
const AccessCardDetails = lazy(() => import("@/tenant-context/control-tenant-admin/components/TenantAccessCardDetails"));
const TenantAccessCardLayouts = lazy(() => import("@/tenant-context/control-tenant-admin/components/TenantAccessCardLayouts"));
const TenantAccessCards = lazy(() => import("@/tenant-context/control-tenant-admin/components/TenantAccessCards"));
const VisitorDetails = lazy(() => import("@/tenant-context/control-visitor-management/components/VisitorDetails"));
const VisitorManagement = lazy(() => import("@/tenant-context/control-visitor-management/components/VisitorManagement"));
const VisitRequest = lazy(() => import("@/tenant-context/control-visitor-management/components/VisitRequest"));
const SituationTypesList = lazy(() => import('@/platform-context/control-icm-management/components/SituationTypes/List'));
const SituationTypeDetails = lazy(() => import('@/platform-context/control-icm-management/components/SituationTypes/Details'));
const AssetsManagementList = lazy(() => import('@/tenant-context/control-icm-management/components/Assets/List'));
const IntegrationsList = lazy(() => import("@/tenant-context/control-integrations-mapping/components/IntegrationsList"));
const IntegrationDetail = lazy(() => import("@/tenant-context/control-integrations-mapping/components/IntegrationDetail"));
const PlaybooksList = lazy(() => import('@/platform-context/control-icm-management/components/Playbooks/List'));
const PlaybookDetails = lazy(() => import('@/platform-context/control-icm-management/components/Playbooks/Details'));
const CaseTypesList = lazy(() => import('@/platform-context/control-icm-management/components/CaseTypes/List'));
const CaseTypeDetails = lazy(() => import('@/platform-context/control-icm-management/components/CaseTypes/Details'));
const CaseEdit = lazy(() => import('@/tenant-context/control-icm-management/components/CaseManagement/Edit'));
const CaseInfo = lazy(() => import('@/tenant-context/control-icm-management/components/CaseManagement/Info'));
const CaseList = lazy(() => import('@/tenant-context/control-icm-management/components/CaseManagement/CaseList'));

const { routes } = RouterConfig;

const RouterRoutes: FC = () => {
  // Rerender on location change
  useLocation();

  const tenantToken = browserDatabase.getItem(TENANT_TOKEN_KEY);
  const regularToken = browserDatabase.getItem(REGULAR_TOKEN_KEY);

  const isAuthenticated = tenantToken && regularToken;
  const { isAnalyticsEnabled } = useSelector((state: RootState) => state.tableauDashboard);

  return (
    <>
      <Routes>
        { isAuthenticated && (
          <>
            <Route
              path={ routes.home }
              element={ <AuthenticatedMap requiredProduct={ Products.CORE_SERVICE }>
                <BigMap />{ " " }
              </AuthenticatedMap> }
            />

            <Route
              path={ routes.arc() }
              element={ <ProtectedRouteElement
                pagePolicies={ ArcCasePoliciesConfig.ARC_CASE_BY_ID }
              >
                <ARC />{ " " }
              </ProtectedRouteElement> }
            />

            <Route
              path={ routes.tableauDashboard }
              element={ <ConditionalComponent
                fallback={ (isAnalyticsEnabled === undefined) ?
                  <LoaderScreen isLoading/> : <UnauthorizedPageComponent isLoadingMode={ false }/> }
                conditions={ isAnalyticsEnabled }
              >
                <TableauDashboard dashboardUrl={ TableauDashboardConfig.TABLEAU_DASHBOARD_URL } />
              </ConditionalComponent> }
            />

            <Route
              path={ routes.locationConfiguration.addLocations() }
              element={ <ProtectedRouteElement
                pagePolicies={ LocationConfigurationPoliciesConfig.LOCATION_CONFIGURATION_ROUTE_ACCESS }
              >
                <LocationDetails />
              </ProtectedRouteElement> }
            />

            { /* Mass Comms */ }
            <Route
              path={ routes.massCommsCreate }
              element={ <ProtectedRouteElement
                pagePolicies={ MassCommsPoliciesConfig.MC_CREATE_PAGE }
              >
                <MassComms />
              </ProtectedRouteElement> }
            />

            <Route
              path={ routes.massCommsTemplates }
              element={ <ProtectedRouteElement
                pagePolicies={ MassCommsPoliciesConfig.MC_CONFIG }
              >
                <MassCommsTemplates />
              </ProtectedRouteElement> }
            />

            <Route
              path={ routes.massCommsEmailTemplate }
              element={ <ProtectedRouteElement
                pagePolicies={ MassCommsPoliciesConfig.MC_CONFIG }
              >
                <MassCommsEmailTemplate />
              </ProtectedRouteElement> }
            />

            <Route
              path={ routes.massComms }
              element={ <ProtectedRouteElement
                pagePolicies={ MassCommsPoliciesConfig.MC_SENT_COMMS_PAGE }
              >
                <SentCommsList />
              </ProtectedRouteElement> }
            />

            <Route
              path={ routes.massCommsReports() }
              element={ <ProtectedRouteElement
                pagePolicies={ MassCommsPoliciesConfig.MC_REPORTS_PAGE }
              >
                <MassCommsReports />
              </ProtectedRouteElement> }
            />

            <Route
              path={ routes.peopleGroups }
              element={ <ProtectedRouteElement
                pagePolicies={ PeopleGroupsPoliciesConfig.PEOPLE_GROUPS_LANDING }
              >
                <ControlGroups />
              </ProtectedRouteElement> }
            />

            <Route
              path={ routes.peopleGroupsId() }
              element={ <ProtectedRouteElement
                pagePolicies={ PeopleGroupsPoliciesConfig.PEOPLE_GROUPS_VIEW_GROUP }
              >
                <ControlGroupsInfo />
              </ProtectedRouteElement> }
            />

            <Route
              path={ routes.bigProfile() }
              element={ <ProtectedProfileElement
                pagePolicies={ ProfileRoutePoliciesConfig.PROFILE_ROUTE_ACCESS }
              >
                <BigProfileInitialiser />
              </ProtectedProfileElement> }
            />

            <Route
              path={ routes.lookupTool }
              element={ <ProtectedProfileElement
                pagePolicies={ LookupPoliciesConfig.LOOKUP_ASSERT_RANKING_READONLY }
              >
                <LookupTool />
              </ProtectedProfileElement> }
            />

            <Route
              path={ routes.profileList }
              element={ <ProtectedRouteElement
                pagePolicies={ ProfileRoutePoliciesConfig.PROFILE_LIST_ROUTE_ACCESS }
              >
                <ProfileList />
              </ProtectedRouteElement> }
            />
            <Route
              path={ routes.unProcessedProfiles() }
              element={ <ProtectedRouteElement
                pagePolicies={ ProfileRequiredPoliciesConfig.EDIT_FULL_PROFILE }
              >
                <UnProcessedProfileList />
              </ProtectedRouteElement> }
            />
            <Route
              path={ routes.geofenceManage }
              element={ <ProtectedRouteElement
                pagePolicies={ GeofenceRequiredPoliciesConfig.GEO_FENCE_MANAGE }
              >
                <GeofenceManageControl />
              </ProtectedRouteElement> }
            />

            <Route
              path={ routes.tenantAdmin.tenantAccessCards }
              element={ <ProtectedRouteElement
                pagePolicies={ TenantAccessCardRequiredPoliciesConfig.TENANT_ACCESS_CARD_ROUTE_ACCESS }
              >
                <TenantAccessCards />
              </ProtectedRouteElement> }
            />
            <Route
              path={ routes.tenantAdmin.tenantAccessCardDetails() }
              element={ <ProtectedRouteElement
                pagePolicies={ TenantAccessCardRequiredPoliciesConfig.TENANT_ACCESS_CARD_ROUTE_ACCESS }
              >
                <AccessCardDetails />
              </ProtectedRouteElement> }
            />
            <Route
              path={ routes.tenantAdmin.tenantAccessCardsLayouts }
              element={ <ProtectedRouteElement
                pagePolicies={ TenantAccessCardRequiredPoliciesConfig.TENANT_ACCESS_CARD_LAYOUTS_ROUTE_ACCESS }
              >
                <TenantAccessCardLayouts />
              </ProtectedRouteElement> }
            />
            <Route
              path={ routes.tenantAdmin.tenantAccessCardLayoutDetails() }
              element={ <ProtectedRouteElement
                pagePolicies={ TenantAccessCardRequiredPoliciesConfig.TENANT_ACCESS_CARD_LAYOUTS_ROUTE_ACCESS }
              >
                <AccessCardLayoutDetails />
              </ProtectedRouteElement> }
            />
            <Route
              path={ routes.companiesManagement.companies }
              element={ <ProtectedRouteElement
                pagePolicies={ ProfileRoutePoliciesConfig.COMPANY_LIST_ROUTE_ACCESS }
              >
                <CompaniesManagement />
              </ProtectedRouteElement> }
            />
            <Route
              path={ routes.companiesManagement.companyId() }
              element={ <ProtectedRouteElement
                pagePolicies={ ProfileRequiredPoliciesConfig.EDIT_COMPANIES }
              >
                <CompanyDetails />
              </ProtectedRouteElement> }
            />

            <Route
              path={ routes.locationConfiguration.locationsList }
              element={ <ProtectedRouteElement
                pagePolicies={ LocationConfigurationPoliciesConfig.LOCATION_CONFIGURATION_ROUTE_ACCESS }
              >
                <LocationsList />
              </ProtectedRouteElement> }
            />
            <Route
              path={ routes.locationManagement }
              element={ <ProtectedRouteElement
                pagePolicies={ LocationConfigurationPoliciesConfig.LOCATION_CONFIGURATION_ROUTE_ACCESS }
              >
                <LocationManagement />
              </ProtectedRouteElement> }
            />
            <Route
              path={ routes.locationConfiguration.unProcessedLocations() }
              element={ <ProtectedRouteElement
                pagePolicies={ LocationConfigurationPoliciesConfig.LOCATION_CONFIGURATION_BULK_UPLOAD }
              >
                <UpProcessesLocationsList />
              </ProtectedRouteElement> }
            />
            <Route
              path={ routes.visitorManagement }
              element={ <ProtectedRouteElement
                pagePolicies={ VisitorManagementRequiredPoliciesConfig.VISITOR_MANAGEMENT_ROUTE_ACCESS }
              >
                <VisitorManagement />
              </ProtectedRouteElement> }
            />
            <Route
              path={ routes.visitRequest() }
              element={ <ProtectedRouteElement
                pagePolicies={ VisitorManagementRequiredPoliciesConfig.VISITOR_MANAGEMENT_ROUTE_ACCESS }
              >
                <VisitRequest />
              </ProtectedRouteElement> }
            />
            <Route
              path={ routes.visitorDetails() }
              element={ <ProtectedRouteElement
                pagePolicies={ VisitorManagementRequiredPoliciesConfig.VISITOR_MANAGEMENT_ROUTE_ACCESS }
              >
                <VisitorDetails />
              </ProtectedRouteElement> }
            />
            <Route
              path={ routes.tenantAdmin.printJobList }
              element={ <ProtectedRouteElement
                pagePolicies={ TenantAccessCardRequiredPoliciesConfig.PRINT_JOBS_ROUTE_ACCESS }
              >
                <PrintJobList />
              </ProtectedRouteElement> }
            />
            <Route
              path={ routes.tenantAdmin.printJobDetails() }
              element={ <ProtectedRouteElement
                pagePolicies={ TenantAccessCardRequiredPoliciesConfig.PRINT_JOBS_ROUTE_ACCESS }
              >
                <PrintJobDetails />
              </ProtectedRouteElement> }
            />
            <Route
              path={ routes.visitorRequestDashboard }
              element={ <ProtectedRouteElement
                pagePolicies={ DashboardRequiredPoliciesConfig.DASHBOARD_ROUTE_ACCESS }
              >
                <ConnectedSitesDashboard />
              </ProtectedRouteElement> }
            />
            <Route
              path={ routes.threatLibraryCountry }
              element={ <ProtectedRouteElement pagePolicies={ ThreatReportPolicies.COUNTRY_RISK_REPORTS_READONLY }>
                <CountryRiskReportsPage />
              </ProtectedRouteElement> } />
            <Route
              path={ routes.threatLibraryCity }
              element={ <ProtectedRouteElement pagePolicies={ ThreatReportPolicies.CITY_RISK_REPORTS_READONLY }>
                <CityRiskReportsPage />
              </ProtectedRouteElement> } />
            <Route
              path={ routes.integrationMapping.integrationList }
              element={ <ProtectedRouteElement pagePolicies={ IntegrationMappingPoliciesConfig.INTEGRATION_MAPPING }>
                <IntegrationsList /></ProtectedRouteElement> }
            />
            <Route
              path={ routes.integrationMapping.integrationDetails() }
              element={ <ProtectedRouteElement pagePolicies={ IntegrationMappingPoliciesConfig.INTEGRATION_MAPPING }>
                <IntegrationDetail /> </ProtectedRouteElement> }
            />
            <Route
              path={ routes.icmConfig.severityLevelsId() }
              element={ <ProtectedRouteElement
                pagePolicies={ SeverityLevelsTenantRequiredPoliciesConfig.SEVERITY_LEVELS_READ_ACCESS }>
                <SeverityLevelsManagement />
              </ProtectedRouteElement> }
            />
            <Route
              path={ routes.icmConfig.severityLevels }
              element={ <ProtectedRouteElement
                pagePolicies={ SeverityLevelsTenantRequiredPoliciesConfig.SEVERITY_LEVELS_READ_ACCESS }>
                <SeverityLevels />
              </ProtectedRouteElement> }
            />

            <Route path="*" element={ <Navigate replace to={ routes.home } /> } />

            <Route path={ routes.showcase } element={ <Showcase /> } />
            <Route
              path={ routes.icmConfig.situationTypes }
              element={ <ProtectedRouteElement
                pagePolicies={ SituationTypePoliciesConfig.tenant.SITUATION_TYPE_ROUTE_ACCESS }>
                <SituationTypesList />
              </ProtectedRouteElement> }
            />
            <Route
              path={ routes.icmConfig.situationTypeDetails() }
              element={ <ProtectedRouteElement
                pagePolicies={ SituationTypePoliciesConfig.tenant.SITUATION_TYPE_ROUTE_ACCESS }>
                <SituationTypeDetails />
              </ProtectedRouteElement> }
            />
            <Route
              path={ routes.icmConfig.assets }
              element={ <ProtectedRouteElement
                pagePolicies={ AssetsManagementPoliciesConfig.ASSETS_MANAGEMENT_ROUTE_ACCESS }>
                <AssetsManagementList />
              </ProtectedRouteElement> }
            />
            <Route
              path={ routes.icmConfig.playbooks }
              element={ <ProtectedRouteElement
                pagePolicies={ PlaybookRequiredPoliciesConfig.tenant.PLAYBOOK_ROUTE_ACCESS }>
                <PlaybooksList />
              </ProtectedRouteElement> }
            />
            <Route
              path={ routes.icmConfig.playbookDetails() }
              element={ <ProtectedRouteElement
                pagePolicies={ PlaybookRequiredPoliciesConfig.tenant.PLAYBOOK_ROUTE_ACCESS }>
                <PlaybookDetails />
              </ProtectedRouteElement> }
            />
            <Route
              path={ routes.icmConfig.caseTypes }
              element={ <ProtectedRouteElement
                pagePolicies={ CaseTypeRequiredPoliciesConfig.tenant.CASE_TYPE_ROUTE_ACCESS }>
                <CaseTypesList />
              </ProtectedRouteElement> }
            />
            <Route
              path={ routes.icmConfig.caseTypeDetails() }
              element={ <ProtectedRouteElement
                pagePolicies={ CaseTypeRequiredPoliciesConfig.tenant.CASE_TYPE_ROUTE_ACCESS }>
                <CaseTypeDetails />
              </ProtectedRouteElement> }
            />

            <Route
              path={ routes.icmConfig.caseTypeRoleDetails() }
              element={ <ProtectedRouteElement
                pagePolicies={ CaseTypeRequiredPoliciesConfig.tenant.CASE_TYPE_ROUTE_ACCESS }>
                <RoleDetails />
              </ProtectedRouteElement> }
            />
            <Route
              path={ routes.icmConfig.caseManagement.caseList }
              element={ <ProtectedRouteElement
                pagePolicies={ CaseManagementPoliciesConfig.CASE_MANAGEMENT_ROUTE_ACCESS }>
                <CaseList />
              </ProtectedRouteElement> }
            />
            <Route
              path={ routes.icmConfig.caseManagement.edit() }
              element={ <ProtectedRouteElement
                pagePolicies={ CaseManagementPoliciesConfig.EDIT_CASE_MANAGEMENT }>
                <CaseEdit />
              </ProtectedRouteElement> }
            />
            <Route
              path={ routes.icmConfig.caseManagement.info() }
              element={ <ProtectedRouteElement
                pagePolicies={ CaseManagementPoliciesConfig.CASE_MANAGEMENT_ROUTE_ACCESS }>
                <CaseInfo />
              </ProtectedRouteElement> }
            />
          </>
        ) }

        { /* SSO Integration */ }
        <Route path={ routes.ssoIntegration } element={ <SSOIntegration /> } />
        <Route
          path={ routes.massCommsResponse }
          element={ <MassCommsResponse /> }
        />
        <Route
          path={ routes.massCommsSmsResponse }
          element={ <MassCommsSmsResponse /> }
        />

        { /* Fallback to login for unauthenticated users */ }
        { /* Redirect to Unified Experience to perform login */ }
        { !isAuthenticated && (
          <Route
            path="*"
            element={ <ExternalRedirect url={ environment.unifiedExperienceUrl } /> }
          />
        ) }
      </Routes>

      <RouterOverlay />
    </>
  );
};

export default RouterRoutes;
