import { Button, Checkbox, Select } from "@mantine/core";
import { FC } from "react";
import { Controller, FieldValues, UseFormReturn } from "react-hook-form";

import { constructTestId } from "@/core/util/test-id";
import {
  usePlaybookSelectModalStyles
} from "@/platform-context/control-icm-management/components/CaseTypes/Details/components/TeamsAndRoles/components/modals/PlaybookSelectModal/PlaybookSelectModal.styles";
import {
  ItemProps,
  PlaybookTaskItem
} from "@/platform-context/control-icm-management/types/CaseTypes/caseTypeTeamsAndRoles";

const FORM_NAME = 'playbook-select';
type Props = {
    cancelButtonHandler: () => void
    onSubmit: (data: FieldValues) => void
    formControls: UseFormReturn<FieldValues, unknown>
    selectOptions: ItemProps[]
    activePlaybookItem?: string
    handleSetActivePlaybookItem: (val: string) => void
    tasks: PlaybookTaskItem[]
}

const PlaybookSelectModalComponent: FC<Props> = ({
  cancelButtonHandler,
  onSubmit,
  formControls,
  selectOptions,
  activePlaybookItem,
  handleSetActivePlaybookItem,
  tasks
}) => {

  const { classes } = usePlaybookSelectModalStyles();

  const { control } = formControls;

  return (
    <div className={ classes.wrapper }>
      <Select
        data-testid={ constructTestId("SELECT", FORM_NAME, "playbookItemsList") }
        required
        searchable
        wrapperProps={ {
          style: {
            marginBottom: 24
          }
        } }
        label="Select Playbook"
        placeholder="Select"
        onChange={ handleSetActivePlaybookItem }
        data={ selectOptions }
        value={ activePlaybookItem }
      />
      <form onSubmit={ formControls.handleSubmit(onSubmit) }>
        { tasks.length > 0 && <div className={ classes.section }>
          <Controller
            control={ control }
            name="selectAll"
            /* eslint-disable-next-line react/jsx-no-bind */
            render={ ({
              field: { onChange, value }
            }) => (
              <div className={ classes.selectAllCheckboxContainer }>
                <Checkbox
                  data-testid={ constructTestId("CHECKBOX", FORM_NAME, "playbookItemsSelectAll") }
                  checked={ value }
                  onChange={ onChange }
                  label="Select All"
                />
              </div>
            ) }
          />
   
          { tasks.map((task) => (<Controller
            key={ task._id }
            control={ control }
            name={ `${activePlaybookItem}.${task._id}` }
            /* eslint-disable-next-line react/jsx-no-bind */
            render={ ({
              field: { onChange, value }
            }) => (
              <div className={ classes.checkboxContainer }>
                <Checkbox
                  data-testid={ constructTestId("CHECKBOX", FORM_NAME, `playbookItems-${task._id}`) }
                  checked={ value }
                  onChange={ onChange }
                  label={ task.name }
                />
              </div>
            ) }
          />)) }
        </div> }
        <div className={ classes.buttonContainer }>
          <div className={ classes.submitButtonContainer }>
            <Button
              data-testid={ constructTestId("BUTTON", FORM_NAME, "cancel") }
              variant="light"
              onClick={ cancelButtonHandler }
            >
              Cancel
            </Button>
            <Button
              data-testid={ constructTestId("BUTTON", FORM_NAME, "submit") }
              type={ "submit" }
            >
              Copy Checklist
            </Button>
          </div>
        </div>
      </form>
    </div>

  );
};

export default PlaybookSelectModalComponent;