import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';

import { logger } from "@/common/util/ConsoleLogger";

// Initialize plugins
dayjs.extend(customParseFormat);
dayjs.extend(utc);

export function standardizeTimestamp(timeStamp: number | string): number {
  if (typeof timeStamp === 'string') {
    try {
      return standardizeTimestamp(parseInt(timeStamp, 10));
    } catch (e) {
      return NaN;
    }
  }

  if (timeStamp.toString().length === 10) {
    return timeStamp * 1000;
  }

  return timeStamp;
}

export function formatDateTime(timestamp: number | string, format: string): string {
  const date = new Date(standardizeTimestamp(timestamp));

  const pad = (n: number, width: number) => {
    const z = '0';
    const nStr = n.toString();
    return nStr.length >= width ? nStr : new Array(width - nStr.length + 1).join(z) + n;
  };

  const replacements: { [key: string]: string } = {
    'YYYY': date.getFullYear().toString(),
    'MM': pad(date.getMonth() + 1, 2),
    'DD': pad(date.getDate(), 2),
    'HH': pad(date.getHours(), 2),
    'mm': pad(date.getMinutes(), 2),
    'ss': pad(date.getSeconds(), 2)
  };

  return format.replace(/YYYY|MM|DD|HH|mm|ss/g, match => replacements[match]);
}

export function dateTimeStringToTimestamp(dateTimeString: string): number {
  const date = new Date(dateTimeString);

  if (isNaN(date.getTime())) {
    throw new Error("Invalid date-time string");
  }

  return standardizeTimestamp(date.getTime());
}

export function formatNumberToDecimalPoints(num: number, decimalPoints: number): number {
  if (decimalPoints < 0) {
    logger.error("Decimal points must be a non-negative integer.");
    return num;
  }

  try {
    return parseFloat(num.toFixed(decimalPoints));
  } catch (e) {
    logger.error("Error occurred while formatting number to decimal points.");
    return num;
  }
}

export const parseDateToDay = (input: string | number): dayjs.Dayjs | undefined => {
  if (!input) {
    return undefined;
  }

  // Convert to string for consistency in checking
  const inputStr = input.toString().trim();

  // Try parsing as timestamp (both ms and s)
  if (/^\d+$/.test(inputStr)) {
    const numValue = parseInt(inputStr, 10);
    // If length is 13, assume milliseconds
    if (inputStr.length === 13) {
      return dayjs(numValue);
    }
    // If length is 10, assume seconds
    if (inputStr.length === 10) {
      return dayjs.unix(numValue);
    }
  }

  // Common date formats to try
  const formats = [
    'YYYY-MM-DD',
    'YYYY-MM-DD HH:mm:ss',
    'MM/DD/YYYY',
    'DD-MM-YYYY',
    'DD/MM/YYYY',
    'MM-DD-YYYY',
    'YYYY/MM/DD',
    'DD.MM.YYYY',
    'YYYY.MM.DD',
    'MM/DD/YYYY HH:mm:ss',
    'DD-MM-YYYY HH:mm:ss',
    'YYYY-MM-DDTHH:mm:ss.SSSZ'
  ];

  // First try parsing as ISO
  const isoAttempt = dayjs(inputStr);
  if (isoAttempt.isValid()) {
    return isoAttempt;
  }

  // Try each format using reduce instead of a loop
  const parsedResult = formats.reduce<dayjs.Dayjs | null>((result, format) => {
    if (result?.isValid()) {
      return result;
    }
    const attempt = dayjs(inputStr, format, true);
    return attempt.isValid() ? attempt : result;
  }, null);

  if (parsedResult) {
    return parsedResult;
  }

  // If nothing worked, try loose parsing as last resort
  const looseParsed = dayjs(inputStr);
  return looseParsed.isValid() ? looseParsed : undefined;
};
