/* eslint-disable max-lines */
import { createStyles } from "@mantine/core";
import { MantineTheme } from "@mantine/styles/lib/theme";

import { CSS_KEY } from "@/core/styles/mantine/constants";
import { genericColorPalette } from '@/core/styles/mantine/palettes';

type Props = {
  isCommsSequenceNoAvailable: boolean
}

export const useCreateCommsPageStyles = createStyles((theme: MantineTheme, { isCommsSequenceNoAvailable }: Props) => ({
  editorWrap:{
    '.tox:not(.tox-tinymce-inline) .tox-editor-header, .tox .tox-toolbar-overlord, .tox .tox-toolbar, .tox .tox-toolbar__overflow, .tox .tox-toolbar__primary':{
      background: "transparent"
    },
    '.mce-content-body':{
      backgroundColor: theme.colors.primary[5]
    }
  },
  container: {
    display: 'flex',
    flex: 1
  },
  layoutContainer: {
    display: 'grid',
    gridTemplateColumns: '505px minmax(0, 1fr)',
    gap: '16px',
    minHeight: '500px',
    overflowY: 'auto',
    overflowX: 'hidden',
    height: 'inherit'
  },
  configsWrapper: {
    backgroundColor: theme.colors.neutral[8],
    padding: 24,
    flex: 1
  },
  configurationContainer: {
    height: 'max-content',
    minHeight: '-webkit-fill-available'
  },
  createComms: {
    width: '505px',
    height: 'max-content'
  },
  commsWrapper: {
    position: 'relative',
    backgroundColor: theme.colors.neutral[8],
    padding: '24px'
  },
  sectionHeader: {
    fontWeight: theme.other.fw.semibold,
    fontSize: theme.headings.sizes.h6.fontSize,
    lineHeight: theme.other.lh.s
  },

  itemHeader: {
    color: theme.colors.neutral[5],
    fontSize: theme.fontSizes.md,
    lineHeight: theme.other.lh.l
  },

  templatesHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },

  specHeader: {
    color: theme.colors.neutral[5],
    fontSize: theme.fontSizes.sm,
    lineHeight: theme.other.lh.l
  },

  specDesc: {
    marginLeft: '5px'
  },

  standardMargin: {
    marginTop: '24px'
  },

  extendedMargin: {
    marginTop: '48px'
  },

  selectedItem: {
    backgroundColor: theme.colors.neutral[9]
  },

  inputItem: {
    input: {
      backgroundColor: `${theme.colors.neutral[8]} !important`,
      color: theme.colors.neutral[0],
      height: '40px',
      '&:disabled': {
        border: `1px solid ${theme.colors.neutral[6]}`
      }
    },
    marginTop: '7px',
    marginBottom: '0px'
  },
  originatorSelect: {
    input: {
      backgroundColor: theme.colors.neutral[8],
      height: '40px',
      border: `1px solid ${theme.colors.neutral[5]}`,
      color: theme.colors.neutral[0]
    },
    marginTop: '7px',
    marginBottom: '0px',
    width: '460px'
  },

  responseMethodContainer: {
    display: 'flex',
    height: '90px',
    marginTop: '24px',
    justifyContent: 'space-between'
  },

  responseItemContainer: {
    width: '135px',// Original value was 100px. Changed to 135px to fill the space caused by removing the voice icon
    padding: '5px',
    border: `1px solid ${theme.colors.neutral[6]}`,
    backgroundColor: theme.colors.neutral[8],
    color: theme.colors.neutral[5],
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.colors.neutral[9]
    }
  },

  responseItemContainerDisabled: {
    backgroundColor: theme.colors.neutral[7],

    '&:hover': {
      backgroundColor: theme.colors.neutral[7],
      cursor: 'not-allowed'
    }
  },

  responseItemIconDisabled: {
    '& > path': {
      fill: theme.colors.neutral[6]
    }
  },

  checkBoxSection: {
    width: '100%',
    height: '20px',

    [`.${CSS_KEY}-Checkbox-root`]: {
      height: 'inherit',
      width: '100%',
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-end'
    },
    [`.${CSS_KEY}-Checkbox-root .${CSS_KEY}-Checkbox-inner`]: {
      'svg path': {
        fill: theme.colors.neutral[9]
      }
    },
    input: {
      backgroundColor: theme.colors.neutral[5]
    },
    'input:checked:hover': {
      backgroundColor: theme.other.semantic.accessibility.outer,
      cursor: 'pointer'
    },
    'input:checked, input:hover': {
      backgroundColor: theme.other.semantic.accessibility.outer,
      cursor: 'pointer'
    }
  },

  iconSection: {
    height: '25px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    svg: {
      height: '24px',
      width: '24px'
    }
  },

  iconTextSection: {
    height: '35px',
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.fontSizes.sm,
    justifyContent: 'center'
  },

  root: {
    backgroundColor: 'transparent',
    minHeight: 300
  },

  toolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
    backgroundColor: 'transparent',
    border: "none",
    padding: 8,

    [`& .${CSS_KEY}-RichTextEditor-control`]: {
      backgroundColor: 'transparent',
      border: 'none',
      borderRadius: 4,

      '&:hover': {
        backgroundColor: theme.colors.neutral[6]
      }
    }
  },
  smsSpecSection: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between'
  },

  commsTextArea: {
    marginTop: '7px',
    textarea: {
      height: '252px',
      backgroundColor: theme.colors.neutral[9],
      color: theme.colors.neutral[0]
    }
  },

  smsSpecs: {
    display: 'flex',
    alignItems: 'center'
  },
  specItem: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '29px',
    fontSize: theme.fontSizes.sm
  },
  contentTitle: {
    fontWeight: theme.other.fw.bold,
    marginBottom: 12
  },
  contentTextArea: {
    'textarea': {
      backgroundColor: `${theme.colors.neutral[8]} !important`,
      color: theme.colors.neutral[0]
    }
  },
  smsContentTitle: {
    fontWeight: theme.other.fw.bold
  },
  infoText: {
    marginTop: 32,
    lineHeight: theme.other.lh.l,
    color: theme.colors.neutral[6]
  },

  section: {
    marginBottom: 48
  },

  noResultBody: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  },

  leftArrow: {
    marginRight: 24
  },

  commNameContainer: {
    marginBottom: 24,
    [`.${CSS_KEY}-Input-icon`]: {
      width: 'auto'
    },
    input: {
      paddingLeft: isCommsSequenceNoAvailable ? '64px' : 12
    }
  },

  sequenceNo: {
    color: theme.colors.neutral[5],
    paddingLeft: '10px'
  },

  alertNo: {
    color: theme.colors.neutral[5],
    paddingRight: '10px'
  },

  tabContent: {
    marginTop: 48,
    position: "relative"
  },

  switches: {
    display: 'flex',
    gap: 48
  },

  configItemsContainer: {
    display: 'flex',
    width: '100%',
    marginTop: '24px'
  },

  configItem: {
    color: theme.colors.neutral[5],
    fontSize: theme.fontSizes.md,
    width: '100%',
    input: {
      backgroundColor: theme.colors.neutral[9]
    },
    'div:first-child': {
      marginBottom: '7px'
    }
  },
  standardMarginLeft: {
    marginLeft: '24px'
  },
  settingsButton: {
    display: 'flex',
    marginLeft: 'auto',
    marginRight: 0
  },
  modalButtons: {
    display: 'flex',
    justifyContent: 'flex-end',

    '& > *:not(:first-child)': {
      marginLeft: 24
    }
  },

  channelConfigWrapper: {
    display: 'flex',
    alignItems: 'center',

    '& > *:not(:first-child)': {
      marginLeft: 24
    }
  },

  voiceContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: 24,
    marginTop: 16,
    border: `1px solid ${ theme.colors.neutral[7] }`
  },

  voiceMessageTypesRadioWrapper: {
    marginTop: 32,
    marginBottom: 24
  },

  radioGroup: {
    gap: '16px',
    marginTop: '7px',
    label: {
      fontSize: theme.fontSizes.md,
      color: theme.colors.neutral[4]
    },
    'input:checked': {
      backgroundColor: theme.colors.neutral[9],
      borderColor: theme.colors.neutral[5]
    },
    input: {
      backgroundColor: theme.colors.neutral[8],
      borderColor: theme.colors.neutral[5]
    },
    svg: {
      color: theme.other.semantic.accessibility.outer
    }
  },
  manageTemplatesLink: {
    display: 'flex',
    alignItems: 'center',
    color: '#6BA5FC', // We don't have this color in our palette. Need to revise all the color vars,
    fontSize: theme.fontSizes.sm,
    textDecoration: 'none'
  },
  templateLabel: {
    [`.${CSS_KEY}-Select-label`]: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%'
    }
  },
  orignEmailSelect: {
    select: {
      backgroundColor: theme.colors.neutral[8]
    }
  },
  deliveryTabs: {
    button: {
      '&[data-active]': {
        backgroundColor: theme.colors.neutral[8] + '!important'
      }
    }
  },
  caModalRoot: {
    [`.${CSS_KEY}-Paper-root`] :{
      backgroundColor: `${theme.colors.neutral[8]} !important`,
      border: '1px solid ' + theme.colors.neutral[6],
      padding: '24px !important'
    }
  },
  caModalRootGrid: {
    backgroundColor: theme.colors.neutral[9],
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gap: 8,
    padding: '24px 16px',
    rowGap: 16
  },
  caModalContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: 32
  },
  caModalSubHeading: {
    fontSize: 16,
    fontWeight: theme.other.fw.bold
  },
  caModalContentGrid: {
    display: 'flex',
    flexDirection: 'column',
    gap: 32,
    maxHeight: 440,
    overflowY: 'scroll',
    marginRight: -16,
    paddingRight: 16,
    '&::-webkit-scrollbar': {
      backgroundColor: theme.colors.neutral[9]
    },
    '::-webkit-scrollbar-thumb': {
      background: genericColorPalette.neutral[6],
      border: `2px solid ${theme.colors.neutral[9]}`
    }
  },
  caModalContentTitle: {
    fontSize: 14,
    fontWeight: 700,
    color: genericColorPalette.neutral[0]
  },
  caModalContentText: {
    fontSize: 14,
    fontWeight: 400,
    color: genericColorPalette.neutral[6]
  },
  caModalFooter: {
    marginTop: 32,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  caOkButton: {
    width: 150
  }
}));
