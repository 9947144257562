import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { logger } from "@/common/util/ConsoleLogger";
import { Dispatch, RootState } from "@/core/store";
import {
  useButtonAsLinkStyles
} from "@/tenant-context/control-risk-config/components/RiskProviderTreeContent/RiskProviderTreeContent.styles";

type UseToggleCategoryFilterOptions = {
  /** use this to get `filterId` if specified, or use `categoryId` as a filter  */
  categoryIdToFilterIdMap: Record<string, string>;
};

export const useToggleCategoryFilter = (
  providerId: string,
  options?: UseToggleCategoryFilterOptions
) => {
  const { categoryIdToFilterIdMap } = options || {};
  const {
    riskEventFilterDataOptions: {
      ENABLE_FILTER,
      DISABLE_FILTER,
      ENABLE_GLOBAL_CATEGORY_TOGGLE
    }
  } = useDispatch<Dispatch>();

  return useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked, value: categoryId } = event.currentTarget;
      const filter = categoryIdToFilterIdMap ? categoryIdToFilterIdMap[categoryId] : categoryId;

      if (!filter) {
        logger.error(
          `Can't apply risk threat filter', providerId=${providerId}, categoryId=${categoryId}`
        );

        return;
      }

      if (checked) {
        ENABLE_FILTER(filter);
        ENABLE_GLOBAL_CATEGORY_TOGGLE(providerId);
      } else {
        DISABLE_FILTER(filter);
      }
    },
    [categoryIdToFilterIdMap, providerId, ENABLE_FILTER, ENABLE_GLOBAL_CATEGORY_TOGGLE, DISABLE_FILTER]
  );
};

export const useDisabledRiskFilterTypes = () => {
  const disabledRiskFilterTypes = useSelector(
    (state: RootState) =>
      state.riskEventFilterDataOptions.disabledRiskFilterTypes
  );

  return useMemo(
    () => new Set(disabledRiskFilterTypes),
    [disabledRiskFilterTypes]
  );
};

export function useLegendInTree() {
  const { classes: buttonAsLinkClasses } = useButtonAsLinkStyles();
  const [risklineLegendVisible, setRisklineLegendVisible] = useState(false);
  const toggleRisklineLegend = useCallback(() => {
    setRisklineLegendVisible((prev) => !prev);
  }, []);

  return { buttonAsLinkClasses, risklineLegendVisible, toggleRisklineLegend };
}