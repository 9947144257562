/* eslint-disable react/jsx-props-no-spreading */
import { Button, Checkbox, Textarea, TextInput } from "@mantine/core";
import { FC, useCallback, useEffect } from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";

import { constructTestId } from "@/core/util/test-id";
import {
  useCreateChecklistItemModalStyles
} from "@/platform-context/control-icm-management/components/CaseTypes/Details/components/TeamsAndRoles/components/modals/CreateChecklistItemModal/CreateCheckListItemModal.styles";
import { ChecklistItem } from "@/platform-context/control-icm-management/types/CaseTypes/caseTypeTeamsAndRoles";

const FORM_NAME = 'checklist-item';

type Props = {
    cancelButtonHandler: () => void
    createHandler: (data: FieldValues) => void
    updateHandler:  (data: FieldValues) => void
    formControls: UseFormReturn<FieldValues, unknown>
    activeChecklistItem?: ChecklistItem,
    isAllowedToUpdate: boolean
}

const CreateChecklistItemModalComponent: FC<Props> = ({
  cancelButtonHandler,
  createHandler,
  updateHandler,
  formControls,
  activeChecklistItem,
  isAllowedToUpdate
}) => {

  const { register, setValue } = formControls;

  const { classes } = useCreateChecklistItemModalStyles();

  const handleResetWhenUserAssignedChange = useCallback((val) => {
    setValue('resetWhenUserAssigned', val, { shouldDirty: true });
  }, [setValue]);

  useEffect(() => {
    if(activeChecklistItem?._id) {
      setValue('_id', activeChecklistItem?._id);
    }
  }, [activeChecklistItem?._id, setValue]);

  useEffect(() => {
    setValue('resetWhenUserAssigned', activeChecklistItem?.resetWhenUserAssigned || false);
  }, [activeChecklistItem?.resetWhenUserAssigned, setValue]);

  return (
    <div className={ classes.wrapper }>
      <form onSubmit={ formControls.handleSubmit(activeChecklistItem ? updateHandler : createHandler) }>
        <div className={ classes.section }>
          <TextInput
            data-testid={ constructTestId("INPUT", FORM_NAME, "itemName") }
            label="Name"
            required={ true }
            readOnly={ !isAllowedToUpdate }
            defaultValue={ activeChecklistItem?.itemName }
            maxLength={ 128 }
            wrapperProps={ {
              style: {
                marginBottom: 0
              }
            } }
            { ...register('itemName') }
          />
          <label className={ classes.descriptionLabel }>No longer than 128 characters</label>
        </div>
        <div className={ classes.section }>
          <Textarea
            data-testid={ constructTestId("INPUT", FORM_NAME, "description") }
            maxRows={ 8 }
            minRows={ 8 }
            label="Description"
            defaultValue={ activeChecklistItem?.description }
            readOnly={ !isAllowedToUpdate }
            maxLength={ 1024 }
            wrapperProps={ {
              style: {
                marginBottom: 0
              }
            } }
            { ...register('description') }
          />
          <label className={ classes.descriptionLabel }>No longer than 1024 characters</label>
        </div>
        <div className={ classes.section }>
          <Checkbox
            { ...register('resetWhenUserAssigned') }
            data-testid={ constructTestId("CHECKBOX", FORM_NAME, "resetWhenUserAssigned") }
            onChange={ handleResetWhenUserAssignedChange }
            label="Reset when user assigned"
            defaultChecked={ activeChecklistItem?.resetWhenUserAssigned || false }
          />
        </div>
        <div className={ classes.buttonContainer }>
          <div className={ classes.submitButtonContainer }>
            <Button
              data-testid={ constructTestId("BUTTON", FORM_NAME, "cancel") }
              variant="light"
              onClick={ cancelButtonHandler }
            >
              Cancel
            </Button>
            <Button
              data-testid={ constructTestId("BUTTON", FORM_NAME, "submit") }
              disabled={ !isAllowedToUpdate }
              type={ "submit" }
            >
              { activeChecklistItem ? 'Edit Item' : 'Create Item' }
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateChecklistItemModalComponent;