import { FC, useCallback, useContext, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Dispatch, RootState } from "@/core/store";
import { ArcContext } from "@/tenant-context/control-action-response-center/context/ARC/ARC.context";
import { generateArcCaseImpactSummary } from "@/tenant-context/control-action-response-center/helpers/arc.helper";
import { ArcImpactStatus, ArcPeopleImpactSummary } from "@/tenant-context/control-action-response-center/types/ARC.types";
import { massCommsDefaultState } from "@/tenant-context/control-mass-comms/store/MassComms.model";

import MassCommsWidgetComponent from "./MassCommsWidget.component";

const MassCommsWidget: FC = () => {

  const {
    massCommsNavigation
  } = useSelector((state: RootState) => state.massComms);

  const selectedImpactStatus = useSelector((state: RootState) => state.arc.selectedImpactStatus);

  const { arcCase } = useContext(ArcContext);

  const {
    massComms: {
      navigateToMassComms,
      RESET_MASS_COMMS_TO_DEFAULT_STATE
    },
    arc: {
      SET_IS_ARC_OPEN,
      SET_SELECTED_IMPACT_STATUS
    }
  } = useDispatch<Dispatch>();

  const impactSummary: ArcPeopleImpactSummary = useMemo(
    () => generateArcCaseImpactSummary(arcCase),
    [arcCase]
  );

  const handleMassCommsNav = useCallback(() => {
    RESET_MASS_COMMS_TO_DEFAULT_STATE(massCommsDefaultState);
    SET_IS_ARC_OPEN(false);
    navigateToMassComms({ ...massCommsNavigation, isOpen: true, fromArc: true });
  }, [RESET_MASS_COMMS_TO_DEFAULT_STATE, SET_IS_ARC_OPEN, navigateToMassComms, massCommsNavigation]);

  const handleStatusFilterChange = useCallback((status: ArcImpactStatus) => 
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked){
        SET_SELECTED_IMPACT_STATUS(status);
      } else {
        SET_SELECTED_IMPACT_STATUS('ALL');
      }
    }, [SET_SELECTED_IMPACT_STATUS]);

  return (
    <MassCommsWidgetComponent  
      isPollSent={ arcCase?.communicationsAvailable || false }
      onMassCommsNavigation={ handleMassCommsNav }
      impactSummary={ impactSummary }
      responseCounts ={ arcCase?.responseStatusCount }
      onStatusFilterChange={ handleStatusFilterChange }
      selectedImpactStatus={ selectedImpactStatus }
    />
  );
};

export default MassCommsWidget;
