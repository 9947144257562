import { FC, useMemo } from "react";
import { useSelector } from "react-redux";

import { RootState } from "@/core/store";
import LocalErrorBoundaryComponent from "@/tenant-context/common/components/LocalErrorBoundary";
import { weatherLayersConfig } from "@/tenant-context/visualisation-weather";
import {
  WeatherMapLayersComponent
} from "@/tenant-context/visualisation-weather/components/WeatherMapLayers.component";
import WeatherOpacityControl from "@/tenant-context/visualisation-weather/components/WeatherOpacityControl";


export const WeatherMapLayersContainer: FC = () => {
  const contextualLayers = useSelector((state: RootState) => state.dataOptions?.contextLayerOptions);
  const tenantSubscribedProducts = useSelector((state: RootState) => state.commonData.tenantSubscribedProducts);

  const isContextualLayersSubscribed = useMemo<boolean>(
    () =>
      tenantSubscribedProducts
        ?.findIndex((product) => product.name === "Contextual Map Layers") !== -1,
    [tenantSubscribedProducts]
  );
  
  const opacityControls = weatherLayersConfig.map(
    ({ contextualLayerType, options }) => {
      const layerId = options.id;
      const isVisible = contextualLayers[contextualLayerType];
      const hasOpacityControl = options.paint?.opacity !== undefined;

      return isVisible
        && hasOpacityControl
        && <WeatherOpacityControl key={ layerId } layerId={ layerId } />;
    }
  );

  // TODO - Handle error boundary - reset error using weather controller
  return (
    <LocalErrorBoundaryComponent>
      <WeatherMapLayersComponent
        contextualLayers={ contextualLayers }
        isContextualLayersSubscribed={ isContextualLayersSubscribed }
      />

      { opacityControls }
    </LocalErrorBoundaryComponent>
  );
};
