import { FC, useContext } from "react";

import { ArcContext } from "@/tenant-context/control-action-response-center/context/ARC/ARC.context";

import RiskWidgetComponent from "./RiskWidget.component";

const RiskWidget: FC = () => {

  const { arcCase } = useContext(ArcContext);

  return (
    <RiskWidgetComponent
      arcCase ={ arcCase }
    />
  );
};

export default RiskWidget;
