import { Box, Text } from "@mantine/core";
import { FC } from "react";

import { ReactComponent as FutureRisk } from '@/tenant-context/control-action-response-center/icons/future-risk.svg';
import { ReactComponent as HighRisk } from '@/tenant-context/control-action-response-center/icons/high-risk.svg';
import { ReactComponent as ImpactPeople } from '@/tenant-context/control-action-response-center/icons/impact-people.svg';
import { ReactComponent as LowerRisk } from '@/tenant-context/control-action-response-center/icons/lower-risk.svg';
import { ArcCaseEvent } from "@/tenant-context/control-action-response-center/types/ARC.types";

import { useArcHeaderStyles } from "../../../ArcHeader/ArcHeader.style";


type Props = {
  arcCase?: ArcCaseEvent
}

const RiskWidgetComponent: FC<Props> = ({  
  arcCase }) => {
  const { classes } = useArcHeaderStyles();

  return (
    <Box className={ classes.riskWidgetContainer }>
      <Box className={ classes.riskStatContainer }>
        <Text className={ `${classes.riskValue} ${classes.totalRiskBG}` }>{ arcCase?.totalPeopleCount || 0 }</Text>
        <Box className={ classes.totalPeopleTier }>
          <ImpactPeople />
          <Box ml={ 8 }>
            <Text weight={ 'bold' } size={ 'sm' }>Impact Zone Population</Text>
            <Text className={ classes.miniTextLabel }>People impacted grouped by risk tier</Text>
          </Box>
        </Box>
      </Box>
      <Box className={ classes.riskStatContainer }>
        <Box className={ classes.riskValue }>{ arcCase?.riskTierCount?.highRiskCount || 0 }</Box>
        <Box className={ classes.otherRiskTier }>
          <Text className={ classes.tierHeader }><HighRisk /> Higher Risk</Text>
          <Text className={ classes.tierDescription }>Currently Inside Impact Zone</Text>
        </Box>
      </Box>
      <Box className={ classes.riskStatContainer }>
        <Box className={ classes.riskValue }>{ arcCase?.riskTierCount?.lowRiskCount || 0 }</Box>
        <Box className={ classes.otherRiskTier }>
          <Text className={ classes.tierHeader }><LowerRisk/> Lower Risk</Text>
          <Text className={ classes.tierDescription }>Linked to Impact Zone, Currently Outside</Text>
        </Box>
      </Box>
      <Box className={ classes.riskStatContainer }>
        <Box className={ classes.riskValue }>{ arcCase?.riskTierCount?.futureRiskCount || 0 }</Box>
        <Box className={ classes.otherRiskTier }>
          <Text className={ classes.tierHeader }><FutureRisk /> Future Risk</Text>
          <Text className={ classes.tierDescription }>Outside Zone, Expected to Enter During Threat</Text>
        </Box>
      </Box>
    </Box>
  );
};

export default RiskWidgetComponent;
