import { closeModal, openModal } from "@mantine/modals";
import { FC, useCallback, useContext, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { formatDate } from "@/common/util/format/date";
import { Dispatch, RootState } from "@/core/store";
import { changeArcPersonStatus } from "@/tenant-context/control-action-response-center/api/risk-alert";
import {
  ImpactStatusChangeModalComponent
} from "@/tenant-context/control-action-response-center/components/ImpactTab/components/ImpactStatusChangeModal";
import ImpactTabComponent from "@/tenant-context/control-action-response-center/components/ImpactTab/ImpactTab.component";
import {
  ImpactPeopleGridData,
  ImpactPeopleGridDataList
} from "@/tenant-context/control-action-response-center/components/ImpactTab/types/ImpactTab.types";
import { ArcContext } from "@/tenant-context/control-action-response-center/context/ARC/ARC.context";
import { ArcImpactStatus } from "@/tenant-context/control-action-response-center/types/ARC.types";
import { LocationContext } from "@/tenant-context/control-lookup-tool/types/Lookup.types";
import { handleArcLocationTypeDisplay, handleLocationTypeDisplay } from "@/tenant-context/control-lookup-tool/utils/LocationType";
import { getTime } from "@/tenant-context/control-profile/util/time-convector";
import { handleAdapterSourceDisplay } from "@/tenant-context/visualisation-people/util/utils";

import { useImpactTabStyles } from "./ImpactTab.styles";

const ImpactTab: FC = () => {
  const [ searchString, setSearchString ] = useState('');
  const [ personTypeFilter, setPersonTypeFilter ] = useState<string>('');
  const [ locationTypeFilter, setLocationTypeFilter ] = useState<string>('');
  const [ impLocationTypeFilter, setImpLocationTypeFilter ] = useState<string>('');
  const [ riskTierFilter, setRiskTierFilter ] = useState<string>('');
  const [ alertSent, setAlertSent ] = useState<string>('');
  const [ isPeopleImpactedTab, setIsPeopleImpactedTab ] = useState<boolean>(true);

  const { arcCase, rawImpactPeople, handleRowExpand, impactedDetails, lastUpdateTime } = useContext(ArcContext);
  const tenantId = useSelector((state: RootState) => state.commonData.tenantId);
  const selectedImpactStatus = useSelector((state: RootState) => state.arc.selectedImpactStatus);

  const [isProfileOpened, setIsProfileOpened] = useState(false);
  const carouselRef = useRef<HTMLDivElement>(null);
  
  const {
    userProfile:{
      SET_CURRENT_USER_ID,
      SET_CURRENT_USER_PROFILE_ID
    }
  } = useDispatch<Dispatch>();

  const { classes } = useImpactTabStyles();

  const {
    arc: {
      SET_SELECTED_IMPACT_STATUS
    }
  } = useDispatch<Dispatch>();

  const rowData: ImpactPeopleGridDataList = useMemo(() => {
    if (!rawImpactPeople || rawImpactPeople.length === 0) {
      return [];
    }

    const stringFilter = (value?: string, filter?: string) => {
      if (!value) {
        return false;
      }
      if (!filter || filter === 'ALL') {
        return true;
      }

      return value.toLowerCase().includes(filter.toLowerCase());
    };

    return rawImpactPeople
      .filter((impactItem) =>
        selectedImpactStatus === 'ALL' || selectedImpactStatus === undefined || 
      impactItem?.communicationInfo?.responseStatus === selectedImpactStatus)
      .map((person) => ({
        name: `${person.profileInfo?.fistName} ${person.profileInfo?.lastName}`,
        nameExpanded: <div className={ classes.textWrap }>
          <span className={ classes.dimmed }>
            { person.personDetails?.company }
          </span>
          { person.personDetails?.jobTitle }
        </div>,
        personId: person.personId,
        personType: person.profileInfo?.personType || person.personType || "N/A",
        personTypeExpanded: '',
        locationType: person.locationContextEnum ? handleLocationTypeDisplay(person.locationContextEnum) : "N/A",
        locationTypeExpanded:  <div className={ classes.textWrap }>
          <span className={ classes.dimmed }>
            { person.topRank?.location.type }
          </span>
          <span className={ classes.accented + " " + classes.underline }>
            Confirmed
          </span>
          <span className={ classes.dimmed }>
            DATA VIA:
          </span>
          { person.topRank?.locationContextEnum === "HOME" ? "Home Location" : handleAdapterSourceDisplay(person.topRank?.adapterSource || "N/A") }
        </div>,
        location: person.locationContextEnum === "HOME" ? "Confidential" : person.impactLocation || "N/A",
        locationExpanded: <div className={ classes.textWrap }>
          <span className={ classes.dimmed }>
            LAST KNOWN LOCATION
          </span>
          { person.locationContextEnum === 'HOME' ? (
            "Home Address - Confidential"
          ) : (
            <>
              Via{ ' ' }
              { handleAdapterSourceDisplay(
                person.topRank?.adapterSource || "N/A"
              ) }
              <span className={ classes.accented }>
                { person.geoLocation?.place_name }
                <br />
                { person.geoPoint.lon } - { person.geoPoint.lat }
              </span>
            </>
          ) }
          <span className={ classes.dimmed }>
            SINCE
          </span>
          <span className={ classes.underline }>
            { getTime(person.topRank?.startTime, true) } AGO
          </span>
          <span className={ classes.dimmed }>
            { formatDate(new Date(person.topRank?.startTime || new Date())) }
          </span>
        </div>,
        lastContacted: '22 Jun 2023 7:00 UTC',
        lastContactedExpanded: <div className={ classes.textWrap }>
          <span className={ classes.dimmed }>
            CHANNEL
          </span>
          Notification
        </div>,
        response: 'Yes',
        responseExpanded: <div className={ classes.textWrap }>
          <span className={ classes.dimmed }>
            DELIVERED
          </span>
          <span className={ classes.underline }>
            Email
          </span>
          <span className={ classes.dimmed }>
            22nd Jun 2023 - 16:30 UTC
          </span>
        </div>,
        safetyStatus: person.communicationInfo?.responseStatus,
        geoPoint: person.geoPoint,
        communicationInfo: person.communicationInfo,
        impactedLocation: handleArcLocationTypeDisplay(person.impactedLocationInfo?.locationContextEnum as LocationContext) || '',
        islatestLocationInside: person.latestLocationInfo?.isAffected ? 'true' : 'false',
        latestLocation: handleArcLocationTypeDisplay(person.latestLocationInfo?.locationContextEnum as LocationContext) || '',
        riskTier: person.riskTier,
        isAlertSent: person.communicationInfo?.alertSent ? 'true' : 'false',
        profilePictureThumbnailUrl: person.profileInfo?.profilePictureThumbnailUrl || undefined
      } as ImpactPeopleGridData))
      .filter((person) => person.name.toLowerCase().includes(searchString.toLowerCase()))
      .filter((person) => stringFilter(person.islatestLocationInside?.toString(), locationTypeFilter))
      .filter((person) => stringFilter(person.impactedLocation?.toString(), impLocationTypeFilter))
      .filter((person) => stringFilter(person.riskTier?.toString(), riskTierFilter))
      .filter((person) => stringFilter(person.isAlertSent?.toString(), alertSent))
      .filter((person) => stringFilter(person.personType.toString(), personTypeFilter));
  }, [ rawImpactPeople, selectedImpactStatus, classes.textWrap, classes.dimmed, classes.accented,
    classes.underline, searchString, personTypeFilter, locationTypeFilter, 
    riskTierFilter, impLocationTypeFilter, alertSent]);

  const handleStatusFilterChange = useCallback((status: ArcImpactStatus) => {
    SET_SELECTED_IMPACT_STATUS(status);
  }, [ SET_SELECTED_IMPACT_STATUS ]);

  const handleSearchStringChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(event.target.value);
  }, [ setSearchString ]);

  const handleManualStatusChange = useCallback(async (
    personId: string,
    status: ArcImpactStatus,
    notes: string
  ) => {
    if (!arcCase || !personId) {
      return;
    }

    await changeArcPersonStatus(
      personId,
      notes,
      arcCase.caseId,
      status,
      tenantId
    );

    closeModal('arcUpdateSafetyStatusModal');
  }, [ arcCase, tenantId ]);

  const handleManualStatusChangeRequest = useCallback((
    personId: string,
    personName: string,
    personStatus: ArcImpactStatus
  ) => {
    openModal({
      modalId: 'arcUpdateSafetyStatusModal',
      title: 'Update safety status',
      children: (
        <ImpactStatusChangeModalComponent
          personName={ personName }
          status={ personStatus }
          // eslint-disable-next-line react/jsx-no-bind
          onSubmit={ (updatedPersonStatus: ArcImpactStatus, notes: string) =>
            handleManualStatusChange(personId, updatedPersonStatus, notes) }
          // eslint-disable-next-line react/jsx-no-bind
          onCancel={ () => {
            closeModal('arcUpdateSafetyStatusModal');
          } }
        />
      )
    });
  }, [ handleManualStatusChange ]);

  const handleNavigateToPersonProfileRequested = useCallback((personId: string) => {
    SET_CURRENT_USER_ID(personId);
    SET_CURRENT_USER_PROFILE_ID(personId);
    setIsProfileOpened(true);
  }, [SET_CURRENT_USER_ID, SET_CURRENT_USER_PROFILE_ID]);

  const handleSubImpactTabChange = useCallback(() => {
    setIsPeopleImpactedTab((prev) => !prev);
  }, []);

  const impactedSites = useMemo<{ siteName: string, siteAddress: string }[]>(() => {
    if (!impactedDetails) {
      return [];
    }

    return impactedDetails
      .filter((detail) => detail.impactedAssetType === 'LOCATION')
      .map(({ assetId }) => ({
        siteName: assetId as string,
        siteAddress: 'N/A'
      }));
  }, [impactedDetails]);

  return (
    <ImpactTabComponent
      rowData={ rowData }
      selectedImpactStatus={ selectedImpactStatus }
      onSearchStringChange={ handleSearchStringChange }
      onStatusFilterChange={ handleStatusFilterChange }
      onManualStatusChangeRequest={ handleManualStatusChangeRequest }
      onNavigateToPersonProfileRequested={ handleNavigateToPersonProfileRequested }
      handleRowExpand = { handleRowExpand }
      isProfileOpened = { isProfileOpened }
      setIsProfileOpened = { setIsProfileOpened }
      onPersonTypeFilterChange={ setPersonTypeFilter }
      onLatLocationFilterChange={ setLocationTypeFilter }
      isPeopleImpactedTab={ isPeopleImpactedTab }
      onSubImpactTabChange={ handleSubImpactTabChange }
      impactedSites={ impactedSites }
      carouselRef={ carouselRef }
      onRiskFilterChange={ setRiskTierFilter }
      onImpLocationFilterChange={ setImpLocationTypeFilter }
      onAlertFilterChange={ setAlertSent }
      lastUpdateTime={ lastUpdateTime }
    />
  );
};

export default ImpactTab;
