import * as mapsgl from "@aerisweather/mapsgl";
import { FC, useCallback, useEffect, useState } from "react";
import { useMap } from "react-map-gl";
import { useSelector } from "react-redux";

import { logger } from "@/common/util/ConsoleLogger";
import { RootState } from "@/core/store";
import { ContextualLayersOptions } from "@/core/store/data-options/data-options.state";
import { useBigMapContext } from "@/tenant-context/core/context/BigMap.context";
import { weatherLayersConfig, WeatherPopupData } from "@/tenant-context/visualisation-weather";
import { WeatherLayerPopup } from "@/tenant-context/visualisation-weather/components/WeatherLayerPopup.component";
import {
  AERIS_ID,
  AERIS_KEY,
  DEFAULT_SURFACE_LAYER_ID
} from "@/tenant-context/visualisation-weather/config/weather.config";
import { mapWeatherRawFeatureToPopupData } from "@/tenant-context/visualisation-weather/utils/weather-utils";

type WeatherMapProps = {
  contextualLayers: ContextualLayersOptions;
  isContextualLayersSubscribed: boolean;
}

export const WeatherMapLayersComponent: FC<WeatherMapProps> = ({ contextualLayers, isContextualLayersSubscribed }) => {
  const [popupData, setPopupData] = useState<WeatherPopupData | null>(null);
  const { current: map } = useMap();
  const { weatherController, setWeatherController } = useBigMapContext();
  const layerOpacity = useSelector((state: RootState) =>
    state.dataOptions.layerOpacity);

  const handlePopupClose = useCallback(() => {
    setPopupData(null);
  }, []);

  // Initialize the controller if not already initialized
  useEffect(() => {
    if (!map) {
      logger.error('Weather Layers: map instance is not available');
      return;
    }

    if (!AERIS_ID || !AERIS_KEY) {
      logger.error('Weather Layers: Aeris ID and Key are required to use the Aeris Interactive Map');
      return;
    } 

    if (!weatherController && isContextualLayersSubscribed) {
      const account = new mapsgl.Account(AERIS_ID, AERIS_KEY);
      const newController = new mapsgl.MapboxMapController(map.getMap(), { account });
      setWeatherController(newController);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      newController.on('click', (e: any) => {
        const rawQueriedFeatures = newController.query(e.coord);
        const mappedPopupData = mapWeatherRawFeatureToPopupData(rawQueriedFeatures);

        if (!mappedPopupData) {
          return;
        }

        setPopupData({
          latitude: e.coord.lat,
          longitude: e.coord.lon,
          ...mappedPopupData
        });
      });
    }

    return () => {
      weatherController?.layers.forEach((layer) => {
        if (weatherController?.hasLayer(layer.id)) {
          weatherController?.removeLayer(layer.id);
        }
      });
      weatherController?.dispose(true);
    };
  }, [weatherController, map, setWeatherController, isContextualLayersSubscribed]);


  // Add or remove layers based on the contextual layer configuration
  useEffect(() => {
    if (!weatherController) {
      return;
    }

    weatherLayersConfig.forEach(({ options, contextualLayerType, beforeId }) => {
      const layerId = options.id;
      const isVisible = contextualLayers[contextualLayerType];
      const opacity = layerOpacity[layerId] || options.paint?.opacity;

      try {
        if (isVisible && !weatherController.hasWeatherLayer(layerId)) {
          weatherController.addWeatherLayer(
            layerId,
            {
              ...options,
              paint: {
                ...options.paint,
                opacity
              }
            },
            beforeId ?? DEFAULT_SURFACE_LAYER_ID
          );
        } else if (weatherController.hasWeatherLayer(layerId) && !isVisible) {
          weatherController.removeWeatherLayer(layerId);

          if (weatherController.layers.length === 0) {
            weatherController.dispose(true);
            setWeatherController(undefined);
          }
        }
      } catch (error) {
        logger.error(`Weather Layers: Error adding/removing layer ${layerId}`, error);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextualLayers]);

  // Remove weather layers on unmount
  useEffect(() => {
    if (!weatherController) {
      return;
    }

    return () => {
      weatherLayersConfig.forEach((layer) => {
        const layerId = layer.options.id;
        if (weatherController.hasWeatherLayer(layerId)) {
          weatherController.removeWeatherLayer(layerId);
        }
      });
    };
  }, [weatherController]);

  return popupData ? (
    <WeatherLayerPopup
      longitude={ popupData.longitude }
      latitude={ popupData.latitude }
      data={ popupData }
      onClose={ handlePopupClose }
    />
  ) : null;
};
