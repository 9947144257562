import { callApi } from '@/common/util/api';
import { CityRiskProvider, MaxSecCityRiskData, RiskLineCityRiskData } from '@/tenant-context/control-reports/types/city-risk-reports';

export const getCityRiskReport = (provider: string, cityTid: string) => callApi<MaxSecCityRiskData>(
  `/report-service/v1/city-risk-content?providerName=${provider.toUpperCase()}&pageSize=10000&pageNum=0&mergeNonTenant=false&orderBy=json.city:ASC&tid=${cityTid}`,
  {
    method: 'get'
  }
);
export const getCityRiskReportsSummery = (provider: string, mergeNonTenant: boolean) => 
  callApi<MaxSecCityRiskData[] | RiskLineCityRiskData[]>(
    `/report-service/v1/city-risk-content/summery?providerName=${provider.toUpperCase()}&pageSize=10000&pageNum=0&mergeNonTenant=${mergeNonTenant}&orderBy=json.city:ASC`,
    {
      method: 'get'
    }
  );
export const getCityRiskProvidersList = () => callApi<CityRiskProvider[]>(
  `/report-service/v1/city-risk-content/provider?pageSize=0&pageNum=0&mergeNonTenant=false&contentProviderType=risk`,
  {
    method: 'get'
  }
);
