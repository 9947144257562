/* eslint-disable no-magic-numbers */
import bbox from '@mapbox/geojson-extent';
import { Feature, FeatureCollection, Geometry } from 'geojson';
import mapboxgl from 'mapbox-gl';
import { FC, SetStateAction, useContext, useEffect, useState } from 'react';
import { useMap } from 'react-map-gl';
import { useDispatch, useSelector } from 'react-redux';

import { Dispatch, RootState } from '@/core/store';
import { fitToViewportBounds } from '@/tenant-context/common/util/map';
import { RiskContext } from '@/tenant-context/visualisation-risk-alerts/context/Risk.context';
import { ImpactPosition } from '@/tenant-context/visualisation-risk-details/types/risk-impact';
import {
  drawRiskImpactAreaFeatureCollection
} from '@/tenant-context/visualisation-risk-details/util/drawRiskImpactAreaFeatureCollection';
import { drawRiskImpactCircle } from '@/tenant-context/visualisation-risk-details/util/drawRiskImpactCircle';

import RiskImpactLayer from './RiskImpactLayer.component';

type Props = {
  impactPositionData?: ImpactPosition
  isImpactZoneModeEnabled?: boolean
  impactRadius?: number
};

const _getTimout = (zoomLevel: number) => {
  if (zoomLevel >= 17) {
    return 4000;
  } else if (zoomLevel > 14) {
    return 3000;
  } else if (zoomLevel > 11) {
    return 500;
  } else if (zoomLevel > 8) {
    return 1000;
  } else if (zoomLevel > 6) {
    return 2000;
  } else if (zoomLevel > 5) {
    return 3000;
  } else {
    return 4000;
  }
};

const RiskImpactLayerContainer: FC<Props> = ({
  impactPositionData,
  isImpactZoneModeEnabled,
  impactRadius
}) => {
  const { current: map } = useMap();
  const riskImpactData = useSelector((state: RootState) => state.riskDetails.currentRiskAlertImpactEvent);
  const radius = isImpactZoneModeEnabled ? impactRadius : riskImpactData?.calculation.appliedTo;
  const {
    isRiskImpactCircleEnabled,
    impactedAreaDetails
  } = useSelector((state: RootState) => state.riskDetails);
  const impactPosition = isImpactZoneModeEnabled
    ? impactPositionData
    : riskImpactData?.request?.geometry;

  const [riskImpactArea, setRiskImpactArea] = useState<Feature<Geometry> | FeatureCollection<Geometry>>();
  const {
    riskDetails: {
      TOGGLE_POPUP_LINE
    }
  } = useDispatch<Dispatch>();

  const {
    calculatePopupOffset
  } = useContext(RiskContext);

  useEffect(() => {
    if (!map || !impactPosition) {
      return;
    }
    map.on('click', () => {
      if (!isImpactZoneModeEnabled) {
        TOGGLE_POPUP_LINE(false);
      }
    });

    const isMultiPolygon = (riskImpactData?.impactedAreas && (riskImpactData?.impactedAreas?.length > 0));

    // eslint-disable-next-line fp/no-let
    let impactArea: SetStateAction<Feature | FeatureCollection | undefined>;
    // eslint-disable-next-line fp/no-let
    let selectedImpactArea: Feature<Geometry> | undefined;
    if (isMultiPolygon) {
      impactArea = drawRiskImpactAreaFeatureCollection(riskImpactData?.impactedAreas);
      selectedImpactArea = impactArea.features.find(
        (feature: Feature<Geometry>) => feature?.properties?.id === impactedAreaDetails?.selectedImpactedAreaId
      );
    } else {
      impactArea = drawRiskImpactCircle([impactPosition.lon, impactPosition.lat], radius ?? 0, 64);
    }

    setRiskImpactArea(impactArea);

    const impactAreaBBOX = bbox(selectedImpactArea || impactArea);

    if (!impactAreaBBOX) {
      return;
    }

    // eslint-disable-next-line fp/no-let
    let bounds;

    // Check if the bbox crosses the 180 line
    if (impactAreaBBOX?.[0] > 180 || impactAreaBBOX?.[2] > 180) {
      bounds = new mapboxgl.LngLatBounds(
        [impactAreaBBOX[2], impactAreaBBOX[1]],
        [impactAreaBBOX[0], impactAreaBBOX[3]]
      );
    } else {
      bounds = new mapboxgl.LngLatBounds(
        [impactAreaBBOX[0], impactAreaBBOX[1]],
        [impactAreaBBOX[2], impactAreaBBOX[3]]
      );
    }

    fitToViewportBounds(map, bounds, { right: !isImpactZoneModeEnabled });


    // Commented out due to https://restrataplatform.atlassian.net/browse/RP-8867
    // // Popup line should draw just after the zooming in is completed
    // // timeout various based on the users current zoom level and getTimout() returns
    // // necessary time for the timeout function to wait
    // if(!isImpactZoneModeEnabled){
    //   setTimeout(() => {
    //     calculatePopupOffset();
    //     TOGGLE_POPUP_LINE(true); 
    //   }, 200); // Changed this from getTimout(bigMapZoomLevel) to 200. Need to check if this is correct
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TOGGLE_POPUP_LINE, calculatePopupOffset,map,
    impactPosition, radius, impactedAreaDetails]);

  return (
    <RiskImpactLayer
      impactCircleData = { riskImpactArea }
      isRiskImpactCircleEnabled = { isRiskImpactCircleEnabled ||
        (isImpactZoneModeEnabled !== undefined && isImpactZoneModeEnabled) }
    />
  );
};

export default RiskImpactLayerContainer;
