import { Feature, FeatureCollection } from "geojson";

export type Key = {
  id: string,
  value: string
};

export type KeyAccessor<T> = (datum: T) => Key[];

export type ArrayElement<ArrayType extends readonly unknown[]> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

const getKeysFromFeature = <I extends Feature>(
  feature: I,
  keys: string[] | KeyAccessor<I>
): Key[] => {
  if (!feature.properties) {
    throw new Error(
      'Something went wrong! Feature does not has properties'
    );
  }

  if (typeof keys === 'function') {
    return keys(feature);
  }

  return keys.reduce(
    (acc, key): Key[] => [
      ...acc,
      {
        id: key,
        value: feature?.properties?.[key]
      }
    ],
    [] as Key[]
  );
};

/**
 * Given 2 arrays of keys (id, value) -> ensure they are equal by value
 * Order is not respected
 */
export const areKeysEqual = (
  keysA: Key[],
  keysB: Key[]
): boolean => {
  return keysA.every(
    ({ id: idA, value: valueA }) => keysB.some(
      ({ id: idB, value: valueB }) => idA === idB && valueA === valueB
    )
  );
};

/**
 * Merge featureCollectionB into featureCollectionA,
 * -> For each feature of featureCollectionB as b that exists (by keys) in featureCollectionA as a
 *    Replace a with b
 * -> For each feature of featureCollectionB as b that does not exist in featureCollectionA
 *    Add it there
 */
export function mergeFeatureCollections<
  T extends FeatureCollection,
  I extends ArrayElement<T['features']>
>(
  featureCollectionA: T,
  featureCollectionB: T,
  idKeyOrIdKeys: string | string[] | KeyAccessor<I>  = 'assetId'
): T {
  const idKeys: string[] | KeyAccessor<I> = typeof idKeyOrIdKeys === 'function'
    ? idKeyOrIdKeys
    : Array.isArray(idKeyOrIdKeys)
      ? idKeyOrIdKeys
      : [idKeyOrIdKeys];

  // Make a structure for quick search an element index in A by keys: sorted keys=values pairs => index in A
  const lookupOnA = new Map<string, number>();
  // Sort them because order is not respected, see `areKeysEqual` for comparison principle
  const getLookupKey = (keys: Key[]) =>
    keys.map(({ id, value }) => `${id}=${value}`).sort((a, b) => a.localeCompare(b)).join(',');

  featureCollectionA.features.forEach((feature, index) => {
    lookupOnA.set(
      getLookupKey(getKeysFromFeature(feature as I, idKeys)),
      index
    );
  });

  const updatedFeatures = featureCollectionB.features.reduce(
    // Starting with featureCollectionA as nergedFeatures
    // Go through each featureCollectionB member as incomingFeature
    (mergedFeatures, incomingFeature) => {
      // An entity can be considered unique by having a certain set of keys
      // Say, having A and B
      // A.k1 === B.k1 && A.k2 === B.k2 <=> A and B are representations of the same entity
      const incomingKeys = getKeysFromFeature(
        incomingFeature as I,
        idKeys
      );
      const keyForLookup = getLookupKey(incomingKeys);

      // Find a feature equal to the given one by defined set of keys
      const featureToUpdateIndex = lookupOnA.get(keyForLookup) ?? -1;

      // If such feature doesn't yet exist -> push the new representation
      if (featureToUpdateIndex === -1) {
        mergedFeatures.push(incomingFeature);
        // also update lookup map
        lookupOnA.set(keyForLookup, mergedFeatures.length - 1);
        return mergedFeatures;
      }

      // If such feature does exist -> replace it with the new one

      // eslint-disable-next-line no-param-reassign
      mergedFeatures[featureToUpdateIndex] = incomingFeature;

      return mergedFeatures;
    },
    [...featureCollectionA.features]
  );

  const updatedFeatureCollection: T = {
    ...featureCollectionA,
    features: updatedFeatures
  };

  return updatedFeatureCollection;
}