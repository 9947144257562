import { Flex, Stack, Text, useMantineTheme } from "@mantine/core";
import React, { FC, isValidElement } from "react";

import { CustomColors } from "@/core/styles/mantine/palettes/types";

type MetaDataItem = {
  label: string;
  value: React.ReactNode;
};

type Props = {
  items: MetaDataItem[];
};

export const LayerMetadataComponent: FC<Props> = ({ items }) => {
  const theme = useMantineTheme();
  return (
    <Stack spacing={ 8 }>
      <Text size={ 16 } fw={ 700 }>
        Layer Metadata
      </Text>
      <Stack spacing={ 8 }>
        { items.map(({ label, value }) => (
          <Flex key={ label } gap={ 12 } h={ 44 } px={ 12 } py={ 4 }
            align={ "center" } justify={ "space-between" }
            bg={ theme.colors.royalBlue[3] as CustomColors }
            sx={ { borderRadius: 8 } }
          >
            <Text size={ 14 }>{ label }</Text>
            { isValidElement(value) ? value : <Text size={ 14 }>{ value }</Text> }
          </Flex>
        )) }
      </Stack>
    </Stack>
  );
};
