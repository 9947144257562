import { Carousel } from "@mantine/carousel";
import { Box } from "@mantine/core";
import { FC } from "react";

import { useScrollableSliderStyles } from "./ScrollableSlider.styles";

type Props = {
  children: React.ReactNode[],
  isScrollable: boolean,
  childWidth: number,
  gap: number
}

const ScrollableSliderComponent: FC<Props> = ({ children, isScrollable, childWidth, gap }) => {
  const { classes } = useScrollableSliderStyles(gap);

  return (
    <Box className={ classes.carouselWrapper }>
      { isScrollable ? 
        <Carousel 
          slideSize={ `${childWidth}px` } 
          slideGap={ gap } 
          align="start" 
          containScroll="trimSnaps" 
          controlsOffset="xs" 
          withControls
        > 
          { children?.map((child, index) => (<Carousel.Slide key={ index }>
            <Box>{ child }</Box></Carousel.Slide>)) } 
        </Carousel> : 
        <Box className={ classes.carouselInnerContainer }>
          { children?.map((child, index) => (
            <Box key={ index }>{ child }</Box>
          )) }
        </Box> }
    </Box>
  );
};

export default ScrollableSliderComponent;
