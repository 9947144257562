import { Flex, Progress, Rating, Stack, Text } from '@mantine/core';
import { FC, Fragment, useCallback, useMemo } from 'react';

import { ReactComponent as StarEmpty } from '@/common/icons/Components/rating-star-empty.svg';
import { ReactComponent as StarFilled } from '@/common/icons/Components/rating-star-filled.svg';
import {
  useCountryRiskStatusCardStyles
} from '@/tenant-context/control-reports/components/CountryRiskStatusCard/CountryRiskStatusCard.styles';
import {
  RisklineCountryRiskSummary
} from "@/tenant-context/control-reports/types/country-risk-reports";
import { RiskReportProvider } from '@/tenant-context/control-reports/types/risk-reports-common';
import {
  camelCaseToTitleCase,
  getRiskColorByRiskLevel, getRiskLevelValueByRiskLevel, getStartRatingForRiskline
} from "@/tenant-context/control-reports/util/country-risk-reports";

type Props = {
  countryRiskData: RisklineCountryRiskSummary;
}

const CountryRiskStatusCardRisklineComponent: FC<Props> = ({
  countryRiskData
}) => {

  const getProgressColor = useMemo(() => {
    return getRiskColorByRiskLevel(countryRiskData.riskLevel, RiskReportProvider.RISKLINE);
  }, [countryRiskData.riskLevel]);

  const ratingKeys = useMemo(
    () => Object.keys(countryRiskData?.attributes.ratings),
    [countryRiskData?.attributes.ratings]
  );

  const getRatingFieldName = useCallback((key: string) => {
    const splitKey = camelCaseToTitleCase(key)?.split(' ') || [];
    return splitKey.length > 1 ? splitKey[0] : '';
  }, []);

  const riskLevel = useMemo(() => {
    return getRiskLevelValueByRiskLevel(countryRiskData.riskLevel, RiskReportProvider.RISKLINE);
  }, [countryRiskData.riskLevel]);

  const { classes } = useCountryRiskStatusCardStyles({ progressColor: getProgressColor });

  return (
    <Fragment>
      <Flex className={ classes.progressBarContainer } direction="column">
        <Flex direction="column" gap="sm">
          <Flex justify="space-between">
            <Text size={ 12 } transform="capitalize">{ countryRiskData.attributes?.ratings?.SecurityRiskRating }</Text>
          </Flex>
          <Progress value={ riskLevel } className={ classes.progressBar }/>
        </Flex>
      </Flex>
      <Stack spacing={ 4 }>
        { ratingKeys?.map((key, index) => (
          <Flex
            className={ classes.statusBadge }
            gap="xs"
            justify="space-between"
            align="center"
            key={ index }
          >
            <Text className={ classes.dimText }>{ key }</Text>
            <Rating
              value={ getStartRatingForRiskline(countryRiskData?.attributes.ratings[key]) }
              size="md"
              className={ classes.rating }
              emptySymbol={ <StarEmpty /> }
              fullSymbol={ <StarFilled /> }
              readOnly
            />
          </Flex>
        )) }
      </Stack>
    </Fragment>
  );
};

export default CountryRiskStatusCardRisklineComponent;
