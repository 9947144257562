import { SelectItem } from "@mantine/core";

import { CaseFeature } from "@/platform-context/control-icm-management/types/CaseTypes/caseFeatures";

export type Team = {
    _id: string,
    teamName: string,
    description: string,
    reportsTo: string,
    reporters: string[]
}

export type ReportsToOption = {
    value: string,
    label: string,
    reporters: string[]
}

export type Role = {
    _id: string,
    roleName: string,
    teamRef: string,
    allowImportOrExport: boolean,
    showSensitiveData: boolean,
    showAllEvents: boolean,
    showKeyEvents: boolean,
    showEscalatedEvents: boolean,
    crossTeamManagement: boolean,
    shareWithOtherTeams: boolean,
    featuresWithPermissionList: CaseFeature []
}

export type RoleListItem = {
    _id: string,
    roleName: string,
    teamRef: string,
    allowImportOrExport: boolean,
    showSensitiveData: boolean,
    showAllEvents: boolean,
    showKeyEvents: boolean,
    showEscalatedEvents: boolean,
    crossTeamManagement: boolean,
    shareWithOtherTeams: boolean,
    featurePermissionMap: Record<string, unknown>
    team?: Team
}

export enum RoleFeatures {
    RESTRICTED = 'RESTRICTED',
    READ_ONLY = 'READ_ONLY',
    FULL_ACCESS = 'FULL_ACCESS',
}

export type FeatureCount = {
    fullAccess: number
    readOnly: number
    restricted: number
}

export type CreateTeamParams = {
    teamName: string,
    description: string,
    reportsTo?: string,
    _id?: string
}

export type CreateRoleParams = {
    roleName: string,
    teamRef: string,
    allowImportOrExport: boolean,
    showSensitiveData: boolean,
    showAllEvents: boolean,
    showKeyEvents: boolean,
    showEscalatedEvents: boolean,
    crossTeamManagement: boolean,
    shareWithOtherTeams: boolean,
    featurePermissionMap: Record<string, unknown>
}

export type DeleteTeamParams = {
    teamTid: string,
}

export type DeleteRoleParams = {
    roleTid: string,
}

export type DeleteChecklistItemParams = {
    itemTid: string,
}

export type ChecklistItem = {
    itemName: string
    description: string
    resetWhenUserAssigned: boolean,
    _id: string
}

export type CreateChecklistItemParams = {
    itemName: string
    description: string
    roleRef: string
    resetWhenUserAssigned: boolean,
}

export interface ItemProps extends SelectItem {
    description?: string;
    tasks: PlaybookTaskItem[]
}

export type CopyItemsFromPlaybookParams = {
    playbookItemsList: CopyItemsFromPlaybookItem[]
}

export type CopyItemsFromPlaybookItem = {
    itemName: string
    description: string
    resetWhenUserAssigned: boolean
}

export type PlaybookListItem = {
    _id?: string
    tenantId: string
    name: string
    description: string
    tasks: PlaybookTaskItem[]
}

export type PlaybookTaskItem = {
    _id?: string
    tenantId: string
    updatedDate: number
    updatedBy: string
    name: string
    description: string
    playBookName: string,
    createdDate: number,
}