import { createModel } from "@rematch/core";

import { UploadedFile } from "@/common/components/FileUpload/FileUpload.component";
import { GridParams } from "@/common/types/ag-grid";
import { PaginatedResult } from "@/common/types/reference-data";
import { getBase64String, handleError } from "@/common/util/common-functions";
import { Dispatch, RootModel, RootState } from "@/core/store";
import { CaseStatusItem, PeopleStatusItem } from "@/platform-context/control-icm-management/types/CaseTypes/caseTypesConfigurations";
import { RoleListItem } from "@/platform-context/control-icm-management/types/CaseTypes/caseTypeTeamsAndRoles";
import { SeverityLevel } from "@/platform-context/control-icm-management/types/severityTypes";
import { CaseListTabs } from "@/tenant-context/control-icm-management/components/CaseManagement/Context/CaseList.Context";
import { Case, ListCaseTypeSeverityLevelLocation } from "@/tenant-context/control-icm-management/types/caseList";
import { GeoTimeZone } from "@/tenant-context/control-location-configuration/types/ManageLocations.types";

import { caseManagementApi } from "../api/caseManagement";
import {
  AddCaseForm,
  AddCaseResponse,
  CaseAttachment,
  CaseFields,
  CaseLocationDTO,
  CaseLocationParams,
  CaseOwner, CaseTab,
  CaseTag
} from "../types/caseManagement";

type CaseManagementState = {
  isCaseListLoading: boolean
  caseListCurrentAPICallDataLength: number
    caseList: PaginatedResult<Case>
    selectedCase?: CaseFields
    selectedCaseStatuses?: PaginatedResult<CaseStatusItem>
    selectedCaseSeverityLevels?: PaginatedResult<SeverityLevel>
    selectedPeopleStatuses?: PaginatedResult<PeopleStatusItem>
    selectedCaseRoles?: PaginatedResult<RoleListItem>
    caseOwners?: PaginatedResult<CaseOwner>
    isHeaderLoading: boolean
    listCaseTypeSeverityLevelLocation: ListCaseTypeSeverityLevelLocation
    caseTags?: PaginatedResult<CaseTag>
    isCaseHeaderLoading: boolean
    isICMLocation: boolean //true = Case only, false = Tenant & Case,
    isActivityPanelOpen: boolean
    caseLocations?: PaginatedResult<CaseLocationDTO>
    caseTabs: PaginatedResult<CaseTab>
  };

const initialState: CaseManagementState = {
  isCaseListLoading: false,
  caseList: {
    totalItems: 0,
    totalPages: 0,
    currentPage: 1,
    items: []
  },
  caseListCurrentAPICallDataLength: 0,
  selectedCase: undefined,
  selectedCaseRoles: undefined,
  selectedCaseSeverityLevels: undefined,
  selectedCaseStatuses: undefined,
  selectedPeopleStatuses: undefined,
  isHeaderLoading: true,
  caseOwners: undefined,
  listCaseTypeSeverityLevelLocation: {
    caseTypeList: [],
    severityLevelList: [],
    locationSet: []
  },
  caseTags: undefined,
  isCaseHeaderLoading: true,
  isICMLocation: false,
  isActivityPanelOpen: true,
  caseLocations: undefined,
  caseTabs: {
    totalItems: 0,
    totalPages: 0,
    currentPage: 1,
    items: []
  }
};

const caseManagementDataModel = {
  name: 'caseManagement',
  state: initialState as CaseManagementState,
  reducers: {
    SET_IS_CASE_LIST_LOADING: (state: CaseManagementState, isCaseListLoading: CaseManagementState['isCaseListLoading']) => {
      return ({
        ...state,
        isCaseListLoading
      });
    },
    SET_CASE_LIST: (state: CaseManagementState, caseList: CaseManagementState['caseList']) => {
      return ({
        ...state,
        caseList: { ...caseList, items: [...state.caseList.items,...caseList.items] },
        caseListCurrentAPICallDataLength: caseList?.items?.length || 0
      });
    },
    CLEAR: (state: CaseManagementState) => ({
      ...state,
      caseList: initialState.caseList,
      isCaseListLoading: initialState.isCaseListLoading

    }),
    SET_SELECTED_CASE: (state: CaseManagementState, selectedCase: CaseManagementState['selectedCase']) => ({
      ...state,
      selectedCase
    }),
    SET_SELECTED_CASE_STATUSES: (state: CaseManagementState, selectedCaseStatuses: CaseManagementState['selectedCaseStatuses']) => ({
      ...state,
      selectedCaseStatuses
    }),
    SET_SELECTED_PEOPLE_STATUSES: (state: CaseManagementState, selectedPeopleStatuses: CaseManagementState['selectedPeopleStatuses']) => ({
      ...state,
      selectedPeopleStatuses
    }),
    SET_SELECTED_CASE_SEVERITY_LEVELS: (state: CaseManagementState, selectedCaseSeverityLevels: CaseManagementState['selectedCaseSeverityLevels']) => ({
      ...state,
      selectedCaseSeverityLevels
    }),
    SET_SELECTED_CASE_ROLES: (state: CaseManagementState, selectedCaseRoles: CaseManagementState['selectedCaseRoles']) => ({
      ...state,
      selectedCaseRoles
    }),
    TOGGLE_LOADING_HEADER: (state: CaseManagementState, isHeaderLoading: CaseManagementState['isHeaderLoading']) => ({
      ...state,
      isHeaderLoading
    }),
    SET_LIST_CASE_TYPE_SEVERITY_LEVEL_LOCATION: (state: CaseManagementState, listCaseTypeSeverityLevelLocation: CaseManagementState['listCaseTypeSeverityLevelLocation']) => ({
      ...state,
      listCaseTypeSeverityLevelLocation
    }),
    SET_CASE_OWNERS_LIST: (state: CaseManagementState, caseOwners: CaseManagementState['caseOwners']) => ({
      ...state,
      caseOwners
    }),
    SET_CASE_TAGS: (state: CaseManagementState, caseTags: CaseManagementState['caseTags']) => ({
      ...state,
      caseTags
    }),
    SET_CASE_HEADER_LOAD: (state: CaseManagementState, isCaseHeaderLoading: CaseManagementState['isCaseHeaderLoading']) => ({
      ...state,
      isCaseHeaderLoading
    }),
    SET_ICM_LOCATION: (state: CaseManagementState, isICMLocation: CaseManagementState['isICMLocation']) => ({
      ...state,
      isICMLocation
    }),
    SET_ACTIVITY_PANEL_VISIBILITY: (state: CaseManagementState, isActivityPanelOpen: CaseManagementState['isActivityPanelOpen']) => ({
      ...state,
      isActivityPanelOpen
    }),
    SET_CASE_LOCATIONS: (state: CaseManagementState, caseLocations: CaseManagementState['caseLocations']) => ({
      ...state,
      caseLocations
    }),
    SET_CASE_TABS: (state: CaseManagementState, caseTabs: CaseManagementState['caseTabs']) => ({
      ...state,
      caseTabs
    })
  },
  effects: (dispatch: Dispatch) => ({
    async createCase(params: AddCaseForm): Promise<AddCaseResponse | false> {
      return await caseManagementApi.createCase(params).catch(handleError);
    },
    async updateCase(params: CaseFields, state: RootState): Promise<boolean> {

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const promises: Promise<any>[] = [];

      promises.push(caseManagementApi.updateCase(params));

      //attachment upload
      if (params.attachments?.length > 0) {
        promises.push(caseManagementApi.uploadAttachmentsForCase(
          state.caseManagement.selectedCase?.tid || "",
            params.attachments as unknown as CaseAttachment[]
        ));
      }

      return !!await Promise.all(promises).catch(handleError);
    },
    async fetchCaseReferenceData(_: void, state: RootState): Promise<void> {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const promises: Promise<any>[] = [];
      const id = state.caseManagement.selectedCase?.tid;

      if (!id) {
        return;
      }

      promises.push(caseManagementApi.getCaseStatusesByCase(id));
      promises.push(caseManagementApi.getRolesByCase(id));
      promises.push(caseManagementApi.getSeverityLevelsByCase(id));
      promises.push(caseManagementApi.getCaseOwners());
      promises.push(caseManagementApi.getCaseTags());

      const results = await Promise.all(promises).catch(handleError)
        .finally(() => dispatch.caseManagement.TOGGLE_LOADING_HEADER(false));

      if (results) {
        const [caseStatuses, roles, severityLevels, caseOwners, caseTags] = results;

        dispatch.caseManagement.SET_SELECTED_CASE_STATUSES(caseStatuses);
        dispatch.caseManagement.SET_SELECTED_CASE_ROLES(roles);
        dispatch.caseManagement.SET_SELECTED_CASE_SEVERITY_LEVELS(severityLevels);
        dispatch.caseManagement.SET_CASE_OWNERS_LIST(caseOwners);
        dispatch.caseManagement.SET_CASE_TAGS(caseTags);
      }
    },
    async fetchCaseDetailsHeader(id: string): Promise<void> {
      const res = await caseManagementApi.getCaseById(id).catch(handleError);
      if (res) {
        dispatch.caseManagement.SET_SELECTED_CASE(res);
        dispatch.caseManagement.SET_CASE_HEADER_LOAD(false);

        //dispatch.caseManagement.fetchCaseReferenceData({ caseId: res.tid });
      }
    },
    async getCaseListData(payload: {
      gridParams: GridParams,
    }, _state: RootState): Promise<PaginatedResult<Case>> {

      const query = payload.gridParams.searchQuery;

      const { trainingMode, caseStatus, caseType, severity, location } = payload.gridParams.additionalParams;

      const caseTypeQuery = `caseTypeRef LIKE 'ref:${caseType}'`;
      const severityQuery = `severityLevelRef LIKE 'ref:${severity}'`;
      const isFilterEnabled = !!caseType || !!severity || !!location || !!payload.gridParams.searchQuery;

      // eslint-disable-next-line no-param-reassign
      payload.gridParams.searchQuery = `${query && `(caseNumber LIKE %27%25${query}%25%27 OR summery LIKE %27%25${query}%25%27) AND`} ${caseType && (encodeURIComponent(caseTypeQuery) + 'AND')} ${severity && (encodeURIComponent(severityQuery) + 'AND')} trainingMode LIKE %27%25${trainingMode}%25%27`;

      const caseListData = await caseManagementApi.getCaseListData(
        payload.gridParams.page,
        payload.gridParams.size,
        caseStatus,
        isFilterEnabled,
        payload.gridParams.sort,
        payload.gridParams.searchQuery,
        location
      );

      dispatch.caseManagement.SET_CASE_LIST(caseListData);

      dispatch.caseManagement.SET_IS_CASE_LIST_LOADING(false);

      return caseListData;
    },
    async postUserPinnedCase(payload: {
      caseId: string,
      isPinned: boolean
    }, state: RootState): Promise<boolean> {

      const tenantUserId = state.userProfile?.tenantUserId;

      if (!payload.caseId || !tenantUserId) {
        return false;
      }

      const { caseId, isPinned } = payload;

      dispatch.caseManagement.SET_IS_CASE_LIST_LOADING(true);

      const pinned = await caseManagementApi.postUserPinnedCase(caseId, isPinned, tenantUserId).catch(handleError);

      dispatch.caseManagement.SET_IS_CASE_LIST_LOADING(false);

      return !!pinned;

    },
    async getListCaseTypeSeverityLevelLocation(
      caseStatus?: CaseListTabs
    ): Promise<ListCaseTypeSeverityLevelLocation | boolean> {
      if (!caseStatus) {
        return false;
      }

      const listCaseTypeSeverityLevelLocation = await caseManagementApi.getListCaseTypeSeverityLevelLocation(
        caseStatus
      ).catch(handleError);

      if (listCaseTypeSeverityLevelLocation) {
        dispatch.caseManagement.SET_LIST_CASE_TYPE_SEVERITY_LEVEL_LOCATION(listCaseTypeSeverityLevelLocation);
      }

      return false;
    },
    async uploadAttachmentsForCase(attachments: CaseAttachment[], state: RootState): Promise<boolean> {
      return !!await caseManagementApi.uploadAttachmentsForCase(state.caseManagement.selectedCase?.tid || "", attachments).catch(handleError);
    },
    async deleteAttachment(attachmentId: string, state: RootState): Promise<boolean> {
      return !!await caseManagementApi.deleteAttachment(state.caseManagement.selectedCase?.tid || "", attachmentId).catch(handleError);
    },
    async listAttachmentsForCase(_: void, state: RootState): Promise<UploadedFile[] | undefined> {
      const caseId = state.caseManagement.selectedCase?.tid;

      if (!caseId) {
        return;
      }

      const data = await caseManagementApi.listAttachmentsForCase(caseId).catch(handleError);

      if (data) {
        return data.items.map(item => ({
          name: item.fileName,
          progress: 100,
          size: item.fileSize.toFixed(2) + "mb",
          status: "uploaded",
          id: item.id
        }));
      }
    },
    async createICMLocation(
      type: 'primary' | 'associated',
      state: RootState
    ): Promise<CaseLocationDTO | false> {
      // eslint-disable-next-line fp/no-let

      const {
        manageLocations: { enteredLocationDetails, sitePicture }
      } = state;
      const enteredLocation = { ...enteredLocationDetails };
      const geoTimeZone = (enteredLocation.timezones as GeoTimeZone) || undefined;
      const addRequestObj: CaseLocationParams = {
        criticalLocation: enteredLocation.isCriticalLocation as boolean,
        description: enteredLocation.description,
        lat: enteredLocation.lat,
        lon: enteredLocation.lon,
        locationType: enteredLocation.locationType,
        alias: enteredLocation.alias,
        code: enteredLocation.code,
        name: enteredLocation.id,
        timeZone: {
          label: geoTimeZone?.label,
          offset: geoTimeZone?.offset,
          timeZone: geoTimeZone?.timeZone,
          value: geoTimeZone?.value,
          country: geoTimeZone?.country
        },
        address: {
          city: enteredLocation.city,
          country: enteredLocation.isoCountryCode,
          firstLine: enteredLocation.address01,
          secondLine: enteredLocation.address02,
          postalCode: enteredLocation.postalCode,
          region: enteredLocation.countyRegion
        },
        isICMLocation: !state.caseManagement.isICMLocation,
        locationImage: sitePicture ? await getBase64String(sitePicture) : ''
      };

      return await caseManagementApi.createICMLocation(
        state.caseManagement.selectedCase?.tid || "",
        addRequestObj
      ).catch(handleError);
    },
    async getPeopleStatusesByCase(_: void, state: RootState): Promise<void> {
      const caseId = state.caseManagement.selectedCase?.tid;
      if(!caseId){
        return;
      }

      const res = await caseManagementApi.getPeopleStatusesByCase(caseId).catch(handleError);
      if (res) {
        dispatch.caseManagement.SET_SELECTED_PEOPLE_STATUSES(res);
      }
    },
    async fetchCaseLocations(
      payload: { caseId?: string, page?: number, size?: number },
      _state: RootState
    ): Promise<PaginatedResult<CaseLocationDTO> | false> {
      const caseId = payload.caseId || _state.caseManagement.selectedCase?.tid;
      const page = payload?.page || 0;
      const size = payload?.size || 9999;
      
      if (!caseId) {
        return false;
      }
      
      const locationsData = await caseManagementApi.listICMLocations(caseId, page, size)
        .catch(handleError);
      
      if (locationsData) {
        dispatch.caseManagement.SET_CASE_LOCATIONS(locationsData);
        return locationsData;
      }
      
      return false;
    },
    async getCaseTabs(caseId: string, _state: RootState): Promise<void> {

      if(!caseId){
        return;
      }

      const res = await caseManagementApi.getCaseTabs(caseId).catch(handleError);
      if (res) {
        dispatch.caseManagement.SET_CASE_TABS(res);
      }
    }
  })
};

export const caseManagement = createModel<RootModel>()(caseManagementDataModel);
