import { callApi } from "@/common/util/api";
import { UserGeoPermissionResponse } from "@/tenant-context/core/types/geo-permission";

export const getUserGeoPermissionBoundaries = (userId: string) => callApi<UserGeoPermissionResponse>(
  `managed/platform-service/v1/users/${userId}`,
  {
    method: 'get',
    headers: {
      'Content-Type': 'application/json;domain-model=ListUserGeofence'
    },
    showApiError: false
  }
);
