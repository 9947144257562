import { createModel } from "@rematch/core";
import EventSource from "eventsource";

import { Dispatch, RootModel, RootState } from "@/core/store";
import { filterOutGeoPermission } from "@/tenant-context/common/util/geoPermission/geoPermission";
import { mergeFeatureCollections } from "@/tenant-context/common/util/location-event/merge-feature-collections";
import { mapImpactEngineRiskAlertToRiskAlertEvent } from "@/tenant-context/common/util/risk";
import { Visualizable, withVisualisableTraits } from "@/tenant-context/common/util/with-visualisable-traits";
import { parseGeoJSON } from "@/tenant-context/common/workers/geo-json-mapper-worker/geo-json-mapper-worker.external";
import { createImpactEngineRiskAlertStream } from "@/tenant-context/visualisation-risk-alerts/api/risk-alerts";
import {
  RiskAlertEvent,
  RiskAlertFeatureCollection
} from "@/tenant-context/visualisation-risk-alerts/types/risk-alerts";
import { RiskConnectorExternalData } from "@/tenant-context/visualisation-risk-alerts/types/risk-alerts-generic";

// eslint-disable-next-line fp/no-let
let unprocessedEvents: Array<RiskAlertEvent<RiskConnectorExternalData>> = [];

export type RiskAlertState = Visualizable<{
  currentRiskAlert?:RiskAlertEvent,
  showOnlyImpactedRiskEvents: boolean
}, RiskAlertFeatureCollection>;

export type NonSerializableRiskAlertState = {
  riskAlertStream?: EventSource
};

const initialState: RiskAlertState = {
  geoData: {
    features: [],
    type: 'FeatureCollection'
  },
  currentRiskAlert : undefined,
  showOnlyImpactedRiskEvents: true
};

const nonSerializableState: NonSerializableRiskAlertState = {};

export const RISK_ALERT_UPDATE_INTERVAL = 1000;
const PROCESSING_LIMIT = 1000;

// TODO type this, impossible to read
const riskAlertModel = withVisualisableTraits()({
  name: 'riskAlerts',
  state: initialState,
  reducers: {
    SET_SHOW_ONLY_IMPACTED_RISK_EVENTS(state: RiskAlertState, showOnlyImpactedRiskEvents: boolean): RiskAlertState {
      return {
        ...state,
        showOnlyImpactedRiskEvents
      };
    },

    SET_CURRENT_RISK_ALERT(state:RiskAlertState, clickedAlert:RiskAlertEvent){
      return {
        ...state,
        currentRiskAlert:clickedAlert
      };
    },

    CLEAR_CURRENT_RISK_ALERT(state:RiskAlertState){
      return {
        ...state,
        currentRiskAlert:undefined
      };
    }

  },
  effects: (dispatch: Dispatch) => ({
    async subscribeToRiskAlerts(_: void, state: RootState): Promise<void> {
      const {
        commonData:{
          riskProviders
        },
        riskTimeline: {
          filterDataRange: [ startDate, endDate ]
        }
      } = state;

      // TODO this slows the waterfall down
      // Until riskProviders (that the tenant is subscribed to) are fetched from the server
      // This risk alert subscription will not fire, it will wait for that to be fetched
      if (!riskProviders || riskProviders.length === 0) {
        return;
      }

      const riskProvidersList = state.commonData?.riskProviders
        .map((provider) => provider.providerName)
        .join(',');

      const query = generateRiskQuery(riskProvidersList, startDate, endDate);

      const stream = createImpactEngineRiskAlertStream(
        query,
        state.commonData.tenantId,
        (ev) => {

          const riskAlertEvents = mapImpactEngineRiskAlertToRiskAlertEvent(ev);

          if (!riskAlertEvents.length) {
            return;
          }

          unprocessedEvents.push(...riskAlertEvents);
        }
      );

      setInterval(() => {
        if (unprocessedEvents.length === 0) {
          return;
        }

        const endIndex = Math.min(unprocessedEvents.length, PROCESSING_LIMIT);
        const chunkToProcess = unprocessedEvents.slice(0, endIndex);
        unprocessedEvents = unprocessedEvents.slice(endIndex);

        dispatch.riskAlerts.processEnqueuedRiskAlertEvents(chunkToProcess);
      }, RISK_ALERT_UPDATE_INTERVAL);

      nonSerializableState.riskAlertStream = stream;
    },

    async processEnqueuedRiskAlertEvents(eventsToProcess: typeof unprocessedEvents, state: RootState): Promise<void> {
      const {
        riskAlerts: {
          geoData
        },
        commonData: {
          userGeoPermission
        }
      } = state;

      if (eventsToProcess.length === 0) {
        return;
      }

      const allowedEvents = eventsToProcess.filter((event) =>
        filterOutGeoPermission({
          lat: event.json.meta.geojson.geometry.coordinates[1],
          lon: event.json.meta.geojson.geometry.coordinates[0]
        }, userGeoPermission));

      const { data: geoJSONData } = await parseGeoJSON({
        data: allowedEvents,
        params: {
          Point: [
            'json.meta.geojson.geometry.coordinates.1',
            'json.meta.geojson.geometry.coordinates.0'
          ]
        }
      });

      const incomingRiskAlertFeatureCollection = geoJSONData as RiskAlertFeatureCollection;

      const updatedRiskAlertFeatureCollection: RiskAlertFeatureCollection = await mergeFeatureCollections(
        geoData,
        incomingRiskAlertFeatureCollection,
        'tid'
      );

      // Update risk alert feature collection
      dispatch.riskAlerts.SET_GEO_DATA(updatedRiskAlertFeatureCollection);
    },

    async enableRiskAlertDrawer(clickedEvent:RiskAlertEvent):Promise<void>{
      dispatch.riskAlerts.SET_CURRENT_RISK_ALERT(clickedEvent);

      dispatch.drawer.openRightDrawer('risk-events');
    },

    async disableRiskAlertDrawer() {
      dispatch.drawer.closeDrawer();
    },

    clearCurrentRiskAlerts(_: void, _state: RootState): void {
      dispatch.riskAlerts.SET_GEO_DATA({
        features: [],
        type: 'FeatureCollection'
      });
    }
  })
});

export const riskAlerts = createModel<RootModel>()(
  riskAlertModel
);

const generateRiskQuery = (riskProvider: string, startDate: Date | null, endDate: Date | null) => {
  if (!startDate || !endDate) {
    return `request.source='${riskProvider}'+AND+impactStatus='OPEN'`;
  }

  return `request.source='${riskProvider}' AND request.start_date>${startDate.getTime()} AND request.end_date<${endDate.getTime()}`;
};
