import { formatNumberToDecimalPoints } from '@/tenant-context/common/util/data-standardize';
import { ArcCaseEvent } from '@/tenant-context/control-action-response-center/types/ARC.types';
import { ImpactedArea, ImpactPosition } from '@/tenant-context/visualisation-risk-details/types/risk-impact';

export const calculateOffset = (
  impactedArea: ImpactedArea,
  currentMapZoom: number,
  bigMapZoomLevel: number,
  acrCaseEvent: ArcCaseEvent | undefined
) => {
  const isArcCaseEvent = !!acrCaseEvent;
  const isCircle = impactedArea?.calculation.calculationType === 'CIRCLE';
  const impactRadius = formatNumberToDecimalPoints(impactedArea.calculation.appliedTo, 3);
  const impactPosition = acrCaseEvent ? acrCaseEvent.geometry : impactedArea.calculation.targetPoint;
  if (isCircle) {
    return calculateOffsetForCircles(impactPosition, impactRadius, isArcCaseEvent, currentMapZoom, bigMapZoomLevel);
  } else {
    return calculateOffsetForPolygons(impactedArea, acrCaseEvent, currentMapZoom, bigMapZoomLevel);
  }
};

const calculateOffsetForCircles = (
  impactPosition: ImpactPosition,
  impactRadius: number,
  isArcCaseEvent: boolean,
  currentMapZoom: number,
  bigMapZoomLevel: number
) => {
  if(!impactRadius || !impactPosition){
    return;
  }

  // Pop up offset should be dynamic and it should always just above the drawn impact cicle
  // Mapping meters to pixels at given zoom level

  // 78271.484 is m/px ratio through equator (Latitude 0) when zool level is 0 (reference https://docs.mapbox.com/help/glossary/zoom-level/)
  // appliedTo is multiplied by 1000 to get the distance in meters
  // 1 / cos(phi), uses to obtain the correct meter to pixel ratio for any latitude

  const zoomLevel = isArcCaseEvent ? currentMapZoom : bigMapZoomLevel;
  // eslint-disable-next-line no-magic-numbers
  return (impactRadius * 1000) /
    // eslint-disable-next-line no-magic-numbers
    (78271.484 / 2 ** zoomLevel) / Math.cos(impactPosition.lat * Math.PI / 180);
};

export const calculateOffsetForPolygons = (
  impactedArea: ImpactedArea,
  arcCaseEvent: ArcCaseEvent | undefined,
  currentMapZoom: number,
  bigMapZoomLevel: number
) => {
  const { targetPoint, geoFence } = impactedArea.calculation;
  if (!targetPoint) {
    return;
  }
  const impactPointLat = arcCaseEvent ? arcCaseEvent.geometry.lat : impactedArea.calculation.targetPoint.lat;
  const mostFarthestPointFromTargetPoint =
    geoFence?.[0]?.reduce((point: [number, number], nextPoint: [number, number]) => {
      return nextPoint[1] > point[1] ? nextPoint : point;
    }, [targetPoint.lon, targetPoint.lat]);
  // Calculate distance between points in degrees (lat/long coordinates)
  const distanceInMeters = calculateLatitudeDistanceBetweenPointsInMeters(
    [mostFarthestPointFromTargetPoint[1], mostFarthestPointFromTargetPoint[0]],
    [targetPoint.lat, targetPoint.lon]
  );
  const zoomLevel = arcCaseEvent ? currentMapZoom : bigMapZoomLevel;
  return (distanceInMeters + 10)/(78271.484 / 2 ** zoomLevel) / Math.cos(impactPointLat * Math.PI / 180);
};


const calculateLatitudeDistanceBetweenPointsInMeters = (point1: [number, number], point2: [number, number]) => {
  const [lat1, _lon1] = point1;
  const [lat2, _lon2] = point2;

  // Convert latitude to radians
  const toRadians = (degrees: number): number => degrees * (Math.PI / 180);

  const R = 6371e3; // Radius of the Earth in meters

  // Calculate only vertical (latitude) distance
  const latDiff = toRadians(lat2 - lat1);

  // Distance in meters using spherical law of cosines for latitude only
  return R * Math.abs(latDiff);
};
