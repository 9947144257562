import { createStyles } from "@mantine/core";
import { MantineTheme } from "@mantine/styles/lib/theme";

import { CSS_KEY } from "@/core/styles/mantine/constants";
import { genericColorPalette } from "@/core/styles/mantine/palettes";

type Props = {
  isTaskCardCompactView?: boolean
}
export const useCaseManagementStyles = createStyles((theme: MantineTheme, props?: Props) => ({
  statusBadge:{
    borderRadius: 6,
    padding: '4px 8px',
    overflow: "hidden",
    minWidth: 100,
    textAlign: "center"
  },
  generatePeopleFormInner:{
    maxHeight: "calc(100vh - 152px)",
    overflowY: "auto",
    padding:"0 12px 0 0"
  },
  peopleFormInner:{
    minHeight: "calc(100vh - 224px)",
    maxHeight: "calc(100vh - 224px)",
    overflowY: "auto",
    padding:"0 12px 0 0"
  },
  menuDropdown: {
    backgroundColor: theme.colors.neutral[7],
    border: `1px solid ${theme.colors.neutral[6]}`,
    minWidth: '200px'
  },
  menuControlItem: {
  },
  legend:{
    width: 20,
    height: 20,
    borderRadius: 6
  },
  peopleBlock:{
    padding: 16,
    flex: 1,
    borderRadius: 12,
    backgroundColor: theme.colors.neutral[7]
  },
  closeBtn:{
    position: "absolute",
    top: 0,
    right: 0,
    zIndex: 5
  },
  btnLink:{
    all: 'unset',
    transition: '0.2s all ease',
    cursor:"pointer",
    border: '2px solid transparent',
    borderRadius: 8,
    '& > div':{
      transition: '0.2s all ease'
    },
    '&:hover > div':{
      background: genericColorPalette.dark[12]
    }
  },
  btnLinkSelected:{
    border: `2px solid ${theme.colors.teal[5]}`
  },
  btnDotted: {
    all: "unset",
    padding: 12,
    borderRadius: 8,
    border: `3px dashed ${theme.colors.neutral[6]}`,
    textAlign: 'center',
    fontSize: theme.fontSizes.md,
    color:theme.colors.neutral[6],
    fontWeight: 600,
    cursor: "pointer",
    transition: "0.1s all ease",
    '&:not(:disabled):hover':{
      borderColor: theme.colors.neutral[0],
      color: theme.colors.neutral[0]
    },
    '&:disabled':{
      cursor: "not-allowed",
      color: theme.other.semantic.button.default.disabled,
      '& path':{
        fill: theme.other.semantic.button.default.disabled
      }
    }
  },
  popupContainer: {
    color: theme.colors.neutral[8],
    padding: "5px 8px"
  },
  chip:{
    '& label':{
      display: "inline-flex",
      height: 38,
      paddingLeft: 14,
      borderColor: `${theme.colors.neutral[6]} !important`,
      '&:not(:hover)':{
        backgroundColor: `${theme.colors.neutral[8]} !important`
      },
      '& > button':{
        marginRight: 4
      }
    }
  },
  taskList:{
    backgroundColor: theme.colors.neutral[8],
    borderRadius: 8,
    padding: '0 16px 8px 16px'
  },
  boxWrap: {
    backgroundColor: theme.colors.neutral[8],
    padding: 24,
    borderRadius: 8,
    position: "relative"
  },
  taskForm: {
    backgroundColor: theme.colors.neutral[8],
    borderRadius: 8
  },
  rightDrawer:{
    right: 0
  },
  drawer: {
    right: '0px !important',
    maxHeight: '100vh !important',
    height: '100vh !important',
    [`& .${CSS_KEY}-Drawer-header`]:{
      color: theme.other.semantic.text.primary,
      fontWeight: theme.other.fw.semibold,
      fontSize: theme.headings.sizes.h6.fontSize,
      lineHeight: theme.other.lh.s
    }
  },
  taskFilterWrap:{
    '& > div':{
      flex: 1,
      marginBottom: 0
    }
  },
  checkBlock:{
    padding: 16,
    flex: 1,
    borderRadius: 12,
    backgroundColor: theme.colors.neutral[7],
    '& > div':{
      alignItems: "flex-start",
      width: "100%"
    }
  },
  dropdown:{
    borderColor: theme.colors.neutral[6],
    '& > div > button:not(:last-child)':{
      borderBottom: `1px solid ${theme.colors.neutral[6]}`
    }
  },
  toggleWrap:{
    backgroundColor: theme.colors.neutral[7],
    minHeight: 32,
    borderRadius: 16,
    padding: '0 8px',
    display: "flex",
    alignItems: "center"
  },
  actionIcon:{
    borderRadius: 8,
    borderColor: theme.colors.neutral[6],
    '& svg':{
      width: 24,
      height: 24
    }
  },
  nowWrap:{
    whiteSpace: "nowrap"
  },
  expandBtn:{
    position: "absolute",
    right: "1rem",
    top: "1rem",
    transform: "rotate(180deg)"
  },
  uploadedFile:{
    backgroundColor: genericColorPalette.dark[12],
    borderRadius: 8,
    padding: '10px 16px',
    marginBottom: 8
  },
  dataWrapper: {
    backgroundColor: theme.colors.royalBlue[3],
    padding: 12,
    borderRadius: 8
  },
  addLocationHeader:{
    padding: '16px 32px',
    backgroundColor: theme.colors.royalBlue[3]
  },
  addLocationFooter:{
    padding: '16px 32px'
  },
  addLocationModal:{
    [`& .${CSS_KEY}-Modal-modal`]:{
      padding: '0 !important',
      [`& .${CSS_KEY}-Modal-header`]:{
        margin: 0,
        [`& .${CSS_KEY}-Modal-close`]:{
          zIndex: 5
        }
      }
    }
  },
  btnTextCenter:{
    textAlign: "center",
    '& > div':{
      justifyContent: "center !important"
    }
  },
  locationLeft:{
    maxHeight: "calc(100vh - 255px)",
    minHeight: "calc(100vh - 255px)",
    overflowY: "auto",
    padding: '0 8px 8px'
  },
  overviewTabInner:{
    maxHeight: "calc(100vh - 334px)",
    minHeight: "calc(100vh - 334px)",
    overflowY: "auto"
  },
  descriptionHeader:{
    padding: '8px 0',
    width: "100%"
  },
  summaryBlock:{
    borderRadius: 8,
    backgroundColor: theme.colors.info[2],
    padding: 24
  },
  summaryCount:{
    fontWeight: 700,
    fontSize: theme.fontSizes.xl
  },
  summaryHeader:{
    fontWeight: 400,
    fontSize: theme.fontSizes.md
  },
  overviewTabsWrapper:{
    padding: '12px 24px 24px 24px',
    backgroundColor: theme.colors.neutral[8],
    borderRadius: 8,
    overflowY: "auto"
  },
  btnLg:{
    '& svg':{
      width: 24,
      height: 24
    }
  },
  taskHeader:{
    fontSize: 14,
    fontWeight: 700,
    color: theme.colors.neutral[5]
  },
  taskBlock:{
    backgroundColor: theme.colors.royalBlue[3],
    borderRadius: 8,
    padding: 16,
    margin:  props?.isTaskCardCompactView ? '4px 0px' : '-16px',
    cursor: 'pointer'
  },
  collapseBody:{
    padding: '12px 0'
  },
  sectionBody:{
    padding: 12
  },
  activityBody:{
    maxHeight: "calc(100vh - 72px)",
    overflowY: "auto"
  },
  w100:{
    width: "100%"
  },
  sectionHeader:{
    width: "100%",
    padding: "8px 12px"
  },
  relative:{
    position: "relative"
  },
  caseTabs:{
    '& button[data-active] path':{
      fill: "white"
    }
  },
  overviewTabs:{
    '& button[data-active]': {
      "&::after":{
        bottom: "-2px",
        background: theme.colors.blue[6]
      }
    },
    '& > button':{
      flex: 1
    }
  },
  unauthorizedTab: {
    cursor: 'not-allowed'
  },
  descriptionLabel:{
    marginTop: 8,
    fontSize: 14,
    color: genericColorPalette.neutral[6],
    fontWeight: 500,
    display: "block"
  },
  inputIcon:{
    display: "flex",
    alignItems: "center",
    marginRight: "16px"
  },
  asterisk:{
    '&:after':{
      color: genericColorPalette.blue[4],
      content: '"*"',
      display: "inline-block",
      marginLeft: 4
    },
    position: "relative"
  },
  bgContainer: {
    backgroundColor: theme.colors.neutral[9]
  },
  bgPad:{
    padding: 24
  },
  container: {
    backgroundColor: theme.colors.neutral[9],
    padding: 24
  },
  headerText:{
    fontSize: theme.fontSizes.md
  },
  mainText:{
    fontSize: theme.fontSizes.sm
  },
  subText:{
    fontSize: theme.fontSizes.sm,
    color: theme.colors.neutral[5]
  },
  linkText:{
    fontSize: theme.fontSizes.sm,
    color: theme.colors.royalBlue[2]
  },
  underlinedText:{
    fontSize: theme.fontSizes.sm,
    color: theme.colors.teal[5],
    textDecoration: "underline"
  },
  miniText:{
    fontSize: theme.fontSizes.xs,
    color: theme.colors.neutral[5]
  },
  formLabel:{
    fontSize: theme.fontSizes.md,
    color: theme.colors.neutral[5]
  },
  formWrap:{
    padding: 32,
    backgroundColor: theme.colors.neutral[8],
    borderRadius: 8
  },
  h100:{
    height: "100%"
  },
  formBody:{
    overflowY: "auto",
    overflowX: "hidden",
    maxHeight: "calc(100vh - 266px)"
  },
  link:{
    all: "unset",
    textDecoration: "underline",
    cursor: "pointer",
    color: theme.colors.blue[4],
    transition: "0.1s all ease",
    width: "max-content",
    "&:hover":{
      color: theme.colors.blue[5]
    },
    marginTop: 8
  },
  linkVar:{
    all: "unset",
    textDecoration: "underline",
    cursor: "pointer",
    color: theme.colors.blue[4],
    transition: "0.1s all ease",
    "&:hover":{
      color: theme.colors.blue[5]
    },
    fontSize: theme.fontSizes.sm,
    '&:disabled':{
      cursor: "not-allowed",
      color: theme.other.semantic.button.default.disabled,
      '& path':{
        fill: theme.other.semantic.button.default.disabled
      }
    }
  },
  linkTextColor:{
    color: theme.colors.blue[4],
    transition: "0.1s all ease",
    "&:hover":{
      color: theme.colors.blue[5]
    }
  },
  activityHeader:{
    padding: 12,
    backgroundColor: theme.colors.royalBlue[3]
  },
  activityWrap:{
    backgroundColor: theme.colors.neutral[8],
    // overflowX: "hidden",
    transition: "0.3s all ease"
  },
  clickableArea:{
    cursor: "pointer",
    border: 0,
    background: "transparent"
  },
  blockContainer: {
    marginTop: '8px'
  },
  userImageContainer: {
    width: '24px',
    height: '24px',
    borderRadius: '100px',
    margin: '0px'
  },
  userImage: {
    width: '24px',
    height: '24px',
    borderRadius: '100px'
  },
  avatar: {
    marginRight: '8px',
    [`& .${CSS_KEY}-Avatar-placeholder`]:{
      justifyContent: 'center !important'
    }
  },
  drawerContentContainer: {
    width: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    height: 'calc(100vh - 140px)'
  },
  drawerWithTabsContentContainer: {
    width: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    height: 'calc(100vh - 230px)'
  },
  compactAssigneeName: {
    marginLeft: '8px !important'
  }
}));