import { createStyles, MantineTheme } from "@mantine/core";

export const useRankingSettingsStyles = createStyles((theme: MantineTheme) => ({
  root: {
    marginTop: theme.spacing.md
  },
  numberInput: {
    width: "48px",
    marginBottom: 0,
    input: {
      paddingLeft: "14px",
      paddingRight: "10px"
    }
  },
  actionIcon: {
    svg: {
      width: "unset",
      height: "unset"
    }
  }
}));
