import { Fragment } from 'react';

import { ReactComponent as DataMinr } from "@/common/icons/dataminr.svg";
import { ReactComponent as MaxSecurity } from "@/common/icons/RiskProviders/max-security.svg";
import { getTimeDurationFromCurrentDate } from '@/common/util/format/date';
import theme from '@/core/styles/mantine/theme';
import { dateTimeStringToTimestamp } from '@/tenant-context/common/util/data-standardize';
import {
  DataminrRelatedEvent,
  GeneralizedRelatedEvent, MaxSecurityRelatedEvent, MaxSecurityRiskLevel,
  RelatedEvent
} from '@/tenant-context/control-risk-config/types/risk';

const getRiskLevelColor = (value?: 'alert' | 'urgent' | MaxSecurityRiskLevel) => {
  const valueIndexMap = {
    'alert': 2,
    'urgent': 3,
    'Low': 0,
    'Moderate': 1,
    'Medium': 2,
    'High': 3,
    'Extreme': 4
  };

  return  !value ? "" : theme.colors?.risk?.[valueIndexMap[value] || 4] || '#AE3B3B';
};

const getTimeElapsed = (eventTime?: number) => {
  if (!eventTime) {
    return '';
  }

  try {
    const eventTimeStamp = Number(eventTime);
    return `${getTimeDurationFromCurrentDate(new Date(eventTimeStamp))} AGO`;
  } catch (e) {
    return '';
  }
};

const getSourceFromCaption = (caption?: string) => {
  if (!caption) {
    return;
  }

  const sourceMatch = caption.match(/via\s(.+)$/);
  return sourceMatch ? sourceMatch[1].replace('.', '') : undefined;
};

export const addTargetBlankToHTMLLinks = (html: string) => {
  return html.replace(/<a\s+href="([^"]+)"/g, '<a target="_blank" href="$1"');
};

export function generalizeRelatedEvent(event: RelatedEvent, provider: 'dataminr' | 'max-security'): GeneralizedRelatedEvent {
  if (!event) {
    return {} as GeneralizedRelatedEvent;
  }

  if (provider === 'dataminr') {
    const dataminrRelatedEvent = event as DataminrRelatedEvent;

    return {
      id: dataminrRelatedEvent.alertId,
      eventTime: dataminrRelatedEvent.eventTime,
      riskEventAlertType: dataminrRelatedEvent.alertType.name,
      eventLocationName: dataminrRelatedEvent.eventLocation.name,
      caption: dataminrRelatedEvent.caption,
      eventDetails: dataminrRelatedEvent.caption,
      mediaSource: getSourceFromCaption(dataminrRelatedEvent.caption),
      timeElapsed: getTimeElapsed(dataminrRelatedEvent.eventTime),
      riskLevelColor: getRiskLevelColor(dataminrRelatedEvent.alertType.id),
      providerName: 'dataminr',
      providerIcon: <DataMinr/>
    };
  } else if (provider === 'max-security') {
    const maxSecurityRelatedEvent = event as MaxSecurityRelatedEvent;

    return {
      id: maxSecurityRelatedEvent.id,
      eventTime: dateTimeStringToTimestamp(maxSecurityRelatedEvent.publish_date),
      riskEventAlertType: maxSecurityRelatedEvent.incident_risk_level,
      eventLocationName: maxSecurityRelatedEvent.primary_region,
      caption: maxSecurityRelatedEvent.title,
      eventDetails: <div style={ { display: 'grid', gap: 16 } }>
        { maxSecurityRelatedEvent.sections.map((section, index) => (
          <Fragment key={ index }>
            <h4>{ section.section_title }</h4>
            <div style={ { display: 'grid', gap: 16 } } dangerouslySetInnerHTML={ { __html: addTargetBlankToHTMLLinks(section.section_content) } }/>
          </Fragment>
        )) }
      </div>,
      mediaSource: undefined, // todo: find out from BE
      timeElapsed: getTimeElapsed(maxSecurityRelatedEvent.meta.createdTime),
      riskLevelColor: getRiskLevelColor(maxSecurityRelatedEvent.incident_risk_level),
      providerName: 'max-security',
      providerIcon: <MaxSecurity/>
    };
  }

  return {} as GeneralizedRelatedEvent;
}

export function generalizeRelatedEvents(events: RelatedEvent[], provider: 'dataminr' | 'max-security'): GeneralizedRelatedEvent[] {
  if (!events) {
    return [];
  }

  return events.map(event => generalizeRelatedEvent(event, provider));
}
