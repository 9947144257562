import { Box, Flex, Group, Progress, SimpleGrid, Stack, Text, Tooltip } from '@mantine/core';
import { FC, Fragment, useCallback, useMemo } from 'react';

import { ReactComponent as Calendar } from "@/common/icons/date.svg";
import { ReactComponent as Stable } from "@/common/icons/Indicators/stable.svg";
import { ReactComponent as TrendingDown } from "@/common/icons/Indicators/trending-down.svg";
import { ReactComponent as TrendingUp } from "@/common/icons/Indicators/trending-up.svg";
import {
  useDetailedCountryReportStyles
} from '@/tenant-context/control-reports/components/DetailedCountryReport/DetailedCountryReport.styles';
import ReportSectionComponent
  from '@/tenant-context/control-reports/components/DetailedCountryReport/ReportSection.component';
import { CountryRiskData } from '@/tenant-context/control-reports/types/country-risk-reports';
import {
  anyCaseToCapitalizedWords,
  getRiskColorByRiskLevel, getRiskLevelValueByRiskLevel,
  snakeCaseToTitleCase
} from '@/tenant-context/control-reports/util/country-risk-reports';
import {
  getIndicatorIcon,
  getInfoIcon,
  getSafetyCategoryIcon, getSubSectionIcon,
  getTravelSectionIcon
} from '@/tenant-context/control-reports/util/icons-maps';

type Props = {
  countryRiskData: CountryRiskData;
};

const MaxSecurityDetailedCountryReport: FC<Props> = ({
  countryRiskData
}) => {
  const getColorByLevel = (level?: string) => {
    return getRiskColorByRiskLevel(level);
  };

  const { classes } = useDetailedCountryReportStyles( {
    riskLevelColor: getColorByLevel(countryRiskData.riskLevel?.toLowerCase())
  } );

  const riskLevelValue = useMemo(() => {
    return getRiskLevelValueByRiskLevel(countryRiskData.riskLevel);
  }, [countryRiskData.riskLevel]);

  const getInfoCategoryIcon = useCallback((infoKey: string) => {
    return getInfoIcon(infoKey);
  }, []);

  const indicatorIcon = useCallback((key: string) => {
    return getIndicatorIcon(key);
  }, []);

  const safetyCategoryIcon = useCallback((key: string) => {
    return getSafetyCategoryIcon(key);
  }, []);

  const travelIcon = useCallback((key: string) => {
    return getTravelSectionIcon(key);
  }, []);

  const subSectionIcon = useCallback((key: string) => {
    return getSubSectionIcon(key);
  }, []);

  return (
    <Fragment>
      <Stack spacing="sm" className={ classes.riskLevelContainer }>
        <Flex justify="space-between">
          <Text weight={ 400 }>Risk Level</Text>
          <Text weight={ 400 } transform="capitalize">{ countryRiskData.riskLevel }</Text>
        </Flex>
        <Progress value={ riskLevelValue } color="red" size="lg" className={ classes.countryRiskLevel }/>
      </Stack>

      { countryRiskData.indicators && <Stack>
        <Text className={ classes.sectionHeading }>Indicators</Text>
        <SimpleGrid cols={ 4 } spacing={ 8 }>
          { Object.keys(countryRiskData.indicators).map((indicatorKey, index) => (
            <Tooltip
              key={ index }
              withArrow
              label={ `Risk Level: ${anyCaseToCapitalizedWords(countryRiskData.indicators?.[indicatorKey].level) || 'N/A'}${
                countryRiskData.indicators?.[indicatorKey].forecast 
                  ? ` | Forecast: ${anyCaseToCapitalizedWords(countryRiskData.indicators[indicatorKey].forecast)}`
                  : ''
              }` }
              multiline
              classNames={ { tooltip: classes.tooltip } }
            >
              <Box
                style={ { backgroundColor: getColorByLevel(countryRiskData.indicators ? countryRiskData.indicators[indicatorKey].level : '') } }
                className={ classes.indicatorPill }
              >
                <Flex align="center" gap={ 4 }>
                  { indicatorIcon(indicatorKey) }
                  { countryRiskData.indicators && countryRiskData.indicators[indicatorKey].forecast === 'deteriorating' && <TrendingUp /> }
                  { countryRiskData.indicators && countryRiskData.indicators[indicatorKey].forecast === 'improving' && <TrendingDown /> }
                  { countryRiskData.indicators && countryRiskData.indicators[indicatorKey].forecast === 'stable' && <Stable /> }
                </Flex>
                <Text>{ snakeCaseToTitleCase(indicatorKey) }</Text>
              </Box>
            </Tooltip>
          )) }
        </SimpleGrid>
      </Stack> }

      { countryRiskData.generalInformation && <Stack>
        <Text className={ classes.sectionHeading }>General Information</Text>
        <Flex gap={ 8 } wrap="wrap">
          { Object.keys(countryRiskData.generalInformation).map((infoKey, index) => (
            <Group key={ index } spacing="sm" className={ classes.infoPill }>
              { getInfoCategoryIcon(infoKey) }
              <Text size="sm">
                { countryRiskData.generalInformation && countryRiskData.generalInformation[infoKey].title }:
              </Text>
              <Text size="sm">{ countryRiskData.generalInformation && countryRiskData.generalInformation[infoKey].text }</Text>
            </Group>
          )) }
        </Flex>
      </Stack> }

      { countryRiskData.eventCalendar && <Stack>
        <Text className={ classes.sectionHeading }>Event Calendar</Text>
        <Stack spacing="xs">
          { countryRiskData.eventCalendar?.map((event, index) => (
            <Group key={ index } spacing="sm" className={ classes.eventPill }>
              <Calendar/>
              <Text size="sm">{ event.eventDetails }</Text>
            </Group>
          )) }
        </Stack>
      </Stack> }

      { countryRiskData.overview && <Stack>
        <Text className={ classes.sectionHeading }>Overview</Text>
        <div className={ classes.text } dangerouslySetInnerHTML={ { __html: countryRiskData.overview } } />
      </Stack> }

      <ReportSectionComponent
        title={ 'Safety and security' }
        reportData={ countryRiskData.safetySecurity }
        getSubsectionIcon={ safetyCategoryIcon }
      />

      <ReportSectionComponent
        title={ 'Travel' }
        reportData={ countryRiskData.travel }
        getSubsectionIcon={ travelIcon }
      />

      <ReportSectionComponent
        title={ 'Infrastructure' }
        reportData={ countryRiskData.infrastructure }
        getSubsectionIcon={ subSectionIcon }
        isLevelShownAsRating
      />

      <ReportSectionComponent
        title={ 'Local considerations' }
        reportData={ countryRiskData.localConsiderations }
        getSubsectionIcon={ subSectionIcon }
      />

      { countryRiskData.dosAndDont && <Stack spacing="md">
        <Text className={ classes.reportSectionHeading }>{ countryRiskData.dosAndDont?.title }</Text>
        <Flex gap="lg" wrap="wrap">
          <Stack spacing="md">
            <div
              className={ classes.content }
              dangerouslySetInnerHTML={ { __html: countryRiskData.dosAndDont.text } }
            />
          </Stack>
        </Flex>
      </Stack> }

      { countryRiskData.cities && <Stack spacing="md">
        <Text className={ classes.reportSectionHeading }>Cities</Text>
        <Flex gap="lg" wrap="wrap">
          { countryRiskData.cities?.map((city, index) => (
            <Stack key={ index } spacing="md">
              <Text className={ classes.subSectionHeading }>{ city.name }</Text>
              <Box className={ classes.content }>
                { city.description }
              </Box>
            </Stack>
          )) }
        </Flex>
      </Stack> }

      { countryRiskData.geographicalFeature && <Stack spacing="md">
        <Text className={ classes.reportSectionHeading }>Geographical Features</Text>
        <Flex gap="lg" wrap="wrap">
          <div
            className={ classes.content }
            dangerouslySetInnerHTML={ { __html: countryRiskData.geographicalFeature.text } }
          />
        </Flex>
      </Stack> }
    </Fragment>
  );
};

export default MaxSecurityDetailedCountryReport;
