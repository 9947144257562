import { DrawerPosition } from "@mantine/core";
import { FC, ReactNode } from "react";
import { useCallback } from "react";
import { ControlPosition } from "react-map-gl";
import { useSelector } from "react-redux";

import useDrawer from "@/common/hooks/useDrawer";
import { RootState } from "@/core/store";

import { BottomDrawerVariant } from "../BottomDrawer/BottomDrawer.types";
import DrawerControl, { DrawerControlButtonRenderer } from "./DrawerControl.component";

type DrawerProps = {
  header?: ReactNode,
  footer?: ReactNode,
  isCloseButtonHidden?: boolean,
  id: string,
  size?: string | number,
  drawerPosition?: DrawerPosition,
  // TODO deprecate
  isStyledTitle?: boolean,
  isWithTitleBorder?: boolean,
  isSubDrawer?: boolean,
  zIndex?: number,
  onBackButtonClick?: () => void,
  title?: string,
  explainer?: string,
  shadow?: string,
  backButtonText?: ReactNode,
  onDrawerVariantChanged?: (variant: BottomDrawerVariant) => void
}

type DrawerWithControlProps = {
  renderButton?: DrawerControlButtonRenderer,
  controlPosition?: ControlPosition,
  onCloseClick?: () => void
}

type Props = DrawerProps & DrawerWithControlProps;

/**
 * If you want a drawer to be displayed, but don't have a button to open it
 * Say, it's open with a map click (or any other action)
 * Use this component, but don't use values from DrawerWithControlProps
 * This way, the Drawer will be rendered the same as if there was a button
 * But the button will not be there :)
 */
const DrawerControlContainer: FC<Props> = ({
  children,
  header,
  footer,
  isCloseButtonHidden,
  renderButton,
  id,
  size,
  drawerPosition = "right",
  isWithTitleBorder= true,
  isSubDrawer = false,
  onCloseClick,
  onBackButtonClick,
  zIndex,
  title,
  explainer,
  shadow,
  backButtonText,
  onDrawerVariantChanged
}) => {
  const {
    close,
    open,
    isOpen
  } = useDrawer(id,drawerPosition);

  const openRightDrawerId = useSelector((state: RootState) => state.drawer.currentlyOpenRightDrawerId);
  const openBottomDrawerId = useSelector((state: RootState) => state.drawer.currentlyOpenRightDrawerId);
  const openLeftDrawerId = useSelector((state: RootState) => state.drawer.currentlyOpenLeftDrawerId);

  const isDrawerEnabled = (
    drawerPosition === "bottom" && openRightDrawerId !== undefined
  ) || (
    drawerPosition === "right" && openBottomDrawerId !== undefined
  ) || (
    drawerPosition === "left" && openLeftDrawerId !== undefined
  );

  const handleCloseClick = useCallback(() => {
    onCloseClick?.();
    close();
  }, [close, onCloseClick]);

  return (
    <DrawerControl
      renderButton={ renderButton }
      onCloseClick={ handleCloseClick }
      onBackButtonClick={ onBackButtonClick }
      isOpen={ isOpen }
      open={ open }
      close={ handleCloseClick }
      header={ header }
      footer={ footer }
      isCloseButtonHidden={ isCloseButtonHidden }
      size={ size }
      drawerPosition={ drawerPosition }
      isSubDrawer={ isSubDrawer }
      isWithTitleBorder={ isWithTitleBorder }
      zIndex={ zIndex }
      isDrawerExists = { isDrawerEnabled }
      title={ title }
      explainer={ explainer }
      shadow={ shadow }
      backButtonText={ backButtonText }
      onDrawerVariantChanged={ onDrawerVariantChanged }
    >
      { children }
    </DrawerControl>
  );
};

export default DrawerControlContainer;
