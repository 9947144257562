import sessionDatabase from "@/common/util/session-database";
import { environment } from "@/core/config/env";
import { IS_IN_WARNING_PERIOD_KEY, LAST_ACTIVITY_KEY } from "@/tenant-context/core/config/consts";

import browserDatabase from "./browser-database";

// function to base64 decode JWT token's payload
export const decodeToken = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
};
export const TENANT_TOKEN_KEY = 'tenantToken';
export const DECODED_TENANT_TOKEN_KEY = 'tenantTokenDecoded';
export const TENANT_REFRESH_TOKEN_KEY = 'tenantRefreshToken';
export const REGULAR_TOKEN_KEY = 'regularToken';
export const DECODED_REGULAR_TOKEN_KEY = 'regularTokenDecoded';
export const REGULAR_REFRESH_TOKEN_KEY = 'regularRefreshToken';
export const SITE_TOKEN_KEY = 'siteToken';
export const DECODED_SITE_TOKEN_KEY = 'siteTokenDecoded';

export const handleLogout = () => {
  browserDatabase.deleteItem(REGULAR_TOKEN_KEY);
  browserDatabase.deleteItem(TENANT_TOKEN_KEY);
  browserDatabase.deleteItem(TENANT_REFRESH_TOKEN_KEY);
  browserDatabase.deleteItem(DECODED_REGULAR_TOKEN_KEY);
  browserDatabase.deleteItem(DECODED_TENANT_TOKEN_KEY);

  sessionDatabase.deleteItem(LAST_ACTIVITY_KEY);
  sessionDatabase.deleteItem(IS_IN_WARNING_PERIOD_KEY);

  window.location.replace(`${environment.unifiedExperienceUrl}/login`);
};
