import { ReactComponent as Crime } from "@/common/icons/crime.svg";
import { ReactComponent as HighImpactExplosiveActivity } from "@/common/icons/HighImpactExplosiveActivity.svg";
import { ReactComponent as Uav } from "@/common/icons/IDFUAVActivity.svg";
import { ReactComponent as Ied } from "@/common/icons/IED.svg";
import { ReactComponent as Saf } from "@/common/icons/SAF.svg";
import { ReactComponent as SecurityOperations } from "@/common/icons/SecurityOperations.svg";
import { ReactComponent as ProtestsAndGatherings } from "@/common/icons/unrest.svg";
import { Sigact } from '@/tenant-context/visualisation-risk-alerts/types/risk-alert-response';

export const bsocRiskAlertCategories = [
  {
    Icon: <Crime />,
    categorySigact: Sigact.Crime
  },
  {
    Icon: <Ied />,
    categorySigact: Sigact.IED
  },
  {
    Icon: <HighImpactExplosiveActivity />,
    categorySigact: Sigact.HighImpactExplosiveActivity
  },
  {
    Icon: <ProtestsAndGatherings />,
    categorySigact: Sigact.ProtestsAndGatherings
  },
  {
    Icon: <Saf />,
    categorySigact: Sigact.SAF
  },
  {
    Icon: <SecurityOperations />,
    categorySigact: Sigact.SecurityOperations
  },
  {
    Icon: <Uav />,
    categorySigact: Sigact.IDFUAVActivity
  }
];
