/* eslint-disable max-lines */
import { BSOC_CATEGORY_PREFIX } from "@/tenant-context/common/util/risk";
import { RiskAlertLayerType } from "@/tenant-context/visualisation-risk-alerts/types/risk-alerts";

const BASE_ZOOM_LEVEL = 3;

// expression for filter the given risk category
const getFilter = (id:string) => {
  return   ['==',
    ['get', 'id', ['get', 'category', ['get', 'alert', ['get', 'json']]]],
    id
  ];
};

const getDataMinrWatchlistFilter = (activeWatchlist: Array<string>) => {
  // Mapbox filter to check if the watchlist id is in the active watchlist
  return [
    'in',
    ['get', 'id', ['get', 'category', ['get', 'alert', ['get', 'json']]]],
    ['literal', activeWatchlist]
  ];
};

// expression for get the icon name for given risk category name
const getIconName = (iconName:string) => {
  return ['concat',
    'RiskAlertIcon_',
    ['get', 'name', ['get', 'risk_level', ['get', 'alert', ['get', 'json']]]],
    iconName
  ];
};

const getMaxSecurityIconName = () => {
  return ['concat',
    'RiskAlertIcon_MaxSecurity_',
    ['get', 'id', ['get', 'category', ['get', 'alert', ['get', 'json']]]],
    ['case',
      ['==', ['get', 'name', ['get', 'risk_level', ['get', 'alert', ['get', 'json']]]], 'Extreme'],
      '_White',
      ''
    ]
  ];
};

export const RISKLINE_LAYERS: Array<RiskAlertLayerType> = [
  {
    id: 'r__risk-alert-riskline-political',
    type: 'symbol',
    filter: [
      getFilter('1')
    ],
    layout: {
      'icon-image': [
        'step',
        ['zoom'],
        [
          'case',
          ['==', ['get', 'isImpacted'], true],
          getIconName('Political'),
          getIconName('Base')
        ],
        BASE_ZOOM_LEVEL,
        getIconName('Political')
      ],
      'icon-size': 0.25
    }
  },
  {
    id: 'r__risk-alert-riskline-conflict-and-terrorism',
    type: 'symbol',
    filter: [
      getFilter('2')
    ],
    layout: {
      'icon-image': [
        'step',
        ['zoom'],
        [
          'case',
          ['==', ['get', 'isImpacted'], true],
          getIconName('Conflict'),
          getIconName('Base')
        ],
        BASE_ZOOM_LEVEL,
        getIconName('Conflict')
      ],
      'icon-size': 0.25
    }
  },
  {
    id: 'r__risk-alert-riskline-unrest',
    type: 'symbol',
    filter: [
      getFilter('3')
    ],
    layout: {
      'icon-image': [
        'step',
        ['zoom'],
        [
          'case',
          ['==', ['get', 'isImpacted'], true],
          getIconName('ProtestsAndGatherings'),
          getIconName('Base')
        ],
        BASE_ZOOM_LEVEL,
        getIconName('ProtestsAndGatherings')
      ],
      'icon-size': 0.25
    }
  },
  {
    id: 'r__risk-alert-riskline-crime',
    type: 'symbol',
    filter: [
      getFilter('4')
    ],
    layout: {
      'icon-image': [
        'step',
        ['zoom'],
        [
          'case',
          ['==', ['get', 'isImpacted'], true],
          getIconName('Crime'),
          getIconName('Base')
        ],
        BASE_ZOOM_LEVEL,
        getIconName('Crime')
      ],
      'icon-size': 0.25
    }
  },
  {
    id: 'r__risk-alert-riskline-natural',
    type: 'symbol',
    filter: [
      getFilter('5')
    ],
    layout: {
      'icon-image': [
        'step',
        ['zoom'],
        [
          'case',
          ['==', ['get', 'isImpacted'], true],
          getIconName('Natural'),
          getIconName('Base')
        ],
        BASE_ZOOM_LEVEL,
        getIconName('Natural')
      ],
      'icon-size': 0.25
    }
  },
  {
    id: 'r__risk-alert-riskline-medical',
    type: 'symbol',
    filter: [
      getFilter('6')
    ],
    layout: {
      'icon-image': [
        'step',
        ['zoom'],
        [
          'case',
          ['==', ['get', 'isImpacted'], true],
          getIconName('Medical'),
          getIconName('Base')
        ],
        BASE_ZOOM_LEVEL,
        getIconName('Medical')
      ],
      'icon-size': 0.25
    }
  },
  {
    id: 'r__risk-alert-riskline-travel-safety',
    type: 'symbol',
    filter: [
      getFilter('7')
    ],
    layout: {
      'icon-image': [
        'step',
        ['zoom'],
        ['case',
          ['==', ['get', 'isImpacted'], true],
          getIconName('TravelSafety'),
          getIconName('Base')
        ],
        BASE_ZOOM_LEVEL,
        getIconName('TravelSafety')
      ],
      'icon-size': 0.25
    }
  }
];

export const BSOC_LAYERS: Array<RiskAlertLayerType> = [
  {
    id: 'r__risk-alert-BSOC-SAF',
    type: 'symbol',
    filter: [
      getFilter(BSOC_CATEGORY_PREFIX + 'SAF')
    ],
    layout: {
      'icon-image': [
        'step',
        ['zoom'],
        [
          'case',
          ['==', ['get', 'isImpacted'], true],
          getIconName('SAF'),
          getIconName('Base')
        ],
        BASE_ZOOM_LEVEL,
        getIconName('SAF')
      ],
      'icon-size': 0.25
    }
  },
  {
    id: 'r__risk-alert-BSOC-Crime',
    type: 'symbol',
    filter: [
      getFilter(BSOC_CATEGORY_PREFIX + 'Crime')
    ],
    layout: {
      'icon-image': [
        'step',
        ['zoom'],
        [
          'case',
          ['==', ['get', 'isImpacted'], true],
          getIconName('Crime'),
          getIconName('Base')
        ],
        BASE_ZOOM_LEVEL,
        getIconName('Crime')
      ],
      'icon-size': 0.25
    }
  },
  {
    id: 'r__risk-alert-BSOC-IED',
    type: 'symbol',
    filter: [
      getFilter(BSOC_CATEGORY_PREFIX + 'IED')
    ],
    layout: {
      'icon-image': [
        'step',
        ['zoom'],
        [
          'case',
          ['==', ['get', 'isImpacted'], true],
          getIconName('IED'),
          getIconName('Base')
        ],
        BASE_ZOOM_LEVEL,
        getIconName('IED')
      ],
      'icon-size': 0.25
    }
  },
  {
    id: 'r__risk-alert-BSOC-HighImpactExplosiveActivity',
    type: 'symbol',
    filter: [
      getFilter(BSOC_CATEGORY_PREFIX + 'HighImpactExplosiveActivity')
    ],
    layout: {
      'icon-image': [
        'step',
        ['zoom'],
        [
          'case',
          ['==', ['get', 'isImpacted'], true],
          getIconName('HighImpactExplosiveActivity'),
          getIconName('Base')
        ],
        BASE_ZOOM_LEVEL,
        getIconName('HighImpactExplosiveActivity')
      ],
      'icon-size': 0.25
    }
  },
  {
    id: 'r__risk-alert-BSOC-IDFUAVActivity',
    type: 'symbol',
    filter: [
      getFilter(BSOC_CATEGORY_PREFIX + 'IDFUAVActivity')
    ],
    layout: {
      'icon-image': [
        'step',
        ['zoom'],
        [
          'case',
          ['==', ['get', 'isImpacted'], true],
          getIconName('IDFUAVActivity'),
          getIconName('Base')
        ],
        BASE_ZOOM_LEVEL,
        getIconName('IDFUAVActivity')
      ],
      'icon-size': 0.25
    }
  },
  {
    id: 'r__risk-alert-BSOC-SecurityOperations',
    type: 'symbol',
    filter: [
      getFilter(BSOC_CATEGORY_PREFIX + 'SecurityOperations')
    ],
    layout: {
      'icon-image': [
        'step',
        ['zoom'],
        [
          'case',
          ['==', ['get', 'isImpacted'], true],
          getIconName('SecurityOperations'),
          getIconName('Base')
        ],
        BASE_ZOOM_LEVEL,
        getIconName('SecurityOperations')
      ],
      'icon-size': 0.25
    }
  },
  {
    id: 'r__risk-alert-BSOC-Other',
    type: 'symbol',
    filter: [
      getFilter(BSOC_CATEGORY_PREFIX + 'Other')
    ],
    layout: {
      'icon-image': [
        'step',
        ['zoom'],
        [
          'case',
          ['==', ['get', 'isImpacted'], true],
          getIconName('SecurityOperations'),
          getIconName('Base')
        ],
        BASE_ZOOM_LEVEL,
        getIconName('SecurityOperations')
      ],
      'icon-size': 0.25
    }
  },
  {
    id: 'r__risk-alert-BSOC-ProtestsAndGatherings',
    type: 'symbol',
    filter: [
      getFilter(BSOC_CATEGORY_PREFIX + 'ProtestsAndGatherings')
    ],
    layout: {
      'icon-image': [
        'step',
        ['zoom'],
        [
          'case',
          ['==', ['get', 'isImpacted'], true],
          getIconName('ProtestsAndGatherings'),
          getIconName('Base')
        ],
        BASE_ZOOM_LEVEL,
        getIconName('ProtestsAndGatherings')
      ],
      'icon-size': 0.25
    }
  }
];

// Dataminr Layers
export const DATAMINR_LAYERS = 
  (dataminrActiveWatchList: Array<string> = []): Array<RiskAlertLayerType<typeof DATAMINR_LAYER_IDS[number]>> => [
    {
      id: 'r__risk-alert-dataminr-generic',
      type: 'symbol',
      filter: [
        getDataMinrWatchlistFilter(dataminrActiveWatchList)
      ],
      layout: {
        'icon-image': [
          'step',
          ['zoom'],
          ['case',
            ['==', ['get', 'isImpacted'], true],
            getIconName('DataMinrBase'),
            getIconName('DataMinrBase')
          ],
          BASE_ZOOM_LEVEL,
          getIconName('DataMinrBase')
        ],
        'icon-size': [
          'step',
          ['zoom'],
          ['case',
            ['==', ['get', 'isImpacted'], true],
            0.25,
            0.125
          ],
          BASE_ZOOM_LEVEL,
          0.25
        ]
      }
    }
  ];

export const MAX_SECURITY_LAYERS: Array<RiskAlertLayerType> = [
  // MAX security base layer
  {
    id: 'r__risk-alert-max-security-generic',
    type: 'symbol',
    filter: [
      ['==',
        ['get', 'source'],
        'max-security'
      ]
    ],
    layout: {
      'icon-image': [
        'step',
        ['zoom'],
        getIconName('TriangleBase'),
        BASE_ZOOM_LEVEL,
        getIconName('TriangleBase')
      ],
      'icon-size': [
        'step',
        ['zoom'],
        ['case',
          ['==', ['get', 'isImpacted'], true],
          0.25,
          0.125
        ],
        BASE_ZOOM_LEVEL,
        0.25
      ]
    }
  },
  // MAX security risk category layer
  {
    id: 'r__risk-alert-max-security-incident-type',
    type: 'symbol',
    filter: [
      ['==',
        ['get', 'source'],
        'max-security'
      ]
    ],
    layout: {
      'icon-image': [
        'step',
        ['zoom'],
        getMaxSecurityIconName(),
        BASE_ZOOM_LEVEL,
        getMaxSecurityIconName()
      ],
      'icon-size': [
        'step',
        ['zoom'],
        ['case',
          ['==', ['get', 'isImpacted'], true],
          0.3,
          0.13
        ],
        BASE_ZOOM_LEVEL,
        0.3
      ]
      // 'icon-size': 0.3
    }
  }
];

export const HEALIX_LAYERS: Array<RiskAlertLayerType> = [
  // Healix base layer
  {
    id: 'r__risk-alert-healix-generic',
    type: 'symbol',
    filter: [
      ['==',
        ['get', 'source'],
        'healix'
      ]
    ],
    layout: {
      'icon-image': [
        'step',
        ['zoom'],
        getIconName('TriangleBase'),
        BASE_ZOOM_LEVEL,
        getIconName('TriangleBase')
      ],
      'icon-size': [
        'step',
        ['zoom'],
        ['case',
          ['==', ['get', 'isImpacted'], true],
          0.25,
          0.125
        ],
        BASE_ZOOM_LEVEL,
        0.25
      ]
    }
  },
  // Healix risk category layer
  {
    id: 'r__risk-alert-healix-incident-type',
    type: 'symbol',
    filter: [
      ['==',
        ['get', 'source'],
        'healix'
      ]
    ],
    layout: {
      'icon-image': [
        'step',
        ['zoom'],
        getMaxSecurityIconName(),
        BASE_ZOOM_LEVEL,
        getMaxSecurityIconName()
      ],
      'icon-size': [
        'step',
        ['zoom'],
        ['case',
          ['==', ['get', 'isImpacted'], true],
          0.3,
          0.13
        ],
        BASE_ZOOM_LEVEL,
        0.3
      ]
      // 'icon-size': 0.3
    }
  }
];

export const FACTAL_LAYERS: Array<RiskAlertLayerType> = [
  // Factal base layer
  {
    id: 'r__risk-alert-factal-generic',
    type: 'symbol',
    filter: [
      ['==',
        ['get', 'source'],
        'factal'
      ]
    ],
    layout: {
      'icon-image': [
        'step',
        ['zoom'],
        getIconName('TriangleBase'),
        BASE_ZOOM_LEVEL,
        getIconName('TriangleBase')
      ],
      'icon-size': [
        'step',
        ['zoom'],
        ['case',
          ['==', ['get', 'isImpacted'], true],
          0.25,
          0.125
        ],
        BASE_ZOOM_LEVEL,
        0.25
      ]
    }
  },
  // Factal risk category layer
  {
    id: 'r__risk-alert-factal-incident-type',
    type: 'symbol',
    filter: [
      ['==',
        ['get', 'source'],
        'factal'
      ]
    ],
    layout: {
      'icon-image': [
        'step',
        ['zoom'],
        getMaxSecurityIconName(),
        BASE_ZOOM_LEVEL,
        getMaxSecurityIconName()
      ],
      'icon-size': [
        'step',
        ['zoom'],
        ['case',
          ['==', ['get', 'isImpacted'], true],
          0.3,
          0.13
        ],
        BASE_ZOOM_LEVEL,
        0.3
      ]
    }
  }
];

export const OTHER_LAYERS: Array<RiskAlertLayerType> = [
  // icon to indicate impacted risk alerts
  {
    id: 'r__risk-impact-alert-hovered',
    type: 'symbol',
    filter: [
      [ '==', ['get', 'isImpacted'], true ]
    ],
    layout: {
      'icon-image': 'RiskAlertIcon_RiskAlertImpacted',
      'icon-size': 0.3,
      // setting the offset for the impacted icon
      // eslint-disable-next-line no-magic-numbers
      'icon-offset':[25, -35]
    }
  }
];

// Layer IDs
// Riskline Risk alerts
export const RISKLINE_LAYER_IDS = RISKLINE_LAYERS.map(layer => layer.id);

// BSOC risk alerts
export const BSOC_LAYER_IDS = BSOC_LAYERS.map(layer => layer.id);

// Dataminr Risk alerts
export const DATAMINR_LAYER_IDS = [
  'r__risk-alert-dataminr-generic'
] as const;

// Max-security Risk alerts
export const MAX_SECURITY_LAYER_IDS = MAX_SECURITY_LAYERS.map(layer => layer.id);

export const HEALIX_LAYER_IDS = HEALIX_LAYERS.map(layer => layer.id);

// Factal Risk alerts
export const FACTAL_LAYER_IDS = ['r__risk-alert-factal-generic', 'r__risk-alert-factal-incident-type'];

// Impact alerts
const IMPACT_ALERT_LAYER_IDS = [
  'r__risk-impact-alert-hovered',
  'r__risk-impacthovericons-impacted-warning',
  'r__risk-impacthovericons'
] as const;

// Selected alerts
const SELECTED_ALERT_LAYER_IDS = [
  'r__risk-current-selected-risk-event',
  'r__risk-current-selected-risk-event-max-security-category',
  'r__risk-current-selected-risk-event-impacted-icon'
] as const;

export const LAYER_IDS = [
  ...RISKLINE_LAYER_IDS,
  ...BSOC_LAYER_IDS,
  ...DATAMINR_LAYER_IDS,
  ...MAX_SECURITY_LAYER_IDS,
  ...HEALIX_LAYER_IDS,
  ...FACTAL_LAYER_IDS,
  ...IMPACT_ALERT_LAYER_IDS,
  ...SELECTED_ALERT_LAYER_IDS
];
