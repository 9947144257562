import { MantineThemeOverride } from "@mantine/core";

import { componentOverrides } from "@/core/styles/mantine/components/components";
import { primitiveNumbers } from "@/core/styles/mantine/constants";

import { genericColorPalette, semanticColorPalette } from './palettes';

const appTheme: MantineThemeOverride = {
  cursorType: 'pointer',
  lineHeight: '125%',

  primaryShade: 6,
  primaryColor: 'royalBlue',

  colorScheme: 'dark',
  colors: genericColorPalette as MantineThemeOverride['colors'],
  white: '#FFFFFF',
  black: '#000000',

  defaultRadius: 0,

  // `linear-gradient(180deg, #185BD8 0.94%, ${genericColorPalette.blue[5]} 102.79%)`
  defaultGradient: {
    from: 'rgba(24, 91, 216, 0.94)',
    to: 'rgba(52, 123, 254, 102.79)',
    deg: 180
  },

  fontFamily: `'Inter', sans-serif`,
  fontSizes: {
    xl: 26,
    lg: 20,
    md: 16,
    sm: 14,
    xs: 12
  },

  headings: {
    fontWeight: 700,
    sizes: {
      h1: { fontSize: '26px', lineHeight: '125%' },
      h2: { fontSize: '22px', lineHeight: '125%' },
      h3: { fontSize: '20px', lineHeight: '125%' },
      h4: { fontSize: '18px', lineHeight: '125%' },
      h5: { fontSize: '18px', lineHeight: '125%' },
      h6: { fontSize: '18px', lineHeight: '125%' }
    }
  },

  components: componentOverrides,

  radius: {
    lg: primitiveNumbers["13x"],
    md: primitiveNumbers["4x"],
    sm: primitiveNumbers["2x"],
    xs: primitiveNumbers.x
  },

  other: {
    semantic: semanticColorPalette,

    fs: {
      md: 16,
      sm: 14,
      xs: 12,
      xxs: 10,
      tiny: 8
    },

    headings: {
      display: 40,
      h1: 26,
      h2: 22,
      h3: 20,
      h4: 18,
      h5: 18,
      h6: 18
    },

    lh: {
      l: '150%',
      s: '125%',
      none: '100%'
    },

    fw: {
      bold: 700,
      semibold: 600,
      regular: 400
    },

    shp: {
      br: {
        rounded: '1px',
        sharp: '0'
      }
    },

    sdw: {
      right: '4px 0 10px rgba(0,0,0,.25)',
      left: '-4px 0 10px rgba(0,0,0,.25)',
      top: '0 -4px 20px rgba(0,0,0,.25)',
      bottom: '0px 2px 4px rgba(0, 0, 0, 0.25)',
      halo: '0 0 8px rgba(0,0,0,.25)'
    },

    spacing: {
      '2xl': primitiveNumbers["6x"],
      xl: primitiveNumbers["4x"],
      lg: primitiveNumbers["3x"],
      md: primitiveNumbers["2x"],
      sm: primitiveNumbers.x,
      xs: primitiveNumbers.halfX,
      xxs: primitiveNumbers.quarterX,
      none: 0
    },

    radius: {
      lg: primitiveNumbers["13x"],
      md: primitiveNumbers["4x"],
      sm: primitiveNumbers["2x"],
      xs: primitiveNumbers.x,
      none: primitiveNumbers.none
    }
  },

  breakpoints: {
    sm: 1000,
    md: 1200,
    lg: 1400,
    xl: 1900
  }
};

export default appTheme;
