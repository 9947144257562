import { CaptureConsole } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { createContext, FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import usePermission from "@/common/hooks/usePermission";
import { loadGlobalFontAwesomeIcons } from "@/common/icons/FontAwesome";
import { DECODED_TENANT_TOKEN_KEY } from "@/common/util/auth";
import { getURLParam } from "@/common/util/browser";
import browserDatabase from '@/common/util/browser-database';
import { logger } from "@/common/util/ConsoleLogger";
import LoaderScreen from "@/core/components/LoaderScreen/LoaderScreen.component";
import { RouterConfig } from "@/core/components/Router/Router.config";
import { CorePolicies } from "@/core/config/CorePolicies.config";
import { ENV, Environment } from '@/core/config/env';
import {
  REPLAYS_ON_ERROR_SAMPLE_RATE,
  REPLAYS_SESSION_SAMPLE_RATE,
  TRACE_PROPAGATION_TARGETS,
  TRACES_SAMPLE_RATE
} from '@/core/config/Sentry.config';
import { Dispatch, RootState } from "@/core/store";
import { useAuthContext } from "@/tenant-context/core/context/Auth.context";

const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;

if (SENTRY_DSN && ENV === Environment.Qa) {
  try {
    Sentry.init({
      dsn: SENTRY_DSN,
      integrations: [
        new Sentry.BrowserTracing({
          tracePropagationTargets: TRACE_PROPAGATION_TARGETS
        }),
        new Sentry.Replay(),
        new CaptureConsole()
      ],
      tracesSampleRate: TRACES_SAMPLE_RATE,
      replaysSessionSampleRate: REPLAYS_SESSION_SAMPLE_RATE,
      replaysOnErrorSampleRate: REPLAYS_ON_ERROR_SAMPLE_RATE
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (Sentry && Sentry.getCurrentHub().getClient()?._dsn) {
      logger.log('Sentry has been initialized!');
    } else {
      logger.log('Sentry has not been initialized.');
    }

  } catch (e) {
    logger.error(e);
  }
} else {
  logger.log('Sentry has not been initialized.');
  if (!SENTRY_DSN) {
    logger.error('SENTRY_DSN not found');
  }
}

export const AppInitContext = createContext({});

export const AppInitContextProvider: FC = ({
  children
}) => {
  const [isInitTasksFinished, setIsInitTasksFinished] = useState<boolean>(false);

  const isSSOIntegration = window.location.pathname.includes(RouterConfig.routes.ssoIntegration);
  const isPublicRoute = RouterConfig.publicURLs().includes(window.location.pathname);

  const {
    commonData: {
      loadAllCountries,
      loadAllRiskProviders,
      loadAllLocations,
      setTenant,
      loadLegacyModeCheck,
      loadTenantSubscribedProducts,
      loadUserGeoPermission
    }
  } = useDispatch<Dispatch>();

  const {
    isAuthenticated
  } = useAuthContext();

  const isConnectorPermissionAvailable = usePermission(CorePolicies.CONNECTOR_PROVIDER_POLICY);
  const isLocationPermissionAvailable = usePermission(CorePolicies.GLOBAL_LOCATION_PERMISSIONS);
  const userInfo = useSelector((state: RootState) => state.profile.loggedUser);

  // App init tasks. Please note that these won't run in public routes
  const runInitTasks = useCallback(async() => {
    setIsInitTasksFinished(false);

    // Add your init tasks here
    await loadUserGeoPermission();
    // End init tasks

    setIsInitTasksFinished(true);
  }, [loadUserGeoPermission]);

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    // initializeDataDogLogs(); // Disabled temporarily due to a request
    const tenantIdParam = getURLParam('tenantId');

    if (tenantIdParam) {
      setTenant({
        tenantId: tenantIdParam,
        refreshBrowser: false
      });
    }

    if (isLocationPermissionAvailable){
      loadAllLocations();
    }

    if (isConnectorPermissionAvailable) {
      loadAllRiskProviders();
      loadLegacyModeCheck();
    }
  }, [ isAuthenticated, loadAllCountries, loadAllRiskProviders, loadAllLocations, setTenant, loadLegacyModeCheck,
    isConnectorPermissionAvailable, loadTenantSubscribedProducts, isLocationPermissionAvailable]);

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }
    loadTenantSubscribedProducts();
    loadAllCountries();

  }, [isAuthenticated, loadAllCountries, loadTenantSubscribedProducts]);

  // Load global font awesome icons
  useEffect(() => {
    loadGlobalFontAwesomeIcons();
  }, []);

  useEffect(()=>{
    if(!isAuthenticated || !userInfo){
      return;
    }

    const { assignedTenantUserTid } = userInfo;

    if(assignedTenantUserTid){
      Sentry.setUser({
        id: assignedTenantUserTid
      });
    }
  }, [isAuthenticated, userInfo]);

  useEffect(() => {
    if (isPublicRoute) {
      setIsInitTasksFinished(true);
      return;
    }

    const currentUserId = browserDatabase.getItem<{userId: string}>(DECODED_TENANT_TOKEN_KEY)?.userId;
    if(!isAuthenticated || !currentUserId){
      return;
    }

    runInitTasks();
  }, [isAuthenticated, isPublicRoute, runInitTasks]);

  if (!isSSOIntegration && !isInitTasksFinished) {
    return <LoaderScreen isLoading={ true } text="Getting resilienceOS ready for you - Please wait..."/>;
  }

  return (
    <AppInitContext.Provider value={ 1337 }>
      { children }
    </AppInitContext.Provider>
  );
};
