import { Box, Button, Checkbox, Text } from "@mantine/core";
import { FC } from "react";

import { ArcImpactStatus, ArcPeopleImpactSummary, ArcResponseStatusCount } from "@/tenant-context/control-action-response-center/types/ARC.types";

import { useArcHeaderStyles } from "../../../ArcHeader/ArcHeader.style";
import McArcPieChart from "../McArcPieChart/McArcPieChart.component";


type Props = {
    isPollSent: boolean,
    onMassCommsNavigation: () => void,
    impactSummary: ArcPeopleImpactSummary,
    responseCounts?: ArcResponseStatusCount,
    onStatusFilterChange: (status: ArcImpactStatus) => (event: React.ChangeEvent<HTMLInputElement>) => void,
    selectedImpactStatus?: ArcImpactStatus
}

const MassCommsWidgetComponent: FC<Props> = ({  
  isPollSent, onMassCommsNavigation, impactSummary, responseCounts, onStatusFilterChange, selectedImpactStatus }) => {
  const { classes } = useArcHeaderStyles();

  return (
    <Box className={ classes.mcWidgetContainer }>
      <Box className={ classes.pieChartContainer }>
        <McArcPieChart
          summaryData={ impactSummary }
          width={ 80 }
          height={ 80 }
          isAnimated={ true }
        /> 
        <Box className={ classes.mcInnerContainer }>
          <Text className={ classes.mcWidgetHeader }>{ isPollSent ? `Safety Poll Responses` : `Send a Safety Poll to Check on Impacted People` }</Text>
          <Text className={ classes.mcInnerText }>{ isPollSent ? `Live status updates from impact individuals, \nsee who is safe, needs help, or hasn't responded.` : `Ask if they are safe or need help. No poll sent yet—send now for real-time updates.` }</Text>
        </Box>
      </Box>
      { isPollSent ? <Box className={ classes.responseTypeContainer }>
        <Box className={ `${classes.resItem} ${classes.itemRed}` }>
          <Text className={ classes.safetyStatus }>
            Not Safe
          </Text>
          <Box className={ classes.safetyValue }>
            <Text>{ responseCounts?.notSafeCount || 0 }</Text>
            <Checkbox checked={ selectedImpactStatus === 'NOT_SAFE' } size="sm" onChange={ onStatusFilterChange('NOT_SAFE') } />
          </Box>
        </Box>
        <Box className={ `${classes.resItem} ${classes.itemOrange}` }>
          <Text className={ classes.safetyStatus }>
            No Response
          </Text>
          <Box className={ classes.safetyValue }>
            <Text>{ responseCounts?.noResponseCount || 0 }</Text>
            <Checkbox checked={ selectedImpactStatus === 'NO_RESPONSE' } size="sm" onChange={ onStatusFilterChange('NO_RESPONSE') } />
          </Box>
        </Box>
        <Box className={ `${classes.resItem} ${classes.itemGreen}` }>
          <Text className={ classes.safetyStatus }>
            Safe
          </Text>
          <Box className={ classes.safetyValue }>
            <Text>{ responseCounts?.safeCount || 0 }</Text>
            <Checkbox checked={ selectedImpactStatus === 'SAFE' } size="sm" onChange={ onStatusFilterChange('SAFE') } />
          </Box>
        </Box>
        <Box className={ `${classes.resItem} ${classes.itemGrey}` }>
          <Text className={ classes.safetyStatus }>
            Other
          </Text>
          <Box className={ classes.safetyValue }>
            <Text>{ responseCounts?.otherCount || 0 }</Text>
            <Checkbox checked={ selectedImpactStatus === 'OTHER' } size="sm" onChange={ onStatusFilterChange('OTHER') } />
          </Box>
        </Box>
      </Box> : 
        // eslint-disable-next-line react/jsx-indent, react/jsx-no-bind
        < Button onClick={ onMassCommsNavigation }>Send New Safety Poll</Button> }
    </Box>
  );
};

export default MassCommsWidgetComponent;
