import { useMantineTheme } from "@mantine/core";
import { useMemo } from "react";

import { healixRiskLevelMap } from '@/tenant-context/common/config/risk.config';
import { getRiskLevelColor } from "@/tenant-context/common/util/risk";


export const useHealixLegend = () => {
  const theme = useMantineTheme();

  return useMemo(
    () => Object.entries(healixRiskLevelMap)
      .map(([label, { id }]) =>
        ({ level: parseInt(id, 10), label, color: getRiskLevelColor(id, theme) })).reverse()
    ,
    [theme]
  );
};
