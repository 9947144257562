import { FC, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '@/core/store';
import { factalRiskLevelMap } from '@/tenant-context/common/config/risk.config';
import { RiskProviders } from "@/tenant-context/common/types/risk";
import {
  FactalEventsComponent
} from "@/tenant-context/control-risk-config/components/RiskProviderTreeContent/FactalEvents.component";

import { ProviderCategory } from "../../types/risk";
import { useDisabledRiskFilterTypes, useToggleCategoryFilter } from "./hooks";

const FactalEventsContainer: FC = () => {
  const [legendVisible, setLegendVisible] = useState(false);
  // TODO: Implement when API is ready
  // const { riskEventFilterDataOptions: { getFactalTopics } } = useDispatch<Dispatch>();
  const factalTopics = useSelector(
    (state: RootState) => state.riskEventFilterDataOptions.factalTopics
  );

  const items = useMemo<Array<ProviderCategory>>(
    () =>
      factalTopics.map((category) => ({
        ...category,
        categoryId: category.id.toString()
      })),
    [factalTopics]
  );

  const legendItems = useMemo(() => {
    return Object.entries(factalRiskLevelMap).map(([_label, { id , color, vendorLevel }]) => ({
      color,
      label: vendorLevel,
      level: id
    })).reverse();
  }, []);

  const disabledRiskFilterTypes = useDisabledRiskFilterTypes();

  const handleSwitchToggle = useToggleCategoryFilter(RiskProviders.factal);

  const toggleLegendVisibility = useCallback(() => {
    setLegendVisible((visible) => !visible);
  }, []);

  // TODO: Implement when API is ready
  // useEffect(() => {
  //   getFactalTopics();
  // }, [getFactalTopics]);

  return (
    <FactalEventsComponent
      items={ items }
      onCategoryToggle={ handleSwitchToggle }
      disabledRiskFilterTypes={ disabledRiskFilterTypes }
      toggleLegendVisibility={ toggleLegendVisibility }
      isLegendVisible={ legendVisible }
      legendItems={ legendItems }
    />
  );
};

export default FactalEventsContainer; 
