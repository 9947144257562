import { createStyles, MantineTheme } from "@mantine/core";

export const useRiskImpactPopupStyles = createStyles((theme: MantineTheme) => ({


  popUp:{
    color: theme.white,

    '& > .mapboxgl-popup-content': {
      background: theme.colors.blue[6],
      padding: '5px 10px' 

    },
    '& > .mapboxgl-popup-tip': {
      border: 'none'
    }
  },

  popupContainer:{
    fontFamily:theme.fontFamily,
    backgroundColor: theme.colors.blue[6],
    font:theme.fontFamily,
    top: '50px',
    justifyContent:'space-between',
    alignItems:'center'
  },

  riskImpactPopupContentItem:{
    fontWeight:theme.other.fw.bold,
    fontSize:theme.fontSizes.xs
  }
}));
