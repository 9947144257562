/* eslint-disable max-lines */
import { RouterConfig } from "@/core/components/Router/Router.config";
import { ENV, environment } from "@/core/config/env";
import {
  NavigationItemId,
  NavigationSidebarItem,
  OutSystemsApplication,
  ProductArea, ProductName
} from '@/core/navigation/types/navigation';
import {
  SeverityLevelsTenantRequiredPoliciesConfig
} from "@/platform-context/control-icm-management/components/SeverityLevels/config/SeverityLevelsRequiredPolicies.config";
import { CaseTypeRequiredPoliciesConfig } from "@/platform-context/control-icm-management/config/CaseTypesRequiredPolicies.config";
import { PlaybookRequiredPoliciesConfig } from "@/platform-context/control-icm-management/config/PlaybookRequiredPoliciesConfig";
import { SituationTypePoliciesConfig } from "@/platform-context/control-icm-management/config/SituationTypeRequiredPolicies.config";
import { Environment } from "@/platform-context/navigation/config/env";
import {
  DashboardRequiredPoliciesConfig
} from "@/tenant-context/control-connected-sites-dashboard/config/DashboardRequiredPolicies.config";
import { GeofenceRequiredPoliciesConfig } from "@/tenant-context/control-draw/config/GeofenceRequiredPolicies.config";
import { AssetsManagementPoliciesConfig } from '@/tenant-context/control-icm-management/config/AssetsManagementRequiredPolicies.config';
import {
  CaseManagementPoliciesConfig
} from "@/tenant-context/control-icm-management/config/CaseManagementRequiredPolicies.config";
import { IntegrationMappingPoliciesConfig } from '@/tenant-context/control-integrations-mapping/config/integration-mapping.policies';
import { LocationConfigurationPoliciesConfig } from '@/tenant-context/control-location-configuration/config/location-configuration.policies';
import { LocationPoliciesConfig } from "@/tenant-context/control-location-management/config/location-management.policies";
import { MassCommsPoliciesConfig } from "@/tenant-context/control-mass-comms/config/mass-comms.policies";
import { ReactComponent as GlobalMapIcon } from "@/tenant-context/navigation/icons/v2-icons/global-map.svg";
import { ReactComponent as ICMIcon } from "@/tenant-context/navigation/icons/v2-icons/icm.svg";
import { ReactComponent as LibraryIcon } from "@/tenant-context/navigation/icons/v2-icons/library.svg";
import { ReactComponent as LocationsIcon } from "@/tenant-context/navigation/icons/v2-icons/locations.svg";
import { ReactComponent as MassCommsIcon } from "@/tenant-context/navigation/icons/v2-icons/mass-comms.svg";
import { ReactComponent as PeopleIcon } from "@/tenant-context/navigation/icons/v2-icons/people.svg";
import { ReactComponent as SettingsIcon } from "@/tenant-context/navigation/icons/v2-icons/settings.svg";
import { ReactComponent as SupportIcon } from "@/tenant-context/navigation/icons/v2-icons/support.svg";

export const navigationSidebarItems: NavigationSidebarItem[] = [
  {
    title: 'Global Map',
    hintMessage: 'Monitor for incidents globally',
    path: '/',
    itemId: NavigationItemId.Map,
    area: ProductArea.Global,
    icon: <GlobalMapIcon />
  },
  {
    title: 'Mass Comms',
    hintMessage: <><span>Manage & Send</span><span>Mass Communication messages</span></>,
    path: RouterConfig.routes.massComms,
    itemId: NavigationItemId.Comms,
    area: ProductArea.Global,
    isNotReady: false,
    icon: <MassCommsIcon />,
    policies: [
      ...MassCommsPoliciesConfig.MC_SENT_COMMS_PAGE.policyNames
    ]
  },
  {
    title: 'People',
    itemId: NavigationItemId.People,
    area: ProductArea.Global,
    isNotReady: false,
    type: 'menu',
    icon: <PeopleIcon />,
    subMenuItems: [
      {
        sectionHeader: 'People Management',
        subItems: [
          {
            itemId: NavigationItemId.PeopleUsers,
            area: ProductArea.Global,
            title: 'People',
            policies: [
              'Tenant_System_People-SensitiveData_Readonly',
              'Tenant_System_People-SensitiveData_FullAccess',
              'Tenant_System_People-Addresses_FullAccess',
              'Tenant_System_People-Addresses_Readonly',
              'Tenant_System_People-CompanyDetails_FullAccess',
              'Tenant_System_People-CompanyDetails_Readonly',
              'Tenant_System_People-ContactDetails_FullAccess',
              'Tenant_System_People-ContactDetails_Readonly',
              'Tenant_System_People-Documents_FullAccess',
              'Tenant_System_People-Documents_Readonly',
              'Tenant_System_People-EmergencyContact_FullAccess',
              'Tenant_System_People-EmergencyContact_Readonly',
              'Tenant_System_People-Features_FullAccess',
              'Tenant_System_People-Features_Readonly',
              'Tenant_System_People-FullProfile_FullAccess',
              'Tenant_System_People-FullProfile_Readonly',
              'Tenant_System_People-Groups_FullAccess',
              'Tenant_System_People-Groups_Readonly',
              'Tenant_System_People-MedicalDetails_FullAccess',
              'Tenant_System_People-MedicalDetails_Readonly',
              'Tenant_System_People-MiniProfile_Readonly',
              'Tenant_System_People-PersonalAttributes_FullAccess',
              'Tenant_System_People-PersonalAttributes_Readonly',
              'Tenant_System_People-PersonalDetails_FullAccess',
              'Tenant_System_People-PersonalDetails_Readonly'
            ],
            path: RouterConfig.routes.profileList
          },
          {
            itemId: NavigationItemId.PeopleGroups,
            area: ProductArea.Global,
            title: 'People Groups',
            policies: ['Tenant_System_People-Groups_Readonly', 'Tenant_System_People-Groups_FullAccess'],
            path: RouterConfig.routes.peopleGroups
          }
        ]
      }
    ]
  },
  {
    title: 'Location Management',
    hintMessage: 'Manage your Locations',
    itemId: NavigationItemId.LocationManagement,
    area: ProductArea.OutSystems,
    type: 'menu',
    icon: <LocationsIcon />,
    subMenuItems: [
      {
        sectionHeader: 'Location Management',
        subItems: [
          {
            title: 'Locations',
            path: '/location-configuration/',
            itemId: NavigationItemId.LocationConfiguration,
            area: ProductArea.Global,
            policies: LocationConfigurationPoliciesConfig.LOCATION_CONFIGURATION_ROUTE_ACCESS.policyNames,
            productName: ProductName.LocationConfiguration
          },
          {
            title: 'Connected Sites',
            path: RouterConfig.routes.locationManagement,
            itemId: NavigationItemId.LocationManagement,
            area: ProductArea.Global,
            isNotReady: false,
            policies: LocationPoliciesConfig.LOCATION_ROUTE_ACCESS.policyNames
          },
          {
            title: 'Geofences',
            hintMessage: 'Manage and Create Geofences',
            path: RouterConfig.routes.geofenceManage,
            itemId: NavigationItemId.Geofence,
            area: ProductArea.Global,
            policies: [...GeofenceRequiredPoliciesConfig.LONE_WORKER_MANAGE_GEO_FENCE_READ_ACCESS.policyNames],
            isNotReady: false
          },
          {
            title: 'Visitor Management',
            path: RouterConfig.routes.visitorRequestDashboard,
            itemId: NavigationItemId.VisitorRequestsDashboard,
            area: ProductArea.Global,
            isNotReady: false,
            policies: [
              ...DashboardRequiredPoliciesConfig.DASHBOARD_ROUTE_ACCESS.policyNames
            ]
          }

        ]
      }
    ]
  },
  {
    title: 'Library',
    hintMessage: <><span>Store and Manage </span><span>shared documents and resources</span></>,
    itemId: NavigationItemId.References,
    area: ProductArea.Global,
    type: 'menu',
    icon: <LibraryIcon />,
    subMenuItems: [
      {
        sectionHeader: 'Reference Library',
        subItems: [
          {
            title: 'References',
            path: '/references/document',
            unifiedExperienceApplication: 'references',
            policies: ['Tenant_ReferenceLibrary_References_ReadOnly', 'Tenant_ReferenceLibrary_References_FullAccess', 'Tenant_ReferenceLibrary_References_Download'],
            productName: ProductName.References,
            itemId: NavigationItemId.References,
            area: ProductArea.UnifiedExperience
          }
        ]
      },
      {
        sectionHeader: 'Risk Reports',
        subItems: [
          {
            title: 'Country Risk',
            path: RouterConfig.routes.threatLibraryCountry,
            policies: ['Tenant_RiskReport_CountryRisks_ReadOnly'],
            productName: ProductName.References,
            itemId: NavigationItemId.ThreatReportsCountry,
            area: ProductArea.Global
          },
          {
            title: 'City Risk',
            path: RouterConfig.routes.threatLibraryCity,
            policies: ['Tenant_RiskReport_CityRisks_ReadOnly'],
            productName: ProductName.References,
            itemId: NavigationItemId.ThreatReportsCity,
            area: ProductArea.Global
          }
        ]
      }
    ]
  },
  {
    title: 'Incident & Crisis Manager',
    hintMessage: 'Manage your incidents here',
    itemId: NavigationItemId.Incident,
    area: ProductArea.OutSystems,
    type: 'menu',
    icon: <ICMIcon />,
    subMenuItems: [
      {
        sectionHeader: 'Incident & Crisis Manager',
        subItems: [

          {
            title: 'Incident & Crisis Manager - Legacy',
            itemId: NavigationItemId.Incident,
            area: ProductArea.OutSystems,
            outsystemsApplication: OutSystemsApplication.ICM,
            policies: ['Tenant_ICM_Case_Access']
          },
          ...(ENV !== Environment.PROD ? [
            {
              itemId: NavigationItemId.IncidentCrisisManager,
              area: ProductArea.Global,
              title: 'Incident & Crisis Manager',
              productName: ProductName.ICM,
              policies: CaseManagementPoliciesConfig.CASE_MANAGEMENT_ROUTE_ACCESS.policyNames,
              path: RouterConfig.routes.icmConfig.caseManagement.caseList
            }
          ] : [])
        ]
      }
    ]
  }
];

export const navigationSidebarBottomItems: NavigationSidebarItem[] = [
  {
    title: 'Settings & Configuration',
    itemId: NavigationItemId.Settings,
    area: ProductArea.UnifiedExperience,
    path: '',
    unifiedExperienceApplication: 'tenant-landing-page',
    isAlwaysEnabled: true,
    type: 'menu',
    icon: <SettingsIcon />,
    subMenuItems: [
      {
        sectionHeader: 'Locations',
        subItems: [
          {
            title: 'Integrations Mapping',
            path: RouterConfig.routes.integrationMapping.integrationList,
            itemId: NavigationItemId.IntegrationMapping,
            area: ProductArea.Global,
            policies: IntegrationMappingPoliciesConfig.INTEGRATION_MAPPING.policyNames
          },
          {
            title: 'Location Configuration Legacy',
            path: '/tenant-location/',
            itemId: NavigationItemId.LocationConfiguration,
            area: ProductArea.UnifiedExperience,
            unifiedExperienceApplication: 'tenant-location',
            policies: ['Tenant_System_Tenant-LocationConfig-Legacy_FullAccess', 'Tenant_System_Tenant-LocationConfig-Legacy_ReadOnly'],
            productName: ProductName.LocationConfiguration
          }
        ]
      },
      {
        sectionHeader: 'Connected Sites',
        subItems: [
          {
            itemId: NavigationItemId.SitesConfig,
            area: ProductArea.OutSystems,
            title: 'Sites Configuration',
            policies: ['Tenant_LM_Config_Access'],
            outsystemsApplication: OutSystemsApplication.LastMile
          },
          {
            title: 'Sites (Legacy)',
            itemId: NavigationItemId.SitesLegacy,
            area: ProductArea.OutSystems,
            outsystemsApplication: OutSystemsApplication.Sites,
            policies: ['Tenant_LM_Portal_Access'],
            productName: ProductName.LastMile
          }
        ]
      },
      {
        sectionHeader: 'Incident & Crisis Manager',
        subItems: [
          {
            itemId: NavigationItemId.IncidentCrisisMan,
            area: ProductArea.OutSystems,
            title: 'Incident & Crisis Configuration - Legacy',
            policies: ['Tenant_ICM_Config_Access'],
            outsystemsApplication: OutSystemsApplication.ICMConfig
          },
          ...(ENV !== Environment.PROD ? [
            {
              itemId: NavigationItemId.SeverityLevels,
              area: ProductArea.Global,
              title: 'Severity Levels',
              policies: SeverityLevelsTenantRequiredPoliciesConfig.SEVERITY_LEVELS_READ_ACCESS.policyNames,
              path: RouterConfig.routes.icmConfig.severityLevels
            },
            {
              title: 'Threat Types',
              path: RouterConfig.routes.icmConfig.situationTypes,
              itemId: NavigationItemId.SituationTypes,
              area: ProductArea.Global,
              policies: SituationTypePoliciesConfig.tenant.SITUATION_TYPE_ROUTE_ACCESS.policyNames,
              productName: ProductName.ICM
            },
            {
              title: 'Assets',
              path: RouterConfig.routes.icmConfig.assets,
              itemId: NavigationItemId.AssetsManagement,
              area: ProductArea.Global,
              policies: AssetsManagementPoliciesConfig.ASSETS_MANAGEMENT_ROUTE_ACCESS.policyNames,
              productName: ProductName.ICM
            },
            {
              title: 'Playbooks',
              path: RouterConfig.routes.icmConfig.playbooks,
              itemId: NavigationItemId.Playbooks,
              area: ProductArea.Global,
              policies: PlaybookRequiredPoliciesConfig.tenant.PLAYBOOK_ROUTE_ACCESS.policyNames,
              productName: ProductName.ICM
            },
            {
              title: 'Case Types',
              path: RouterConfig.routes.icmConfig.caseTypes,
              itemId: NavigationItemId.CaseTypes,
              area: ProductArea.Global,
              policies: CaseTypeRequiredPoliciesConfig.tenant.CASE_TYPE_ROUTE_ACCESS.policyNames,
              productName: ProductName.ICM
            }
          ] : [])
        ]
      },
      {
        sectionHeader: 'Lone Worker',
        subItems: [
          {
            itemId: NavigationItemId.VerifiedCheckin,
            area: ProductArea.UnifiedExperience,
            title: 'Verified Check-In',
            policies: [
              'Tenant_LoneWorker_Configuration_FullAccess',
              'Tenant_LoneWorker_Configuration_ReadOnly',
              'Tenant_LoneWorker_VerifiedCheckInConfig_FullAccess',
              'Tenant_LoneWorker_VerifiedCheckInConfig_ReadOnly'],
            unifiedExperienceApplication: 'tenant-verified-checkin'
          },
          {
            itemId: NavigationItemId.EndTimeConfig,
            area: ProductArea.UnifiedExperience,
            title: 'End Task',
            policies: [
              'Tenant_LoneWorker_Configuration_FullAccess',
              'Tenant_LoneWorker_Configuration_ReadOnly',
              'Tenant_LoneWorker_EndTaskConfig_ReadOnly',
              'Tenant_LoneWorker_EndTaskConfig_FullAccess'],
            unifiedExperienceApplication: 'tenant-end-task-config'
          }
        ]
      },
      {
        sectionHeader: 'User Management',
        subItems: [
          {
            itemId: NavigationItemId.UserManagement,
            title: 'Users',
            policies: ['Tenant_System_User_FullAccess', 'Tenant_System_User_ReadOnly'],
            area: ProductArea.UnifiedExperience,
            unifiedExperienceApplication: 'tenant-user-management'
          },
          {
            itemId: NavigationItemId.UserGroups,
            title: 'User Groups',
            policies: ['Tenant_System_UserGroup_FullAccess', 'Tenant_System_UserGroup_ReadOnly'],
            area: ProductArea.UnifiedExperience,
            unifiedExperienceApplication: 'tenant-user-group-management'
          },
          {
            itemId: NavigationItemId.FunctionalRoles,
            title: 'Functional Roles',
            policies: ['Tenant_System_FunctionRole_ReadOnly', 'Tenant_System_FunctionRole_FullAccess'],
            area: ProductArea.UnifiedExperience,
            unifiedExperienceApplication: 'tenant-functional-roles'
          }
        ]
      },
      {
        sectionHeader: 'Company Management',
        subItems: [
          {
            itemId: NavigationItemId.CompanyManagement,
            area: ProductArea.Global,
            title: 'Companies',
            policies: ['Tenant_System_Company_FullAccess', 'Tenant_System_Company_ReadOnly'],
            path: RouterConfig.routes.companiesManagement.companies
          }
        ]
      },
      {
        sectionHeader: 'Access Control',
        subItems: [
          {
            itemId: NavigationItemId.AccessCards,
            title: 'Access Cards',
            policies: ['Tenant_AccessCards_Management_FullAccess', 'Tenant_AccessCards_Management_ReadOnly'],
            area: ProductArea.Global,
            path: RouterConfig.routes.tenantAdmin.tenantAccessCards
          },
          {
            itemId: NavigationItemId.CardLayouts,
            title: 'Card Layouts',
            policies: ['Tenant_AccessCards_Layouts_FullAccess', 'Tenant_AccessCards_Layouts_ReadOnly'],
            area: ProductArea.Global,
            path: RouterConfig.routes.tenantAdmin.tenantAccessCardsLayouts
          },
          {
            itemId: NavigationItemId.PrintJobs,
            title: 'Print Jobs',
            policies: ['Tenant_AccessCards_PrintJobs_ReadOnly', 'Tenant_AccessCards_PrintJobs_FullAccess'],
            area: ProductArea.Global,
            path: RouterConfig.routes.tenantAdmin.printJobList
          }
        ]
      },
      {
        sectionHeader: 'Configurations',
        subItems: [
          {
            area: ProductArea.UnifiedExperience,
            unifiedExperienceApplication: 'tenant-control-room-contact',
            title: 'Control Room Contacts',
            isDivided: true,
            path: '',
            policies: ['Tenant_Core_ControlRoomContacts_ReadOnly', 'Tenant_Core_ControlRoomContacts_FullAccess'],
            itemId: NavigationItemId.ControlRoomContact
          },
          {
            area: ProductArea.UnifiedExperience,
            unifiedExperienceApplication: 'landing-page',
            title: 'Cross Tenant',
            path: '',
            // todo validate
            itemId: NavigationItemId.CrossTenant
          }
        ]
      }
    ]
  },
  {
    title: 'Help & Support',
    path: 'https://restrataone.zendesk.com/hc/en-gb',
    itemId: NavigationItemId.Support,
    area: ProductArea.ThirdParty,
    icon: <SupportIcon />
  }
];

export const baseUrlMap: Record<ProductArea, string> = {
  [ProductArea.OutSystems]: environment.outSystemsGlobalDashboardUrl,
  [ProductArea.UnifiedExperience]: environment.unifiedExperienceUrl,
  [ProductArea.Global]: '',
  [ProductArea.ThirdParty]: '',
  [ProductArea.OfflineProxy]: ''
};
