import { ActionIcon, Autocomplete, Box, Drawer, Flex, ScrollArea, Skeleton, Text } from "@mantine/core";
import { FC, useMemo } from 'react';

import { ReactComponent as More } from '@/common/icons/Actions/more-three-dots.svg';
import { ReactComponent as Notifications } from '@/common/icons/Actions/notification-bell.svg';
import Page, { Body, Header } from '@/tenant-context/common/components/Page';
import {
  useCountryRiskReportsPageStyles
} from '@/tenant-context/control-reports/components/CountryRiskReportsPage/CountryRiskReportsPage.styles';
import CountryRiskStatusCard from '@/tenant-context/control-reports/components/CountryRiskStatusCard';
import DetailedCountryReport from '@/tenant-context/control-reports/components/DetailedCountryReport';
import RiskProviderBadge from '@/tenant-context/control-reports/components/RiskProviderBadge';
import { CountryRiskData, CountryRiskProvider } from '@/tenant-context/control-reports/types/country-risk-reports';
import { uppercaseKebabToCapitalizedWords } from '@/tenant-context/control-reports/util/country-risk-reports';

type Props = {
  selectedRiskProvider?: CountryRiskProvider;
  onRiskProviderChange: (value: CountryRiskProvider) => void;
  onDrawerClose: () => void;
  countryRiskDataList: CountryRiskData[];
  selectedCountryRiskReport?: CountryRiskData;
  setSelectedCountryReport?: (countryRiskData: CountryRiskData) => void;
  isDrawerExpanded: boolean;
  toggleDrawerExpand: () => void;
  onSearchStringChange: (value: string) => void;
  allCountries: string[];
  currentTimestamp?: number;
  isCountrySummaryLoading?: boolean;
  countryRiskProvidersList?: CountryRiskProvider[];
}

const CountryRiskReportsPageComponent: FC<Props> = ({
  selectedRiskProvider,
  onRiskProviderChange,
  onDrawerClose,
  countryRiskDataList,
  selectedCountryRiskReport,
  setSelectedCountryReport,
  isDrawerExpanded,
  toggleDrawerExpand,
  onSearchStringChange,
  allCountries,
  currentTimestamp,
  isCountrySummaryLoading,
  countryRiskProvidersList
}) => {
  const { classes, cx } = useCountryRiskReportsPageStyles();
  const riskProviders = useMemo(() => {
    return countryRiskProvidersList?.map((provider) => ({
      label: uppercaseKebabToCapitalizedWords(provider.providerName),
      value: provider
    })) || [];
  }, [countryRiskProvidersList]);

  return <Page>
    <Header heading="Country Risk Reports" isBeta>
      <Flex gap="sm" hidden>
        <ActionIcon variant="outline" radius="xs" size="xl" className={ classes.headerIcons }><Notifications/></ActionIcon>
        <ActionIcon variant="outline" radius="xs" size="xl" className={ classes.headerIcons }><More/></ActionIcon>
      </Flex>
    </Header>
    <Body gap={ 40 }>
      <Flex gap={ 12 }>
        <Flex gap={ 12 }>
          { riskProviders.map((provider, index) => <RiskProviderBadge
            isSelected={ selectedRiskProvider?.providerName === provider.value.providerName }
            label={ provider.label }
            value={ provider.value }
            onClick={ onRiskProviderChange }
            key={ index }
          /> ) }
        </Flex>
        <Autocomplete
          data={ allCountries }
          placeholder="Search for country"
          className={ classes.searchBox }
          onChange={ onSearchStringChange }
        />
      </Flex>
      <ScrollArea>
        <Text typeof="h4" mb={ 40 } className={ classes.subHeading }>All Countries</Text>

        { !isCountrySummaryLoading && (countryRiskDataList.length === 0) ? (
          <Flex align="center" justify="center" className={ classes.noCountryRiskDataTextContainer }>
            <Text className={ classes.noCountryRiskDataText }>No Country Risk Reports Found</Text>
          </Flex>
        ): (
        
          <Box className={ classes.countryStatusCardGrid }>
            { isCountrySummaryLoading ? (
              Array.from({ length: 8 }).map((_, index) => (
                <Skeleton key={ index } height={ 360 } radius={ 24 } opacity={ 0.05 }/>
              ))
            ): countryRiskDataList.map(
              (countryRiskData) => (
                <CountryRiskStatusCard
                  key={ countryRiskData.tid + '/' + countryRiskData.countryName }
                  countryRiskData={ countryRiskData }
                  onClick={ setSelectedCountryReport }
                  currentTimestamp={ currentTimestamp }
                  providerName={ selectedRiskProvider?.providerName || '' }
                />
              )
            ) }
          </Box>
        
        ) }
      </ScrollArea>
    </Body>

    <Drawer
      opened={ !!selectedCountryRiskReport }
      onClose={ onDrawerClose }
      position="right"
      className={ cx({
        [classes.riskStatusDrawer]: true,
        [classes.riskStatusDrawerSide]: !isDrawerExpanded,
        [classes.riskStatusDrawerExpanded]: isDrawerExpanded
      }) }
      title={ 'Risk Status' }
      size={ isDrawerExpanded ? 'full' : undefined }
    >
      { selectedCountryRiskReport && <DetailedCountryReport
        countryRiskData={ selectedCountryRiskReport }
        onClose={ onDrawerClose }
        onExpand={ toggleDrawerExpand }
        isExpanded={ isDrawerExpanded }
      /> }
    </Drawer>
  </Page>;
};

export default CountryRiskReportsPageComponent;
