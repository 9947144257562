import { createStyles } from "@mantine/core";

import { CSS_KEY } from "@/core/styles/mantine/constants";
import { genericColorPalette, semanticColorPalette } from "@/core/styles/mantine/palettes";
import { getRiskColorByRiskLevel } from "@/tenant-context/control-reports/util/country-risk-reports";

export const useArcHeaderStyles = createStyles((t) => ({
  // Some styles are temporarily added until the header is implemented
  header: {
    backgroundColor: t.colors.neutral[9],
    height: 60,
    padding: '0px 24px'
  },
  buttons: {
    display: 'flex',
    '& > button:not(:first-child)': {
      marginLeft: 20
    }
  },

  createNotificationBtn:{
    fontSize:t.fontSizes.sm
  },

  arcTabs:{
    [`& .${CSS_KEY}-Tabs-tab`]:{
      '&[data-active]': {
        borderBottom: 0,
        backgroundColor: t.colors.neutral[9] + '!important',
        '&::before': {
          backgroundColor: t.colors.neutral[9],
          borderBottom: 0
        }
      }
    }
  },
  menuTitle: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '16px',
    backgroundColor: t.colors.royalBlue[0],
    minWidth: '120px',
    height: '50px',
    maxHeight: 48
  },

  menuDropdown: {
    backgroundColor: t.colors.neutral[9],
    minWidth: '250px',
    width: '100%',
    boxShadow: t.other.sdw.right,
    border: "none",
    padding: 0
  },

  menuControlItem: {
    backgroundColor: t.colors.neutral[9],
    padding: '16px',
    fontSize: t.fontSizes.md,
    border: `1px solid ${semanticColorPalette.border.general.lighter}`,
    marginTop: '2px'
  },

  rightContainer: {
    display: 'flex',
    flex: 1,
    borderBottom: `1px solid ${t.colors.neutral[6]}`,
    justifyContent: "flex-end",
    alignItems: 'flex-start'
  },

  headerContainer: {
    paddingTop: '24px',
    position: 'sticky',
    top: 0,
    zIndex: 3,
    backgroundColor: t.colors.neutral[9]
  },

  segmentedTabContainer: {
    height: '48px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'stretch',
    marginTop: '24px'
  },

  periodContainer: {
    display: 'flex',
    alignItems: 'center',
    fontSize: t.fontSizes.xs,
    gap: '6px'
  },

  riskWidgetContainer: {
    marginTop: '12px',
    backgroundColor: t.colors.royalBlue[3],
    borderRadius: '16px',
    display: 'flex',
    alignItems: 'center',
    padding: '8px 24px',
    gap: '48px',
    minHeight: '112px'
  },

  riskStatContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '4px',
    height: '80px',
    gap: '12px'
  },

  riskValue: {
    minHeight: '56px',
    minWidth: '56px',
    textAlign: 'center',
    fontWeight: t.other.fw.bold,
    fontSize: t.headings.sizes.h4.fontSize,
    color: t.white,
    borderRadius: '12px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: t.colors.alphaLightShades[2],
    padding: '8px'
  },

  totalRiskBG: {
    backgroundColor: t.colors.royalBlue[2]
  },

  totalPeopleTier: {
    color: t.colors.royalBlue[2],
    display: 'flex',
    div: {
      display: 'flex',
      flexDirection: 'column',
      gap: '4px',
      justifyContent: 'center'
    }
  },

  miniTextLabel: {
    fontSize: t.other.fs.xxs
  },

  otherRiskTier: {
    color: t.white
  },

  tierHeader: {
    fontWeight: t.other.fw.bold,
    fontSize: t.fontSizes.sm,
    display: 'flex',
    alignItems: 'center',
    svg: {
      marginRight: '4px'
    }
  },

  tierDescription: {
    fontSize: t.other.fs.xxs,
    opacity: '64%',
    marginTop: '4px'
  },

  mcWidgetContainer: {
    minHeight: '112px',
    borderRadius: '12px',
    backgroundColor: t.colors.neutral[8],
    padding: '16px 24px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '12px'
  },

  mcInnerContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2px'
  },

  mcWidgetHeader: {
    fontSize: t.headings.sizes.h4.fontSize,
    color: t.white,
    fontWeight: t.other.fw.bold
  },
  mcInnerText: {
    color: t.white,
    opacity: '64%',
    fontSize: t.fontSizes.xs,
    whiteSpace: 'pre-line',
    lineHeight: '15px'
  },

  pieChartContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '24px'
  },

  responseTypeContainer: {
    gap: '12px',
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end'
  },

  resItem: {
    padding: '6px 16px',
    display: 'flex',
    borderRadius: '8px',
    flexDirection: 'column',
    gap: '12px',
    justifyContent: 'space-between',
    height: '80px',
    maxWidth: '156px',
    flexGrow: 1
  },

  safetyStatus: {
    fontSize: t.fontSizes.sm,
    lineHeight: '21px',
    color: t.white,
    opacity: '64%'
  },

  safetyValue: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: t.headings.sizes.h1.fontSize,
    alignItems: 'center',
    height: '33px',
    fontWeight: t.other.fw.bold
  },

  itemRed: {
    border: `2px solid ${getRiskColorByRiskLevel('extreme')}`
  },
  itemGreen: {
    border: `2px solid ${genericColorPalette.success[2]}`
  },
  itemOrange: {
    border: `2px solid ${getRiskColorByRiskLevel('medium')}`
  },
  itemGrey: {
    border: `2px solid grey`
  }
}));

export const useArcSegmentedControlStyles = createStyles((theme) => ({
  root: {
    backgroundColor: theme.colors.royalBlue[3],
    padding: '8px'
  },
  active: {
    backgroundColor: theme.colors.neutral[8],
    color: theme.black
  },
  label: {
    backgroundColor: 'transparent',
    fontSize: theme.fontSizes.xs,
    fontWeight: theme.other.fw.bold,
    color: theme.white
  },
  labelActive: {
    border: `1px solid ${theme.colors.neutral[6]}`,
    backgroundColor: theme.colors.neutral[0],
    color: `${theme.black} !important`,
    fontWeight: theme.other.fw.bold,
    transition: 'none',
    '&[data-active]': {
      color: `${theme.black} !important`
    }
  }
}));
