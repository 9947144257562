import mapboxgl from "mapbox-gl";
import { createContext, FC, useCallback,useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import useContextValue from "@/common/hooks/useContextValue";
import usePermission from "@/common/hooks/usePermission";
import ensureDeserialized from "@/common/util/ensure-deserialized";
import { Dispatch, RootState } from "@/core/store";
import { ID_COMBINE_DELIMITER } from '@/tenant-context/common/config/consts.config';
import { BigMapPopupIds, useMapPopupList } from "@/tenant-context/common/hooks/useMapPopupList";
import { getClickedOnFeatures as getFeaturesInteractedWith } from "@/tenant-context/common/util/map-click";

import { RiskAlertPolicies } from "../configs/RiskAlert.policies";
import { RiskAlertEvent } from "../types/risk-alerts";

type MapboxHoverEvent = mapboxgl.MapMouseEvent
  & { features?: mapboxgl.MapboxGeoJSONFeature[] | undefined; }
  & mapboxgl.EventData;

export type RiskContextType = {
  showRiskAlertPopup: (evt: MapboxHoverEvent, layerIds: Array<string>) => void,
  showRiskDetails: (riskAlertEvent: RiskAlertEvent) => void,
  handleZoomTo: (riskEvent: RiskAlertEvent) => Promise<void>,
  handleRiskLocationClose(): void,
  enablePopup: (event: RiskAlertEvent, pointerX:number, pointerY:number) => void,
  calculatePopupOffset:() => void
};

export const RiskContext = createContext<RiskContextType>({} as RiskContextType);
export const RiskContextProvider: FC = ({
  children
}) => {
  const {
    riskAlerts: {
      subscribeToRiskAlerts,
      enableRiskAlertDrawer,
      disableRiskAlertDrawer
    },
    riskDetails:{
      TOGGLE_POPUP_LINE,
      getImpactDataForActiveRiskAlert
    }
  } = useDispatch<Dispatch>();

  const { openPopup } = useMapPopupList('bigMapPopups');

  const riskProviders = useSelector((state: RootState) => state.commonData.riskProviders);
  const currentlyOpenRightDrawerId = useSelector((state:RootState) => state.drawer.currentlyOpenRightDrawerId);
  const currentAlertId = useRef<string | null>(null);

  const isRiskAlertStreamPermissionAvailable = usePermission(RiskAlertPolicies.RISK_ALERT_POLICY);

  useEffect(() => {
    if (!riskProviders?.length || !isRiskAlertStreamPermissionAvailable) {
      return;
    }

    subscribeToRiskAlerts();
  }, [ isRiskAlertStreamPermissionAvailable, riskProviders, subscribeToRiskAlerts ]);

  /**
  const {
    isPopupShown: isRiskLocationPopupShown,
    setIsPopupShown: setIsRiskLocationPopupShown,
    popupCoordinates: riskLocationPopupCoordinates,
    setPopupCoordinates: setRiskLocationPopupCoordinates
  } = useMapPopup("Risk");
  */

  const enablePopup = useCallback(
    (event:RiskAlertEvent) => {
      openPopup({
        id: BigMapPopupIds.RiskAlert + event.json.alert.id,
        position: event.json.meta.geojson.geometry.coordinates,
        data: event
      });
    },
    [openPopup]
  );

  const showRiskAlertPopup = useCallback(
    (
      evt: mapboxgl.MapMouseEvent & { features?: mapboxgl.MapboxGeoJSONFeature[] | undefined; } & mapboxgl.EventData,
      layerIds: Array<string>
    ) => {
      if(currentlyOpenRightDrawerId === "risk-events") { // if right risk event panel already opened, should not show the popup
        disableRiskAlertDrawer();
      }

      // adding settimeout to disappear popup smoothly
      const { layerFeatures } = getFeaturesInteractedWith(
        evt,
        layerIds
      );

      if (!layerFeatures.length) {
        return;
      }

      const [hoveredLayerFeature] = layerFeatures;

      if (!hoveredLayerFeature) {
        return;
      }

      // consider only the risk alert layer features
      if (layerIds.includes(hoveredLayerFeature.layer.id)) {
        const { properties } = hoveredLayerFeature;

        const { json } = ensureDeserialized(
          hoveredLayerFeature.properties as RiskAlertEvent,
          ['json']
        );

        if(currentAlertId.current === json.alert.id){
          return;
        }

        currentAlertId.current = json.alert.id;
        const { meta } = ensureDeserialized(
          hoveredLayerFeature.properties as RiskAlertEvent,
          ['meta']
        );

        //trigger the logic to show up the
        enablePopup({ ...properties, meta, json  } as RiskAlertEvent);
      }
    },
    [currentlyOpenRightDrawerId, disableRiskAlertDrawer, enablePopup]
  );

  const handleRiskLocationClose = useCallback(
    () => {
      TOGGLE_POPUP_LINE(false);
      currentAlertId.current = null;
    },
    [TOGGLE_POPUP_LINE]
  );

  const showRiskDetails = useCallback(
    async (riskAlertEvent: RiskAlertEvent) => {
      if (!riskAlertEvent) {
        return;
      }
      const { json } = ensureDeserialized(
        riskAlertEvent,
        ["json"]
      );
      const { meta } = ensureDeserialized(
        riskAlertEvent,
        ["meta"]
      );
      const riskAlertProvider = riskAlertEvent.source;
      const idCombination = riskAlertEvent?.tid?.split(ID_COMBINE_DELIMITER);
      const impactAreaId = idCombination?.length > 1 ? idCombination[1] : undefined;
      await getImpactDataForActiveRiskAlert({
        alertId: `${riskAlertProvider}_${riskAlertEvent.json.alert.id}`,
        impactAreaId
      });
      enableRiskAlertDrawer({ ...riskAlertEvent, json, meta });
    },
    [enableRiskAlertDrawer, getImpactDataForActiveRiskAlert]
  );

  const handleZoomTo = useCallback(
    async (riskEvent: RiskAlertEvent) => {
      const riskAlertProvider = riskEvent.source;
      await getImpactDataForActiveRiskAlert({ alertId: `${riskAlertProvider}_${riskEvent.json.alert.id}` });
    },
    [getImpactDataForActiveRiskAlert]
  );

  /*  calculate the offset between drawer/popup and line-end dynamically. In order to persist the line ,
      when zooming or dragging the map, drawer's end line is drawn based on the browser's X, Y points */
  const calculatePopupOffset = useCallback(() => {
    // if( currentRiskAlert && isRiskImpactCircleEnabled){
    //   // relevent coordinates of X, Y points are mapped using unproject function
    //   const mappedLngLat = map?.unproject(new mapboxgl.Point(
    //     window.screen.width - RIGHT_PANEL_OFFSET_IN_PIXELS,
    //     window.screen.height/2 - RIGHT_PANEL_OFFSET_IN_PIXELS
    //   ));
    //   const popupPosition = getPopupOrDrawerPosition(
    //     map?.getBounds(),
    //     currentRiskAlert?.json.meta.geojson.geometry,
    //     mappedLngLat,
    //     true
    //   );

    //   // setting up the drawer / popup cordinates
    //   setRiskLocationPopupCoordinates({
    //     latitude: popupPosition[1],
    //     longitude:popupPosition[0]
    //   });
    // }
  },[]);

  return (
    <RiskContext.Provider value={ useContextValue({
      showRiskAlertPopup,
      showRiskDetails,
      handleRiskLocationClose,
      enablePopup,
      calculatePopupOffset,
      handleZoomTo
    }) } >
      { children }
    </RiskContext.Provider>
  );
};
