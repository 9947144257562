/* eslint-disable react/jsx-no-bind */
import { ActionIcon, Box, Divider, Flex } from "@mantine/core";
import { FC, MouseEventHandler } from "react";

import TextTruncate from '@/common/components/TextTruncate/TextTruncate.container';
import { ReactComponent as RightArrowIcon } from "@/common/icons/Arrows/right-arrow.svg";
import { ReactComponent as ChevronLeft } from "@/common/icons/chevron-left.svg";
import { ReactComponent as FactalIcon } from "@/common/icons/RiskProviders/factal.svg";
import { getTimeDurationFromCurrentDate } from '@/common/util/format/date';
import CountryFlag from "@/tenant-context/common/components/CountryFlag/CountryFlag.component";
import LocalErrorBoundary from '@/tenant-context/common/components/LocalErrorBoundary';
import NumberHighlightedTextComponent from '@/tenant-context/common/components/NumberHighlightedText';
import { RiskAlertDetailsStyles } from "@/tenant-context/control-risk-config/components/RiskAlertDetails/RiskAlertDetails.styles";
import RiskAlertBasicData from "@/tenant-context/visualisation-risk-alerts/components/RiskAlertBasicData";
import { RiskAlertEvent } from "@/tenant-context/visualisation-risk-alerts/types/risk-alerts";
import { RiskConnectorExternalData } from '@/tenant-context/visualisation-risk-alerts/types/risk-alerts-generic';

type Props = {
  riskAlert: RiskAlertEvent<RiskConnectorExternalData>,
  onRequestToToggleArc: MouseEventHandler<HTMLButtonElement>,
  onRequestToCloseComms: MouseEventHandler<HTMLButtonElement>,
  isArcOpen: boolean;
  isOpenedInArc?: boolean;
  isAnotherOverlayModuleOpen: boolean;
}

const HealixRiskAlertDetails: FC<Props> = ({
  riskAlert,
  onRequestToToggleArc,
  onRequestToCloseComms,
  isArcOpen,
  isOpenedInArc = false,
  isAnotherOverlayModuleOpen
}) => {
  const { classes  } = RiskAlertDetailsStyles({ isInArc: isOpenedInArc, isOverlayOpen: isAnotherOverlayModuleOpen });

  return (
    <LocalErrorBoundary>
      <div className={ classes.container }>
        <div>
          { (isArcOpen || isAnotherOverlayModuleOpen) && <Flex justify={ 'space-between' } mb={ 'md' }>
            { (isArcOpen || isAnotherOverlayModuleOpen) && (
              <div>
                <Flex gap={ 2 } align={ 'flex-end' }>
                  <NumberHighlightedTextComponent
                    numberFw={ 'bold' }
                    numberFs={ 'sm' }
                    alphaFs={ 12 }
                    text={ riskAlert?.json.alert.start_date !== undefined
                      ? `${getTimeDurationFromCurrentDate(new Date(riskAlert?.json.alert.start_date))} AGO`
                      : '' }
                  />
                </Flex>
              </div>
            ) }

            { isArcOpen && (
              <div>
                <ActionIcon size={ 20 } onClick={ onRequestToToggleArc } variant={ 'subtle' }>
                  <RightArrowIcon />
                </ActionIcon>
              </div>
            ) }

            { isAnotherOverlayModuleOpen && (
              <div className={ classes.innerArrow }>
                <ActionIcon size={ 20 } onClick={ onRequestToCloseComms }>
                  <ChevronLeft />
                </ActionIcon>
              </div>
            ) }
          </Flex> }

          <Flex direction={ 'column' } gap={ 32 } mb={ 48 }>
            <Flex direction={ 'column' } gap={ 8 }>
              <div className={ classes.popupHeader }>
                { riskAlert?.json.alert.countries && (
                  <Flex gap="sm" className={ classes.flagContainer }>
                    { riskAlert?.json.alert.countries.map((country, index) => (
                      (country.iso_code && <Flex key={ country.iso_code + index } justify={ 'center' } align={ 'center' } h={ 48 } w={ 48 } className={ classes.flag }>
                        <Box>
                          <CountryFlag
                            width={ 80 }
                            height={ 80 }
                            countryCode={ country.iso_code }
                          />
                        </Box>
                      </Flex> ))) }
                  </Flex>
                ) }
              </div>

              <div className={ classes.countryInfo }>
                { riskAlert?.json.alert.location_explanation }
              </div>

              <div className={ classes.riskAlertTitle }>
                { riskAlert?.json.alert.title }
              </div>
            </Flex>

            <Box>
              <RiskAlertBasicData riskAlertData={ riskAlert } isOnSideDrawer/>
            </Box>
          </Flex>

          <div className={ classes.sectionDetailsHeader }>
            DETAILS
          </div>

          <Divider
            className={ classes.sectionHeaderDivider }
          />

          <div className={ classes.riskDescription }>
            <TextTruncate
              text={ riskAlert?.json.alert.text }
              maxTextLength={ window.innerHeight <= 800 ? 100 : 300 }
            />
          </div>

          <Divider
            className={ classes.divider }
          />

          <Flex gap={ 'xs' } align={ 'center' } justify={ 'flex-start' } mt={ 4 }>
            <div className={ classes.issuedBy }>Issued by</div>

            <FactalIcon className={ classes.riskAlerProviderIcon } />

            <div className={ classes.riskAlertProviderName }> Factal </div>
          </Flex>
        </div>

        <div>

        </div>
      </div>
    </LocalErrorBoundary>
  );
};

export default HealixRiskAlertDetails;
