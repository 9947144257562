import { MapboxMapController as AerisMapboxController } from "@aerisweather/mapsgl";
import { createContext, FC, useCallback, useContext, useEffect, useRef, useState } from "react";
import { useMap } from "react-map-gl";

import useContextValue from "@/common/hooks/useContextValue";
import { GLOBAL_LEVEL_ZOOM } from "@/tenant-context/common/util/constants";

export type BigMapContextType = {
  isBigMapZoomedIn: boolean,
  bigMapZoomLevel:number,
  customIcons: React.MutableRefObject<CustomIcon[]>
  refreshImages: () => void
  weatherController?: AerisMapboxController
  setWeatherController: (controller: AerisMapboxController | undefined) => void
};

type CustomIcon = {
  name: string,
  icon: HTMLImageElement | ImageData | ImageBitmap
}

export const BigMapContext = createContext<BigMapContextType>({} as BigMapContextType);

export const useBigMapContext = () => useContext(BigMapContext);

export const BigMapProvider: FC = ({
  children
}) => {
  const { AssetLocationSnapshotMap: map } = useMap();

  const [isZoomedIn, setIsZoomedIn] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(0);
  const [weatherController, setWeatherController] = useState<AerisMapboxController>();
  const customIcons = useRef<CustomIcon[]>([]);

  const isLoading = useRef<boolean>(false);

  const refreshImages = useCallback(() => {
    if (isLoading.current || !map) {
      return;
    }

    // Ensures that when the style is loaded disabled toggles are applied
    map.once('styledata', () => {

      // On style change, update custom icons in the map since whenever the map style changes, mapbox removes
      // all custom icons from the map, and we need to add them again.
      customIcons.current.forEach((customIcon) => {
        const { name, icon } = customIcon;
        if (map.hasImage(name)) {
          map.updateImage(name, icon);
        } else {
          map.addImage(name, icon);
        }
      });

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      map.off('styledata', () => {});
    });
  }, [map]);

  // Set is map zoomed in
  useEffect(() => {
    if (!map) {
      return;
    }

    const handleMapZoom = () => {
      setIsZoomedIn(
        map.getZoom() > GLOBAL_LEVEL_ZOOM
      );
      setZoomLevel(map.getZoom());
    };

    map.on(
      'zoom',
      handleMapZoom
    );

    return () => {
      map.off(
        'zoom',
        handleMapZoom
      );
    };
  }, [map]);

  return (
    <BigMapContext.Provider value={ useContextValue({
      isBigMapZoomedIn: isZoomedIn,
      bigMapZoomLevel:zoomLevel,
      customIcons: customIcons,
      refreshImages,
      weatherController,
      setWeatherController
    }) }>
      { children }
    </BigMapContext.Provider>
  );
};
