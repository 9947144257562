import { FC, useMemo } from 'react';
import { Layer } from "react-map-gl";

import FilterableSource from "@/common/components/FilterableSource";
import { ToggleableLayerType } from "@/common/components/ToggleableLayer/ToggleableLayer.config";
import DynamicallyVisibleLayer
  from "@/tenant-context/common/components/DynamicallyVisibleLayer";
import { RiskProviders } from '@/tenant-context/common/types/risk';
import {
  BSOC_LAYERS,
  DATAMINR_LAYERS,
  FACTAL_LAYERS,
  HEALIX_LAYERS,
  MAX_SECURITY_LAYERS,
  OTHER_LAYERS,
  RISKLINE_LAYERS } from '@/tenant-context/visualisation-risk-alerts/configs/risk-alert-layer.config';
import { BSOCExternalData, Sigact } from "@/tenant-context/visualisation-risk-alerts/types/risk-alert-response";
import { RiskAlertEvent, RiskAlertFeatureCollection } from "@/tenant-context/visualisation-risk-alerts/types/risk-alerts";
import { RiskConnectorExternalData } from '@/tenant-context/visualisation-risk-alerts/types/risk-alerts-generic';

type Props = {
  riskAlertsGeoData: RiskAlertFeatureCollection,
  disabledRiskFilters:string [],
  riskLevelRisklineFilters: number[],
  riskLevelDataminrFilters: number[],
  isShowOnlyImpactedRiskEvents:boolean,
  hoveredRiskLocation?:RiskAlertEvent,
  selectedRiskEventLocation?:RiskAlertEvent<RiskConnectorExternalData>,
  isRiskImpactCircleEnabled:boolean,
  BSOCRiskLevelFilter: 'true' | 'false' | 'true false' | '',
  dataMinrWatchlist: Array<string>,
  disabledRiskProviders: RiskProviders[],
  riskLevelHealixFilters: number[],
  riskLevelFactalFilters: number[]
};

const riskCategories = [
  {
    Icon: 'Political',
    categoryId:'1'
  },
  {
    Icon: 'Conflict',
    categoryId:'2'
  },
  {
    Icon: 'ProtestsAndGatherings',
    categoryId:'3'
  },
  {
    Icon: 'Crime',
    categoryId:'4'
  },
  {
    Icon: 'Natural',
    categoryId:'5'
  },
  {
    Icon: 'Medical',
    categoryId:'6'
  },
  {
    Icon: 'TravelSafety',
    categoryId:'7'
  }
];

const bsocRiskCategories = [
  {
    Icon: 'Crime',
    categoryId: Sigact.Crime
  },
  {
    Icon: 'SAF',
    categoryId: Sigact.SAF
  },
  {
    Icon: 'IED',
    categoryId: Sigact.IED
  },
  {
    Icon: 'IncendiaryDevices',
    categoryId: Sigact.HighImpactExplosiveActivity
  },
  {
    Icon: 'IDFUAVActivity',
    categoryId: Sigact.IDFUAVActivity
  },
  {
    Icon: 'SecurityOperations',
    categoryId: Sigact.SecurityOperations
  },
  {
    Icon: 'ProtestsAndGatherings',
    categoryId: Sigact.ProtestsAndGatherings
  }
];

const IMPACT_ALERT_OFFSET_Y = -4;

const RiskAlertLayer: FC<Props> = ({
  riskAlertsGeoData,
  disabledRiskFilters,
  riskLevelRisklineFilters,
  riskLevelDataminrFilters,
  isShowOnlyImpactedRiskEvents,
  hoveredRiskLocation,
  selectedRiskEventLocation,
  BSOCRiskLevelFilter,
  dataMinrWatchlist,
  disabledRiskProviders,
  riskLevelHealixFilters,
  riskLevelFactalFilters
}) => {

  const getRiskIcon = (
    riskAlertEvent: RiskAlertEvent<RiskConnectorExternalData> | undefined,
    source: 'bsoc' | 'dataminr' | 'max-security' | 'healix' | 'riskline' | 'factal' = 'riskline'
  ) => {
    if (source === 'bsoc') {
      return bsocRiskCategories
        .find(obj => obj.categoryId === (riskAlertEvent?.json.externalData as BSOCExternalData)?.sigact);
    } else if (source === 'riskline') {
      return riskCategories.find(obj => obj.categoryId === riskAlertEvent?.json.alert.category.id);
    } else if (source === 'dataminr') {
      return { Icon: 'DataMinrBase' };
    } else if (source === 'max-security') {
      return { Icon: 'TriangleBase' };
    } else if (source === 'healix') {
      return { Icon: 'TriangleBase' };
    } else if (source === 'factal') {
      return { Icon: 'TriangleBase' };
    } else {
      return null;
    }
  };

  const icon = getRiskIcon(hoveredRiskLocation, hoveredRiskLocation?.source);
  const iconName = `RiskAlertIcon_${hoveredRiskLocation?.json.alert.risk_level.name}${icon?.Icon}`;
  const iconSelectedEvent = getRiskIcon(selectedRiskEventLocation, selectedRiskEventLocation?.source);
  const iconNameSelectedEvent = `RiskAlertIcon_${selectedRiskEventLocation?.json.alert.risk_level.name}${iconSelectedEvent?.Icon}`;

  const selectedEventMaxSecurityIcon = useMemo(() => (
    `RiskAlertIcon_MaxSecurity_${selectedRiskEventLocation?.json.alert.category.id}` +
    `${selectedRiskEventLocation?.json.alert.risk_level.name === 'Extreme' ? '_White' : ''}`
  ), [selectedRiskEventLocation]);

  const hoveredEventMaxSecurityIcon = useMemo(() => (
    `RiskAlertIcon_MaxSecurity_${hoveredRiskLocation?.json.alert.category.id}` +
    `${hoveredRiskLocation?.json.alert.risk_level.name === 'Extreme' ? '_White' : ''}`
  ), [hoveredRiskLocation]);

  const {
    risklineLevelsFilter,
    dataminrLevelsFilter,
    healixLevelsFilter,
    factalLevelsFilter
  } = useMemo(() => ({
    risklineLevelsFilter: riskLevelRisklineFilters.map(String),
    dataminrLevelsFilter: riskLevelDataminrFilters.map(String),
    healixLevelsFilter: riskLevelHealixFilters.map(String),
    factalLevelsFilter: riskLevelFactalFilters.map(String)
  }), [riskLevelDataminrFilters, riskLevelRisklineFilters, riskLevelHealixFilters, riskLevelFactalFilters]);

  const riskAlertLayersProps = useMemo<typeof BSOC_LAYERS>(() => ([
    ...BSOC_LAYERS,
    ...RISKLINE_LAYERS,
    ...MAX_SECURITY_LAYERS,
    ...HEALIX_LAYERS,
    ...FACTAL_LAYERS,
    ...DATAMINR_LAYERS(dataMinrWatchlist),
    ...OTHER_LAYERS
  ]), [dataMinrWatchlist]);

  // Make sure if you add any new filters or update anything - you need to update the same in the
  // `risk-timeline.utils.ts` file's `filterRiskEventsForRiskTimeline` function too.
  return (
    <>
      <FilterableSource
        id="r__risk-alert-source"
        type="geojson"
        tolerance={ 0 }
        cluster={ false }
        data={ riskAlertsGeoData }
        filter={ [
          'all',
          [
            'case',
            // CASE - if showOnlyImpactedRiskEvents is true
            [ 'boolean', ['literal', isShowOnlyImpactedRiskEvents], true ],
            // then filter risk events which has isImpacted flag set to true
            [ '==', ['get', 'isImpacted'], true ],
            // else, get all (impacted and non-impacted) risk events
            true
          ],
          // Filter by disabled risk providers
          ['!', ['in', ['get', 'source'], ['literal', disabledRiskProviders]]]
        ] }
      >

        { riskAlertLayersProps.map((layer) => (
          <DynamicallyVisibleLayer
            toggleableLayerType={ ToggleableLayerType.RiskAlerts }
            id={ layer.id }
            key={ layer.id }
            type="symbol"
            filter={ [
              'all',
              // Filter hovered alert'
              ['!=',
                ['get', 'id', ['get', 'alert', ['get', 'json']]],
                (hoveredRiskLocation?.isImpacted &&  hoveredRiskLocation?.json.alert.id) ? hoveredRiskLocation?.json.alert.id : ""
              ],

              // Risk level filters
              ['any',
                ['all',
                  [
                    'in',
                    // Risk level ID
                    ['get', 'id', ['get', 'risk_level', ['get', 'alert', ['get', 'json']]]],
                    ['literal', dataminrLevelsFilter]
                  ],
                  ['==', ['get', 'source'], 'dataminr' ]
                ],

                ['all',
                  [
                    'in',
                    // Risk level ID
                    ['get', 'id', ['get', 'risk_level', ['get', 'alert', ['get', 'json']]]],
                    ['literal', risklineLevelsFilter]
                  ],
                  ['==', ['get', 'source'], 'riskline' ]
                ],

                ['all',
                  [
                    'in',
                    // Risk level ID
                    ['get', 'id', ['get', 'risk_level', ['get', 'alert', ['get', 'json']]]],
                    ['literal', healixLevelsFilter]
                  ],
                  ['==', ['get', 'source'], 'healix' ]
                ],

                // Filter by risk criticality for BSOC alerts.
                ['all',
                  [
                    'in',
                    ['get', 'critical_incident', ['get', 'externalData', ['get', 'json']]],
                    BSOCRiskLevelFilter
                  ],
                  ['==', ['get', 'source'], 'bsoc' ]
                ],

                ['==', ['get', 'source'], 'max-security'],

                ['all',
                  [
                    'in',
                    // Risk level ID
                    ['get', 'id', ['get', 'risk_level', ['get', 'alert', ['get', 'json']]]],
                    ['literal', factalLevelsFilter]
                  ],
                  ['==', ['get', 'source'], 'factal' ]
                ]
              ],

              // Filter by categories
              ['!', ['in',
              // Alert category ID
                ['get', 'id', ['get', 'category', ['get', 'alert', ['get', 'json']]]],
                // Disabled category IDs
                ["literal", disabledRiskFilters]
              ]],

              ...layer.filter
            ] }
            layout={ {
              ...layer.layout,
              'icon-allow-overlap': true
            } }
          />
        )) }

        { /* Impacted warning icon (!) */ }
        <DynamicallyVisibleLayer
          toggleableLayerType={ ToggleableLayerType.RiskAlerts }
          id='r__risk-impacthovericons-impacted-warning'
          type='symbol'
          layout={ {
            'icon-image': 'RiskAlertIcon_RiskAlertImpacted',
            'icon-size': 0.3,
            'icon-allow-overlap': true,
            // eslint-disable-next-line no-magic-numbers
            'icon-offset':[35, -35]
          } }
          filter={ [
            'all',
            ['==', ['get', 'isImpacted'], true ],
            ['==',
              ['get', 'id', ['get', 'alert', ['get', 'json']]],
              hoveredRiskLocation?.json.alert.id ?? ""
            ]
          ] }
        />

        { /* Impacted icon enlarged for hover */ }
        {  hoveredRiskLocation && hoveredRiskLocation.json.alert.id && hoveredRiskLocation.isImpacted && (
          <Layer
            id='r__risk-impacthovericons'
            beforeId="r__risk-impacthovericons-impacted-warning"
            type='symbol'
            layout={ {
              'icon-image': iconName,
              'icon-size': 0.35,
              'icon-allow-overlap': true
            } }
            filter={ [
              'all',
              [ '==', ['get', 'isImpacted'], true ],
              ['==',
                ['get', 'id', ['get', 'alert', ['get', 'json']]],
                hoveredRiskLocation?.json.alert.id ?? ""
              ]] } />
        ) }
        { /* Impacted icon enlarged for hover - Max Security category icon */ }
        {  hoveredRiskLocation && hoveredRiskLocation.source === 'max-security' &&
          hoveredRiskLocation.json.alert.id && hoveredRiskLocation.isImpacted && (
          <Layer
            id='r__risk-impacthovericons-max-security-category'
            beforeId='r__risk-impacthovericons'
            type='symbol'
            layout={ {
              'icon-image': hoveredEventMaxSecurityIcon,
              'icon-size': 0.35,
              'icon-allow-overlap': true
            } }
            filter={ [
              'all',
              [ '==', ['get', 'isImpacted'], true ],
              ['==',
                ['get', 'id', ['get', 'alert', ['get', 'json']]],
                hoveredRiskLocation?.json.alert.id ?? ""
              ]] } />
        ) }

        { /* Icon enlarged for selected event */ }
        { /* Icon enlarged for selected event - Event impacted icon */ }
        {  selectedRiskEventLocation && selectedRiskEventLocation.json.alert.id &&
          selectedRiskEventLocation.isImpacted && (
          <Layer
            id='r__risk-current-selected-risk-event-impacted-icon'
            type='symbol'
            layout={ {
              'icon-image': 'RiskAlertIcon_RiskAlertImpacted',
              'icon-size': 0.4,
              'icon-offset':[25, -45]
            } }
            filter={ [
              'all',
              ['==', ['get', 'isImpacted'], true],
              ['==',
                ['get', 'id', ['get', 'alert', ['get', 'json']]],
                selectedRiskEventLocation?.json.alert.id ?? ""
              ]] } />
        ) }
        { /* Icon enlarged for selected event - Max security category */ }
        {  selectedRiskEventLocation && selectedRiskEventLocation.source === 'max-security'
          && selectedRiskEventLocation.json.alert.id && (
          <Layer
            id='r__risk-current-selected-risk-event-max-security-category'
            beforeId="r__risk-impacthovericons-impacted-warning"
            type='symbol'
            layout={ {
              'icon-image': selectedEventMaxSecurityIcon,
              'icon-size': 0.45,
              'icon-allow-overlap': true
            } }
            filter={ [
              'all',
              ['==',
                ['get', 'id', ['get', 'alert', ['get', 'json']]],
                selectedRiskEventLocation?.json.alert.id ?? ""
              ]] } />
        ) }
        { /* Icon enlarged for selected event - Base layer */ }
        {  selectedRiskEventLocation && selectedRiskEventLocation.json.alert.id && (
          <Layer
            id='r__risk-current-selected-risk-event'
            beforeId={ selectedRiskEventLocation.source === 'max-security' ?
              'r__risk-current-selected-risk-event-max-security-category':
              'r__risk-current-selected-risk-event-impacted-icon' }
            type='symbol'
            layout={ {
              'icon-image': iconNameSelectedEvent,
              'icon-size': 0.4,
              'icon-allow-overlap': true
            } }
            filter={ [
              'all',
              ['==',
                ['get', 'id', ['get', 'alert', ['get', 'json']]],
                selectedRiskEventLocation?.json.alert.id ?? ""
              ]] } />
        ) }

        { /* Outline for hovered icon */ }
        {  hoveredRiskLocation && hoveredRiskLocation.json.alert.id && hoveredRiskLocation.isImpacted && (
          <Layer
            id='r__risk-impacthoverOutlineicons'
            type='symbol'
            beforeId="r__risk-impacthovericons-impacted-warning"
            layout={ {
              'icon-image': 'RiskAlertIcon_RiskAlertImpactedOutline',
              'icon-size': 0.9,
              'icon-allow-overlap': true,
              'icon-offset':[0, IMPACT_ALERT_OFFSET_Y]
            } }
            filter={ [
              'all',
              [ '==', ['get', 'isImpacted'], true ],
              ['==',
                ['get', 'id', ['get', 'alert', ['get', 'json']]],
                hoveredRiskLocation?.json.alert.id ?? ""
              ]] } />
        ) }

      </FilterableSource>

      { /* { ((hoveredRiskLocation && hoveredRiskLocation.json.alert.id) || isRiskImpactCircleEnabled) && lineToPopup && (
        <PopupLine
          lineToPopup={ lineToPopup }
        />
      ) } */ }
    </>
  );
};

export default RiskAlertLayer;
