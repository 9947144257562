import { Box, Group, Stack, Text, useMantineTheme } from "@mantine/core";
import { FC } from "react";

import { Popup } from "@/common/components/Popup/Popup.components";
import { ReactComponent as WarningIcon } from "@/common/icons/warning-triangle-light.svg";
import { toTitleCase } from "@/common/util/format/string";
import { WeatherPopupData } from "@/tenant-context/visualisation-weather";
import { useWeatherLayerPopupStyles } from "@/tenant-context/visualisation-weather/components/WeatherLayerPopup.styles";

type Props = {
  longitude: number;
  latitude: number;
  data: WeatherPopupData;
  onClose: () => void;
}

export const WeatherLayerPopup: FC<Props> = ({
  longitude,
  latitude,
  data,
  onClose
}) => {
  const theme = useMantineTheme();
  const { classes } = useWeatherLayerPopupStyles();

  return (
    <Popup
      longitude={ longitude }
      latitude={ latitude }
      onClose={ onClose }
      backgroundColor={ theme.colors.royalBlue[3] }
      padding={ 'md' }
      popUpStyles={ { border: 'none' } }
      closeButton
    >
      <Box className={ classes.wrapper }>
        <Group spacing={ 6 } align={ 'center' } mb={ 12 } fw={ 700 }>
          <WarningIcon/>
          <Text className={ classes.title } size={ 16 }>Alert</Text>
        </Group>

        <Stack className={ classes.body } spacing={ 2 }>
          { data.alerts?.map((val) => (
            <Text key={ val.ADVISORY + val.VTEC + val.ZONE } className={ classes.bodyText } size={ 12 }>
              { toTitleCase(val.ADVISORY) }
            </Text>
          )) }
        </Stack>
      </Box>
    </Popup>
  );
};
